<template>
  <div class="home">
    <HeaderView title="服务商入驻申请"/>
    <div class="banner2">
      <div class='login-container'>
        <div class='login-form'>
          <h2>服务商入驻申请</h2>
          <el-form
              ref="registerRef" :model="registerForm" :rules="registerRules"
              size="default"
              class="el-form"
              label-position='right'
              label-width="140px"
              status-icon
          >
            <el-form-item label="服务商全称：" prop="wholeName">
              <el-input v-model="registerForm.wholeName"
                        type="text"
                        auto-complete="off"
                        placeholder="请输入服务商全称">
              </el-input>
            </el-form-item>
            <el-form-item label="单位地址：" >
              <el-input v-model="registerForm.address"
                        type="text"
                        auto-complete="off"
                        placeholder="请输入单位地址">
              </el-input>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="单位负责人：" prop="leader">
                  <el-input v-model="registerForm.leader"
                            type="text"
                            auto-complete="off"
                            placeholder="请输入单位负责人">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话：">
                  <el-input v-model="registerForm.leaderPhone"
                            type="text"
                            auto-complete="off"
                            placeholder="请输入联系电话">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="业务负责人：" prop="businessOwner">
                  <el-input v-model="registerForm.businessOwner"
                            type="text"
                            auto-complete="off"
                            placeholder="请输入业务负责人">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="联系电话：">
                  <el-input v-model="registerForm.bussinessPhone"
                            type="text"
                            auto-complete="off"
                            placeholder="请输入联系电话">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="服务商简介：" prop="introduction">
              <el-input v-model="registerForm.introduction"
                        type="textarea"
                        :rows="3"
                        auto-complete="off"
                        placeholder="请输入服务商简介">
              </el-input>
            </el-form-item>
            <el-form-item label="服务项简介：" prop="serviceIntroduction">
              <Editor v-model:content="registerForm.serviceIntroduction"/>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="收费标准：" prop="feeStandard">
                  <el-input v-model="registerForm.feeStandard"
                            type="text"
                            auto-complete="off"
                            placeholder="请输入收费标准">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="收费模式：" prop="feeMode">
                  <el-input v-model="registerForm.feeMode"
                            type="text"
                            auto-complete="off"
                            placeholder="请输入收费模式">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="服务项周期：">
                  <el-input v-model="registerForm.serviceCycle"
                            type="text"
                            auto-complete="off"
                            placeholder="请输入服务项周期">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="合作模式：">
                  <el-input v-model="registerForm.coopMode"
                            type="text"
                            auto-complete="off"
                            placeholder="请输入合作模式">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="其他需说明内容：">
              <el-input v-model="registerForm.other"
                        type="textarea"
                        :rows="3"
                        auto-complete="off"
                        placeholder="请输入其他需说明内容">
              </el-input>
            </el-form-item>
            <p>
              <el-checkbox class='agreement' label="" name="type" v-model="isAgreement">
                <span>我已阅读并同意<router-link target="_blank"
                    to="/single-page-service-provider">《服务商入驻政策》</router-link></span>
              </el-checkbox>
              <span class="tips" v-show="showAgreement&&!isAgreement">请勾选已阅读并同意</span>
            </p>
            <el-button
                :loading="loading"
                size="large"
                class='submit'
                @click.prevent="handleRegister"
            >
              <span v-if="!loading">申  请</span>
              <span v-else>申  请...</span>
            </el-button>
          </el-form>

        </div>
      </div>
    </div>

    <BottomView/>
  </div>
</template>
<script>
export default {
  name: "ServiceProviderRegistration"
}
</script>
<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import {getCurrentInstance, onMounted, ref} from "vue";

import {ElMessageBox} from "element-plus";
import router from "@/router";
import {serviceProviderRegistration} from "@/api/service";
import Editor from "@/components/Editor/index.vue"

const {proxy} = getCurrentInstance();
const loading = ref(false)
const isAgreement = ref(false)
const showAgreement = ref(false)
const registerForm = ref({
  wholeName: "",
  address: "",
  leader: "",
  leaderPhone: "",
  businessOwner: "",
  bussinessPhone: "",
  introduction: "",
  serviceIntroduction: "",
  feeStandard: "",
  feeMode: "",
  serviceCycle: "",
  coopMode: "",
  other: "",
})
const registerRules = {
  wholeName: [
    {required: true, trigger: "blur", message: "请输入服务商全称"},
  ],
  leader: [
    {required: true, trigger: "blur", message: "请输入单位负责人"},
  ],
  businessOwner: [
    {required: true, trigger: "blur", message: "请输入业务负责人"},
  ],
  introduction: [
    {required: true, trigger: "blur", message: "请输入服务商简介"},
  ],
  serviceIntroduction: [
    {required: true, trigger: "blur", message: "请输入服务项简介"},
  ],
  feeStandard: [
    {required: true, trigger: "blur", message: "请输入收费标准"},
  ],
  feeMode: [
    {required: true, trigger: "blur", message: "请输入收费模式"},
  ]
};

function handleRegister() {
  if (!isAgreement.value) {
    showAgreement.value = true
    return
  }

  proxy.$refs.registerRef.validate(valid => {
    if (valid) {
      loading.value = true;
      serviceProviderRegistration(registerForm.value).then(res => {
        ElMessageBox.alert("申请成功！", "系统提示", {
          dangerouslyUseHTMLString: true,
          type: "success",
        }).then(() => {
          router.push("/");
        }).catch(() => {
        });
      }).catch((res) => {
        loading.value = false;
      });
    }
  });
}

onMounted(() => {
  //document.title = "企业综合服务系统"
})

</script>

<style lang="scss" scoped>
//.banner2 {
//  min-height: 770px;
//  width: 100%;
//  position:relative;
//  text-align:center;
//  color:white;
//}
//.banner2::before{
//  /* 设置背景模糊 */
//  content:'';
//  position:absolute;
//  top:0;
//  left:0;
//  min-height: 100vh;
//  width: 100%;
//  background: url("@/assets/banner3.png") center center;
//  filter:blur(5px);
//  z-index:-1;
//  background-size:cover;
//}

.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .login-form {
    width: 770px;
    min-height: 580px;
    line-height: 20px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 100);
    text-align: center;
    margin: 24px 0;
    padding: 0 24px;

    h2 {
      margin: 0;
      font-size: 28px;
      color: #BDA26D;
      font-weight: 400;
      text-align: left;
      padding: 30px 12px;
    }


    :deep(.el-input__wrapper) {
      height: 36px;
    }

    .el-input__icon {
      font-size: 18px;
    }

    .submit {
      width: 50%;
      height: 48px;
      line-height: 20px;
      border-radius: 5px;
      background: linear-gradient(264.2deg, rgba(185, 145, 107, 1) 1.7%, rgba(228, 200, 149, 1) 93.74%);
      color: rgba(255, 255, 255, 100);
      font-size: 18px;
      text-align: center;
      margin: 32px 0;
      border: none;
    }

    .tips {
      width: 100%;
      display: inline-block;
      font-size: 12px;
      color: #f56c6c;
      text-align: left;
    }


    p {
      text-align: left;
      margin: 0;
      color: #101010;
      font-size: 12px;
      line-height: 20px;

      .agreement {
        vertical-align: middle;
        margin-right: 5px;
      }

      span {
        display: inline-block;
        font-size: 12px;
      }

      a {
        color: #409eff;
        text-decoration: none;
      }
    }
  }
}
</style>
