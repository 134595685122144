<template>
  <div class="common-layout fixed">
    <el-container>
      <HeaderView title="用户中心-我的申请"/>
      <el-main>
        <div class="mobile-tabs">
          <ul>
            <li v-on:click='$router.push({path:"/mobile/account"})'>企业信息</li>
            <li v-on:click='$router.push({path:"/mobile/account/applied"})' class='active'>我的申请</li>
            <li v-on:click='$router.push({path:"/mobile/account/password"})'>修改密码</li>
          </ul>
        </div>
        <div class="mobile-container">
          <el-table v-loading="loading"
                    :data="tableData"
                    :row-class-name="rowAppliedIndex"
                    border
                    stripe
                    style="width: 100%; margin-bottom: 30px">
            <el-table-column prop="index" label="序号" align="center" width="50" />
            <el-table-column prop="serviceItem" label="服务项目" align="center"/>
            <el-table-column prop="applyTime" label="申请时间" align="center" width="90">
              <template v-slot="scope">
                <span>{{ parseTime(scope.row.applyTime, '{y}-{m}-{d} {h}:{i}') }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="statusText" label="服务进度" align="center" width="70">
              <template v-slot="scope">
                <div v-if="scope.row.status === 1">待审核</div>
                <div v-else-if="scope.row.status === 2">已审核</div>
                <div v-else-if="scope.row.status === 3">待拜访</div>
                <div v-else-if="scope.row.status === 4">已拜访</div>
              </template>
            </el-table-column>
            <el-table-column label="申请表" align="center" width="60">
              <template #default>
                <el-button link type="primary" size="small" @click="handleClick">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </el-main>
      <el-footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script setup>
import HeaderView from '@/components/mobile/Header'
import {onMounted, reactive, ref} from 'vue'
import {parseTime} from "@/utils/coreyee"
import {listApplied} from "@/api/account";
import {ElMessageBox} from "element-plus";
const loading = ref(true);
const tableData = ref([
]);

const pagination = reactive({
  currentPage: 1,
  pageSize: 1,
  small: false,
  background: true,
  disabled: false,
  total: 0,
  pageSizes: [10, 20, 30]
})

const rowAppliedIndex=({ row, rowIndex }) => {
  row.index = rowIndex + 1;
}

const handleSizeChange = () => {
  getList()
};
const handleCurrentChange = () => {
  getList()
};

const getList=function() {
  loading.value = true;
  listApplied().then(response => {
    tableData.value = response.rows;
    pagination.total = response.total;
    loading.value = false;
  });
}
const handleClick=function f() {
  ElMessageBox.alert("该功能正在建设中...", "系统提示", {
    type: "warning",
  }).catch(()=>{})
}

onMounted(() => {
  //document.title = "企业综合服务系统"
  getList()
})
</script>


<style lang="scss" scoped>
.common-layout {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;

  .el-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.fixed {
    .el-container {
      flex: 1;
    }

    .el-footer {
      --el-footer-height: unset;
    }
  }
  .el-footer {
    --el-footer-padding: 0;
    margin-top: 100px;
  }

  .el-main {
    padding: 0 10px;

    .mobile-tabs{
      float: left;
      display: inline-block;

      ul{
        padding: 20px 0 30px;
        margin: 0;
        li {
          display: inline;
          list-style: none;
          text-align: center;
          background: #DDDDDD;
          color: #101010;
          margin-right: 5px;
          font-size: 14px;
          padding: 8px 20px;
        }
        .active{
          background: #5C7EF4;
          color: #ffffff;
          font-size: 14px;
        }
      }
    }

    .mobile-container{
      width: 100%;
    }

  }

}
:deep .el-table--default .cell{
  padding: 0;
}
.el-pagination{
  justify-content: center;
}
</style>
