<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>降低小微企业创新创业成本操作口径-认定评分明细</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>2022 年度浦东新区创新型孵化器、科技企业加速器内的小微企业</td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>工商注册、税收户管均在浦东新区</td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>入驻创新型孵化器的企业成立时间离首次入驻孵化的时间不超过 24 个月，孵化时间不超过 48 个月（生物医药等企业延长至 60 个月）</td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>入驻科技企业加速器的企业原则上获得房租补贴资助不超过 3 次</td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ReduceCosts"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score
  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
