<template>
  <div class="home">
    <HeaderView title="用户中心" />
    <div class='account-container'>
      <div class='left'>
        <div class="user-avatar ">
          <div class="avatar">
            <img src="../../assets/avatar.png" height="64" width="64"/></div>
          <div class="nike-name">
            {{ maskPhoneNumber(userStore.name) }}
          </div>
        </div>
        <ul>
          <li v-on:click='$router.push({path:"/account"})'>企业信息</li>
          <li v-on:click='$router.push({path:"/account/myreport"})'>测评报告</li>
          <li v-on:click='$router.push({path:"/account/applied"})'>我的会员</li>
          <li v-on:click='$router.push({path:"/account/password"})' class='active'>修改密码</li>
        </ul>
      </div>
      <div class='right'>
        <el-form
            ref="passwordRef"
            :model="passwordForm"
            :rules="passwordRules"
            size="large"
            :inline="false"
            label-width="150px"
            class="demo-form-inline">
        <div class='title'><span></span>修改密码</div>
        <p style="padding-left: 20px">注：验证原密码，输入新密码完成修改。</p>

            <el-form-item label="原密码" prop="oldPassword">
              <el-input v-model="passwordForm.oldPassword"
                        type="password"
                        auto-complete="off"
                        placeholder="请输入原密码" />
            </el-form-item>
            <el-form-item label="新密码" prop="password">
              <el-input v-model="passwordForm.password"
                        type="password"
                        auto-complete="off"
                        placeholder="请输入新密码" />
            </el-form-item>
            <el-form-item label="再次输入新密码" prop="repeatPassword">
              <el-input v-model="passwordForm.repeatPassword"
                        type="password"
                        auto-complete="off"
                        placeholder="请再次输入新密码" />
            </el-form-item>
            <el-form-item style='margin-left: 150px'>
              <el-button
                  :loading="loading"
                  size="large"
                  class='submit'
                  @click.prevent="submitForm"
              >
                <span v-if="!loading">确认保存</span>
                <span v-else>保存中...</span>
              </el-button>
            </el-form-item>
        </el-form>
      </div>
      <div style='clear: both'></div>
    </div>
    <BottomView />
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import {getCurrentInstance, onMounted, ref} from "vue";
import {resetPassword} from "@/api/account";
import useUserStore from "@/store/modules/user";
import {ElMessageBox} from "element-plus";
import router from "@/router";
const userStore = useUserStore()
const { proxy } = getCurrentInstance();
const loading=ref(false)
const passwordForm = ref({
  oldPassword: "",
  password: "",
  repeatPassword: ""
})
function maskPhoneNumber(phoneNumber) {
  // 确保手机号码长度为11位
  if (phoneNumber.length === 11) {
    // 使用模板字符串和slice方法替换中间四位
    return `${phoneNumber.slice(0, 3)}****${phoneNumber.slice(-4)}`;
  } else {
    return phoneNumber;
  }
}
const equalToPassword = (rule, value, callback) => {
  if (passwordForm.value.password !== value) {
    callback(new Error("两次输入的密码不一致"));
  } else {
    callback();
  }
};

const passwordRules = {
  oldPassword: [
    { required: true, trigger: "blur", message: "请输入原密码" },
  ],
  password: [
    { required: true, trigger: "blur", message: "请输入您的密码" },
    { min: 5, max: 20, message: "用户密码长度必须介于 5 和 20 之间", trigger: "blur" }
  ],
  repeatPassword: [
    { required: true, trigger: "blur", message: "请再次输入您的密码" },
    { required: true, validator: equalToPassword, trigger: "blur" }
  ],
};

function submitForm() {
  proxy.$refs.passwordRef.validate(valid => {
    if (valid) {
      loading.value = true;
      resetPassword(passwordForm.value).then(res => {
        loading.value = false;
        ElMessageBox.alert("密码修改成功，请重新登录", "系统提示", {
          dangerouslyUseHTMLString: true,
          type: "success",
        }).then(() => {
          userStore.logOut().then(() => {
            router.push("/login");
          })
        }).catch(() => {
        });
      }).catch((res) => {
        loading.value = false;
      });
    }
  });
}

onMounted(() => {
  //document.title = "企业综合服务系统"
})
</script>

<style lang="scss" scoped>
.account-container {
  width: 1200px;
  margin: 0 auto;
  padding: 0 0 100px 0;

  .left {
    width: 200px;
    min-height: 600px;
    height: 1000px;
    float: left;
    display: inline-block;
    border-right: 1px;
    text-align: left;
    background-color: #ffffff;
    padding: 20px 0 0 0;
    box-sizing: border-box;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        height: 50px;
        text-align: center;
        line-height: 50px;

        color: #000;
        margin-bottom: 15px;
        font-size: 14px;
        cursor: pointer;

      }

      .active {
        border-left: 2px solid #E4C895;
        background: #F8F8F8;
        font-size: 18px;
      }
    }

    .user-avatar {
      position: relative;
      margin: 0 auto;
      text-align: center;
      width: 100%;

      .avatar {

      }

      .nike-name {
        margin: 15px 0 15px 0;
      }
    }
  }
  .right{
    width: 1000px;
    min-height: 600px;
    height: 1000px;
    float: right;
    display: inline-block;
    text-align: left;
    background-color: rgba(255, 255, 255, 1);
    border-left: 2px solid #f5f6fa;
    box-sizing: border-box;

    .title {
      padding: 20px;
      font-size: 21px;
      color: #000000;

      span {
        display: inline-block;
        width: 4px;
        height: 22px;
        background: #BDA26D;
        vertical-align: middle;
        margin-right: 10px;
      }
    }

    p {
      color: #ff0000;
      font-size: 13px;
      margin: 0;
      padding: 0 0 20px 0;
    }

    button{
      width: 168px;
      height: 46px;
      line-height: 20px;
      border-radius: 5px;
      background: linear-gradient(264.2deg, rgba(185,145,107,1) 1.7%,rgba(228,200,149,1) 93.74%);
      color: rgba(255, 255, 255, 100);
      font-size: 18px;
      text-align: center;
    }
  }

}
</style>
