import {login, logout, getInfo, loginByMobile} from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import {defineStore} from "pinia";

const useUserStore = defineStore(
  'user',
  {
    state: () => ({
      token: getToken(),
      id:null,
      name: '',
      avatar: '',
      companyName:'',
      memberDeadline:'',
      memberType:'',
      roles: [],
      permissions: [],
      dialogLogin: false
    }),
    actions: {
      // 登录
      login(userInfo) {
        const username = userInfo.username.trim()
        const password = userInfo.password
        const code = userInfo.code
        const uuid = userInfo.uuid
        return new Promise((resolve, reject) => {
          login(username, password, code, uuid).then(res => {
            setToken(res.token)
            this.token = res.token
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 登录
      loginByMobile(userInfo) {
        const mobile = userInfo.mobile.trim()
        const code = userInfo.code
        return new Promise((resolve, reject) => {
          loginByMobile(mobile, code).then(res => {
            setToken(res.token)
            this.token = res.token
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 获取用户信息
      getInfo() {
        return new Promise((resolve, reject) => {
          getInfo().then(res => {
            const user = res.user
            this.id=user.id
            this.name = user.userName
            this.companyName=user.companyName
            this.memberDeadline=user.memberDeadline
            resolve(res)
          }).catch(error => {
            reject(error)
          })
        })
      },
      showLogin(){
        this.dialogLogin = true
      },
      hideLogin(){
        this.dialogLogin = false
      },
      // 退出系统
      logOut() {
        return new Promise((resolve, reject) => {
          logout(this.token).then(() => {
            this.token = ''
            this.roles = []
            this.permissions = []
            this.id=null
            removeToken()
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      }
    }
  })

export default useUserStore
