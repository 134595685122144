<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition high-growth-projects-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">上海市新型研发机构备案与绩效评价</div>
      <BasicInfo ref="basicInfo" :is-financial="false" :is-int-prop="false"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="具有公共研发属性：以开展基础研究、应用基础研究、关键核心技术攻关、产业共性技术研发与服务为核心业务（不包括主要从事生产制造、研发服务外包、教学培训、园区管理、生产性检验检测等活动的单位）"
                            required prop="hasPublicSearchNature">
                <el-radio-group v-model="formData.hasPublicSearchNature">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="具有稳定的研发投入能力：年度研发投入占主营业务收入比例不低于20%或达到500万元，尚未产生主营业务收入的，研发投入占总支出比例不低于50%"
                            required prop="hasStableInvestment">
                <el-radio-group v-model="formData.hasStableInvestment">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="具有稳定的人才队伍：固定研发人员20人以上，占员工总数比例不低于30%，其中全职研发人员不低于10人，且具有硕士、博士学位或高级职称的研发人员占固定研发人员比例不低于30%"
                            required prop="hasStableTeam">
                <el-radio-group v-model="formData.hasStableTeam">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="具备进行研究、开发和试验所需的科研仪器设备及固定场地等研发基础条件：拥有科研仪器设备原价总值不低于200万元或上年度委托研发测试金额不低于200万元，固定场地面积不低于600平方米"
                            required prop="hasBaseCondition">
                <el-radio-group v-model="formData.hasBaseCondition">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="具有完善的管理制度：设立机构章程，并在研发投入、机构运行、人才队伍建设、科研管理和成果转化等方面，建立相关内部管理制度"
                            required prop="hasSoundSystem">
                <el-radio-group v-model="formData.hasSoundSystem">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ShanghaiNewResearchInstitution"
}
</script>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import MenuBar from '@/components/MenuBar.vue'
import BasicInfo from '@/components/BasicInfo.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'shanghaiNewResearchInstitution'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({
  priorSupportArr: [],
})


onMounted(() => {
  getProjectShanghaiByPath()
})
const getProjectShanghaiByPath = () => {
  companyApi.getProjectShanghaiByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
      formData.value.priorSupportArr = formData.value.priorSupportArr || []
    }
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveProjectShanghaiByPath(urlPath, company).then((resp) => {
    if (resp.code === 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getProjectShanghaiByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitProjectShanghaiByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

</script>
<style scoped lang="scss">
.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
}
</style>
