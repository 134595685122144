<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>张江科学城专项政策（对新引进的获股权融资的企业给予支持）</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>申报企业为获得股权融资的主体，工商注册地变更至张江科学城的时间在2023年1月1日后，且获得股权融资时间应在上述工商变更前的2年内
              </td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>申报企业实际经营地需在张江科学城内
              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>投资机构须在中国证券投资基金业协会备案，或经营范围含股权投资、创业投资、股权投资管理、创业投资管理， 以国家市场监督管理部门核发的营业执照为准
              </td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>租赁自用办公用房面积_____平
              </td>
              <td v-html='(result[3] || {option:0}).option'></td>
            </tr>
            </tbody>
          </table>
          <div>
            <p style="line-height: 25px">
              对新引进的获股权融资企业，对其租赁的自用办公用房给予一年房租补贴，补贴单价最高不超过3元/平米/天，补贴面积最高不超过1500平米，年度最高不超过100万元。
              如果认定成功将获得最高不超过 <span style="color: #ff0000; font-weight: bold;">{{calculating((result[3] || {option:0}).option)}}</span> 的补贴。
            </p>
          </div>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "zhangjiangScienceCitySpecialNewEquityCapital"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})

const calculating = (rentArea) => {
  const area = rentArea <= 1500 ? rentArea : 1500
  const res = area * 3 * 365
  return (res <= 1000000 ? res : 1000000)+'元'
}
</script>

<style scoped lang="scss">
</style>
