<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition high-growth-projects-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">浦东新区科技发展基金产学研专项（未来车）</div>
      <BasicInfo ref="basicInfo" :is-financial="true" :is-int-prop="false"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="选择符合条件的专题："
                            required prop="needRange">
                <el-radio-group v-model="formData.needRange">
                  <el-radio :label="1" style="width: 1000px; height: 200px;text-align: left">
                    <strong>专题一：智能网联汽车关键零部件</strong>
                    <br>研究目标：支持产学研用联合攻关，加强智能网联汽车共性关键技术研发，提升智能网联汽车的示范应用及产业化能级。
                    <br>研究内容：车载激光雷达、4D毫米波雷达、深度智能图像传感器等复杂环境融合感知系统部件，车用操作系统、智能计算平台、跨域融合域控制器、算法和基础软件
                    <br>等决策与控制系统部件，特定应用场景无人驾驶车辆，智能座舱，C-V2X通信终端，边缘计算设备，人机交互终端，汽车数据安全和网络安全关键技术、高精地图和
                    <br>定位、车路云一体化系统架构、智能网联汽车数据安全和隐私保护相关技术、大模型赋能智驾算法和数据挖掘等应用技术。
                    <br>执行期限：不超过2年，项目结束时间不得晚于2025年7月31日前完成。
                  </el-radio>
                  <el-radio :label="2" style="width: 1000px; height: 170px;text-align: left">
                    <strong>专题二：新能源汽车关键零部件</strong>
                    <br>研究目标：支持产学研用联合攻关，加强新能源汽车核心技术攻关，提升新能源汽车关键零部件国产化率和技术能级。
                    <br>研究内容：新型电子电气架构、固态电池、半固态电池、钠离子电池等新型动力电池系统、BMS热管理系统、动力电池与管理系统、新一代动力电池成组技术、电池盒技术、
                    <br>高功率密度驱动电机及控制系统、大功率无线充电桩、底盘线控执行系统（转向、制动）、高性能试验检测设备与系统等。
                    <br>执行期限：不超过2年，项目结束时间不得晚于2025年7月31日前完成。
                  </el-radio>
                  <el-radio :label="3" style="width: 1000px; height: 140px;text-align: left">
                    <strong>专题三：燃料电池汽车关键零部件</strong>
                    <br>研究目标：支持产学研用联合攻关，推动燃料电池汽车核心技术加快突破，提升燃料汽车关键零部件国产化率和技术能级。
                    <br>研究内容：电堆、双极板、质子交换膜、膜电极、催化剂、空压机、氢气循环系统、碳纸、站用压缩机、储氢瓶阀、制氢储氢一体化设备等关键零部件研发。
                    <br>执行期限：不超过2年，项目结束时间不得晚于2025年7月31日前完成。
                  </el-radio>
                  <el-radio :label="0">以上均不符合
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="项目责任人应承诺所提交材料真实性，不含涉密内容；申报单位应当对申请材料的真实性进行审核。"
                            required prop="isReal">
                <el-radio-group v-model="formData.isReal">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="项目申报主体须由新区企业牵头，联合1-2家上海地区高校或科研院所共同申报。项目承担方需要有较强的项目研究基础、人员配备和经费保障，合作单位必须为一级法人单位且在合作项目相关技术领域达到国际国内领先水平。"
                            required prop="isLeadInWorld">
                <el-radio-group v-model="formData.isLeadInWorld">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="申报单位需提交正式合作协议等附件证明材料，合作协议应是2022年8月1日后签署。"
                            required prop="isProved">
                <el-radio-group v-model="formData.isProved">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="项目资助资金不超过项目总经费的30%，单个项目最高资助额度不超过200万元，项目经评审后择优立项。资助资金应保障合作单位的各项经费支出。"
                            required prop="isMoneyEnough">
                <el-radio-group v-model="formData.isMoneyEnough">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="已经获得国家、上海市和区级其他财政资金资助的项目不得申报本专项。"
                            required prop="isNotGetFund">
                <el-radio-group v-model="formData.isNotGetFund">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="同一企业原则上只能申报一个项目。同一申报企业承担浦东科技发展基金产学研专项尚未完成验收的，不能申报。"
                            required prop="isOnlyOneProjectCompany">
                <el-radio-group v-model="formData.isOnlyOneProjectCompany">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="产学研合作方项目牵头人原则上只能合作申报一个产学研项目。同一合作方牵头人承担浦东科技发展基金产学研专项尚未完成验收的，不再予以支持。"
                            required prop="isOnlyOneProjectCeo">
                <el-radio-group v-model="formData.isOnlyOneProjectCeo">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="企业应在生产经营中做好安全生产工作。"
                            required prop="isSafe">
                <el-radio-group v-model="formData.isSafe">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongTecholonyDevelopmentSpecial"
}
</script>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import MenuBar from '@/components/MenuBar.vue'
import BasicInfo from '@/components/BasicInfo.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'pudongTecholonyDevelopmentSpecial'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({
})
onMounted(() => {
  getProjectPudongByPath()
})
const getProjectPudongByPath = () => {
  companyApi.getProjectPudongByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveProjectPudongByPath(urlPath, company).then((resp) => {
    if (resp.code === 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getProjectPudongByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitProjectPudongByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

</script>
<style scoped lang="scss">
.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
}
</style>
