import {createRouter, createWebHashHistory} from 'vue-router'
import Index from "@/views/Index.vue";
import News from "@/views/News.vue";
import NewsDetails from "@/views/NewsDetails.vue";
import ServiceDetails from "@/views/ServiceDetails.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import ServiceProviderRegistration from "@/views/ServiceProviderRegistration.vue";
import Account from "@/views/account/Index.vue";
import Applied from "@/views/account/Applied.vue";
import Password from "@/views/account/Password.vue";
import SinglePageBenefit from "@/views/SinglePage/SinglePageBenefit.vue";
import SinglePageAgreement from "@/views/SinglePage/SinglePageAgreement.vue";
import SinglePageLegalNotice from "@/views/SinglePage/SinglePageLegalNotice.vue";
import SinglePageProtectionPolicy from "@/views/SinglePage/SinglePageProtectionPolicy.vue";
import SinglePageAbout from "@/views/SinglePage/SinglePageAbout.vue";
import SinglePageServiceProvider from "@/views/SinglePage/SinglePageServiceProvider.vue";
import SinglePageCondition from "@/views/SinglePage/SinglePageCondition.vue";
import ProjectDeclaration from "@/views/ProjectDeclaration.vue";
import PolicyEncyclopedia from "@/views/PolicyEncyclopedia.vue";
import PolicyEncyclopediaDetails from "@/views/PolicyEncyclopediaDetails.vue";
import PolicyInterpretation from "@/views/PolicyInterpretation.vue";
import PolicyInterpretationDetails from "@/views/PolicyInterpretationDetails.vue";
import DeclarationAnnouncement from "@/views/DeclarationAnnouncement.vue";
import DeclarationFilling from "@/views/DeclarationFilling.vue";
import DeclarationDetails from "@/views/DeclarationDetails.vue";
import ProjectDeclarationDetails from "@/views/ProjectDeclarationDetails.vue";
import Feedback from "@/views/Feedback.vue";
import AllPrivacy from "@/views/AllPrivacy.vue";
import mobile_index from "@/router/mobile_index";
import Vip from "@/views/Vip.vue";
import Help from "@/views/Help.vue";
import MyReport from "@/views/account/MyReport.vue";
import VipDesc from "@/views/VipDesc.vue";
// import form_temp from "@/router/authentication";
import condition_form from "@/router/condition_form";
import condition_result from "@/router/condition_result";
import operating_caliber from "@/router/operating_caliber";
import operating_caliber_result from "@/router/operating_caliber_result";
import pudong from "@/router/pudong";
import pudong_result from "@/router/pudong_result";

const routes = [
    {
        path: '/',
        name: 'home',
        component: Index
    },
    {
        path: '/news',
        name: 'news',
        component: News
    },
    {
        path: '/news-details',
        name: 'news-details',
        component: NewsDetails
    },
    {
        path: '/service-details',
        name: 'service-details',
        component: ServiceDetails
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/account',
        name: 'account',
        component: Account
    },
    {
        path: '/account/applied',
        name: 'applied',
        component: Applied
    },
    {
        path: '/account/password',
        name: 'password',
        component: Password
    },
    {
        path: '/single-page-benefit',
        name: 'single-page-benefit',
        component: SinglePageBenefit
    },
    {
        path: '/single-page-agreement',
        name: 'single-page-agreement',
        component: SinglePageAgreement
    },
    {
        path: '/single-page-legal-notice',
        name: 'single-page-legal-notice',
        component: SinglePageLegalNotice
    },
    {
        path: '/single-page-protection-policy',
        name: 'single-page-protection-policy',
        component: SinglePageProtectionPolicy
    },
    {
        path: '/single-page-about',
        name: 'single-page-about',
        component: SinglePageAbout
    },
    {
        path: '/service-provider-registration',
        name: 'service-provider-registration',
        component: ServiceProviderRegistration
    },
    {
        path: '/single-page-service-provider',
        name: 'single-page-service-provider',
        component: SinglePageServiceProvider
    },
    {
        path: '/single-page-condition',
        name: 'single-page-condition',
        component: SinglePageCondition
    },
    {
        path: '/project-declaration',
        name: 'project-declaration',
        component: ProjectDeclaration
    },
    {
        path: '/project-declaration-details',
        name: 'project-declaration-details',
        component: ProjectDeclarationDetails
    },
    {
        path: '/policy-encyclopedia',
        name: 'policy-encyclopedia',
        component: PolicyEncyclopedia
    },
    {
        path: '/policy-encyclopedia-details',
        name: 'policy-encyclopedia-details',
        component: PolicyEncyclopediaDetails
    },
    {
        path: '/policy-interpretation',
        name: 'policy-interpretation',
        component: PolicyInterpretation
    },
    {
        path: '/policy-interpretation-details',
        name: 'policy-interpretation-details',
        component: PolicyInterpretationDetails
    },
    {
        path: '/declaration-announcement',
        name: 'declaration-announcement',
        component: DeclarationAnnouncement
    },
    {
        path: '/declaration-filling',
        name: 'declaration-filling',
        component: DeclarationFilling
    },
    {
        path: '/declaration-details',
        name: 'declaration-details',
        component: DeclarationDetails
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: Feedback
    },
    {
        path: '/all-privacy',
        name: 'all-privacy',
        component: AllPrivacy
    },
    {
        path: '/vip',
        name: 'vip',
        component: Vip
    },
    {
        path: '/help',
        name: 'help',
        component: Help
    },
    {
        path: '/account/myreport',
        name: 'myreport',
        component: MyReport
    },
    {
        path: '/vipdesc',
        name: 'vipdesc',
        component: VipDesc
    },
    ...mobile_index,
    //...form_temp,
    ...condition_form,
    ...condition_result,
    ...operating_caliber,
    ...operating_caliber_result,
    ...pudong,
    ...pudong_result,
    // {
    //   path: '/about',
    //   name: 'about',
    //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
