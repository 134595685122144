<template>
  <div class="home">
    <HeaderView title="企业用户注册"/>
    <div class="banner2">
      <div class='login-container'>
        <div class='login-form'>
          <h2>企业用户注册</h2>
          <el-form
              ref="registerRef" :model="registerForm" :rules="registerRules"
              size="large"
              class="el-form"
              label-position='top'
              label-width="120px"
              status-icon
          >
            <el-form-item label="企业全称" prop="companyName">
              <el-input v-model="registerForm.companyName"
                        type="text"
                        auto-complete="off"
                        placeholder="请输入企业全称">
                <template #prefix>
                  <el-icon class="el-input__icon"><House /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="用户名（手机号）" prop="username">
              <el-input v-model="registerForm.username"
                        type="text"
                        auto-complete="off"
                        placeholder="请输入手机号码">
                <template #prefix>
                  <el-icon class="el-input__icon"><User /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="密 码" prop="password">
              <el-input v-model="registerForm.password"
                        type="password"
                        auto-complete="off"
                        placeholder="请输入密码">
                <template #prefix>
                  <el-icon class="el-input__icon"><Lock /></el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="再次输入密码" prop="repeatPassword">
              <el-input v-model="registerForm.repeatPassword"
                        type="password"
                        auto-complete="off"
                        placeholder="请再次输入密码">
                <template #prefix>
                  <el-icon class="el-input__icon"><Lock /></el-icon>
                </template>
              </el-input>
            </el-form-item>
             <p v-if="false">
               <el-checkbox class='agreement' label="" name="type" v-model="isAgreement" >
                  <span>我已阅读并同意<router-link to="/single-page-agreement">《用户服务协议》</router-link>及<router-link to='/single-page-protection-policy'>《个人信息保护政策》</router-link></span>
               </el-checkbox>
               <span class="tips" v-show="showAgreement&&!isAgreement">请勾选已阅读并同意</span>
             </p>
            <el-form-item>
              <el-button
                  :loading="loading"
                  size="large"
                  class='submit'
                  @click.prevent="handleRegister"
              >
                <span v-if="!loading">注   册</span>
                <span v-else>注 册 中...</span>
              </el-button>
            </el-form-item>
          </el-form>

        </div>
      </div>
    </div>

    <BottomView :is-image="false"/>
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import {House,User,Lock} from '@element-plus/icons-vue'
import {getCurrentInstance, onMounted, ref} from "vue";
import {register} from "@/api/login";
import { ElMessageBox } from "element-plus";
import router from "@/router";
const { proxy } = getCurrentInstance();
const loading=ref(false)
const isAgreement=ref(true)
const showAgreement=ref(false)
const registerForm = ref({
  companyName: "",
  username: "",
  password: "",
  repeatPassword: "",
})
const equalToPassword = (rule, value, callback) => {
  if (registerForm.value.password !== value) {
    callback(new Error("两次输入的密码不一致"));
  } else {
    callback();
  }
};

const registerRules = {
  companyName: [
    { required: true, trigger: "blur", message: "请输入您的企业名称" },
  ],
  username: [
    { required: true, trigger: "blur", message: "请输入您的账号" },
    { min: 2, max: 20, message: "用户账号长度必须介于 2 和 20 之间", trigger: "blur" }
  ],
  password: [
    { required: true, trigger: "blur", message: "请输入您的密码" },
    { min: 5, max: 20, message: "用户密码长度必须介于 5 和 20 之间", trigger: "blur" }
  ],
  repeatPassword: [
    { required: true, trigger: "blur", message: "请再次输入您的密码" },
    { required: true, validator: equalToPassword, trigger: "blur" }
  ],
};

function handleRegister() {
  if(!isAgreement.value){
    showAgreement.value=true
    return
  }

  proxy.$refs.registerRef.validate(valid => {
    if (valid) {
      loading.value = true;
      register(registerForm.value).then(res => {
        const username = registerForm.value.username;
        ElMessageBox.alert("恭喜你，您的账号 " + username + " 注册成功！", "系统提示", {
          dangerouslyUseHTMLString: true,
          type: "success",
        }).then(() => {
          router.push("/login");
        }).catch(() => {
        });
      }).catch((res) => {
        loading.value = false;
      });
    }
  });
}

onMounted(() => {
  //document.title = "企业综合服务系统"
})

</script>

<style lang="scss" scoped>
.banner2 {
  height: 779px;
  width: 100%;
  background-image: url("../assets/banner2.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: top center;
}

.login-container {
  width: 1140px;
  margin: 0 auto;
  position: relative;

  .login-form {
    position: absolute;
    right: 0;
    top: 70px;
    width: 350px;
    height: 580px;
    line-height: 20px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 100);
    text-align: center;
    padding: 50px;

    h2{
      margin: 0;
      font-size: 36px;
      color: #BDA26D;
      font-weight: 400;
      text-align: left;
      padding-bottom: 50px;
    }


    :deep(.el-input__wrapper){
      height: 44px;
    }

    .el-input__icon{
      font-size: 18px;
    }

    .submit{
      width: 355px;
      height: 52px;
      line-height: 20px;
      border-radius: 5px;
      background: linear-gradient(264.2deg, rgba(185,145,107,1) 1.7%,rgba(228,200,149,1) 93.74%);
      color: rgba(255, 255, 255, 100);
      font-size: 18px;
      text-align: center;
      margin-top: 10px;
      border: none;
    }

    .tips{
      width: 100%;
      display: inline-block;
      font-size: 12px;
      color: #f56c6c;
      text-align: left;
    }


    p{
      text-align: left;
      margin: 0;
      color: #101010;
      font-size: 12px;
      line-height: 20px;

      .agreement {
        vertical-align: middle;
        margin-right: 5px;
      }
      span{
        display: inline-block;
        font-size: 12px;
      }

      a{
        color: #409eff;
        text-decoration: none;
      }
    }
  }
}
</style>
