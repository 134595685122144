import Authentication from "@/views/authentication/index";
import AuthenticationResult from "@/views/authentication/result";

//第一批政策拆解
import ConditionTechSmes from "@/views/authentication/condition_tech_smes";
import ConditionHighNewTech from "@/views/authentication/condition_high_new_tech";
import ConditionInnovateSmes from "@/views/authentication/condition_innovate_smes";
import ConditionSpecUltraNewSmes from "@/views/authentication/condition_spec_ultra_new_smes";
import ConditionShTechCenter from "@/views/authentication/condition_sh_tech_center";
import ConditionHighNewTechTransform from "@/views/authentication/condition_high_new_tech_transform";
import ConditionShPatentPolitEnterprise from "@/views/authentication/condition_sh_patent_polit_enterprise";
import ConditionShPatentPolitPublic from "@/views/authentication/condition_sh_patent_polit_public";
import ConditionShPatentDemoEnterprise from "@/views/authentication/condition_sh_patent_demo_enterprise";
import ConditionShPatentDemoPublic from "@/views/authentication/condition_sh_patent_demo_public";

//第四批政策拆解
import ShanghaiInnovationFund from "@/views/conditionResult/ShanghaiInnovationFund.vue";
import ShanghaiAdvancedService from "@/views/conditionResult/ShanghaiAdvancedService.vue";
import ShanghaiInnovationVoucher from "@/views/conditionResult/ShanghaiInnovationVoucher.vue";
import ShanghaiNewResearchInstitution from "@/views/conditionResult/ShanghaiNewResearchInstitution.vue";
import ShanghaiIntegratedCircuitPrize from "@/views/conditionResult/ShanghaiIntegratedCircuitPrize.vue";
import ShanghaiEntrepreneurialInternship from "@/views/conditionResult/ShanghaiEntrepreneurialInternship.vue";
import ShanghaiStartUpInsuranceGuide from "@/views/conditionResult/ShanghaiStartUpInsuranceGuide.vue";
import ShanghaiEntrepreneurialTeamsVenueFee from "@/views/conditionResult/ShanghaiEntrepreneurialTeamsVenueFee.vue";
import ShanghaiFirstTimeEntrepreneurshipOnceSubsidy from "@/views/conditionResult/ShanghaiFirstTimeEntrepreneurshipOnceSubsidy.vue";
import ShanghaiParkIntellectualProperty from "@/views/conditionResult/ShanghaiParkIntellectualProperty.vue";




export default [
    {path: '/authentication', name: 'authentication', component: Authentication,},
    {path: '/authentication/result', name: 'authentication-result', component: AuthenticationResult,},
    //第一批政策拆解
    {path: '/authentication/condition_tech_smes', name: 'conditionTechSmes', component: ConditionTechSmes,},
    {path: '/authentication/condition_high_new_tech', name: 'conditionHighNewTech', component: ConditionHighNewTech,},
    {path: '/authentication/condition_innovate_smes', name: 'conditionInnovateSmes', component: ConditionInnovateSmes,},
    {path: '/authentication/condition_spec_ultra_new_smes', name: 'conditionSpecUltraNewSmes', component: ConditionSpecUltraNewSmes,},
    {path: '/authentication/condition_sh_tech_center', name: 'conditionShTechCenter', component: ConditionShTechCenter,},
    {path: '/authentication/condition_high_new_tech_transform', name: 'conditionHighNewTechTransform', component: ConditionHighNewTechTransform,},
    {path: '/authentication/condition_sh_patent_polit_enterprise', name: 'conditionShPatentPolitEnterprise', component: ConditionShPatentPolitEnterprise,},
    {path: '/authentication/condition_sh_patent_polit_public', name: 'conditionShPatentPolitPublic', component: ConditionShPatentPolitPublic,},
    {path: '/authentication/condition_sh_patent_demo_enterprise', name: 'conditionShPatentDemoEnterprise', component: ConditionShPatentDemoEnterprise,},
    {path: '/authentication/condition_sh_patent_demo_public', name: 'conditionShPatentDemoPublic', component: ConditionShPatentDemoPublic,},


    //第四批政策拆解
    {path: '/authentication/shanghai-innovation-fund', name: 'shanghai-innovation-fund-result', component: ShanghaiInnovationFund,},
    {path: '/authentication/shanghai-advanced-service', name: 'shanghai-advanced-service-result', component: ShanghaiAdvancedService,},
    {path: '/authentication/shanghai-innovation-voucher', name: 'shanghai-innovation-voucher-result', component: ShanghaiInnovationVoucher,},
    {path: '/authentication/shanghai-new-research-institution', name: 'shanghai-new-research-institution-result', component: ShanghaiNewResearchInstitution,},
    {path: '/authentication/shanghai-integrated-circuit-prize', name: 'shanghai-integrated-circuit-prize-result', component: ShanghaiIntegratedCircuitPrize,},
    {path: '/authentication/shanghai-entrepreneurial-internship', name: 'shanghai-entrepreneurial-internship-result', component: ShanghaiEntrepreneurialInternship,},
    {path: '/authentication/shanghai-start-up-insurance-guide', name: 'shanghai-start-up-insurance-guide-result', component: ShanghaiStartUpInsuranceGuide,},
    {path: '/authentication/shanghai-entrepreneurial-teams-venue-fee', name: 'shanghai-entrepreneurial-teams-venue-fee-result', component: ShanghaiEntrepreneurialTeamsVenueFee,},
    {path: '/authentication/shanghai-first-time-entrepreneurship-once-subsidy', name: 'shanghai-first-time-entrepreneurship-once-subsidy-result', component: ShanghaiFirstTimeEntrepreneurshipOnceSubsidy,},
    {path: '/authentication/shanghai-park-intellectual-property', name: 'shanghai-park-intellectual-property-result', component: ShanghaiParkIntellectualProperty,},


]
