<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition high-growth-projects-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">浦东新区科技发展基金产学研专项（电子信息）</div>
      <BasicInfo ref="basicInfo" :is-financial="false" :is-int-prop="false"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="选择符合条件的专题："
                            required prop="meetRange">
                <el-radio-group v-model="formData.meetRange">
                  <el-radio :label="1" style="width: 1000px; height: 270px;text-align: left">
                    <strong>专题1、集成电路</strong>
                    <br>研究目标：加快集成电路关键领域核心技术联合攻关和产品开发，提升集成电路领域上下游企业协同创新能力，支持创新成果在浦东新区实施产业化。
                    <br>研究内容：
                    <br>1、面向汽车电子、工业控制等多层次行业应用领域的高端和大宗芯片产品研发。支持处理器芯片、传感芯片、通信芯片、存储芯片、功率芯片等芯片产品。
                    <br>2、集成电路前后道工艺关键设备零部件和配套材料的研发。
                    <br>3、具有自主知识产权的EDA和IP的研发。
                    <br>4、支持基于RISC-V指令集架构的芯片研发；硅光、量子科技等前沿领域芯片研发；第三代半导体材料和器件研发；Chiplet等先进封装和测试技术研发。
                    <br>执行期限：不超过2年，2025年6月30日前完成。
                  </el-radio>
                  <el-radio :label="2" style="width: 1000px; height: 210px;text-align: left">
                    <strong>专题2、新一代通信和新型显示</strong>
                    <br>研究目标：加快新一代通信和新型显示关键领域核心技术联合攻关和产品开发，支持创新成果在浦东新区实施产业化。
                    <br>研究内容：
                    <br>1、新一代通信支持基于5G及新一代通信技术(包括低轨卫星互联网、光通信、量子通信等) 前沿领域核心元器件（包括功率放大器、滤波器、大规模天线阵列等）、模块、终端，测试设备和测试方案的集成研发。
                    <br>2、新型显示支持有机发光显示（AMOLED）、微型发光显示（MicroLED，MiniLED）、全息显示等新兴技术研发。
                    <br>执行期限：不超过2年，2025年6月30日前完成。
                  </el-radio>
                  <el-radio :label="0">以上均不符合
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="项目责任人应承诺所提交材料真实性，不含涉密内容；申报单位应当对申请材料的真实性进行审核。"
                            required prop="isReal">
                <el-radio-group v-model="formData.isReal">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="项目申报主体须由新区企业牵头，联合1-2家高校、科研院所、相关功能性平台等共同申报。项目承担方需要有较强的项目研究基础、人员配备和经费保障，合作单位为长三角区域内微电子、通信等领域的高校、科研院所、相关功能性平台，在合作项目相关技术领域达到国际国内领先水平。合作协议应是2022年7月1日后签署的协议。"
                            required prop="isLead">
                <el-radio-group v-model="formData.isLead">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="项目资助资金不超过项目总经费的30%，单个项目最高资助额度不超过200万元，项目经评审后择优立项。资助资金应保障合作机构的各项经费支出。"
                            required prop="isMoneyOk">
                <el-radio-group v-model="formData.isMoneyOk">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="已经获得国家、上海市和区级其他财政资金资助的项目不得申报本专项。"
                            required prop="isNotSubsidized">
                <el-radio-group v-model="formData.isNotSubsidized">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="同一企业原则上只能申报一个项目。同一申报企业承担本专项资金支持的项目尚未完成验收的，不再予以支持。"
                            required prop="hasNoUnfinishedItem">
                <el-radio-group v-model="formData.hasNoUnfinishedItem">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="产学研合作方项目牵头人原则上只能合作申报一个产学研项目。同一合作方牵头人承担本专项资金支持的项目尚未完成验收的，不再予以支持。"
                            required prop="isLeaderInOneItem">
                <el-radio-group v-model="formData.isLeaderInOneItem">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="企业应在生产经营中做好安全生产工作。"
                            required prop="isSafe">
                <el-radio-group v-model="formData.isSafe">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongTechnolonyDevelopmentSpecialElectronic"
}
</script>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import MenuBar from '@/components/MenuBar.vue'
import BasicInfo from '@/components/BasicInfo.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'pudongTechnolonyDevelopmentSpecialElectronic'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({
})
onMounted(() => {
  getProjectPudongByPath()
})
const getProjectPudongByPath = () => {
  companyApi.getProjectPudongByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveProjectPudongByPath(urlPath, company).then((resp) => {
    if (resp.code === 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getProjectPudongByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitProjectPudongByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

</script>
<style scoped lang="scss">
.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
}
</style>
