import request from '@/utils/request'

export default {

    /**
     * 获得公司基本信息
     * @returns {*}
     */
    getBasicInfo: function () {
        //
        return request({
            url: '/open/company/baseInfo',
            method: 'get'
        })
    },
    /**
     * 第一批：
     * techSmes 科技型中小企业认定
     * highNewTech 高新技术企业认定
     * innovateSmes 创新型中小企业认定
     * specUltraNewSmes 专精特新中小企业认定
     * shTechCenter  上海市企业技术中心认定
     * highNewTechTransform 高新技术成果转化认定
     * shPatentPolitEnterprise 上海市企业专利工作（试点）单位认定
     * shPatentPolitPublic     上海市事业专利工作（试点）单位认定
     * shPatentDemoEnterprise   上海市企业专利工作（试点）单位认定
     * shPatentDemoPublic       上海市事业专利工作（试点）单位认定
     *
     * 第三批：
     * highQualityProjectInIncubator 支持优质项目入驻创新型孵化器操作口径认定
     * lowerCost 降低小微企业创新创业成本操作口径认定
     * supportIncreaseInnovation 支持小微企业提升自主创新能力项目操作口径认定
     * supportHighGrowthProject 支持高成长小微科创企业项目操作口径
     * plan135HighGrowth “十三五”高成长小微科创企业验收项目操作口径认定
     *
     * 第四批：
     * shanghaiInnovationFund 上海市科技型中小企业技术创新资金项目
     * shanghaiAdvancedService 上海市技术先进型服务企业认定
     * shanghaiInnovationVoucher 上海市科技创新券
     * shanghaiNewResearchInstitution 上海市新型研发机构备案与绩效评价
     * shanghaiIntegratedCircuitPrize 上海市集成电路和软件企业核心团队专项奖励办法
     * shanghaiEntrepreneurialInternship 上海创业见习生活费补贴
     * shanghaiStartUpInsuranceGuide 申请初创期创业组织社会保险费补贴办事指南
     * shanghaiEntrepreneurialTeamsVenueFee 创业团队孵化场地费补贴
     * shanghaiFirstTimeEntrepreneurshipOnceSubsidy 首次创业一次性补贴
     * shanghaiParkIntellectualProperty 园区知识产权集中托管项目申报工作
     *

     * pudongTecholonyDevelopmentSpecial 浦东新区科技发展基金产学研专项（未来车）
     * pudongTecholonyDevelopmentSpecialFund 浦东新区科技发展基金产学研专项（生物医药）
     * pudongTechnolonyDevelopmentSpecialElectronic 浦东新区科技发展基金产学研专项（电子信息）
     * pudongPromoteDevelopmentOfIcAndCommunication 浦东新区促进集成电路和新一代通信产业高质量发展专项
     * pudongPromoteDevelopmentOfMedicine 浦东新区支持生物医药产业高质量发展专项
     * pudongPromoteDevelopmentOfAi 浦东新区人工智能赋能经济数字化转型高质量发展专项
     * pudongPromoteDevelopmentOfHighendEquipment 浦东新区促进高端装备产业高质量发展专项
     * pudongBigEnterpriseInnovationCenter 浦东新区大企业开放创新中心登记申报
     * pudongEnterpriseResearchCenter 浦东新区企业研发机构认定
     * pudongTechnologyDevelopmentFundDigit 浦东新区科技发展基金社会领域数字化转型专项
     * pudongTecholonyInnovationVouncher 浦东新区科技发展基金科技创新券专项申报
     * pudongTecholonyDevelopmentFundLoan 浦东新区科技发展基金高新技术企业贷款贴息专项
     * pudongPearlPlanTalent 浦东新区“明珠计划”高峰人才项目
     * pudongPearlPlanLeadingTalent 浦东新区“明珠计划”领军人才项目
     * pudongPearlPlanEliteTalent 浦东新区“明珠计划”菁英人才项目
     * pudongPearlPlanEngineer 浦东新区“明珠计划”工程师项目
     * pudongSupportOverseasReturnee 浦东新区海归创业资助
     * pudongPearlPlanTechnician 浦东新区“明珠计划”领军人才技师项目
     * pudongPromoteInvestment 浦东新区促投资提能级专项
     *
     *
     * zhangjiangScienceCitySpecialGrowth 申请张江科学城专项政策（对成长型科技企业给予支持）
     * zhangjiangScienceCitySpecialNewEquityCapital 申请张江科学城专项政策（对新引进的获股权融资的企业给予支持）
     * zhangjiangScienceCitySpecialEquityCapital 申请张江科学城专项政策（对获得股权融资的企业给予支持）
     *
     *
     * @param path
     * @param data
     * @returns {*}
     */
    submitConditionByPath(path, data) {
        data.isApply = 1
        return request({
            url: `/open/company/apply/${path}`,
            method: 'post',
            data
        })
    },
    saveConditionByPath(path, data) {
        data.isApply = 0
        return request({
            url: `/open/company/apply/${path}`,
            method: 'post',
            data
        })
    },
    getConditionByPath(path) {
        return request({
            url: `/open/company/get/${path}`,
            method: 'get'
        })
    },

    //上海
    submitProjectShanghaiByPath(path, data) {
        data.isApply = 1
        return request({
            url: `/open/project/shanghai/apply/${path}`,
            method: 'post',
            data
        })
    },
    saveProjectShanghaiByPath(path, data) {
        data.isApply = 0
        return request({
            url: `/open/project/shanghai/apply/${path}`,
            method: 'post',
            data
        })
    },
    getProjectShanghaiByPath(path) {
        return request({
            url: `/open/project/shanghai/get/${path}`,
            method: 'get'
        })
    },

    //浦东
    submitProjectPudongByPath(path, data) {
        data.isApply = 1
        return request({
            url: `/open/project/pudong/apply/${path}`,
            method: 'post',
            data
        })
    },
    saveProjectPudongByPath(path, data) {
        data.isApply = 0
        return request({
            url: `/open/project/pudong/apply/${path}`,
            method: 'post',
            data
        })
    },
    getProjectPudongByPath(path) {
        return request({
            url: `/open/project/pudong/get/${path}`,
            method: 'get'
        })
    },

    //张江
    submitProjectZhangjiangByPath(path, data) {
        data.isApply = 1
        return request({
            url: `/open/project/zhangjiang/apply/${path}`,
            method: 'post',
            data
        })
    },
    saveProjectZhangjiangByPath(path, data) {
        data.isApply = 0
        return request({
            url: `/open/project/zhangjiang/apply/${path}`,
            method: 'post',
            data
        })
    },
    getProjectZhangjiangByPath(path) {
        return request({
            url: `/open/project/zhangjiang/get/${path}`,
            method: 'get'
        })
    },
    submit: {},
    get: {}


}


