<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>浦东新区关于促进技能人才发展的若干意见（加大企业首席技师、技能大师工作室支持力度）</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>申报类型： 首席技师 / 技能大师工作室
              </td>
              <td v-html='(result[0] || {}).option'></td>
            </tr>
            <tr v-if="result[0].option==='首席技师'">
              <td>在一线岗位上直接从事技术技能性工作或技能培训工作，掌握关键技术，能够带领团队解决生产过程中的实际问题，或擅长推广新技术新工艺、总结先进操作法等，或在传技带徒、培养技能人才等方面成绩显著。
              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr v-if="result[0].option==='首席技师'">
              <td>具有国家职业资格或技能等级评价二级（技师）2年、一级（高级技师）1年以上。对于从事职业（工种）无国家职业资格标准或未进行技能等级认定评价的，如在本岗位长期工作、贡献突出、技能在同行业中处于领先水平并有较高声誉，也可聘为首席技师。上述情况需在申请报告中重点说明其个人技能水平及在行业中的影响、业绩等情况。
              </td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr v-if="result[0].option==='首席技师'">
              <td>首席技师由所在单位根据上述条件自主选聘。
              </td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>

            <tr v-if="result[0].option==='技能大师工作室'">
              <td>工作室有明晰的管理制度、工作职责、目标任务，具备一定数量技能人才，一般不少于3名。有实际工作场所进行技能攻关、带徒传技等活动，为企业技术革新、提升经济效益等起到积极作用。
              </td>
              <td v-html='(result[4] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr v-if="result[0].option==='技能大师工作室'">
              <td>工作室带头人是本行业（领域）中技能拔尖、技艺精湛的高技能人才，具有较强创新创造能力和社会影响力，在带徒传技方面经验丰富，身体健康，能够承担工作室日常工作
              </td>
              <td v-html='(result[5] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>

            <tr>
              <td>建立健全首席技师或技能大师工作室工作制度，明确具体的选拔聘用程序和办法，落实专门机构和专人组织实施相关工作。
              </td>
              <td v-html='(result[6] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>规范首席技师选拔聘用，坚持德才兼备、好中选优、公开透明的原则，严格按照既定条件及程序选拔、聘用首席技师。
              </td>
              <td v-html='(result[7] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>为首席技师或技能大师工作室承担技术革新、技术攻关等提供资金、场地、设备、设施、人员等方面的支持。
              </td>
              <td v-html='(result[8] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>落实高技能人才待遇，实行首席技师岗位津贴或给予其它薪酬待遇激励，并为首席技师交流学习、培训进修、体检休养等创造条件。
              </td>
              <td v-html='(result[9] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>重视高技能人才队伍建设，按规定提取职工教育经费并对职工开展培训，积极开展企业内高技能人才培养和评价、高师带徒、技能竞赛、技师继续培训等活动。
              </td>
              <td v-html='(result[10] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPearlPlanTechnician"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([
  {option: ''}
])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
