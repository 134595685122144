<template>
  <div class="home">
    <HeaderView title="新闻资讯" />
<!--    <div class="banner">-->
<!--    </div>-->
    <div class='main-container'>
      <div class="news">
        <div class="title">News Headlines<span class="text">资讯头条</span></div>
        <div class='news-images'>
          <swiper
              class="swiper"
              :modules='modules'
              :loop="true"
              :slides-per-view="1"
              :space-between="10"
              :navigation="navigation"
              :autoplay="true"
              :pagination="{ clickable: true }"
              :scrollbar="{ draggable: true }"
              style="width: 570px;height: 323px;"
          >
            <swiper-slide v-for="(item) in carouselList" :key="item.id" @click='onClickCart(item.id)'>
              <img :src='`${baseUrl+ item.image}`' style='width: 570px;height: 323px'>
              <div class='news-title'>
                <p>{{item.title}}</p>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" />
            <div class="swiper-button-next" />
          </swiper>
        </div>
        <div class='news-list'>
          <router-link v-for="(item) in newsTopList" :key="item.id" :to="`/news-details?code=${item.id}`">
            <span class="tit">{{item.title}}</span><span class="date">{{item.releaseDate.substring(0,10)}}</span>
          </router-link>
        </div>
      </div>
      <div style='clear: both'></div>
      <div class="news-tab">
        <span :class='`${newsType===1?"active":""}`' @click='newTypeChange(1)'>产业政策</span>
        <span :class='`${newsType===2?"active":""}`' @click='newTypeChange(2)'>活动宣传</span>
      </div>
      <ul class='news-page'>
         <li><router-link  v-for="(item) in pageData" :key="item.id" :to="`/news-details?code=${item.id}`">
           <i></i>{{item.title}}
           <span>{{item.releaseDate.substring(0,10)}}</span></router-link>
         </li>
      </ul>
      <el-pagination
          v-model:current-page="pagination.currentPage"
          v-model:page-size="pagination.pageSize"
          :page-sizes="pagination.pageSizes"
          :small="pagination.small"
          :disabled="pagination.disabled"
          :background="pagination.background"
          popper-class=""
          layout="total,sizes,prev,pager,next,slot,jumper"
          :total="pagination.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"/>
    </div>
    <BottomView />
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'

import {onMounted, reactive, ref} from 'vue'
// 引入样式
import { Swiper, SwiperSlide } from 'swiper/vue' // swiper 所需组件
import { Autoplay, Navigation, A11y } from 'swiper'// 分页器
// import 'swiper/swiper-bundle.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import router from "@/router";
import {getNewsCarousel, getNewsPage, getNewsTop} from "@/api/news";
const modules = [Autoplay, Navigation, A11y]
const navigation = ref({
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev'
})

const newsType=ref(1)


const baseUrl = process.env.VUE_APP_BASE_API
const carouselList=ref()
const newsTopList=ref()
const pageData = ref()

const onClickCart=function(id) {
  router.push('news-details?code='+id);
}

const pagination = reactive({
  currentPage: 1,
  pageSize: 10,
  small: false,
  background: true,
  disabled: false,
  total: 0,
  pageSizes: [10, 20, 30]
})

onMounted(() => {
  // //document.title = "企业综合服务系统"

  getNewsCarousel().then( response => {
    carouselList.value = response.data
  })

  getNewsTop().then( response => {
    newsTopList.value = response.data
  })
  searchList()
})

const newTypeChange = (type) => {
  newsType.value=type
  searchList()
}

const handleSizeChange = () => {
  searchList()
};
const handleCurrentChange = () => {
  searchList()
};
const searchList = () => {
  getNewsPage(pagination.pageSize, pagination.currentPage, newsType.value).then(response => {
    pageData.value = response.rows;
    pagination.total = response.total;
  });
}

</script>

<style lang="scss" scoped>
.swiper {
  width: 339rem;
  height: 150rem;
  background-color: antiquewhite;
  .swiper-slide {
    .swiper-img {
      width: 570px;
      height: 323px;
    }
    .news-title{
      background: rgba(255,255,255,0.75);
      width: 570px;
      height: 70px;
      position: absolute;
      bottom: 0;
      left: 0;
      color: #000000;
      text-align: center;
      p{
        font-size: 16px;
        font-weight: 600;
        line-height: 70px;
        margin: 0;
        padding: 0 20px;
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    --swiper-theme-color: #F6F6F6;
    --swiper-navigation-size: 24px;
    background: #E4C895;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
}

.banner {
  height: 770px;
  width: 100%;
  background: url("../assets/banner.png") center no-repeat;
}
.main-container {
  width: 1140px;
  margin: 0 auto;
  padding-bottom:100px;

  .news {
    .title {
      font-size: 75px;
      color: #F2F2F2;
      font-weight: bold;
      position: relative;
      text-align: left;
      margin:15px 0 15px 0;

      .text {
        font-size: 36px;
        color: #BDA26D;
        position: absolute;
        left: 50px;
        top: 25px;
      }

      .more {
        font-size: 14px;
        color: #101010;
        position: absolute;
        right: 0px;
        top: 50px;
        text-decoration: none;
        span{
          font-family: 'Simsun';
        }
      }

      .hotline{
        font-size: 28px;
        color: #101010;
        font-weight: lighter;
        position: absolute;
        right: 0px;
        top: 30px;

        i{
          color: #BDA26D;
        }
      }
    }
  }

  .news-images{
    width: 570px;
    height: 323px;
    float: left;
    background: #E4C895;
  }

  .news-list{
    width: 538px;
    float: right;
    text-align: left;
    margin-top: -10px;

    a{
      width: 538px;
      font-size: 16px;
      padding: 22px 0;
      border-bottom: #DDDDDD dashed 1px;
      display: inline-block;
      text-decoration: none;
      color: #101010;

      span.tit{
        display: inline-block;
        /*强制文本在一行内显示*/
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width:410px;
      }

      span.date{
        float: right;
        color: #8C9093;
      }
    }
    a:hover{
      color: #BDA26D;
      font-weight: bold;
    }
  }

  .news-tab{
    text-align: left;
    padding: 50px 0 30px 0;

    span{
      width: 168px;
      height: 44px;
      line-height: 44px;
      color: #101010;
      border:#E4C895 1px dashed;
      background: #FFFFFF;
      text-align: center;
      display: inline-block;
      font-weight: bold;
      cursor: pointer;
    }

    .active{
      color: #FFFFFF;
      background-color: rgba(228, 200, 149, 100);
    }

  }
  .news-page {
    margin: 0;
    padding: 0 0 20px 0;

    li {
      list-style: none;
      margin: 0;
      padding: 0;

      a {
        width: 100%;
        font-size: 16px;
        padding: 22px 0;
        border-bottom: #DDDDDD dashed 1px;
        display: inline-block;
        text-decoration: none;
        color: #101010;
        text-align: left;

        i{
          display: inline-block;
          width: 10px;
          height: 10px;
          background: #BDA26D;
          margin-right: 10px;
        }
        span {
          float: right;
          color: #8C9093;
        }
      }

      a:hover {
        color: #BDA26D;
        font-weight: bold;
      }
    }
  }
}
</style>
