<template>
  <div class="home">
    <HeaderView title="用户中心"/>
    <div class='account-container'>
      <div class='left'>
        <div class="user-avatar ">
          <div class="avatar">
            <img src="../../assets/avatar.png" height="64" width="64"/></div>
          <div class="nike-name">
            {{ maskPhoneNumber(userStore.name) }}
          </div>
        </div>
        <ul>
          <li v-on:click='$router.push({path:"/account"})'>企业信息</li>
          <li v-on:click='$router.push({path:"/account/myreport"})' class='active'>测评报告</li>
          <li v-on:click='$router.push({path:"/account/applied"})'>我的会员</li>
          <li v-on:click='$router.push({path:"/account/password"})'>修改密码</li>
        </ul>
      </div>
      <div class='right'>
        <div class='title'><span></span>我的测评报告</div>
        <el-row class="el-row-header" :gutter="20">
          <el-col :span="2" style="text-align: center">序号</el-col>
          <el-col :span="14">测评项目</el-col>
          <el-col :span="2">分值</el-col>
          <el-col :span="4">是否符合申请标准</el-col>
          <el-col :span="2"></el-col>
        </el-row>
        <el-row class="el-row-list" :gutter="20" v-for="(item,index) in result" :key="index">
          <el-col :span="2" style="text-align: center">{{ index + 1 }}</el-col>
          <el-col :span="14">
            <div class="name">
              {{ item.title }}
            </div>
          </el-col>
          <el-col :span="2">
            <div class="box-score">
                <span v-if="notShow('total',item.evaluationType)">
                   {{ Number(item.total) !== Math.round(item.total) ? item.total.toFixed(1) : item.total }}分
                </span>
            </div>
          </el-col>
          <el-col :span="4">
            <div v-if="notShow('success',item.evaluationType)">
              <div v-if="item.success" class="box-score-en">符合申请标准</div>
              <div v-else class="box-score-de">不符合申请标准</div>
            </div>
            <div v-else-if="notShow('comment',item.evaluationType)">
              <div class="box-score-de">{{ item.comment }}</div>
            </div>
            <div v-else>
              <div class="box-score-en"></div>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="box-btn">
              <el-link class="btn" title="点击在新页面查看测评报告"
                       @click="goOpenNumber(item.evaluationType,
                           Number(item.total) !== Math.round(item.total) ? item.total.toFixed(1)  : item.total,
                           item.type)"
                       round>查看报告
              </el-link>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style='clear: both'></div>
    </div>
    <BottomView/>
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import {onBeforeMount, ref} from "vue";
import {getResultList} from "@/api/authentication";
import useUserStore from "@/store/modules/user";

const userStore = useUserStore()

function maskPhoneNumber(phoneNumber) {
  // 确保手机号码长度为11位
  if (phoneNumber.length === 11) {
    // 使用模板字符串和slice方法替换中间四位
    return `${phoneNumber.slice(0, 3)}****${phoneNumber.slice(-4)}`;
  } else {
    return phoneNumber;
  }
}

const result = ref([])
const unResult = ref([])

onBeforeMount(() => {
  getResultList().then((res) => {
    result.value = res.data.testedProjects
    unResult.value = res.data.unTestedProjects
  }).catch(() => {
  })
})

const urlMap = {
  '4': 'condition_tech_smes',
  '5': 'condition_high_new_tech',
  '8': 'condition_innovate_smes',
  '9': 'condition_spec_ultra_new_smes',
  '10': 'condition_sh_tech_center',
  '11': 'condition_high_new_tech_transform',
  '12': 'condition_sh_patent_polit_enterprise',
  '13': 'condition_sh_patent_polit_public',
  '14': 'condition_sh_patent_demo_enterprise',
  '15': 'condition_sh_patent_demo_public',
  '16': 'carrier-construction',
  '17': 'incubator',
  '18': 'reduce-costs',
  '19': 'competency-projects',
  '20': 'high-growth-projects',
  '21': 'acceptance-project',
  '22': 'shanghai-innovation-fund',
  '23': 'shanghai-advanced-service',
  '24': 'shanghai-innovation-voucher',
  '25': 'shanghai-new-research-institution',
  '26': 'shanghai-integrated-circuit-prize',
  '27': 'shanghai-entrepreneurial-internship',
  '28': 'shanghai-start-up-insurance-guide',
  '29': 'shanghai-entrepreneurial-teams-venue-fee',
  '30': 'shanghai-first-time-entrepreneurship-once-subsidy',
  '31': 'shanghai-park-intellectual-property',

  '32': 'techolony-development-special',
  '33': 'techolony-development-special-fund',
  '34': 'technolony-development-special-electronic',
  '35': 'promote-development-of-ic-and-communication',
  '36': 'promote-development-of-medicine',
  '37': 'promote-development-of-ai',
  '38': 'promote-development-of-highend-equipment',
  '39': 'big-enterprise-innovation-center',
  '40': 'enterprise-research-center',
  '41': 'technology-development-fund-digit',
  '42': 'techolony-innovation-vouncher',
  '43': 'techolony-development-fund-loan',
  '44': 'pearl-plan-talent',
  '45': 'pearl-plan-leading-talent',
  '46': 'pearl-plan-elite-talent',
  '47': 'pearl-plan-engineer',
  '48': 'support-overseas-returnee',
  '49': 'pearl-plan-technician',

  '50': 'zhangjiang-science-city-special-growth',
  '51': 'zhangjiang-science-city-special-new-equity-capital',
  '52': 'zhangjiang-science-city-special-equity-capital',

  '53': 'promote-investment',
}
const notShow = (type, typeCode) => {
  let flag = true
  const typeName = urlMap[typeCode]
  switch (type) {
    case 'total' : {
      const total = [
        'condition_high_new_tech_transform',
        'incubator',
        'reduce-costs',
        'competency-projects',
        'high-growth-projects',
        'acceptance-project',
        'shanghai-innovation-fund',
        'shanghai-advanced-service',
        'shanghai-innovation-voucher',
        'shanghai-new-research-institution',
        'shanghai-integrated-circuit-prize',
        'shanghai-entrepreneurial-internship',
        'shanghai-start-up-insurance-guide',
        'shanghai-entrepreneurial-teams-venue-fee',
        'shanghai-first-time-entrepreneurship-once-subsidy',
        'shanghai-park-intellectual-property',

        'techolony-development-special',
        'techolony-development-special-fund',
        'technolony-development-special-electronic',
        'promote-development-of-ic-and-communication',
        'promote-development-of-medicine',
        'promote-development-of-ai',
        'promote-development-of-highend-equipment',
        'big-enterprise-innovation-center',
        'enterprise-research-center',
        'technology-development-fund-digit',
        'techolony-innovation-vouncher',
        'techolony-development-fund-loan',
        'pearl-plan-talent',
        'pearl-plan-leading-talent',
        'pearl-plan-elite-talent',
        'pearl-plan-engineer',
        'support-overseas-returnee',
        'pearl-plan-technician',

        'zhangjiang-science-city-special-growth',
        'zhangjiang-science-city-special-new-equity-capital',
        'zhangjiang-science-city-special-equity-capital',

        'promote-investment',
      ]
      flag = !total.includes(typeName)
      break
    }
    case 'comment':
      flag = typeName === 'condition_sh_tech_center'
      break
    case 'success': {
      const success = [
        'condition_sh_tech_center',
        'condition_sh_patent_polit_enterprise',
        'condition_sh_patent_polit_public',
        'condition_sh_patent_demo_enterprise',
        'condition_sh_patent_demo_public',
      ]
      flag = !success.includes(typeName)
      break
    }

  }
  return flag
}
const goOpenNumber = (type, score, carrierConstructionType) => {
  let url = `#/authentication/${urlMap[type]}?score=${score}&evaluationType=${type}`;
  if (urlMap[type] === 'carrier-construction') {
    url += `&carrierConstructionType=${carrierConstructionType}`;
  }
  window.open(url, '_blank'); // 打开新标签页
}
</script>

<style lang="scss" scoped>
.account-container {
  width: 1200px;
  margin: 0 auto;
  padding: 0 0 100px 0;
  display: -webkit-box;

  .left {
    width: 200px;
    min-height: 600px;
    float: left;
    display: inline-block;
    border-right: 1px;
    text-align: left;
    background-color: #ffffff;
    padding: 20px 0 0 0;
    box-sizing: border-box;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        height: 50px;
        text-align: center;
        line-height: 50px;

        color: #000;
        margin-bottom: 15px;
        font-size: 14px;
        cursor: pointer;

      }

      .active {
        border-left: 2px solid #E4C895;
        background: #F8F8F8;
        font-size: 18px;
      }
    }

    .user-avatar {
      position: relative;
      margin: 0 auto;
      text-align: center;
      width: 100%;

      .avatar {

      }

      .nike-name {
        margin: 15px 0 15px 0;
      }
    }
  }

  .right {
    width: 1000px;
    min-height: 600px;
    float: right;
    display: inline-block;
    text-align: left;
    background-color: rgba(255, 255, 255, 1);
    border-left: 2px solid #f5f6fa;
    box-sizing: border-box;

    .title {
      padding: 20px;
      font-size: 21px;
      color: #000000;

      span {
        display: inline-block;
        width: 4px;
        height: 22px;
        background: #BDA26D;
        vertical-align: middle;
        margin-right: 10px;
      }
    }

    p {
      color: #ff0000;
      font-size: 13px;
      margin: 0;
      padding: 0 0 20px 0;
    }

    .el-row-header {
      background: #e4e7ed;
      font-size: 16px;
      height: 40px;
      line-height: 40px;
      text-align: left;
      width: 950px;
      margin: 0 !important;
      margin-left: 20px !important;
    }

    .el-row-list {
      font-size: 14px;
      min-height: 30px;
      line-height: 30px;
      text-align: left;
      background: #ffffff;
      padding-bottom: 7px;
      padding-top: 7px;
      width: 950px;
      margin: 0 !important;
      margin-left: 20px !important;

      .btn {
        color: var(--el-color-primary);
      }
    }

  }
}
</style>
