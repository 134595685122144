<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>浦东新区科技发展基金产学研专项（生物医药）</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>工商注册、税收户管均在浦东新区，且经营状态正常、信用记录良好的企业。
              </td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>专题一、创新药品医疗器械临床研究
                <br>支持方式和内容：对生物医药企业的创新药物（一类新药、二类新药）在符合条件的合作单位开展I期临床研究的，对项目给予30%，最高不超过100万的支持。
                <br>对生物医药企业的医疗器械（三类医疗器械或创新医疗器械）在符合条件的合作单位开展临床研究的，对项目给予30%，最高不超过50万的支持。
                <br>执行期限：不超过2年，项目结束时间不得晚于2025年6月30日。
                <br>专题二、创新药品医疗器械临床前研究
                <br>支持内容和方式：对生物医药企业联合符合条件的合作单位开展以产品上市为目的研究者发起的临床研究（IIT），重点支持细胞基因、合成生物等新领域新赛道项目。对项目总投入给予30%，最高不超过80万的支持。
                <br>执行期限：不超过2年，项目结束时间不得晚于2025年6月30日。
              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>项目责任人应承诺所提交材料真实性，不含涉密内容；申报单位应当对申请材料的真实性进行审核。
              </td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>项目申报须由新区企业牵头，联合1-2家医疗机构，或1家医疗机构与1家上海市的高校院所共同申报。其中临床研究（专题一）的医疗机构范围为浦东区属二级以上医院、上海市级及其他区属三级乙等（含）以上医院、江浙皖三省卫健委直属三甲医院以及三省范围内的知名医科大学（医学院）直属附属医院（江浙皖三省医院名单附后）。临床前研究（专题二）的医疗机构范围为浦东区属二级以上医院、上海市级及其他区属三级乙等（含）以上医院。
              </td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>申报单位需提交卫生主管部门批准立项材料、临床批件、正式合作协议等附件证明材料。其中合作协议应是2022年7月1日后签署。
              </td>
              <td v-html='(result[4] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>项目经评审后择优立项。资助资金应保障专款专用。已经获得国家、上海市和区级其他财政资金资助的项目不得申报本专项。
              </td>
              <td v-html='(result[5] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>目前承担浦东科技发展基金产学研专项并未结题的企业不能申报。
              </td>
              <td v-html='(result[6] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>企业应在生产经营过程中做好安全生产工作。
              </td>
              <td v-html='(result[7] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ShanghaiInnovationFund"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
