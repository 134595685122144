import MobileLogin from "@/views/mobile/Login";
import MobileRegister from "@/views/mobile/Register";
import MobileIndex from "@/views/mobile";
import MobileNews from "@/views/mobile/News";
import MobileNewsDetails from "@/views/mobile/NewsDetails";
import MobileServiceDetails from "@/views/mobile/ServiceDetails";
import MobileAccount from "@/views/mobile/account";
import MobileApplied from "@/views/mobile/account/Applied.vue";
import MobilePassword from "@/views/mobile/account/Password.vue";
import MobileSinglePageBenefit from "@/views/mobile/SinglePageBenefit.vue";
import MobileSinglePageAgreement from "@/views/mobile/SinglePageAgreement.vue";
import MobileSinglePageLegalNotice from "@/views/mobile/SinglePageLegalNotice.vue";
import MobileSinglePageProtectionPolicy from "@/views/mobile/SinglePageProtectionPolicy.vue";
import MobileSinglePageAbout from "@/views/mobile/SinglePageAbout.vue";

export default [
  {
    path: '/mobile',
    name: 'mobile',
    component: MobileIndex,
  },
  {
    path: '/mobile/login',
    name: 'mobile-login',
    component: MobileLogin
  },
  {
    path: '/mobile/register',
    name: 'mobile-register',
    component: MobileRegister,
  },
  {
    path: '/mobile/news',
    name: 'mobile-news',
    component: MobileNews,
  },
  {
    path: '/mobile/news-details',
    name: 'mobile-details',
    component: MobileNewsDetails,
  },
  {
    path: '/mobile/service-details',
    name: 'mobile-service-details',
    component: MobileServiceDetails,
  },{
    path: '/mobile/account',
    name: 'mobile-account',
    component: MobileAccount,
  },
  {
    path: '/mobile/account/applied',
    name: 'mobile-applied',
    component: MobileApplied
  },
  {
    path: '/mobile/account/password',
    name: 'mobile-password',
    component: MobilePassword
  },
  {
    path: '/mobile/single-page-benefit',
    name: 'mobile-single-page-benefit',
    component: MobileSinglePageBenefit
  },
  {
    path: '/mobile/single-page-agreement',
    name: 'mobile-single-page-agreement',
    component: MobileSinglePageAgreement
  },
  {
    path: '/mobile/single-page-legal-notice',
    name: 'mobile-single-page-legal-notice',
    component: MobileSinglePageLegalNotice
  },
  {
    path: '/mobile/single-page-protection-policy',
    name: 'mobile-single-page-protection-policy',
    component: MobileSinglePageProtectionPolicy
  },
  {
    path: '/mobile/single-page-about',
    name: 'mobile-single-page-about',
    component: MobileSinglePageAbout
  },

]