<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar/>
    <el-divider/>
    <div class="declaration-details-container">
      <div class="company-name">上海芯屹信息技术有限公司</div>
      <div class="analyze-results flex-flex-start">
        <div class="icon-passed"><img src="../assets/image/icon/icon-passed.png" alt=""/></div>
        <div class="analyze-results-content">
          <div class="flex-space-between">
            <div class="analyze-results-info"><span>分析完成！</span>符合您企业申报的项目共 <span
                class="span-number">3</span>个
            </div>
            <div class="analyze-results-money">-- 万元</div>
          </div>
          <div class="flex-space-between analyze-results-company-info">
            <div>
              资料越完整，匹配越精准，<a href="#">立即完善企业信息</a>(已完善5%)
            </div>
            <div>
              预计最多可获得的额度
            </div>
          </div>
        </div>
      </div>
      <div class="declared-projects">
        <div class="declared-projects-title">申报项目</div>
        <el-divider/>
        <el-table v-if="true" :data="tableRows">
          <el-table-column prop="title" label="标题"/>
          <el-table-column prop="money" label="资金范围" width="180"/>
          <el-table-column prop="department" label="主管部门" width="180"/>
          <el-table-column prop="term" label="申报期限" width="180"/>
          <el-table-column label="操作" width="80">
            <template #default="scope">
              <el-button
                  link
                  type="primary"
                  size="small"
                  @click.prevent="goToApply(scope.row)"
              >
                去测评
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-else class="no-data-yet">
          <span>暂无申报的项目~</span>
          <img src="../assets/image/image-01.png" alt="">
        </div>
      </div>

    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "DeclarationDetails"
}
</script>

<script setup>

import MenuBar from '@/components/MenuBar';
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import {ref} from "vue";

const tableRows = ref([{
  title: '2024年高新企业申报',
  money: '10~25 万',
  department: '科委',
  term: '剩45天'
},{
  title: '2024年高新企业申报',
  money: '10~25 万',
  department: '科委',
  term: '剩45天'
},{
  title: '2024年高新企业申报',
  money: '10~25 万',
  department: '科委',
  term: '剩45天'
},{
  title: '2024年高新企业申报',
  money: '10~25 万',
  department: '科委',
  term: '剩45天'
}])
const goToApply = (row) => {
  console.log(row)
}
</script>

<style scoped lang="scss">
.el-divider {
  margin: 0;
}

.declaration-details-container {
  width: 1200px;
  height: 992px;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: left;

  .company-name {
    color: #101010;
    font-size: 32px;
    margin: 24px 0;

  }

  .analyze-results {
    height: 132px;
    background-color: rgba(245, 251, 255, 1);
    padding: 0 30px;

    .icon-passed {
      width: 50px;
      margin-right: 30px;

      img {
        width: 50px;
        height: 50px;
      }
    }

    .analyze-results-content {
      flex: 1;

      .analyze-results-info {
        font-size: 22px;
        color: #020202;

        span {
          color: #2E5AA6;
          margin-right: 10px;
        }

        .span-number {
          color: #F70B0B;
        }
      }

      .analyze-results-money {
        font-size: 16px;
        color: #F70B0B;
      }

      .analyze-results-company-info {
        margin-top: 22px;
        font-size: 16px;
        color: #020202;

        a {
          color: #2E5AA6;
        }
      }
    }

  }

  .declared-projects {
    .declared-projects-title {
      color: #020202;
      font-size: 22px;
      margin-bottom: 10px;
    }

    .el-divider {
      border-top: 3px solid rgba(2, 2, 2, 1);
    }

    .el-table {
      width: 100%;
      color: #020202;
      font-size: 16px;

      :deep thead {
        color: #41476E !important;
        font-weight: unset !important;
      }

      .el-button {
        color: #020202;
        font-size: 16px;
      }
    }

    .no-data-yet {
      color: rgba(65, 71, 110, 1);
      font-size: 22px;
      height: 95px;
      line-height: 95px;
      text-align: center;

      img {
        width: 177px;
        height: 95px;
        margin-left: 68px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
</style>
