<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>支持创新创业载体建设操作口径-认定评分明细</div>
      <div class="result-boolean">
        <div class="title"><span>*审核规则</span></div>
        <div class="title-content">
          <p>(一)创新型孵化器</p>
          <p>1、综合评估。60分及以上单位，按照预算安排和实际评分情况，实行分档分级评分，计算奖励金额。</p>
          <p>2、重大贡献评估。按照预算安排和实际评分情况计算奖励金额。</p><br>
          <p>(二)科技企业加速器</p>
          <p>1、基础评估。基础评估指标根据加速器认定标准制定，评估分值低于 60 分的单位，不得参与奖励评估，</p>
          <p>2、奖励评估。基础评估达到60 分的单位，可以参加奖励评估，根据实际分值予以奖励。</p>
          <br>
        </div>
      </div>
      <div v-if="carrierConstructionType == 1" class="result-score">
        <div class="table">
          <table>
            <thead>
            <tr>
              <th style="width: 500px">创新型孵化器绩效评估指标</th>
              <th>评分标准</th>
              <th style="width: 115px;">得分</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="3" style="font-weight: bold; background: #efefef">一、重大贡献</td>
            </tr>
            <tr>
              <td>考核期内新招商引进的新注册企业</td>
              <td>引进企业3分/家，引进高企、专精特新企业的10分/家，引进科技小巨人(培育)等资质企业的20分/家</td>
              <td class="core">{{ (result[0] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>在孵企业能级提升情况</td>
              <td>高企、专精特新10分/家</td>
              <td class="core">{{ (result[1] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内在孵企业获得重大投融资</td>
              <td>实到融资额100-500万的2分/个;500-1000万的3分/个;1000-3000万的，4分/个:3000-5000万的，5分/个;5000万以上的10分/个。
                如是孵化器自有资金或基金投资的，额外增加2分/个。为在孵企业提供金融支持(为1-10个在孵企业购买保险加0.5，为11-20个在孵企业购买保险加1分)</td>
              <td class="core">{{ (result[2] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内毕业企业情况</td>
              <td>考核期内毕业企业1分/个:留在新区的4分/家;迁出新区的不子计分;毕业企业获得科技小巨人(培育)或专精特新小巨人的，20分/家:毕业企业科创板、主板上市的30分/家</td>
              <td class="core">{{ (result[3] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内国际化取得重大进展</td>
              <td>1、举办具有国际影响力论坛会议等活动，10分个(活动需提前报备);
                2、在海外设点并实际运营的，10分/个:3、引进海外项目并实际潜地注册的，5分/个。</td>
              <td class="core">{{ (result[4] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>推动揭榜挂帅项目成功对接</td>
              <td>每在浦东新区揭榜挂帅公共服务平台上对接成功一次，10分。</td>
              <td class="core">{{ (result[5] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内孵化载体技术平台服务能力提升</td>
              <td>平台建设:自建:20分/家
                  联合建设:10分/家
                  平台资质:市、区创新券服务机构:5分/家,</td>
              <td class="core">{{ (result[6] || {}).score || 0 }}</td>
            </tr>

            <tr>
              <td colspan="3" style="font-weight: bold; background: #efefef">二、综合能力</td>
            </tr>
            <tr>
              <td>考核期内孵化企业新增的知识产权</td>
              <td>授权:PCT每个5分:一类的每个3分，二类每个1分，二类得分最多不超过10分，最高30分</td>
              <td class="core">{{ (result[7] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内推动企业开展产业资源对接、产学研合作、市场拓展等</td>
              <td>推动孵化企业与大企业:形成虽大技术合作的，10分/个:推动孵化企业与国内外知名高校、研究院所实现技术合作的，5分/个;推动孵化企业签订合作订单，订单金额超1000万的，3分/个，最高30分</td>
              <td class="core">{{ (result[8] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内与特色园区、街镇、开发区建立联动招商合作机制</td>
              <td>签订具有工作目标及内容的合作协议并有实际案例 5分/个，最高20分</td>
              <td class="core">{{ (result[9] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内孵化企业引进的高端人才</td>
              <td>国家级20分/人，市级10分/人，区级5分/人，海外人才2分/人，博士2分/人(博士与海外人才合计上限10分)，最高25分</td>
              <td class="core">{{ (result[10] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>专职服务人员与孵化企业总数的比例</td>
              <td>不低于5%，5%-10%的3分，每增加5%得1分，最高8分</td>
              <td class="core">{{ (result[11] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>服务人员接受专业培训</td>
              <td>不低于10%，10%-20%1分，每增加10%得1分，最高6分</td>
              <td class="core">{{ (result[12] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期实际在孵企业数量</td>
              <td>2分/家(当年度新注册企业纳入到重大贡献中不重复计算)，最高20分</td>
              <td class="core">{{ (result[13] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>可自主支配使用的孵化场地面积</td>
              <td>1000平起算基础分10分，每增100平加1分，最高15分</td>
              <td class="core">{{ (result[14] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内在孵企业参加各项创业比赛</td>
              <td>在孵企业中参加比赛0.5分/个，获得市级奖项2分/个，获得国家奖项5分/个，最高15分</td>
              <td class="core">{{ (result[15] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>创业基础配套服务种类（工商注册、法律咨询、财务管理、人力资源、知识产权、政策和管理咨询等）</td>
              <td>具有并实际开展一项服务得0.5分，最高5分</td>
              <td class="core">{{ (result[16] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内举办的创新创业活动</td>
              <td>常规活动0.5分/个，最高10分</td>
              <td class="core">{{ (result[17] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>企业服务数据收集与报送</td>
              <td>及时汇总提交每季度运营数据得1.5分，未及时填报双创信息动态，每次扣0.5分，此项最高分为6分，最低分为0分。</td>
              <td class="core">{{ (result[18] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内孵化载体或孵化企业综合能级提升的(获得市级及以上资质荣誉或重大专项等)</td>
              <td>孵化载体:国家级:8分/个，市级:5分/个:孵化企业:国家级:5分/个，市级:3分/个;最高分10分</td>
              <td class="core">{{ (result[19] || {}).score || 0 }}</td>
            </tr>

            <tr>
              <td colspan="3" style="font-weight: bold; background: #efefef">三、负面清单</td>
            </tr>
            <tr>
              <td>孵化器管理不规范，被培育企业或社会主体投诉经查实</td>
              <td>扣5-20分</td>
              <td class="core">{{ (result[20] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>不及时配合区重大工作部署</td>
              <td>扣2-10分;</td>
              <td class="core">{{ (result[21] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>报送虚假信息经查实或者被依法判定欺诈行为属实</td>
              <td>不合格</td>
              <td class="core" v-html='(result[22] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>孵化载体及所属孵化企业发生重大安全生产问题</td>
              <td>不合格</td>
              <td class="core" v-html='(result[23] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>对新区双创形象造成较为严重影响</td>
              <td>不合格</td>
              <td class="core" v-html='(result[24] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="carrierConstructionType == 2" class="result-score">
        <div class="table">
          <table>
            <thead>
            <tr>
              <th style="width: 500px">企业加速器绩效评估指标</th>
              <th>评分标准</th>
              <th style="width: 115px;">得分</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="3" style="font-weight: bold; background: #efefef">一、基础评估</td>
            </tr>
            <tr>
              <td>加速场地</td>
              <td>
                1、可自主支配场地面积1万平方米以上，4分;面积每增加2000平米，加1分，最高9分。
                2、用于高成长科技企业使用的场地不少于总面积的70%，3分。
                3、作为加速器用途使用期限1年以上，3分。
                总分最高15分。</td>
              <td class="core">{{ (result[0] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>入驻企业</td>
              <td>参照登记管理办法企业标准，入驻企业须大于(含)7家，10分。每增加一家入驻企业，加3分。最高25分</td>
              <td class="core">{{ (result[1] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>制度规范</td>
              <td>管理服务规范、制度健全，5分。制定科技企业加速器服务规范和中长期发展规划，5分。</td>
              <td class="core">{{ (result[2] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>服务团队</td>
              <td>拥有5人以上的高素质、专业化管理团队，10分。
                每增加1人，增加1分，最高15分。</td>
              <td class="core">{{ (result[3] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>中介服务机构</td>
              <td>在加速器内以及与加速器紧密合作的中介服务机构每1家1分，最高15分。</td>
              <td class="core">{{ (result[4] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>自有资金投资或成立相关的投资基金</td>
              <td>配备自有投资资金(基金)或合作基金，6分。在加速器内每一个投资案例2分。最高20分。</td>
              <td class="core">{{ (result[5] || {}).score || 0 }}</td>
            </tr>

            <tr>
              <td colspan="3" style="font-weight: bold; background: #efefef">二、奖励评估</td>
            </tr>
            <tr>
              <td>考核期内引进高成长企业</td>
              <td>新增一家孵化器外高成长企业3分/家，新增一家孵化器毕业企业5分/家，最高50分</td>
              <td class="core">{{ (result[6] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内入驻企业营业收入</td>
              <td>加速器加速企业营业收入增长较上年度增长20%以上的，按照考核年度营收增加额的1%奖励，最高100分。</td>
              <td class="core">{{ (result[7] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内入驻企业融资</td>
              <td>按照风投实到资本(万元)的2%计分，最高100分</td>
              <td class="core">{{ (result[8] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内加速服务企业上市成果</td>
              <td>科创板:30分/个:主板上市50分/个，最高100分</td>
              <td class="core">{{ (result[9] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内入驻企业高端人才聚集</td>
              <td>国家级:15分/人，市级:10分/人，区级5分/人，最高100分</td>
              <td class="core">{{ (result[10] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内入驻企业新增知识产权量</td>
              <td>PCT:授权4分/个;发明专利:授权2分/个，最高50分</td>
              <td class="core">{{ (result[11] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内入驻企业获市级以上重大专项或资质荣誉的</td>
              <td>市级:5分:国家级:10分。最高50分</td>
              <td class="core">{{ (result[12] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>入驻企业产业集中度达到80%</td>
              <td>入驻企业达到10家以上，聚焦于一个产业的集中度达到80%，20分。</td>
              <td class="core">{{ (result[13] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>加速载体自建或共建公共技术服务平台的</td>
              <td>平台建设:自建:30分/家，联合建设:10分/家，平台资质:市、区创新券服务机构备案:5分/家。最高30分</td>
              <td class="core">{{ (result[14] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>考核期内加速载体开展产业资源对接、产学研合作、推动大中小融通等方面的成效</td>
              <td>推动入驻企业与大企业在浦东重点发展领域形成重大技术合作的，10分/个:推动入驻企业与国内外知名高校、研究院所实现技术合作的，5分/个。最高50分</td>
              <td class="core">{{ (result[15] || {}).score || 0 }}</td>
            </tr>

            <tr>
              <td colspan="3" style="font-weight: bold; background: #efefef">三、负面清单</td>
            </tr>
            <tr>
              <td>报送虚假信息经查实或者被依法判定欺诈行为属实</td>
              <td>不合格</td>
              <td class="core" v-html='(result[16] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>加速器管理不规范，被培育企业或社会主体投诉经查实</td>
              <td>扣5-20分</td>
              <td class="core">{{ (result[17] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>不能及时配合区重大工作部署</td>
              <td>扣2-10分</td>
              <td class="core">{{ (result[18] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>对新区双创形象造成较为严重影响</td>
              <td>不合格</td>
              <td class="core" v-html='(result[19] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="false" class="result-total">指标总得分： <span>{{ total }}</span> 分</div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "CarrierConstruction"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
const carrierConstructionType = ref(0)
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score
  carrierConstructionType.value = query.carrierConstructionType
  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
.table table tbody tr td:nth-child(1) {
  font-size: 15px;
}
 .table table tbody tr td:nth-child(2) {
   font-size: 13px;
   line-height: 25px;
   color: #1e1e1e;
 }
</style>
