import PudongTecholonyDevelopmentSpecial from "@/views/conditionResult/pudong/PudongTecholonyDevelopmentSpecial";
import PudongTecholonyDevelopmentSpecialFund from "@/views/conditionResult/pudong/PudongTecholonyDevelopmentSpecialFund";
import PudongTechnolonyDevelopmentSpecialElectronic from "@/views/conditionResult/pudong/PudongTechnolonyDevelopmentSpecialElectronic";
import PudongPromoteDevelopmentOfIcAndCommunication from "@/views/conditionResult/pudong/PudongPromoteDevelopmentOfIcAndCommunication";
import PudongPromoteDevelopmentOfMedicine from "@/views/conditionResult/pudong/PudongPromoteDevelopmentOfMedicine";
import PudongPromoteDevelopmentOfAi from "@/views/conditionResult/pudong/PudongPromoteDevelopmentOfAi";
import PudongPromoteDevelopmentOfHighendEquipment from "@/views/conditionResult/pudong/PudongPromoteDevelopmentOfHighendEquipment";
import PudongBigEnterpriseInnovationCenter from "@/views/conditionResult/pudong/PudongBigEnterpriseInnovationCenter";
import PudongEnterpriseResearchCenter from "@/views/conditionResult/pudong/PudongEnterpriseResearchCenter";
import PudongTechnologyDevelopmentFundDigit from "@/views/conditionResult/pudong/PudongTechnologyDevelopmentFundDigit";
import PudongTecholonyInnovationVouncher from "@/views/conditionResult/pudong/PudongTecholonyInnovationVouncher";
import PudongTecholonyDevelopmentFundLoan from "@/views/conditionResult/pudong/PudongTecholonyDevelopmentFundLoan";
import PudongPearlPlanTalent from "@/views/conditionResult/pudong/PudongPearlPlanTalent";
import PudongPearlPlanLeadingTalent from "@/views/conditionResult/pudong/PudongPearlPlanLeadingTalent";
import PudongPearlPlanEliteTalent from "@/views/conditionResult/pudong/PudongPearlPlanEliteTalent";
import PudongPearlPlanEngineer from "@/views/conditionResult/pudong/PudongPearlPlanEngineer";
import PudongSupportOverseasReturnee from "@/views/conditionResult/pudong/PudongSupportOverseasReturnee";
import PudongPearlPlanTechnician from "@/views/conditionResult/pudong/PudongPearlPlanTechnician";
import PudongPromoteInvestment from "@/views/conditionResult/pudong/PudongPromoteInvestment";

//张江
import ZhangjiangScienceCitySpecialGrowth from "@/views/conditionResult/pudong/zhangjiang/ZhangjiangScienceCitySpecialGrowth";
import zhangjiangScienceCitySpecialNewEquityCapital from "@/views/conditionResult/pudong/zhangjiang/zhangjiangScienceCitySpecialNewEquityCapital";
import ZhangjiangScienceCitySpecialEquityCapital from "@/views/conditionResult/pudong/zhangjiang/ZhangjiangScienceCitySpecialEquityCapital";


const parentPath = '/authentication'

export default [
    {path: `${parentPath}/techolony-development-special`, name: 'techolony-development-special-result', component: PudongTecholonyDevelopmentSpecial,},
    {path: `${parentPath}/techolony-development-special-fund`, name: 'techolony-development-special-fund-result', component: PudongTecholonyDevelopmentSpecialFund,},
    {path: `${parentPath}/technolony-development-special-electronic`, name: 'technolony-development-special-electronic-result', component: PudongTechnolonyDevelopmentSpecialElectronic,},
    {path: `${parentPath}/promote-development-of-ic-and-communication`, name: 'promote-development-of-ic-and-communication-result', component: PudongPromoteDevelopmentOfIcAndCommunication,},
    {path: `${parentPath}/promote-development-of-medicine`, name: 'promote-development-of-medicine-result', component: PudongPromoteDevelopmentOfMedicine,},
    {path: `${parentPath}/promote-development-of-ai`, name: 'promote-development-of-ai-result', component: PudongPromoteDevelopmentOfAi,},
    {path: `${parentPath}/promote-development-of-highend-equipment`, name: 'promote-development-of-highend-equipment-result', component: PudongPromoteDevelopmentOfHighendEquipment,},
    {path: `${parentPath}/big-enterprise-innovation-center`, name: 'big-enterprise-innovation-center-result', component: PudongBigEnterpriseInnovationCenter,},
    {path: `${parentPath}/enterprise-research-center`, name: 'enterprise-research-center-result', component: PudongEnterpriseResearchCenter,},
    {path: `${parentPath}/technology-development-fund-digit`, name: 'technology-development-fund-digit-result', component: PudongTechnologyDevelopmentFundDigit,},
    {path: `${parentPath}/techolony-innovation-vouncher`, name: 'techolony-innovation-vouncher-result', component: PudongTecholonyInnovationVouncher,},
    {path: `${parentPath}/techolony-development-fund-loan`, name: 'techolony-development-fund-loan-result', component: PudongTecholonyDevelopmentFundLoan,},
    {path: `${parentPath}/pearl-plan-talent`, name: 'pearl-plan-talent-result', component: PudongPearlPlanTalent,},
    {path: `${parentPath}/pearl-plan-leading-talent`, name: 'pearl-plan-leading-talent-result', component: PudongPearlPlanLeadingTalent,},
    {path: `${parentPath}/pearl-plan-elite-talent`, name: 'pearl-plan-elite-talent-result', component: PudongPearlPlanEliteTalent,},
    {path: `${parentPath}/pearl-plan-engineer`, name: 'pearl-plan-engineer-result', component: PudongPearlPlanEngineer,},
    {path: `${parentPath}/support-overseas-returnee`, name: 'support-overseas-returnee-result', component: PudongSupportOverseasReturnee,},
    {path: `${parentPath}/pearl-plan-technician`, name: 'pearl-plan-technician-result', component: PudongPearlPlanTechnician,},
    {path: `${parentPath}/promote-investment`, name: 'promote-investment-result', component: PudongPromoteInvestment,},

    //张江
    {path: `${parentPath}/zhangjiang-science-city-special-growth`, name: 'zhangjiang-science-city-special-growth-result', component: ZhangjiangScienceCitySpecialGrowth,},
    {path: `${parentPath}/zhangjiang-science-city-special-new-equity-capital`, name: 'zhangjiang-science-city-special-new-equity-capital-result', component: zhangjiangScienceCitySpecialNewEquityCapital,},
    {path: `${parentPath}/zhangjiang-science-city-special-equity-capital`, name: 'zhangjiang-science-city-special-equity-capital-result', component: ZhangjiangScienceCitySpecialEquityCapital,},

]



