<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition high-growth-projects-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">浦东新区“明珠计划”高峰人才项目</div>
      <BasicInfo ref="basicInfo" :is-financial="false" :is-int-prop="false"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="申报类型："
                            required prop="applyType" v-if="false">
                <el-radio-group v-model="formData.applyType">
                  <el-radio :label="1">个人申报</el-radio>
                  <el-radio :label="2">项目申报</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="个人申报标准：" style="text-align: left">
                <el-checkbox-group v-model="formData.individualStandardArr">
                  <el-checkbox :label="1">1、国际性重大科学技术奖项获得者。</el-checkbox><br>
                  <el-checkbox :label="2">2、中国及其他重要创新国家科学技术奖项或学术荣誉获得者。</el-checkbox><br>
                  <el-checkbox :label="3">3、国家级、上海市级人才计划重点项目入选者或相当层面的高层次人才。</el-checkbox><br>
                  <el-checkbox :label="4">4、在世界知名大学、科研机构任职的科学家，或世界一流科技型企业的科学家、技术官等。</el-checkbox><br>
                  <el-checkbox :label="5">5、担任国家或上海市重大科技基础设施、重大平台机构、重大科研任务、大科学计划(工程)的领军科学家。</el-checkbox><br>
                  <el-checkbox :label="6" style="height: 50px"><span style="line-height: 21px">6、近5年内国家顶级期刊、顶级会议、顶级论坛等评出的杰出青年科技人才，包括但不限于:《麻省理工科技评论》年度35岁以下的全球最佳35名创新人才、世界经济论坛青<br>年科学家、《科学》杂志年度青年科学家等。</span></el-checkbox><br>
                  <el-checkbox :label="7">7、其他对引领区高端产业发展或科技创新策源具有战略性、决定性和带动性作用的人才。</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="项目申报标准：" style="text-align: left">
                <el-checkbox-group v-model="formData.projectStandardArr">
                  <el-checkbox :label="1" style="height: 50px"><span style="line-height: 21px">1、属产业项目的，2年内新增投资额原则上应不低于1亿元，项目技术能级属于国际领先或先进，项目投资强度及产出效益一般应高于同产业领域30%以上。对于技术创新度高、
                    <br>具有重大产业化潜力和前景的可适当放宽以上要求。</span></el-checkbox>
                  <el-checkbox :label="2" style="height: 50px"><span style="line-height: 21px">2、创新项目的，原则上须为2年内新立项项目，项目的实施将对于浦东新区加强基础研究和应用基础研究，打好关键核心技术攻坚战，加速科技成果向现实生产力转化，提升产
                    <br>业链水平具有重大引领性、突破性、示范性作用。</span></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="支持对象年龄一般不超过70周岁，原则上须已在用人单位全职工作或已与用人单位签订全职工作合同或意向协议，并承诺入选后在用人单位全职工作不少于三年。"
                            required prop="meetOtherStandard">
                <el-radio-group v-model="formData.meetOtherStandard">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPearlPlanTalent"
}
</script>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import MenuBar from '@/components/MenuBar.vue'
import BasicInfo from '@/components/BasicInfo.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'pudongPearlPlanTalent'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({
  individualStandardArr: [],
  projectStandardArr:[]
})
onMounted(() => {
  getProjectPudongByPath()
})
const getProjectPudongByPath = () => {
  companyApi.getProjectPudongByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
      formData.value.individualStandardArr = formData.value.individualStandardArr || []
      formData.value.projectStandardArr = formData.value.projectStandardArr || []
    }
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveProjectPudongByPath(urlPath, company).then((resp) => {
    if (resp.code === 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getProjectPudongByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitProjectPudongByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

</script>
<style scoped lang="scss">
.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
}
</style>
