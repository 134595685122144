<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition high-growth-projects-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">浦东新区促投资提能级专项</div>
      <BasicInfo ref="basicInfo" :is-financial="false" :is-int-prop="false"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="请选择符合您企业的专题类型："
                            required prop="meetRange">
                <el-radio-group v-model="formData.meetRange" style="text-align: left">
                  <el-radio :label="1" style="height: 150px;">
                    （一）集聚优质产业创新资源
                    <p class="radio-p">定期发布战略招商、精准招商目录，对新引进的产业生态塑造能力强、产业链带动能力强的领军企业、创新型企业、重点产
                    业配套企业等，按不超过实际投资的 50%，根据企业能级、综合贡献，分别给予不超过 5000 万元、2000 万元、1000 万元的奖励。
                    区级产业招商统筹，经区领导决策后事前立项，按项目投入或产出考核指标，分阶段拨付奖励资金。对新引进的企业和项目，
                    在后续享受与贡献度挂钩的财政扶持政策时，应予以查重扣除。
                    </p>
                  </el-radio>
                  <el-radio :label="2" style="height: 150px;">
                    （二）鼓励企业加大投资力度
                    <p class="radio-p">
                      1、对纳入工业固定资产投资统计的、投资总额 1000 万元及以上的项目，项目建设完成后、经申报评定为浦东新区重点投资项目的，验收完成后，按照不超过项 目核定固定资产投资额的10%予以奖励。
                      <br>2、对经申报评定为上海市重点技术改造项目的，纳入浦东新区重点投资项目给予立项支持，验收完成后，按照不超过市核定的固定资产投资额的 10%予以奖励， 且单个项目市、区两级总支持金额不超过该项目核定固定资产投资额的 15%。
                      <br>以上项目优先考虑符合“六大硬核”优势产业定位，单个项目奖励不超过 2000 万元；企业两年内不得关停并转或外迁。
                    </p>
                  </el-radio>
                  <el-radio :label="3" style="height: 120px;">
                    （三）支持产业规模跃升
                    <p class="radio-p">
                      对纳入浦东新区规上企业统计范围，连续生产经营两年以上，且近三年未出现负增长（不可抗因素除外），产值或增速达到一
                      定条件的制造业、软件信息服务业、科技服务业企业，给予奖励。
                    </p>
                  </el-radio>
                  <el-radio :label="4" style="height: 120px;">
                    （四）配套支持
                    <p class="radio-p">对获得上海市级专项资金立项资助的项目，给予一定配套奖励。对配套要求明确的，按立项时明确的区级配套资金足额给予
                    配套支持。对未明确配套要求的，根据申报指南明确的市级专项政策配套目录，经区相关部门协商一致，可在项目验收完成后，
                    给予不超过企业获得上海市级项目扶持资金 50%的奖励，最高5000 万元。企业项目已获新区支持的，视同区级配套或奖励部
                    分，可补差支持
                    </p>
                  </el-radio>
                  <el-radio :label="0">以上均不符合</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPromoteInvestment"
}
</script>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import MenuBar from '@/components/MenuBar.vue'
import BasicInfo from '@/components/BasicInfo.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'pudongPromoteInvestment'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({
})
onMounted(() => {
  getProjectPudongByPath()
})
const getProjectPudongByPath = () => {
  companyApi.getProjectPudongByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveProjectPudongByPath(urlPath, company).then((resp) => {
    if (resp.code === 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getProjectPudongByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitProjectPudongByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

</script>
<style scoped lang="scss">
.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }

  :deep .el-radio__label{
    white-space: normal;  /* 换行 */
  }

  :deep .el-checkbox__label{
    white-space: normal;  /* 换行 */
  }

  .radio-p {
    width: 1080px;
    word-wrap: break-word;
    background: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
    line-height: 21px;
    margin-top: 0;
    text-align: left;
  }
}
</style>
