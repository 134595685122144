<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class="condition sh-tech-center-container">
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">上海市企业技术中心认定</div>
      <BasicInfo ref="basicInfo" :is-financial="true" :is-int-prop="true"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'">
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-row :gutter="0">
                <el-col :span="24">
                  <div class="tip custom-block">
                    <p><strong style="color: #ff0000">注意：初次申报 市级企业技术中心 的前提是您的企业已具有
                      区级企业技术中心资质</strong>。</p>
                    <p>注意“企业研究开发仪器设备原值及研发用软件购置费”、“新产品销售收入”、“新产品销售利润”金额单位为
                      <strong style="color: #ff0000">千元</strong>。</p>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="50">
                <el-col :span="8">
                  <el-form-item required prop="highLevelRdPersonnel">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          技术中心高层次研发人数：指截至报告年度末全职在技
                          <br>术中心工作的高级专家和博士人数（包括在站博士后）。高级
                          <br>专家包括获得国家、省、部等政府部门认定的有突出贡献的专
                          <br>家，享受国家、省、部专项津贴的专家，具有高级职称的专家
                          <br>（含高级技师），以及纳入各级人才计划的专家。
                        </template>
                        <span>
                          <span>技术中心高层次研发人数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.highLevelRdPersonnel"
                                  @update:modelValue="formData.highLevelRdPersonnel = $event" :is-decimal="false"
                                  :append="'人'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item required prop="externalExpertsWorkHours">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          来技术中心从事研发工作的外部专家工作时间：指截至
                          <br>报告年度末来技术中心从事研究、技术开发工作的具有较高科
                          <br>技开发能力的海内外专家累计人月。最小统计单位为：0.5 人
                          <br>月。（注：1 人月指一个人在报告年度内工作满 22 个工作日）。
                        </template>
                        <span>
                          <span>来技术中心从事研发工作的外部专家工作时间</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.externalExpertsWorkHours"
                                  @update:modelValue="formData.externalExpertsWorkHours = $event" :is-decimal="false"
                                  :append="'人月'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item required prop="foreignCooperationProjects">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          企业对外合作项目数：指企业在报告年度当年立项并开
                          <br>展研发（制）工作、以前年份立项仍继续进行研发（制）的研
                          <br>究开发项目或课题中，与境内外高校、科研院所、产业链上下
                          <br>游企业等联合开展研发的项目数，但不包括完全委托外单位进
                          <br>行研发的项目。
                        </template>
                        <span>
                          <span>企业对外合作项目数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.foreignCooperationProjects"
                                  @update:modelValue="formData.foreignCooperationProjects = $event" :is-decimal="false"
                                  :append="'项'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item required prop="changjiangDelta">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          长三角等区域合作项目数：是指企业对外合作项目中，
                          <br>合作对象是位于浙江省、江苏省和安徽省的企业、高校或研究
                          <br>机构。
                        </template>
                        <span>
                          <span>其中：长三角区域合作项目数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.changjiangDelta"
                                  @update:modelValue="formData.changjiangDelta = $event" :is-decimal="false"
                                  :append="'项'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item required prop="basicApplication">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          基础研究和应用研究项目数：指企业在报告年度当年立
                          <br>项并开展研发（制）工作、以前年份立项仍继续进行研发（制）
                          <br>的研究开发项目或课题中，主要以科学原理的探索与发现、技
                          <br>术原理的研究为目标的项目数。
                        </template>
                        <span>
                          <span>基础研究和应用研究项目数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.basicApplication"
                                  @update:modelValue="formData.basicApplication = $event" :is-decimal="false"
                                  :append="'项'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item required prop="acquisitionCosts">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          企业技术开发仪器设备原值及研发用软件购置费：指
                          <br>报告年度末企业用于研发的固定资产中的仪器和设备原价以
                          <br>及软件购置费用。其中，设备包括用于研发活动的各类机器和
                          <br>设备、试验测量仪器、运输工具、工装工具等，软件购置费用
                          <br>包括用于研发活动的开发软件、设计软件、测试软件等。
                        </template>
                        <span>
                          <span style="font-size: 12px">企业研究开发仪器设备原值及研发用软件购置费<strong
                              style="color: #ff0000">（千元）</strong></span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.acquisitionCosts"
                                  @update:modelValue="formData.acquisitionCosts = $event" :is-decimal="true" :digit="4"
                                  :append="'千元'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item required prop="institutionsNumber">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          拥有经认证的研发平台、实验室和检测机构数：指企
                          <br>业作为项目法人承担建设、国家或省级有关部门归口管理且已
                          <br>经获得批复的科技类、研究开发类平台数，以及通过中华人民
                          <br>共和国有关国家部门、国际组织企业认证认可的、仍在有效期
                          <br>内的实验室、检验检测机构数。
                        </template>
                        <span>
                          <span>拥有经认证的研发平台、实验室和检测机构数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.institutionsNumber"
                                  @update:modelValue="formData.institutionsNumber = $event" :is-decimal="false"
                                  :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item required prop="jointInstitutionsNumber">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          技术中心与其他组织合办研发机构数：指技术中心与
                          <br>高校、研究院所、其他企业联合设立的以科研开发设计为目的
                          <br>的组织机构数量，包括共建实验室、基地、院士工作站等。
                        </template>
                        <span>
                          <span>技术中心与其他组织合办研发机构数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.jointInstitutionsNumber"
                                  @update:modelValue="formData.jointInstitutionsNumber = $event" :is-decimal="false"
                                  :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item required prop="newsProdSalesRevenue">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          新产品销售收入：对于制造业企业，新产品销售收入
                          <br>指报告年度内企业销售采用新技术原理、新设计构思研制、生
                          <br>产的全新产品，或在结构、材质、工艺等某一方面比原有产品
                          <br>有明显改进，从而显著提高了产品性能或扩大了使用功能的产
                          <br>品实现的销售收入。新产品既包括经政府有关部门认定并在有
                          <br>效期内的新产品，也包括企业自行研制开发，未经政府有关部
                          <br>门认定，从投产之日起一年之内的新产品。
                          <br>对于建筑业企业，新产品销售收入指报告年度内企业采用
                          <br>新技术、新工艺、新结构、新材料等实现的营业收入。
                          <br>对于服务业企业，新产品销售收入指报告年度内企业通过
                          <br>提供在服务内容、服务方式、服务传递系统、服务技术手段等
                          <br>方面全新的、或者作出明显改进的服务实现的营业收入。
                        </template>
                        <span>
                          <span>新产品销售收入<strong style="color: #ff0000">（千元）</strong></span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.newsProdSalesRevenue"
                                  @update:modelValue="formData.newsProdSalesRevenue = $event" :is-decimal="true"
                                  :digit="4" :append="'千元'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item required prop="newsProdProfit">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          新产品销售利润：指报告年度内企业通过销售新产品实现的销售（营业）利润。
                        </template>
                        <span>
                          <span>新产品销售利润<strong style="color: #ff0000">（千元）</strong></span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.newsProdProfit"
                                  @update:modelValue="formData.newsProdProfit = $event" :is-decimal="true" :digit="4"
                                  :append="'千元'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item required prop="createStandardCount">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          最近三年主持和参加制定的标准数：指企业在报告年
                          <br>度、报告年度前一年、报告年度前二年主持或参加制定，目前
                          <br>仍有效执行的国际、国家、地方、行业、团体和企业标准的数
                          <br>量，包括工程建设工法数。
                        </template>
                        <span>
                          <span>最近三年主持和参加制定的标准</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.createStandardCount"
                                  @update:modelValue="formData.createStandardCount = $event" :is-decimal="false"
                                  :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item required prop="ipInternationalCount">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          企业拥有的全部国际专利数：指报告年度末企业作为
                          <br>专利权人拥有的、经国外知识产权行政部门授予且在有效期内
                          <br>的专利件数。
                        </template>
                        <span>
                          <span>企业拥有的全部国际专利数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.ipInternationalCount"
                                  @update:modelValue="formData.ipInternationalCount = $event" :is-decimal="false"
                                  :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item required prop="ipHighQualityCount">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          企业拥有高质量专利数：包括在海外有同族专利权的
                          <br>发明专利，维持年限超过 10 年以上的发明专利，获得国家科
                          <br>学技术奖或中国专利奖的发明专利。
                        </template>
                        <span>
                          <span>企业拥有高质量专利数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.ipHighQualityCount"
                                  @update:modelValue="formData.ipHighQualityCount = $event" :is-decimal="false"
                                  :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item required prop="overseasInstitutions">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          技术中心在境外设立的研发机构数：<br>指技术中心在港澳台地区及国外设立以科研开发、设计为目的的研发机构数量。
                        </template>
                        <span>
                          <span>技术中心在境外设立的研发机构数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.overseasInstitutions"
                                  @update:modelValue="formData.overseasInstitutions = $event" :is-decimal="false"
                                  :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="未提交快报材料次数">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          初次认定的企业默认填写0；
                          <br>如果您已具有市级企业技术中心资质，应当定期填报企业技术创新快报。
                          <br>市经济信息化委按照企业技术中心管理要求，定期发布技术创新快报填报通知。
                        </template>
                        <span>
                          <span>未提交快报材料次数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.unsubmitQuickReport"
                                  @update:modelValue="formData.unsubmitQuickReport = $event" :is-decimal="false"
                                  :append="'次'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item required prop="honorNationalCount">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          近两年年获国家、省部级或行业奖励数：指企业在报
                          <br>告年度、报告年度前一年度，报告年度前两年度获得的，由国
                          <br>务院或省级人民政府以及国家行业学会或协会颁发的奖励数。
                        </template>
                        <span>
                          <span>近两年获国家、省（市）部级或行业奖励数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <div class="normal-show">
                      国家奖励数：
                      <input-number size="small" v-model="formData.honorNationalCount"
                                    @update:modelValue="formData.honorNationalCount = $event" :is-decimal="false"/>
                      项，
                      省部级奖励数：
                      <input-number size="small" v-model="formData.honorProvincialCount"
                                    @update:modelValue="formData.honorProvincialCount = $event" :is-decimal="false"/>
                      项。
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item required prop="ipNationalCount">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          近两年承担国家和省市专项数：指企业在报告年度、
                          <br>报告年度前一年度，报告年度前两年度承担的，由国家部委和
                          <br>地方政府部门批准立项并开展研发（制）工作的专项数量。
                        </template>
                        <span>
                          <span>近两年承担市级以上专项数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <div class="normal-show">
                      国家专项：
                      <input-number size="small" v-model="formData.ipNationalCount"
                                    @update:modelValue="formData.ipNationalCount = $event" :is-positive="true"
                                    :is-decimal="false"/>
                      项，
                      省市级专项：
                      <input-number size="small" v-model="formData.ipProvincialCount"
                                    @update:modelValue="formData.ipProvincialCount = $event" :is-positive="true"
                                    :is-decimal="false"/>
                      项。
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ShPatentDemoEnterprise"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import BasicInfo from '@/components/BasicInfo'
import Breadcrumb from '@/components/Breadcrumb'
import {CircleCheckFilled, Finished, Promotion, QuestionFilled} from "@element-plus/icons-vue";
import InputNumber from '@/components/InputNumber'
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'shTechCenter'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref('')
const formData = ref({
  highLevelRdPersonnel: 0,
  externalExpertsWorkHours: 0,
  foreignCooperationProjects: 0,
  changjiangDelta: 0,
  basicApplication: 0,
  acquisitionCosts: 0,
  institutionsNumber: 0,
  jointInstitutionsNumber: 0,
  newsProdSalesRevenue: 0,
  newsProdProfit: 0,
  createStandardCount: 0,
  honorNationalCount: 0,
  honorProvincialCount: 0,
  ipNationalCount: 0,
  ipProvincialCount: 0,
  ipInternationalCount: 0,
  ipHighQualityCount: 0,
  overseasInstitutions: 0,
  unsubmitQuickReport: 0,
})
onMounted(() => {
  getConditionByPath()
})
const getConditionByPath = () => {
  companyApi.getConditionByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  })
}

const isResult = ref(true)
const loading = ref(false)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveConditionByPath(urlPath, company).then((resp) => {
    if (resp.code == 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getConditionByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitConditionByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {

          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          console.log('error', fields)
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}
</script>
<style scoped lang="scss">
.sh-tech-center-container {

  .custom-block {
    text-align: left;
    border-radius: 4px;
    padding: 8px 16px;
    margin-bottom: 18px;
    border-left: 5px solid var(--el-color-primary);
    background-color: rgba(64, 158, 255, .1);
  }

  .custom-block .custom-block-title {
    font-weight: 700
  }

  .if-true-show, .normal-show {
    text-align: left;

    :deep .el-form-item .el-form-item {
      display: inline-block;
    }

    :deep .el-input--small {
      display: inline-block;
      width: 100px;
      padding-bottom: 4px;
    }

    :deep .el-radio-group {
      vertical-align: middle;
    }

    :deep .el-checkbox-group {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .if-true-show {
    margin-left: 20px;
  }
}

.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }

}
</style>
