<template>
  <el-header>
    <div class="mobile-header">
      <div class="mobile-logo" @click='$router.push({path:"/mobile"})'></div>
      <div class="mobile-un-login" v-if='userStore.id===null'>
        <router-link to="/mobile/login">登录</router-link>
        <span>|</span>
        <router-link to="/mobile/register">注册</router-link>
      </div>
      <div class='mobile-user-info' v-if='userStore.id!=null'>
        <p>
          <router-link to="/mobile/account/">用户中心</router-link>|<span @click="logout">退出</span>
        </p>
      </div>
    </div>
  </el-header>
</template>

<script setup>
import {ElMessageBox} from 'element-plus'
import useUserStore from '@/store/modules/user'
import {toRefs, defineProps, onMounted} from 'vue'
import router from "@/router";

const props = defineProps({
  //子组件接收父组件传递过来的值
  title: String,
})
//使用父组件传递过来的值
const {title} = toRefs(props)

const userStore = useUserStore()


function logout() {
  ElMessageBox.confirm('确定退出系统吗？', '系统提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    userStore.logOut().then(() => {
      router.push('/mobile')
    })
  }).catch(() => {
  });
}

onMounted(() => {

})

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.el-header {
  --el-header-padding: 0;
  --el-header-height: 52px;
  padding: 0;
  margin: 0;
}

.mobile-header {
  height: 52px;
  background-color: #5C7EF4;
  padding: 0 10px;
  line-height: 52px;

  .mobile-logo {
    width: 121px;
    height: 52px;
    float: left;
    cursor: pointer;
    background-size: contain;
    background-image: url("@/assets/logo01.png");
    background-repeat: no-repeat;
  }

  .mobile-un-login {
    text-align: right;

    a {
      font-size: 16px;
      color: #eeeeee;
      text-decoration: none;
      display: inline-block;
    }

    span {
      display: inline-block;
      padding: 0 15px;
      vertical-align: top;
      color: #eeeeee;
    }
  }

  .mobile-user-info {
    text-align: right;

    p {
      font-size: 17px;
      color: #eeeeee;
      margin: 0;
      text-align: right !important;

      a {
        font-size: 18px;
        color: #eeeeee;
        display: inline-block;
        margin-right: 8px;
        text-decoration: none;
      }

      span {
        font-size: 18px;
        margin-left: 8px;
        color: #eeeeee;
        cursor: pointer;
      }
    }

  }

}
</style>
