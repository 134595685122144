import request from '@/utils/request'
import axios from 'axios'

export function checkVideo(videoUrl) {
    return axios.head(videoUrl)
        .then(req => true)
        .catch(e => false)
}

export function getList(pageSize, pageNum) {
    return request({
        url: `/open/policyInfo/list`,
        method: 'get',
        params: {pageSize, pageNum}
    })
}

export function getDetail(id) {
    return request({
        url: `/open/policyInfo/${id} `,
        method: 'get'
    })
}

export default {
    getList, getDetail, checkVideo
}
