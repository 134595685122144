import request from '@/utils/request'

export function getList(data) {
    return request({
        url: `/open/project/list`,
        method: 'get',
        params: {...data, pageNum: data.pageNo}
    })
}

export function getDetail(id) {
    return request({
        url: `/open/project/${id}`,
        method: 'get'
    })
}

export function getHotList() {
    return request({
        url: `/open/project/hotList`,
        method: 'get'
    })
}

export default {
    getList, getDetail, getHotList
}
