<template>
  <div class="menu-bar-container"  v-if="false" >
    <el-menu mode="horizontal" :default-active="menuBarActive" :ellipsis="false" :router="true">
      <el-menu-item index="/">
        <span>首页</span>
      </el-menu-item>
      <el-menu-item index="/project-declaration">
        <span>项目测评</span>
      </el-menu-item>
      <el-menu-item  index="/policy-encyclopedia">
        <span>政策大全</span>
      </el-menu-item>
      <el-menu-item v-if="false" index="/declaration-announcement">
        <span>申报公告</span>
      </el-menu-item>
      <el-menu-item index="/policy-interpretation">
        <span>政策解读</span>
      </el-menu-item>
      <div class="flex-grow"/>
      <el-menu-item index="/single-page-about">
        <span>关于我们</span>
      </el-menu-item>
    </el-menu>
  </div>
  <div class="fill"></div>
</template>


<script setup>
import {toRefs, defineProps, onMounted} from 'vue'

const props = defineProps({
  //子组件接收父组件传递过来的值
  title: String,
  menuBarActive: String
})
//使用父组件传递过来的值
const {menuBarActive} = toRefs(props)


onMounted(() => {
})

</script>

<style lang="scss" scoped>
.el-divider {
  margin: 0;
}
.fill{
  width: 100%;
  //height: 54px;
}
.menu-bar-container {
  width: 100%;
  background-color: #CBB486;
  margin: 0 auto 0;
  position: fixed;
  top: 72px;
  left: 0;
  z-index: 996;

  .el-menu {
    width: 1200px;
    margin: 0 auto;
    background-color: #CBB486;
    font-size: 18px;
    border-bottom: unset;
  }

  .el-menu-item {
    height: 54px;
    padding: 0;
    margin-right: 40px;
    font-size: 18px;
    color: rgba(255, 255, 255, 1);

    &:last-child {
      margin-right: 0
    }

    &:hover {
      background-color: unset !important;
      color: #ffffff !important;
    }

    &:focus {
      background-color: unset;
      color: #ffffff;
    }

    &.is-active {
      background-color: unset;
      color: #ffffff;
      border-bottom: 3px solid rgba(255, 255, 255, 1);
    }
  }

  .el-menu--horizontal > .el-menu-item.is-active {
    color: #ffffff !important;
  }
  .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
    background-color: #CBB486;
  }
  .flex-grow {
    flex-grow: 1;
  }
}
</style>
