<template>
  <div class="common-layout fixed">
    <el-container>
      <HeaderView title="企业用户登录"/>
      <el-main>
        <div class="mobile-login">
          <div class="mobile-login-logo"></div>
          <div class="mobile-login-form">
            <h2>企业用户登录</h2>
            <el-form ref="loginRef"
                     :model="loginForm"
                     :rules="loginRules"
                     class="el-form"
                     label-position='top'
                     label-width="120px"
                     status-icon
                     size="large"
            >
              <el-form-item label="用户名（手机号）" prop="username">
                <el-input v-model="loginForm.username"
                          type="text"
                          placeholder="请输入手机号码"
                          @keyup.enter="handleLogin">
                  <template #prefix>
                    <el-icon class="el-input__icon">
                      <User/>
                    </el-icon>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="密 码">
                <el-input v-model="loginForm.password"
                          type="password"
                          placeholder="请输入密码"
                          @keyup.enter="handleLogin">
                  <template #prefix>
                    <el-icon class="el-input__icon">
                      <Lock/>
                    </el-icon>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="code" v-if="captchaEnabled">
                <el-input
                    v-model="loginForm.code"
                    size="large"
                    auto-complete="off"
                    placeholder="请输入验证码"
                    style="width: 60%"
                    @keyup.enter="handleLogin"
                >
                  <template #prefix>
                    <el-icon class="el-input__icon">
                      <CircleCheck/>
                    </el-icon>
                  </template>
                </el-input>
                <div class="login-code">
                  <img :src="codeUrl" @click="getCode" class="login-code-img"/>
                </div>
              </el-form-item>
              <p style="text-align: left; padding: 0">
                <el-checkbox v-model="loginForm.rememberMe">记住密码</el-checkbox>
              </p>
              <el-form-item>
                <el-button
                    :loading="loading"
                    size="large"
                    class="submit"
                    @click.prevent="handleLogin"
                >
                  <span v-if="!loading">登 录</span>
                  <span v-else>登 录 中...</span>
                </el-button>
              </el-form-item>
            </el-form>
            <p>没有账号？
              <router-link to='/mobile/register'>去注册</router-link>
            </p>
          </div>
        </div>
      </el-main>
      <el-footer>

      </el-footer>
    </el-container>
  </div>
</template>

<script setup>
import HeaderView from '@/components/mobile/Header'
import {User, Lock, CircleCheck} from '@element-plus/icons-vue'

import {getCurrentInstance, ref} from "vue";
import {getCodeImg} from "@/api/login";
import Cookies from "js-cookie";
import {encrypt, decrypt} from "@/utils/jsencrypt";
import useUserStore from '@/store/modules/user'

const userStore = useUserStore()
import router from "@/router";
import cache from "@/plugins/cache";

const {proxy} = getCurrentInstance();

const loginForm = ref({
  username: "",
  password: "",
  rememberMe: false,
  code: "",
  uuid: ""
});

const loginRules = {
  username: [{required: true, trigger: "blur", message: "请输入您的账号"}],
  password: [{required: true, trigger: "blur", message: "请输入您的密码"}],
  code: [{required: true, trigger: "change", message: "请输入验证码"}]
};

const codeUrl = ref("");
const loading = ref(false);
// 验证码开关
const captchaEnabled = ref(true);
// 注册开关
const redirect = ref(undefined);

function handleLogin() {
  proxy.$refs.loginRef.validate(valid => {
    if (valid) {
      loading.value = true;
      // 勾选了需要记住密码设置在 cookie 中设置记住用户名和密码
      if (loginForm.value.rememberMe) {
        Cookies.set("username", loginForm.value.username, {expires: 30});
        Cookies.set("password", encrypt(loginForm.value.password), {expires: 30});
        Cookies.set("rememberMe", loginForm.value.rememberMe, {expires: 30});
      } else {
        // 否则移除
        Cookies.remove("username");
        Cookies.remove("password");
        Cookies.remove("rememberMe");
      }
      // 调用action的登录方法
      userStore.login(loginForm.value).then(() => {
        cache.session.setJSON('sessionObj', '')
        router.push({path: redirect.value || "/mobile"});
      }).catch(() => {
        loading.value = false;
        // 重新获取验证码
        if (captchaEnabled.value) {
          getCode();
        }
      });
    }
  });
}

function getCode() {
  getCodeImg().then(res => {
    captchaEnabled.value = res.captchaEnabled === undefined ? true : res.captchaEnabled;
    if (captchaEnabled.value) {
      codeUrl.value = "data:image/gif;base64," + res.img;
      loginForm.value.uuid = res.uuid;
    }
  });
}

function getCookie() {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  const rememberMe = Cookies.get("rememberMe");
  loginForm.value = {
    username: username === undefined ? loginForm.value.username : username,
    password: password === undefined ? loginForm.value.password : decrypt(password),
    rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
  };
}

getCode();
getCookie();

</script>

<style lang="scss" scoped>
.common-layout {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;

  .el-container {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  &.fixed {
    .el-container {
      flex: 1;
    }

    .el-footer {
      --el-footer-height: unset;
    }
  }
  .el-footer {
    --el-footer-padding: 0;
    margin-top: 100px;
  }

  .el-main {
    padding: 0 10px;

    .mobile-login {
      padding-top: 10px;


      .mobile-login-logo {
        margin: auto;
        width: 264px;
        height: 231px;
        background: url("@/assets/login&register-logo.png") no-repeat;
      }

      .mobile-login-form {
        h2 {
          font-size: 25px;
          color: #1F0757;
          font-weight: 400;
          margin: 15px 0;
        }

        .login-code {
          .login-code-img {
            height: 38px;
            margin-left: 38px;
            vertical-align: middle;
          }
        }



        .submit {
          width: 100%;
          height: 45px;
          line-height: 20px;
          border-radius: 5px;
          //background: linear-gradient(264.2deg, rgba(185, 145, 107, 1) 1.7%, rgba(228, 200, 149, 1) 93.74%);
          background: #025CE2;
          color: rgba(255, 255, 255, 100);
          font-size: 18px;
          text-align: center;
          margin-top: 20px;
        }

        p {
          margin: 0;
          padding: 20px 0 0 0;
          color: #101010;
          font-size: 16px;

          a {
            color: #BDA26D;
            text-decoration: none;
          }
        }
      }

    }
  }
}
</style>
