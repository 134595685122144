import request from '@/utils/request'

export function updateCompany(data) {
  return request({
    url: '/sme/user/qd',
    method: 'put',
    data: data
  })
}

export function getCompany() {
  return request({
    url: '/sme/user/getInfo',
    method: 'get'
  })
}

export function resetPassword(data) {
  return request({
    url: '/sme/user/resetPwd',
    method: 'put',
    data: data
  })
}

export function listApplied() {
  return request({
    url: '/sme/request/qd/list',
    method: 'get',
    params: {}
  })
}

// 会员申请
export function membership() {
  return request({
    url: '/sme/user/free/membership',
    method: 'get'
  })
}

// 获取会员天数
export function getVipDayNum() {
  return request({
    url: '/sme/user/getVipDayNum',
    method: 'get'
  })
}
