<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>上海市企业技术中心-认定评分明细</div>
      <div class="result-score">
        <div class="title"><span>*</span>市级企业技术中心评价结果分为优秀、良好、基本合格、不合格：
          <br>（一）评价得分 90 分及以上为优秀；
          <br>（二）评价得分 65 分至 90 分（不含 90 分）为良好；
          <br>（三）评价得分 60 分（含 60 分）至 65 分（不含 65 分）为基本合格；
          <br>（四）评价得分低于 60 分为不合格。
          <br>评价结果不合格的企业技术中心，由市经济信息化委会同相关部门撤销其资格；评价结果基本合格的企业技术中心，由所在地的区政府业务主管部门负责督促并指导整改。</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th style="width: 80px;">一级指标</th>
              <th style="width: 80px;">二级指标</th>
              <th style="width: 50px;">权重</th>
              <th>三级指标</th>
              <th style="width: 50px;">单位</th>
              <th style="width: 50px;">权重</th>
              <th style="width: 130px;">基本要求</th>
              <th style="width: 80px;">满分标准</th>
              <th style="width: 124px;">得分</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td rowspan="5">创新投入</td>
              <td rowspan="2">创新经费</td>
              <td rowspan="2">28</td>
              <td>研发人员人均研发经费支出</td>
              <td>千元</td>
              <td>12</td>
              <td>≥180</td>
              <td>870</td>
              <td class="core">{{ (result[0] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>研发经费支出占主营业务收入的比重</td>
              <td>%</td>
              <td>16</td>
              <td>≥3（乘以规模系数和行业系数后）</td>
              <td>20</td>
              <td class="core">{{ (result[1] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="3">创新人才</td>
              <td rowspan="3">16</td>
              <td>研发人员占企业职工总数的比重</td>
              <td>%</td>
              <td>10</td>
              <td>≥7</td>
              <td>60</td>
              <td class="core">{{ (result[2] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>技术中心拥有的高层次人才数</td>
              <td>人</td>
              <td>4</td>
              <td>≥3</td>
              <td>35</td>
              <td class="core">{{ (result[3] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>来技术中心从事研发工作的外部专家工作时间</td>
              <td>人月</td>
              <td>2</td>
              <td>≥5</td>
              <td>30</td>
              <td class="core">{{ (result[4] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="6">创新条件</td>
              <td rowspan="3">技术积累</td>
              <td rowspan="3">12</td>
              <td>企业拥有的全部Ⅰ类知识产权数</td>
              <td>件</td>
              <td>6</td>
              <td>≥5</td>
              <td>55</td>
              <td class="core">{{ (result[5] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>企业对外合作项目数</td>
              <td>项</td>
              <td>3</td>
              <td>≥1</td>
              <td>5</td>
              <td class="core">{{ (result[6] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>基础研究和应用研究项目数</td>
              <td>项</td>
              <td>3</td>
              <td>≥1</td>
              <td>5</td>
              <td class="core">{{ (result[7] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="3">创新平台</td>
              <td rowspan="3">12</td>
              <td>企业研究开发仪器设备原值及研发用软件购置费</td>
              <td>千元</td>
              <td>6</td>
              <td>≥10000</td>
              <td>800000</td>
              <td class="core">{{ (result[8] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>拥有经认证的研发平台、实验室和检测机构数</td>
              <td>个</td>
              <td>4</td>
              <td>≥1</td>
              <td>4</td>
              <td class="core">{{ (result[9] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>技术中心与其他组织合办研发机构数</td>
              <td>个</td>
              <td>2</td>
              <td>≥1</td>
              <td>3</td>
              <td class="core">{{ (result[10] || {}).score || 0 }}</td>
            </tr>

            <tr>
              <td rowspan="6">创新绩效</td>
              <td rowspan="3">技术产出</td>
              <td rowspan="3">12</td>
              <td>当年被受理的Ⅰ类知识产权数申请数</td>
              <td>件</td>
              <td>6</td>
              <td>≥1</td>
              <td>32</td>
              <td class="core">{{ (result[11] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>当年被受理的PCT专利申请数</td>
              <td>件</td>
              <td>2</td>
              <td>≥1</td>
              <td>2</td>
              <td class="core">{{ (result[12] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>最近三年主持和参加制定的标准数</td>
              <td>项</td>
              <td>4</td>
              <td>≥1</td>
              <td>10</td>
              <td class="core">{{ (result[13] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="3">创新效益</td>
              <td rowspan="3">20</td>
              <td>新产品销售收入占主营业务收入的比重</td>
              <td>%</td>
              <td>8</td>
              <td>≥20（乘以行业系数后）</td>
              <td>60</td>
              <td class="core">{{ (result[14] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>新产品销售利润占利润总额的比重</td>
              <td>%</td>
              <td>7</td>
              <td>≥15（乘以行业系数后）</td>
              <td>60</td>
              <td class="core">{{ (result[15] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>利润率</td>
              <td>%</td>
              <td>5</td>
              <td>≥5</td>
              <td>30</td>
              <td class="core">{{ (result[16] || {}).score || 0 }}</td>
            </tr>

            <tr>
              <td rowspan="6">加分</td>
              <td rowspan="6">加分</td>
              <td rowspan="6">20</td>
              <td>近两年获国家、省（市）部级或行业奖励数</td>
              <td>项</td>
              <td>≤3</td>
              <td>国家奖励一个加2分；省部级或行业奖励一个加1分</td>
              <td></td>
              <td class="core">{{ (result[17] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>近两年承担市级以上专项数</td>
              <td>项</td>
              <td>≤5</td>
              <td>国家专项加2分；省市级专项加1分</td>
              <td></td>
              <td class="core">{{ (result[18] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>企业拥有的全部有效国际专利数</td>
              <td>件</td>
              <td>≤4</td>
              <td>每有一个加1分</td>
              <td></td>
              <td class="core">{{ (result[19] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>企业拥有高质量专利数</td>
              <td>件</td>
              <td>≤4</td>
              <td>每有一个加1分</td>
              <td></td>
              <td class="core">{{ (result[20] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>技术中心在境外设立研发机构数</td>
              <td>个</td>
              <td>≤2</td>
              <td>每有一个加1分</td>
              <td></td>
              <td class="core">{{ (result[21] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>企业参与长三角区域合作项目数</td>
              <td>个</td>
              <td>≤2</td>
              <td>每有一个加1分</td>
              <td></td>
              <td class="core">{{ (result[22] || {}).score || 0 }}</td>
            </tr>

            <tr>
              <td>减分</td>
              <td>减分</td>
              <td>6</td>
              <td>未提交快报材料</td>
              <td>次</td>
              <td>≤6</td>
              <td>一次未提交扣3分</td>
              <td></td>
              <td class="core">{{ (result[23] || {}).score || 0 }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="result-total">评价总得分： <span>{{ total }}</span> 分</div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "result_2"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score
  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>


<style scoped lang="scss">

</style>
