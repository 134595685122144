import request from '@/utils/request'

// 服务项目列表
export function getServiceItems() {
  return request({
    url: '/sme/item/qd/list',
    method: 'get',
    params: {}
  })
}

// 服务项目详情
export function getServiceItemDetails(code) {
  return request({
    url: '/sme/item/qd/'+code,
    method: 'get',
    params: {}
  })
}

export function serviceApplication(serviceItemId) {
  return request({
    url: '/sme/request/qd',
    method: 'post',
    data: {serviceItemId}
  })
}

export function serviceProviderRegistration(data){
  return request({
    url: '/sme/provider/qd',
    method: 'post',
    data: data
  })
}