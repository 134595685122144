import request from '@/utils/request'
/**
 * 行业分类 sys_industry_category
 * 上海区域 sys_sh_district
 * 政策分类 sys_policy_types
 * 委办局 sys_weibanju
 * 申报项目时效性 sme_project_progress
 * @param type
 * @returns {*}
 */
export function getDict(type) {
    return request({
        url: `/system/dict/data/type/${type}`,
        method: 'get'
    })
}

export async function getDictStorage() {
    await getDict('sys_industry_category').then(resp => {
        const industryCategories = resp.data.map(item => {
            return {dictLabel: item.dictLabel, dictValue: item.dictValue}
        })
        sessionStorage.setItem('industryCategories', JSON.stringify(industryCategories))
        sessionStorage.setItem('industryCategoriesEnum', JSON.stringify(reduceEnum(industryCategories)))
    })
    await getDict('sys_sh_district').then(resp => {
        const districts = resp.data.map(item => {
            return {dictLabel: item.dictLabel, dictValue: item.dictValue}
        })
        sessionStorage.setItem('districts', JSON.stringify(districts))
        sessionStorage.setItem('districtEnum', JSON.stringify(reduceEnum(districts)))
    })
    await getDict('sys_policy_types').then(resp => {
        const policyTypes = resp.data.map(item => {
            return {dictLabel: item.dictLabel, dictValue: item.dictValue}
        })
        sessionStorage.setItem('policyTypes', JSON.stringify(policyTypes))
        sessionStorage.setItem('policyTypesEnum', JSON.stringify(reduceEnum(policyTypes)))
    })
    await getDict('sys_weibanju').then(resp => {
        const offices = resp.data.map(item => {
            return {dictLabel: item.dictLabel, dictValue: item.dictValue}
        })
        sessionStorage.setItem('offices', JSON.stringify(offices))
        sessionStorage.setItem('officesEnum', JSON.stringify(reduceEnum(offices)))
    })
    await getDict('sme_project_progress').then(resp => {
        const progress = resp.data.map(item => {
            return {dictLabel: item.dictLabel, dictValue: item.dictValue}
        })
        sessionStorage.setItem('progress', JSON.stringify(progress))
        sessionStorage.setItem('progressEnum', JSON.stringify(reduceEnum(progress)))
    })
}

function reduceEnum(arr) {
    return arr.reduce((pre, cur) => {
        pre[cur.dictValue] = cur.dictLabel
        return pre
    }, {})
}


export default {
    getDict,getDictStorage
}
