<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive=""/>
<!--    <div class="banner">-->
<!--    </div>-->
    <div class='main-container'>
      <Breadcrumb :items="['用户服务协议']"/>
      <div class="news-details">
        <h1>{{ singlePage.title }}</h1>
        <div class='news-content'>
          <div class="ql-editor">
          <article v-html="singlePage.content"/>
          </div>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import {onMounted, ref} from "vue";
import {getSinglePage} from "@/api/news";
import MenuBar from "@/components/MenuBar.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

let singlePage = ref({});

onMounted(() => {
  //document.title = "企业综合服务系统"

  getSinglePage(2).then(response => {
    if (response.data){
      singlePage.value = response.data
    }
  }).catch(()=>{
  })

})
</script>

<style lang="scss" scoped>
.banner {
  height: 770px;
  width: 100%;
  background: url("../../assets/banner.png") center no-repeat;
}

.main-container {
  width: 1200px;
  margin: 30px auto;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px 30px 35px 30px ;
  box-sizing: border-box;

  .news-details {
    h1 {
      margin: 0;
      color: #333333;
      font-size: 24px;
      padding: 60px 0 30px 0;
    }

    p {
      margin: 0;
      padding: 0 0 20px 0;
      border-bottom: #DDDDDD dashed 1px;

      span {
        color: #999999;
        padding: 0 50px;
        font-size: 13px;
      }
    }

    .news-content {
      padding: 30px 0;
      text-align: left;
    }
  }
}
:deep .ql-editor{
  max-height: unset;
}
</style>
