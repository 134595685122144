import request from '@/utils/request'

export function getList(data) {
    return request({
        url: `/open/policy/list`,
        method: 'get',
        params: {...data, pageNum: data.pageNo}
    })
}

export function getDetail(id) {
    return request({
        url: `/open/policy/${id} `,
        method: 'get'
    })
}

export default {
    getList, getDetail
}
