<template>
  <div class="common-layout fixed">
    <el-container>
      <HeaderView title="用户中心-企业信息"/>
      <el-main>
        <div class="mobile-tabs">
          <ul>
            <li v-on:click='$router.push({path:"/mobile/account"})' class='active'>企业信息</li>
            <li v-on:click='$router.push({path:"/mobile/account/applied"})'>我的申请</li>
            <li v-on:click='$router.push({path:"/mobile/account/password"})'>修改密码</li>
          </ul>
        </div>
        <div class="mobile-container">

          <el-form ref="companyRef"
                   :model="companyForm"
                   :rules="companyRules"
                   label-width="150px"
                   class="demo-form-inline">
            <div class='title'><span></span>用户信息编辑</div>
            <p>注：请准确填写表单信息，以便我们的服务人员与您取得联系并提供更好的服务支持。</p>
            <el-form-item label="用户（手机号码）">
              {{companyForm.userName}}
            </el-form-item>
            <el-form-item label="联系人" prop="contactName">
              <el-input v-model="companyForm.contactName" placeholder="请输入联系人" />
            </el-form-item>
            <el-form-item label="联系方式" prop="contactPhone">
              <el-input v-model="companyForm.contactPhone" placeholder="请输入联系方式" />
            </el-form-item>
            <el-form-item label="岗位职务" prop="contactPost">
              <el-input v-model="companyForm.contactPost" placeholder="请输入岗位职务" />
            </el-form-item>
            <div class='title'><span></span>企业信息编辑</div>
            <el-form-item label="企业（单位）名称" prop="companyName">
              <el-input v-model="companyForm.companyName" placeholder="请输入企业（单位）名称" />
            </el-form-item>
            <el-form-item label="统一社会信用代码" prop="creditCode">
              <el-input v-model="companyForm.creditCode" placeholder="请输入统一社会信用代码" />
            </el-form-item>
            <el-form-item label="注册资本" prop="registeredCapital">
              <el-input v-model="companyForm.registeredCapital" placeholder="请输入注册资本" />
            </el-form-item>
            <el-form-item label="纳税人性质" prop="taxpayerNature">
              <el-input v-model="companyForm.taxpayerNature" placeholder="请输入纳税人性质" />
            </el-form-item>
            <el-form-item label="国标行业" prop="industry">
              <el-input v-model="companyForm.industry" placeholder="请输入国标行业" />
            </el-form-item>
            <el-form-item label="法定代表人" prop="legalRepresentative">
              <el-input v-model="companyForm.legalRepresentative" placeholder="请输入法定代表人" />
            </el-form-item>
            <el-form-item label="企业类型" prop="companyType">
              <el-input v-model="companyForm.companyType" placeholder="请输入企业类型" />
            </el-form-item>
            <el-form-item label="参保人数" prop="insuredPeopleNumber">
              <el-input type="number" v-model="companyForm.insuredPeopleNumber" placeholder="请输入参保人数" />
            </el-form-item>
            <el-form-item label="登记机关" prop="registrationAuthority">
              <el-input v-model="companyForm.registrationAuthority" placeholder="请输入登记机关" />
            </el-form-item>
            <el-form-item label="成立日期" prop="foundDate">
              <el-date-picker clearable
                              style="width:100%"
                              v-model="companyForm.foundDate"
                              type="date"
                              value-format="YYYY-MM-DD"
                              placeholder="请选择成立日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="经营范围" prop="businessRange">
              <el-input
                  v-model="companyForm.businessRange"
                  :autosize="{ minRows: 4, maxRows: 10 }"
                  type="textarea"
                  placeholder="请输入经营范围内容"
              />
            </el-form-item>
            <div style="text-align: center">
              <el-button
                  :loading="loading"
                  size="large"
                  class='submit'
                  @click.prevent="submitForm"
              >
                <span v-if="!loading">确认保存</span>
                <span v-else>保存中...</span>
              </el-button>
            </div>
          </el-form>
        </div>
      </el-main>
      <el-footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script setup>
import HeaderView from '@/components/mobile/Header'
import {getCurrentInstance, onMounted, ref} from "vue";
import {getCompany, updateCompany} from "@/api/account";

const { proxy } = getCurrentInstance();
const loading=ref(false)
const companyForm = ref({
  userName:"",
  companyName: "",
  companyType: "",
  contactName: "",
  contactPhone: "",
  contactPost: "",
  creditCode: "",
  foundDate: "",
  industry: "",
  insuredPeopleNumber: 0,
  legalRepresentative: "",
  registeredCapital: "",
  registrationAuthority: "",
  taxpayerNature: "",
  businessRange: "",
})

const isNumber = (rule, value, callback) => {
  let regPos = /^\d+(\.\d+)?$/; //非负浮点数
  if(!regPos.test(value)){
    callback(new Error("请输入数值"));
  } else {
    callback();
  }
};

const companyRules = {
  companyName: [{ required: true, trigger: "blur", message: "请输入您的企业名称" },],
  companyType: [{ required: true, trigger: "blur", message: "请输入企业类型" },],
  contactName: [{ required: true, trigger: "blur", message: "请输入联系人姓名" },],
  contactPhone: [{ required: true, trigger: "blur", message: "请输入联系方式" },],
  contactPost: [{ required: true, trigger: "blur", message: "请输入岗位职务" },],
  creditCode: [{ required: true, trigger: "blur", message: "请输入信用代码" },],
  foundDate: [{ required: true, trigger: "blur", message: "请选择成立日期" },],
  industry: [{ required: true, trigger: "blur", message: "请输入行业" },],
  insuredPeopleNumber: [
    { required: true, trigger: "blur", message: "请输入参保人数" },
    { required: true, validator: isNumber, trigger: "blur" }
  ],
  legalRepresentative: [{ required: true, trigger: "blur", message: "请输入法人" },],
  registeredCapital: [{ required: true, trigger: "blur", message: "请输入注册资本" },],
  registrationAuthority: [{ required: true, trigger: "blur", message: "请输入登记机关" },],
  taxpayerNature: [{ required: true, trigger: "blur", message: "请输入纳税人性质" },],
  businessRange: [{ required: true, trigger: "blur", message: "请输入经营范围" },],
};

function submitForm() {
  proxy.$refs.companyRef.validate(valid => {
    if (valid) {
      loading.value = true;
      updateCompany(companyForm.value).then(res => {
        loading.value = false;
        proxy.$modal.msgSuccess("保存成功");
      }).catch((res) => {
        loading.value = false;
      });
    }
  });
}

onMounted(() => {
  //document.title = "企业综合服务系统"

  getCompany().then(response => {
    console.log(response)
    companyForm.value = response.user;
  });
})
</script>


<style lang="scss" scoped>
.common-layout {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;

  .el-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.fixed {
    .el-container {
      flex: 1;
    }

    .el-footer {
      --el-footer-height: unset;
    }
  }
  .el-footer {
    --el-footer-padding: 0;
    margin-top: 100px;
  }

  .el-main {
    padding: 0 10px;
    .mobile-tabs{
      float: left;
      display: inline-block;

      ul{
        padding: 20px 0 30px;
        margin: 0;
        li {
          display: inline;
          list-style: none;
          text-align: center;
          background: #DDDDDD;
          color: #101010;
          margin-right: 5px;
          font-size: 14px;
          padding: 8px 20px;
        }
        .active{
          background: #5C7EF4;
          color: #ffffff;
          font-size: 14px;
        }
      }
    }

    .mobile-container{
      width: 100%;
      display: inline-block;
      text-align: left;
      .title{
        padding: 0 0 20px 0;
        font-size: 21px;
        color: #000000;

        span{
          display: inline-block;
          width: 8px;
          height: 22px;
          background: #5C7EF4;
          vertical-align: middle;
        }
      }

      p{
        color: #ff0000;
        font-size: 13px;
        margin: 0;
        padding: 0 0 20px 0;
      }

      button{
        width: 85%;
        height: 45px;
        line-height: 20px;
        border-radius: 5px;
        //background: linear-gradient(264.2deg, rgba(185,145,107,1) 1.7%,rgba(228,200,149,1) 93.74%);
        background: #025CE2;
        color: rgba(255, 255, 255, 100);
        font-size: 18px;
        text-align: center;
      }
    }
  }
}
</style>
