<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class="condition high-new-tech-transform-container">
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">高新技术成果转化项目认定</div>
      <BasicInfo ref="basicInfo" :is-financial="false" :is-int-prop="false" :is-input-validation="false"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'">
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-row :gutter="50">
                <el-col :span="8">
                  <el-form-item label="申报转化项目名称" required prop="projectName">
                    <el-input v-model="formData.projectName"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="申报转化项目所属技术领域" required prop="techDomain">
                    <el-select v-model="formData.techDomain" clearable>
                      <el-option value="一、电子信息" label="一、电子信息"/>
                      <el-option value="二、生物与新医药" label="二、生物与新医药"/>
                      <el-option value="三、航空航天" label="三、航空航天"/>
                      <el-option value="四、新材料" label="四、新材料"/>
                      <el-option value="五、高技术服务" label="五、高技术服务"/>
                      <el-option value="六、新能源与节能" label="六、新能源与节能"/>
                      <el-option value="七、资源与环境" label="七、资源与环境"/>
                      <el-option value="八、先进制造与自动化" label="八、先进制造与自动化"/>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="申报项目属于(单选)" required prop="projectBelong">
                <template #label>
                  <el-tooltip placement="right">
                    <template #content>
                      如果您申报的项目属于以下三种情况中任一种，将被视为不满足申报要求。
                    </template>
                    <span>
                          <span>申报项目属于(单选)</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                  </el-tooltip>
                </template>
                <el-radio-group v-model="formData.projectBelong">
                  <el-radio :label="1">一般性的工艺改进或产品改型所形成的产品（服务）</el-radio>
                  <br>
                  <el-radio :label="2">已形成规模销售（提供）的成熟产品（服务）</el-radio>
                  <br>
                  <el-radio :label="3">食品、保健品、饮料、化妆品、服装、家具、家用电器、工艺品、办公用品等常规日用产品
                  </el-radio>
                  <br>
                  <el-radio :label="4">不属于以上三种</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                  label="近两年无知识产权侵权行为，未发生重大安全、重大质量事故或严重环境违法、科研失信行为，且未列入严重违法失信名单"
                  required prop="notViolations">
                <el-radio-group v-model="formData.notViolations">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                  label="申报转化项目的高新技术成果须为基于核心技术研发，首次实现转化并形成样品、样机或服务的项目"
                  required prop="isCoreRd">
                <el-radio-group v-model="formData.isCoreRd">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="申报转化项目的核心技术属于《国家重点支持的高新技术领域》规定的范围" required
                            prop="isFocusDomain">
                <el-radio-group v-model="formData.isFocusDomain">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item required prop="hasIp">
                <template #label>
                  <el-tooltip placement="right">
                    <template #content>知识产权的类型包括：近 3 年内获得授权的发明专利、登记的集成电路布图设计专有权；
                      <br/>近 2 年内获得授权的实用新型专利、登记的软件著作权。
                      <br>项目的知识产权应当是通过自主研发、受让等方式，获得在技术上发挥核心支持作用的知识产权所有权。
                      <br><br>获得1类、2类新药的药品注册证书，且在新药监测期的项目；或近一年内首次取得国内三类医疗器械注册证的项目，其核心技术获得发明专利授权的，可不受上述专利年限限制。
                    </template>
                    <span>
                          <span>项目的核心技术拥有受中国法律保护的知识产权</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                  </el-tooltip>
                </template>
                <el-radio-group v-model="formData.hasIp">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item required prop="hasThirdReport">
                <template #label>
                  <el-tooltip placement="right">
                    <template #content>属医药、医疗器械、农药、计量器具、压力容器、邮电通信等有特殊行业管理要求的，须具有相关行业主管部门批准颁发的产品生产许可。
                      <br>属国家实施强制性产品认证的产品，必须通过强制性产品认证。
                    </template>
                    <span>
                          <span>项目所形成的产品应有第三方出具的产品质量性能检测报告</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                  </el-tooltip>
                </template>
                <el-radio-group v-model="formData.hasThirdReport">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="项目所形成的产品（服务）属于工商营业执照、事业单位法人证书的经营范围内" required
                            prop="isWithinBusiness">
                <el-radio-group v-model="formData.isWithinBusiness">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                  label="项目的总体技术与其它同类产品（服务）相比具有良好的创新性和先进性，并具有潜在的经济效益和较好的市场前景"
                  required prop="isAdvanced">
                <el-radio-group v-model="formData.isAdvanced">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并认定
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "HighNewTechTransform"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import BasicInfo from '@/components/BasicInfo'
import Breadcrumb from '@/components/Breadcrumb'
import {CircleCheckFilled, Finished, Promotion, QuestionFilled} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'highNewTechTransform'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref('')
const formData = ref({
  projectName: '',
  techDomain: '',
  projectBelong: 4,
  notViolations: 0,
  isCoreRd: 0,
  isFocusDomain: 0,
  hasIp: 0,
  hasThirdReport: 0,
  isWithinBusiness: 0,
  isAdvanced: 0,
})
onMounted(() => {
  getConditionByPath()
})
const getConditionByPath = () => {
  companyApi.getConditionByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  })
}

const isResult = ref(true)
const loading = ref(false)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveConditionByPath(urlPath, company).then((resp) => {
    if (resp.code == 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getConditionByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitConditionByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}
</script>
<style scoped lang="scss">
.high-new-tech-transform-container {

}

.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
}
</style>
