<template>
  <div class="home">
    <HeaderView title="申请表填写"/>
    <div class="scroll-container">
      <div class="form-container">
        <el-form
            ref="authenticationForm"
            :model="formData"
            :label-position="'top'"
        >
          <el-row :gutter="0">
            <el-col :span="12" style="font-size: 13px; text-align: left">
              <div class="tip custom-block"><p class="custom-block-title">填表说明：</p>
                <p>标红色<span style="color: #ff0000">*</span>号的为必填项，请在“提交并认定”按钮点击前完成必填项的输入。</p>
                <p><span style="color:#67c23a">“临时保存”</span>按钮可以保存您未完成的表单内容，临时有事离开下一次进来可继续编辑。
                </p>
                <p>鼠标悬停在
                  <el-icon color="#1ab394">
                    <QuestionFilled/>
                  </el-icon>
                  图标上方，系统会提示您该项内容的说明文字或者填写规范。
                </p>
              </div>
            </el-col>
            <el-col :span="12">
              <div style="text-align: right">
                <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
                  <el-button type="success" :icon="Finished" :loading="loading" @click="saveForm(authenticationForm)"
                             circle></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
                  <el-button type="danger" @click="submitForm(authenticationForm)" :icon="CircleCheckFilled"
                             :loading="loading"
                             circle></el-button>
                </el-tooltip>
                <el-tooltip v-if="isResult" effect="dark" content="查看上次提交测评的结果" placement="top-end">
                  <router-link :to="`/authentication/result`" style="margin-left: 10px">
                    <el-button type="primary" :icon="Promotion" :loading="loading" circle></el-button>
                  </router-link>
                </el-tooltip>
              </div>
            </el-col>
          </el-row>
          <div class="basic-info">
            <h2>一、单位基本信息</h2>
            <el-row :gutter="50">
              <el-col :span="6">
                <el-form-item label="企/事业单位名称" required prop="companyName">
                  <el-input v-model="formData.companyName"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="统一社会信用代码" required prop="creditCode">
                  <el-input v-model="formData.creditCode"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="注册资本（万元）" required prop="registerCapital">
                  <el-input v-model="formData.registerCapital"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="是否事业单位" required prop="isInstitutions">
                  <el-radio-group v-model="formData.isInstitutions" @change="handleChoiceItemChange">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="50">
              <el-col :span="6">
                <el-form-item label="法定代表人" required prop="ceo">
                  <el-input v-model="formData.ceo"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="企业类型|经济性质" required prop="enterpriseType" class="enterpriseOptions">
                  <el-select v-model="formData.enterpriseType" placeholder="请选择">
                    <el-option :value="1" label="民营"></el-option>
                    <el-option :value="2" label="国有"></el-option>
                    <el-option :value="3" label="合资"></el-option>
                    <el-option :value="4" label="外资"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="注册日期" required prop="registerTime">
                  <el-date-picker clearable
                                  style="width:100%"
                                  v-model="formData.registerTime"
                                  type="date"
                                  value-format="YYYY-MM-DD"
                                  placeholder="请选择注册日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="企业规模" required prop="enterpriseSize">
                  <el-radio-group v-model="formData.enterpriseSize">
                    <el-radio :label="1">大型</el-radio>
                    <el-radio :label="2">中型</el-radio>
                    <el-radio :label="3">小型</el-radio>
                    <el-radio :label="4">微型</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="联系人姓名" required prop="contactName">
                  <el-input v-model="formData.contactName"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="联系人手机" required prop="contactMobile">
                  <el-input v-model="formData.contactMobile"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="联系人邮箱" required prop="email">
                  <el-input v-model="formData.email"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="邮编" prop="postCode" v-if="false">
                  <el-input v-model="formData.postCode"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="企业注册地" required prop="registerProvince">
                  <el-select v-model="formData.registerProvince">
                    <el-option label="浦东新区" value="浦东新区"/>
                    <el-option label="黄浦区" value="黄浦区"/>
                    <el-option label="静安区" value="静安区"/>
                    <el-option label="徐汇区" value="徐汇区"/>
                    <el-option label="长宁区" value="长宁区"/>
                    <el-option label="虹口区" value="虹口区"/>
                    <el-option label="杨浦区" value="杨浦区"/>
                    <el-option label="普陀区" value="普陀区"/>
                    <el-option label="闵行区" value="闵行区"/>
                    <el-option label="宝山区" value="宝山区"/>
                    <el-option label="嘉定区" value="嘉定区"/>
                    <el-option label="金山区" value="金山区"/>
                    <el-option label="松江区" value="松江区"/>
                    <el-option label="青浦区" value="青浦区"/>
                    <el-option label="奉贤区" value="奉贤区"/>
                    <el-option label="崇明区" value="崇明区"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="通讯地址" required prop="address">
                  <el-input v-model="formData.address"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item required prop="industry">
                  <template #label>
                    <el-tooltip content="按照《国民经济行业分类(GB/T 4754-2017)》的大类行业填写所属行业." placement="right">
                        <span>
                          <span>所属行业</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                    </el-tooltip>
                  </template>
                  <el-select v-model="formData.industry" :filterable="true" style="width: 100%;"
                             @change="selectSmallTypes">
                    <el-option v-for="item in bigTypes" :key="item.id" :label="item.name" :value="item.industryCode"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="false">
                <el-form-item required prop="detailIndustry">
                  <template #label>
                    <el-tooltip content="按照《国民经济行业分类(GB/T 4754-2017)》的大类行业填写所属行业." placement="right">
                        <span>
                          <span>具体细分领域及名称</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                    </el-tooltip>
                  </template>
                  <el-select v-model="formData.detailIndustry" :filterable="true" style="width: 100%;">
                    <el-option v-for="item in smallTypes" :key="item.id" :label="item.name" :value="item.industryCode"/>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="financial-info">
            <h2>二、近三年经营与财务状况</h2>
            <div class="table-data">
              <div class="table-header">
                <div class="table-cell">重要指标</div>
                <div class="table-cell">第一年
                  <el-tooltip placement="right">
                    <template #content>2020年
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell">第二年
                  <el-tooltip placement="right">
                    <template #content>2021年
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell">第三年
                  <el-tooltip placement="right">
                    <template #content>2022年
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell bold"><span>*</span>全职员工数量</div>
                <div class="table-cell">
                  <el-form-item required prop="employeeNum1">
                    <input-number v-model="formData.employeeNum1" @blur="handleResearcherRate"
                                  @update:modelValue="formData.employeeNum1 = $event" :is-decimal="false"
                                  :is-positive="true" :append="'人'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="employeeNum2">
                    <input-number v-model="formData.employeeNum2" @blur="handleResearcherRate"
                                  @update:modelValue="formData.employeeNum2 = $event" :is-decimal="false"
                                  :is-positive="true" :append="'人'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="employeeNum3">
                    <input-number v-model="formData.employeeNum3" @blur="handleResearcherRate"
                                  @update:modelValue="formData.employeeNum3 = $event" :is-decimal="false"
                                  :is-positive="true" :append="'人'"/>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell bold"><span>*</span>其中：研发人员数量
                  <el-tooltip placement="right">
                    <template #content>
                      研究开发人员数：指报告年度内企业内部直接参加研发
                      <br>项目人员，以及研发活动的管理和直接服务的人员。不包括全
                      <br>年累计从事研发活动时间占制度工作时间 10%以下的人员。
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="researcherNum1">
                    <input-number v-model="formData.researcherNum1" @blur="handleResearcherRate"
                                  @update:modelValue="formData.researcherNum1 = $event" :is-decimal="false"
                                  :is-positive="true" :append="'人'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="researcherNum2">
                    <input-number v-model="formData.researcherNum2" @blur="handleResearcherRate"
                                  @update:modelValue="formData.researcherNum2 = $event" :is-decimal="false"
                                  :is-positive="true" :append="'人'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="researcherNum3">
                    <input-number v-model="formData.researcherNum3" @blur="handleResearcherRate"
                                  @update:modelValue="formData.researcherNum3 = $event" :is-decimal="false"
                                  :is-positive="true" :append="'人'"/>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row calculation"
                   v-if="formData.researcherRate1!=0||formData.researcherRate2!=0||formData.researcherRate3!=0">
                <div class="table-cell"><i></i>研发人员占全部职工比重</div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.researcherRate1" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.researcherRate2" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.researcherRate3" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell bold"><span>*</span>营业收入
                  <el-tooltip placement="right">
                    <template #content>
                      请以《财务报表》中 利润表 的 '营业收入' 本期金额数值为准。
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="revenue1">
                    <input-number v-model="formData.revenue1" @blur="handleRevenue"
                                  @update:modelValue="formData.revenue1 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="revenue2">
                    <input-number v-model="formData.revenue2" @blur="handleRevenue"
                                  @update:modelValue="formData.revenue2 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="revenue3">
                    <input-number v-model="formData.revenue3" @blur="handleRevenue"
                                  @update:modelValue="formData.revenue3 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell bold"><span>*</span>其中：主营业务收入（或销售收入）
                  <el-tooltip placement="right">
                    <template #content>
                      主营业务收入：指报告年度内企业确认的销售商品、提
                      <br>供劳务等主营业务的收入。根据会计“主营业务收入”科目的
                      <br>期末贷方余额填报。若会计报告和会计报表中未设置该科目，
                      <br>填报企业年度财务报表附注中“主营业务收入”数据。
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="mainRevenue1">
                    <input-number v-model="formData.mainRevenue1" @blur="handleRevenue"
                                  @update:modelValue="formData.mainRevenue1 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="mainRevenue2">
                    <input-number v-model="formData.mainRevenue2" @blur="handleRevenue"
                                  @update:modelValue="formData.mainRevenue2 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="mainRevenue3">
                    <input-number v-model="formData.mainRevenue3" @blur="handleRevenue"
                                  @update:modelValue="formData.mainRevenue3 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row calculation"
                   v-if="formData.mainRevenuePercent1!=0||formData.mainRevenuePercent2!=0||formData.mainRevenuePercent3!=0">
                <div class="table-cell"><i></i>主营业务收入总额占营业收入总额比重</div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.mainRevenuePercent1" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.mainRevenuePercent2" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.mainRevenuePercent3" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row calculation"
                   v-if="formData.revenueGrowthRate1!=0||formData.revenueGrowthRate2!=0||formData.revenueGrowthRate3!=0">
                <div class="table-cell"><i></i>营业收入增长率</div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.revenueGrowthRate1" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.revenueGrowthRate2" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.revenueGrowthRate3" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row calculation"
                   v-if="formData.mainRevenueGrowthRate1!=0||formData.mainRevenueGrowthRate2!=0||formData.mainRevenueGrowthRate3!=0">
                <div class="table-cell"><i></i>主营业务收入(或销售收入)增长率</div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.mainRevenueGrowthRate1" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.mainRevenueGrowthRate2" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.mainRevenueGrowthRate3" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell bold"><span>*</span>研发费用总额（即研发费用投入）
                  <el-tooltip placement="right">
                    <template #content>
                      研究开发（简称“研发”）经费支出：指报告年度内企
                      <br>业研发活动的经费支出合计，包括企业内部的日常研发经费支
                      <br>出，当年形成用于研发的固定资产支出和委托外单位开展研发的经费支出。
                      <br>
                      <br>请以《财务报表》中 利润表 的 '营业收入：（减项）研发费用' 本期金额数值为准。
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="researchFee1">
                    <input-number v-model="formData.researchFee1" @blur="handleRevenue"
                                  @update:modelValue="formData.researchFee1 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="researchFee2">
                    <input-number v-model="formData.researchFee2" @blur="handleRevenue"
                                  @update:modelValue="formData.researchFee2 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="researchFee3">
                    <input-number v-model="formData.researchFee3" @blur="handleRevenue"
                                  @update:modelValue="formData.researchFee3 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row calculation"
                   v-if="formData.researchFeeRate1!=0||formData.researchFeeRate2!=0||formData.researchFeeRate3!=0">
                <div class="table-cell"><i></i>研发费用总额占营业收入总额比重</div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.researchFeeRate1" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.researchFeeRate2" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.researchFeeRate3" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell bold"><span>*</span>成本费用总额
                  <el-tooltip placement="right">
                    <template #content>
                      计算公式：成本费用总额=营业成本+营业税金及附加+销售费用+管理费用+财务费用+资产减值损失。
                      <br>请以《财务报表》中 利润表 的 '营业收入：各类减项' 本期金额累加数值为准。
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="totalCost1">
                    <input-number v-model="formData.totalCost1" @update:modelValue="formData.totalCost1 = $event"
                                  :is-decimal=true :digit="4" :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="totalCost2">
                    <input-number v-model="formData.totalCost2" @update:modelValue="formData.totalCost2 = $event"
                                  :is-decimal=true :digit="4" :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="totalCost3">
                    <input-number v-model="formData.totalCost3" @update:modelValue="formData.totalCost3 = $event"
                                  :is-decimal=true :digit="4" :append="'万元'"/>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell bold"><span>*</span>上缴税金
                  <el-tooltip placement="right">
                    <template #content>
                      请以《财务报表》中 利润表 的 '减：所得税费用' 项本期金额数值为准。
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="paidTaxes1">
                    <input-number v-model="formData.paidTaxes1" @blur="handleRevenue"
                                  @update:modelValue="formData.paidTaxes1 = $event"
                                  :is-decimal=true :digit="4" :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="paidTaxes2">
                    <input-number v-model="formData.paidTaxes2" @blur="handleRevenue"
                                  @update:modelValue="formData.paidTaxes2 = $event"
                                  :is-decimal=true :digit="4" :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="paidTaxes3">
                    <input-number v-model="formData.paidTaxes3" @blur="handleRevenue"
                                  @update:modelValue="formData.paidTaxes3 = $event"
                                  :is-decimal=true :digit="4" :append="'万元'"/>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell bold"><span>*</span>净利润总额
                  <el-tooltip placement="right">
                    <template #content>
                      请以《财务报表》中 利润表 的 '净利润' 项本期金额数值为准。
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="netProfit1">
                    <input-number v-model="formData.netProfit1"
                                  @update:modelValue="formData.netProfit1 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="netProfit2">
                    <input-number v-model="formData.netProfit2"
                                  @update:modelValue="formData.netProfit2 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="netProfit3">
                    <input-number v-model="formData.netProfit3"
                                  @update:modelValue="formData.netProfit3 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row calculation"
                   v-if="formData.netProfitRate1!=0||formData.netProfitRate2!=0||formData.netProfitRate3!=0">
                <div class="table-cell"><i></i>净利润率</div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.netProfitRate1" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.netProfitRate2" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.netProfitRate3" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row calculation"
                   v-if="formData.netProfitGrowthRate1!=0||formData.netProfitGrowthRate2!=0||formData.netProfitGrowthRate3!=0">
                <div class="table-cell"><i></i>净利润增长率</div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.netProfitGrowthRate1" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.netProfitGrowthRate2" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.netProfitGrowthRate3" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell bold"><span>*</span>资产总额
                  <el-tooltip placement="right">
                    <template #content>
                      请以《财务报表》中 资产负债表 的 '资产总计' 项本期金额数值为准。
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="totalAssets1">
                    <input-number v-model="formData.totalAssets1" @blur="handleAssets"
                                  @update:modelValue="formData.totalAssets1 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="totalAssets2">
                    <input-number v-model="formData.totalAssets2" @blur="handleAssets"
                                  @update:modelValue="formData.totalAssets2 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="totalAssets3">
                    <input-number v-model="formData.totalAssets3" @blur="handleAssets"
                                  @update:modelValue="formData.totalAssets3 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell bold"><span>*</span>负债总额
                  <el-tooltip placement="right">
                    <template #content>
                      请以《财务报表》中 资产负债表 的 '负债合计' 项本期金额数值为准。
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="totalLiability1">
                    <input-number v-model="formData.totalLiability1" @blur="handleAssets"
                                  @update:modelValue="formData.totalLiability1 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="totalLiability2">
                    <input-number v-model="formData.totalLiability2" @blur="handleAssets"
                                  @update:modelValue="formData.totalLiability2 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="totalLiability3">
                    <input-number v-model="formData.totalLiability3" @blur="handleAssets"
                                  @update:modelValue="formData.totalLiability3 = $event" :is-decimal=true :digit="4"
                                  :append="'万元'"/>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row calculation"
                   v-if="formData.assetLiabilityRatio1!=0||formData.assetLiabilityRatio2!=0||formData.assetLiabilityRatio3!=0">
                <div class="table-cell"><i></i>资产负债率</div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.assetLiabilityRatio1" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.assetLiabilityRatio2" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.assetLiabilityRatio3" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row calculation"
                   v-if="formData.netAssets1!=0||formData.netAssets2!=0||formData.netAssets3!=0">
                <div class="table-cell">净资产
                  <el-tooltip content="净资产 = 资产总额 - 负债总额" placement="right">
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell">
                  <el-form-item prop="netAssets1">
                    <input-number v-model="formData.netAssets1" :append="'万元'" readonly/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item prop="netAssets2">
                    <input-number v-model="formData.netAssets2" :append="'万元'" readonly/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item prop="netAssets3">
                    <input-number v-model="formData.netAssets3" :append="'万元'" readonly/>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row calculation"
                   v-if="formData.netAssetsGrowth1!=0||formData.netAssetsGrowth2!=0||formData.netAssetsGrowth3!=0">
                <div class="table-cell"><i></i>净资产增长率</div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.netAssetsGrowth1" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.netAssetsGrowth2" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item>
                    <el-input v-model="formData.netAssetsGrowth3" readonly>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell">当年新增股权融资额总额（合格机构投资者的实缴额）
                  <el-tooltip placement="right">
                    <template #content>
                      注意：参与《创新型中小企业认定》 或 《“专精特新”中小企业认定》 请填写。
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="equityCapital1">
                    <input-number v-model="formData.equityCapital1" @update:modelValue="formData.equityCapital1 = $event"
                                  :is-decimal=true :digit="4" :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="equityCapital2">
                    <input-number v-model="formData.equityCapital2" @update:modelValue="formData.equityCapital2 = $event"
                                  :is-decimal=true :digit="4" :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="equityCapital3">
                    <input-number v-model="formData.equityCapital3" @update:modelValue="formData.equityCapital3 = $event"
                                  :is-decimal=true :digit="4" :append="'万元'"/>
                  </el-form-item>
                </div>
              </div>
              <div class="table-row" v-if="false">
                <div class="table-cell"><i></i>对应估值</div>
                <div class="table-cell">
                  <el-form-item required prop="valuation1">
                    <input-number v-model="formData.valuation1" @update:modelValue="formData.valuation1 = $event"
                                  :is-decimal=true :digit="4" :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="valuation2">
                    <input-number v-model="formData.valuation2" @update:modelValue="formData.valuation2 = $event"
                                  :is-decimal=true :digit="4" :append="'万元'"/>
                  </el-form-item>
                </div>
                <div class="table-cell">
                  <el-form-item required prop="valuation3">
                    <input-number v-model="formData.valuation3" @update:modelValue="formData.valuation3 = $event"
                                  :is-decimal=true :digit="4" :append="'万元'"/>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <div class="int-prop-info">
            <h2>三、知识产权情况</h2>
            <el-row :gutter="50">
              <el-col :span="6">
                <el-form-item label="I类知识产权数量" required prop="intellectualProperty1Num">
                  <template #label>
                    <el-tooltip>
                      <template #content>
                        Ⅰ类知识产权包括：
                        <br>1、发明专利（含国防专利）
                        <br>2、植物新品种
                        <br>3、国家级农作物品种
                        <br>4、国家新药
                        <br>5、国家一级中药保护品种
                        <br>6、集成电路布图设计专有权
                        <br>（不包含转让未满1年的知识产权）
                      </template>
                      <span>
                          <span>I类知识产权数量</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                    </el-tooltip>
                  </template>
                  <input-number v-model="formData.intellectualProperty1Num" @update:modelValue="formData.intellectualProperty1Num = $event" :is-decimal="false" :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="formData.intellectualProperty1Num>0">
                <el-form-item label="发明专利">
                  <input-number v-model="formData.patentNum" @update:modelValue="formData.patentNum = $event" :is-decimal="false" :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="formData.intellectualProperty1Num>0">
                <el-form-item label="植物新品种">
                  <input-number v-model="formData.newPlantVariety" @update:modelValue="formData.newPlantVariety = $event" :is-decimal="false" :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="formData.intellectualProperty1Num>0">
                <el-form-item label="国家级农作物品种">
                  <input-number v-model="formData.countryCropVariety" @update:modelValue="formData.countryCropVariety = $event" :is-decimal="false" :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="formData.intellectualProperty1Num>0">
                <el-form-item label="国家新药">
                  <input-number v-model="formData.countryNewMedicine" @update:modelValue="formData.countryNewMedicine = $event" :is-decimal="false" :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="formData.intellectualProperty1Num>0">
                <el-form-item label="国家一级中药保护品种">
                  <input-number v-model="formData.countryChineseMedicine" @update:modelValue="formData.countryChineseMedicine = $event" :is-decimal="false" :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="formData.intellectualProperty1Num>0">
                <el-form-item label="集成电路布图设计专有权">
                  <input-number v-model="formData.integratedCircuitDesign" @update:modelValue="formData.integratedCircuitDesign = $event" :is-decimal="false" :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="50" v-if="formData.intellectualProperty1Num>0">
              <el-col :span="6">
                <el-form-item label="自主研发数量" required prop="ownIntellectualProperty1Num">
                  <input-number v-model="formData.ownIntellectualProperty1Num" @update:modelValue="formData.ownIntellectualProperty1Num = $event" :is-decimal="false" :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="属于I类高价值知识产权数量" required prop="highValueIntellectualProperty1Num">
                  <template #label>
                    <el-tooltip placement="right">
                      <template #content>
                        “Ⅰ类高价值知识产权”须符合以下条件之一：
                        <br>（1）在海外有同族专利权的发明专利或在海外取得收入的其他Ⅰ类知识产权，其中专利限G20成员、新加坡以及欧洲专利局经实质审查后获得授权的发明专利。
                        <br>（2）维持年限超过10年的Ⅰ类知识产权。
                        <br>（3）实现较高质押融资金额的Ⅰ类知识产权。
                        <br>（4）获得国家科学技术奖或中国专利奖的Ⅰ类知识产权。
                      </template>
                      <span>
                          <span>属于I类高价值知识产权数量</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                    </el-tooltip>
                  </template>
                  <input-number v-model="formData.highValueIntellectualProperty1Num" @update:modelValue="formData.highValueIntellectualProperty1Num = $event" :is-decimal="false" :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="当年被受理的Ⅰ类知识产权数申请数" required prop="intellectualProperty1AcceptNum">
                  <input-number v-model="formData.intellectualProperty1AcceptNum" @update:modelValue="formData.intellectualProperty1AcceptNum = $event" :is-decimal="false" :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="当年被受理的PCT专利申请数 " required prop="pctPropertyAcceptNum">
                  <template #label>
                    <el-tooltip
                        content="PCT专利是指通过PCT（专利合作条约，Patent Cooperation Treaty）途径提交的国际专利申请。"
                        placement="right">
                        <span>
                          <span>当年被受理的PCT专利申请数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                    </el-tooltip>
                  </template>
                  <input-number v-model="formData.pctPropertyAcceptNum" @update:modelValue="formData.pctPropertyAcceptNum = $event" :is-decimal="false" :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="false">
                <el-form-item label="其中：全部国际专利数">
                  <input-number v-model="formData.internationalPatentNum" @update:modelValue="formData.internationalPatentNum = $event" :is-decimal="false" :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="false">
                <el-form-item label="其中：国防专利"><input-number v-model="formData.nationalDefencePatentNum" @update:modelValue="formData.nationalDefencePatentNum = $event" :is-decimal="false" :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="50">
              <el-col :span="6">
                <el-form-item label="II类类知识产权数量 " required prop="intellectualProperty2Num">
                  <template #label>
                    <el-tooltip
                        content="Ⅱ类知识产权包括与主导产品相关的软件著作权（不含商标）、授权后维持超过2年的实用新型专利或外观设计专利（均不包含转让未满1年的知识产权）"
                        placement="right">
                        <span>
                          <span>II类类知识产权数量</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                    </el-tooltip>
                  </template>
                  <input-number v-model="formData.intellectualProperty2Num"
                                @update:modelValue="formData.intellectualProperty2Num = $event" :is-decimal="false"
                                :is-positive="true" :append="'件'"/>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="formData.intellectualProperty2Num>0">
                <el-form-item label="实用新型">
                  <input-number v-model="formData.newModel" @update:modelValue="formData.newModel = $event"
                                :is-decimal="false" :is-positive="true" :append="'件'" @blur="handleChangeIP2Num"/>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="formData.intellectualProperty2Num>0">
                <el-form-item label="外观设计">
                  <input-number v-model="formData.design" @update:modelValue="formData.design = $event"
                                :is-decimal="false" :is-positive="true" :append="'件'" @blur="handleChangeIP2Num"/>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="formData.intellectualProperty2Num>0">
                <el-form-item label="软件著作权">
                  <input-number v-model="formData.copyright" @update:modelValue="formData.copyright = $event"
                                :is-decimal="false" :is-positive="true" :append="'件'" @blur="handleChangeIP2Num"/>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="choice-project">
            <h2>请选择需要评测的项目</h2>
            <div class="prompt" v-if="false">*
              由于各申报认定的项目所填写的表单内容存在差异且较多，请选择你需要的申报的项目，系统将为你生成相应项目表单内容。
            </div>
            <div class="choice-item">
              <el-checkbox-group v-model="formData.choiceItem">
                <el-checkbox :label="1" @change="handleClickScroll(1)">科技型中小企业认定</el-checkbox><br>
                <el-checkbox :label="2" @change="handleClickScroll(2)">高新技术企业认定</el-checkbox><br>
                <el-checkbox :label="3" @change="handleClickScroll(3)">创新型中小企业认定</el-checkbox><br>
                <el-checkbox :label="4" @change="handleClickScroll(4)">“专精特新”中小企业认定</el-checkbox><br>
                <el-checkbox :label="5" @change="handleClickScroll(5)">上海市企业技术中心认定</el-checkbox><br>
                <el-checkbox :label="6" @change="handleClickScroll(6)">高新技术成果转化项目认定</el-checkbox><br>
                <el-checkbox :label="7" @change="handleClickScroll(7)" :disabled="isOptionDisabled(7)"><span style="font-size: 13px">上海市企事业专利工作(试点)单位认定</span>
                  <el-tooltip placement="right">
                    <template #content>
                      <strong>申报范围:</strong>
                      <br>（一）在本市注册或登记 3 年以上、具有独立法人资格的企
                      <br>事业单位。企事业单位应属于国家和本市重点发展的产业领域，
                      <br>治理结构完善，管理制度健全，经营状况良好。
                      <br>（二）经市经信委认定为“知识产权示范企业”或“知识产
                      <br>权优势企业”的，以及经市知识产权局认定为“专利示范企业”
                      <br>或专利工作示范单位的，不再申报。
                      <br>（三）2012 年以前经市知识产权局认定为“专利试点企业”
                      <br>的，可以申报专利工作示范单位，但不再申报专利工作试点单位。
                      <br>（四）对存在未处理的非正常专利申请行为的企事业单位，
                      <br>取消申报资格。
                      <br>（五）参与本项目申报的单位，不得同时申报“上海市中小
                      <br>企业知识产权运营能力提升项目”。
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </el-checkbox><br>
                <el-checkbox :label="8" @change="handleClickScroll(8)" :disabled="isOptionDisabled(8)"><span style="font-size: 13px">上海市企事业专利工作(示范)单位认定</span>
                  <el-tooltip placement="right">
                    <template #content>
                      <strong>申报范围:</strong>
                      <br>（一）在本市注册或登记 3 年以上、具有独立法人资格的企
                      <br>事业单位。企事业单位应属于国家和本市重点发展的产业领域，
                      <br>治理结构完善，管理制度健全，经营状况良好。
                      <br>（二）经市经信委认定为“知识产权示范企业”或“知识产
                      <br>权优势企业”的，以及经市知识产权局认定为“专利示范企业”
                      <br>或专利工作示范单位的，不再申报。
                      <br>（三）2012 年以前经市知识产权局认定为“专利试点企业”
                      <br>的，可以申报专利工作示范单位，但不再申报专利工作试点单位。
                      <br>（四）对存在未处理的非正常专利申请行为的企事业单位，
                      <br>取消申报资格。
                      <br>（五）参与本项目申报的单位，不得同时申报“上海市中小
                      <br>企业知识产权运营能力提升项目”。
                    </template>
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="human-resource-list" v-if="false">
            <h2>五、人力资源情况</h2>
            <div class="table-data">
              <div class="table-header">
                <div class="table-cell" style="width: 18%; padding: 0 1%"></div>
                <div class="table-cell" style="width: 38%; padding: 0 1%">企业职工
                  <el-tooltip content="企业职工总数包括企业在职、兼职和临时聘用人员。在职人员通过企业是否签订了劳动合同或缴纳社会保险费来鉴别，兼职、临时聘用人员全年须在企业累计工作6个月以上。
                      <br>企业职工总数、科技人员数均按照全年季平均数计算。
                      <br>季平均数=（季初数+季末数）÷2
                      <br>全年季平均数=全年各季平均数之和÷4" placement="right">
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="table-cell" style="width: 38%; padding: 0 1%">其中：科技（研究开发）人员
                  <el-tooltip
                      content="研发和相关技术创新活动，以及专门从事上述活动管理和提供直接服务的人员，包括在职、兼职和临时聘用人员，兼职、临时聘用人员全年须在企业累计工作6个月以上."
                      placement="right">
                    <el-icon color="#1ab394">
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell" style="width: 18%; padding: 0 1%">总 数（人）</div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.totalEmployeeNum"/>
                </div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.totalResearcherNum"/>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell" style="width: 14%; padding: 0 3%">其中：在职人员</div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.activeEmployeeNum"/>
                </div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.activeResearcherNum"/>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell" style="width: 14%; padding: 0 3%">兼职人员</div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.partTimeEmployeeNum"/>
                </div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.partTimeResearcherNum"/>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell" style="width: 14%; padding: 0 3%">临时聘用人员</div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.tempEmployeeNum"/>
                </div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.tempResearcherNum"/>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell" style="width: 14%; padding: 0 3%">外籍人员</div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.foreignEmployeeNum"/>
                </div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.foreignResearcherNum"/>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell" style="width: 14%; padding: 0 3%">留学归国人员</div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.returnedEmployeeNum"/>
                </div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.returnedResearcherNum"/>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell" style="width: 14%; padding: 0 3%">千人计划人员</div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.plannedEmployeeNum"/>
                </div>
                <div class="table-cell" style="width: 20%; padding: 0 10%">
                  <el-input v-model="formData.plannedResearcherNum"/>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell cell-header">学 历</div>
                <div class="table-cell cell-header">博 士</div>
                <div class="table-cell cell-header">硕 士</div>
                <div class="table-cell cell-header">本 科</div>
                <div class="table-cell cell-header">大专及以下</div>
              </div>
              <div class="table-row">
                <div class="table-cell cell-header">人 数</div>
                <div class="table-cell cell-input">
                  <el-input v-model="formData.doctorNum"/>
                </div>
                <div class="table-cell cell-input">
                  <el-input v-model="formData.masterNum"/>
                </div>
                <div class="table-cell cell-input">
                  <el-input v-model="formData.undergraduateNum"/>
                </div>
                <div class="table-cell cell-input">
                  <el-input v-model="formData.juniorCollegeNum"/>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell cell-header">职 称</div>
                <div class="table-cell cell-header">高级职称</div>
                <div class="table-cell cell-header">中级职称</div>
                <div class="table-cell cell-header">初级职称</div>
                <div class="table-cell cell-header">高级技工</div>
              </div>
              <div class="table-row">
                <div class="table-cell cell-header">人 数</div>
                <div class="table-cell cell-input">
                  <el-input v-model="formData.highTitleNum"/>
                </div>
                <div class="table-cell cell-input">
                  <el-input v-model="formData.mediumTitleNum"/>
                </div>
                <div class="table-cell cell-input">
                  <el-input v-model="formData.primaryTitleNum"/>
                </div>
                <div class="table-cell cell-input">
                  <el-input v-model="formData.seniorTechnicianNum"/>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell cell-header">年 龄</div>
                <div class="table-cell cell-header">30及以下</div>
                <div class="table-cell cell-header">31-40</div>
                <div class="table-cell cell-header">41-50</div>
                <div class="table-cell cell-header">51及以上</div>
              </div>
              <div class="table-row">
                <div class="table-cell cell-header">人 数</div>
                <div class="table-cell cell-input">
                  <el-input v-model="formData.under30Num"/>
                </div>
                <div class="table-cell cell-input">
                  <el-input v-model="formData.under40Num"/>
                </div>
                <div class="table-cell cell-input">
                  <el-input v-model="formData.under50Num"/>
                </div>
                <div class="table-cell cell-input">
                  <el-input v-model="formData.above51Num"/>
                </div>
              </div>
            </div>
          </div>
          <el-collapse ref="techCollapse" v-model="collapseVal">
            <el-collapse-item :ref="(ref) => {techChildCollapse['1'] = ref}" name="1" v-if="isTechSmes">
              <template v-slot:title>
                <h2><img alt="" src="../../assets/project.png"><span>科技型中小企业认定</span></h2>
              </template>
              <div class="form-module">
<!--                <el-row :gutter="0">-->
<!--                  <el-col :span="24">-->
<!--                    <div class="tip custom-block">-->
<!--                      <p class="pdf-view" @click="handleClickPdf('科技型中小企业评价办法.pdf')">查看：<span style="color:#409EFF">科技部 财政部 国家税务总局关于印发《科技型中小企业评价办法》的通知.PDF</span></p>-->
<!--                    </div>-->
<!--                  </el-col>-->
<!--                </el-row>-->
                <el-form-item label="企业提供的产品和服务不属于国家规定的禁止、限制和淘汰类" required prop="notObsolete">
                  <el-radio-group v-model="formData.notObsolete">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="企业在填报上一年及当年内未发生重大安全、重大质量事故和严重环境违法、科研严重失信行为，且企业未列入经营异常名录和严重违法失信企业名单"
                    required prop="notIllegal">
                  <el-radio-group v-model="formData.notIllegal">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="企业拥有有效期内高新技术企业资格证书" required prop="hasQualification">
                  <el-radio-group v-model="formData.hasQualification">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="企业拥有经认定的省部级以上研发机构" required prop="hasInstitution">
                  <el-radio-group v-model="formData.hasInstitution">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="企业近五年内获得过国家级科技奖励，并在获奖单位中排在前三名" required
                              prop="gainTop3Prize">
                  <el-radio-group v-model="formData.gainTop3Prize">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="企业近五年内主导制定过国际标准、国家标准、或行业标准" required
                              prop="createStandardIn5Years">
                  <el-radio-group v-model="formData.createStandardIn5Years">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-collapse-item>

            <el-collapse-item :ref="(ref) => {techChildCollapse['2'] = ref}" name="2" v-if="isHighNewTech">
              <template v-slot:title>
                <h2><img alt="" src="../../assets/project.png"><span>高新技术企业认定</span></h2>
              </template>
              <div class="form-module">
<!--                <el-row :gutter="0">-->
<!--                  <el-col :span="24">-->
<!--                    <div class="tip custom-block">-->
<!--                      <p class="pdf-view" @click="handleClickPdf('高新技术企业认定管理工作指引.pdf')">查看：<span style="color:#409EFF">高新技术企业认定管理工作指引.PDF</span></p>-->
<!--                    </div>-->
<!--                  </el-col>-->
<!--                </el-row>-->
                <el-form-item label="技术的先进程度（单选）" required prop="technologyExtent">
                  <template #label>
                    <el-tooltip placement="right">
                      <template #content>
                        <br>技术的先进性定义：先进性是指科技成果的技术水平必须是先进的。
                        <br>确定一项技术是否具有先进性，需用技术与此前的同类技术水平进行比较。
                        <br>证明该项技术具有突出的特点和明显的进步。新的技术必然是来源于现有的科技成果，而又将高于现有的科技成果。
                        <br><br><strong>评定一项技术成果是否具有技术先进性，主要从以下三个方面来进行：</strong>
                        <br>1）技术原理的进步；
                        <br>2）技术构成的进步；
                        <br>3）技术效果的进步；
                        <br><br><strong>相关证明材料：</strong>
                        <br>1）查新报告；
                        <br>2）专利检索报告；
                        <br>3）软件测试报告；
                        <br>4）应用单位证明材料；
                        <br>5）设计、工艺及质量检测；
                        <br>6）鉴定报告；
                        <br>7）研发项目鉴定报告；
                        <br>8）新技术新产品证书；
                        <br>9）经过第三方鉴定的技术合同
                      </template>
                      <span>
                          <span>技术的先进程度（单选）</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                    </el-tooltip>
                  </template>
                  <el-radio-group v-model="formData.technologyExtent">
                    <el-radio :label="1">高</el-radio>
                    <el-radio :label="2">较高</el-radio>
                    <el-radio :label="3">一般</el-radio>
                    <el-radio :label="4">较低</el-radio>
                    <el-radio :label="5">无</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="相关知识产权对企业主要产品（服务）在技术上发挥核心支持作用（单选）" required
                              prop="centralRole">
                  <template #label>
                    <el-tooltip placement="right">
                      <template #content>
                        知识产权技术对主要产品（服务）在技术上发挥核心支持作用，可以从以下几方面判定：
                        <br><strong>一、是看知识产权的所有权。</strong>
                        <br>企业有完全所有权的，得分肯定比共有的支持作用强；共有者越多，支持作用也就越弱。
                        <br><br><strong>二、是看知识产权权利要求项与产品技术的符合度。</strong>
                        <br>这个很好理解，也就是我们是生产产品的技术如果能从知识产权权利要求项下找得到对应条款，
                        <br>也就说明该项产品技术与知识产权符合度高，知识产权对主要产品（服务）在技术上发挥核心支持作用就强。
                        <br><br><strong>三、是从知识产权的申请授权时间来判定。</strong>
                        <br>申报高企时，知识产权距离授权时间越长，就表明其对主要产品（服务）在技术上发挥核心支持作用是越强的。
                        <br>这是因为知识产权在维持 过程中也会交年费，所以时间越长，就表明了这一点。毕竟如果只是产权对主要
                        <br>产品（服务）在技术上发挥核心支持作用的效果不好，企业估计也不会继续缴纳年费。
                        <br><br><strong>四、是用该知识产权技术生产出来的产品情况米判定。</strong>
                        <br>因为技术只有转化成生产力才能体现其价值。所以我们可以从知识产权技术生产出来对应的产品的成本、效率、
                        <br>功能、维权、获奖等方面，与市场同类产品比较，判定知识产权对主要产品（服务）在技术上是否发挥了核心支持作用。
                        <br>还有就是也可以从技术领先性和含金量这一方面来作出相关评价，比如你所拥有的知识产权技术可以降低产品成本，
                        <br>比市场同类的低，那就能表明知识产权对主要产品（服务）在技术上发挥核心支持作用的强。
                      </template>
                      <span>
                          <span>相关知识产权对企业主要产品（服务）在技术上发挥核心支持作用（单选）</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                    </el-tooltip>
                  </template>
                  <el-radio-group v-model="formData.centralRole">
                    <el-radio :label="1">强</el-radio>
                    <el-radio :label="2">较强</el-radio>
                    <el-radio :label="3">一般</el-radio>
                    <el-radio :label="4">较弱</el-radio>
                    <el-radio :label="5">无</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="企业是否参与编制国家标准、行业标准、检测方法、技术规范的情况" required
                              prop="createStandard">
                  <el-radio-group v-model="formData.createStandard">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="研究开发组织管理水平(可多选)" required prop="rdManageLevel" style="text-align: left">
                  <el-checkbox-group v-model="formData.rdManageLevel">
                    <el-checkbox :label="1">制定了企业研究开发的组织管理制度</el-checkbox>
                    <el-checkbox :label="2">建立了研发投入核算体系</el-checkbox>
                    <el-checkbox :label="3">编制了研发费用辅助账</el-checkbox>
                    <br>
                    <el-checkbox :label="4">设立了内部科学技术研究开发机构并具备相应的科研条件</el-checkbox>
                    <el-checkbox :label="5">与国内外研究开发机构开展多种形式的产学研合作</el-checkbox>
                    <br>
                    <el-checkbox :label="6">建立了科技成果转化的组织实施与激励奖励制度</el-checkbox>
                    <el-checkbox :label="7">建立开放式的创新创业平台</el-checkbox>
                    <br>
                    <el-checkbox :label="8">建立了科技人员的培养进修职工技能培训、优秀人才引进</el-checkbox>
                    <el-checkbox :label="9">建立了人才绩效评价奖励制度</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
              <div class="int-prop-list" v-if="isHighNewTech">
                <h2>知识产权明细</h2>
                <div class="table-data">
                  <div class="table-header">
                    <div class="table-cell">知识产权编号</div>
                    <div class="table-cell">知识产权名称</div>
                    <div class="table-cell">类别</div>
                    <div class="table-cell">授权日期</div>
                    <div class="table-cell">授权号</div>
                    <div class="table-cell">获得方式</div>
                  </div>
                  <div class="table-row" v-for="(item,index) in formData.properties" :key="index">
                    <div class="table-cell">
                      <el-input v-model="item.serialNumber" placeholder="IP......"/>
                    </div>
                    <div class="table-cell">
                      <el-input v-model="item.name"/>
                    </div>
                    <div class="table-cell">
                      <el-select placeholder="请选择" v-model="item.category">
                        <el-option
                            v-for="item in intPropType"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="table-cell">
                      <el-date-picker clearable
                                      style="width:100%"
                                      v-model="item.day"
                                      type="date"
                                      value-format="YYYY-MM-DD"
                                      placeholder="请选择授权日期">
                      </el-date-picker>
                    </div>
                    <div class="table-cell">
                      <el-input v-model="item.code"/>
                    </div>
                    <div class="table-cell">
                      <el-select placeholder="请选择" v-model="item.method" clearable>
                        <el-option
                            v-for="item in accessType"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <el-tooltip content="行数不够，点击再加一行" placement="left">
                  <span class="plus" @click="addPropertiesRows()">+</span>
                </el-tooltip>
                <el-tooltip content="行数太多，点击减一行" placement="right" v-if="formData.properties.length>5">
                  <span class="plus" @click="delPropertiesRows()">-</span>
                </el-tooltip>
              </div>
              <div class="int-prop-act-list">
                <h2>企业研究开发活动情况表（近三年执行的活动，按单一活动填报）</h2>
                <div class="table-data">
                  <div class="table-header">
                    <div class="table-cell">研发活动编号</div>
                    <div class="table-cell">研发活动名称</div>
                    <div class="table-cell">开始日期</div>
                    <div class="table-cell">结束日期</div>
                    <div class="table-cell">技术领域
                      <el-tooltip content="《国家重点支持的高新技术领域》中规定的八大领域。"
                                  placement="top-start">
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="table-cell">技术来源
                      <el-tooltip
                          content="企业自有技术，其他企业技术，中央属科研院所，地方属科研院所，大专院校，引进技术本企业消化创新，国外技术。"
                          placement="top-start">
                        <el-icon>
                          <QuestionFilled/>
                        </el-icon>
                      </el-tooltip>
                    </div>
                    <div class="table-cell">知识产权编号</div>
                  </div>
                  <div class="table-row" v-for="(item,index) in formData.smeCompanyResearchDevActivities" :key="index">
                    <div class="table-cell">
                      <el-input v-model="item.rdNumber" placeholder="RD......"/>
                    </div>
                    <div class="table-cell">
                      <el-input v-model="item.rdName"/>
                    </div>
                    <div class="table-cell">
                      <el-date-picker clearable
                                      style="width:100%"
                                      v-model="item.beginDate"
                                      type="date"
                                      value-format="YYYY-MM-DD"
                                      placeholder="请选择开始日期">
                      </el-date-picker>
                    </div>
                    <div class="table-cell">
                      <el-date-picker clearable
                                      style="width:100%"
                                      v-model="item.endDate"
                                      type="date"
                                      value-format="YYYY-MM-DD"
                                      placeholder="请选择结束日期">
                      </el-date-picker>
                    </div>
                    <div class="table-cell">
                      <el-select v-model="item.technicalField" clearable>
                        <el-option value="一、电子信息" label="一、电子信息"/>
                        <el-option value="二、生物与新医药" label="二、生物与新医药"/>
                        <el-option value="三、航空航天" label="三、航空航天"/>
                        <el-option value="四、新材料" label="四、新材料"/>
                        <el-option value="五、高技术服务" label="五、高技术服务"/>
                        <el-option value="六、新能源与节能" label="六、新能源与节能"/>
                        <el-option value="七、资源与环境" label="七、资源与环境"/>
                        <el-option value="八、先进制造与自动化" label="八、先进制造与自动化"/>
                      </el-select>
                    </div>
                    <div class="table-cell">
                      <el-select v-model="item.technicalSource" clearable>
                        <el-option value="企业自有技术" label="企业自有技术"/>
                        <el-option value="其他企业技术" label="其他企业技术"/>
                        <el-option value="中央属科研院所" label="中央属科研院所"/>
                        <el-option value="地方属科研院所" label="地方属科研院所"/>
                        <el-option value="大专院校" label="大专院校"/>
                        <el-option value="引进技术本企业消化创新" label="引进技术本企业消化创新"/>
                        <el-option value="国外技术" label="国外技术"/>
                      </el-select>
                    </div>
                    <div class="table-cell">
                      <el-input v-model="item.ipNumber" placeholder="IP......"/>
                    </div>
                  </div>
                </div>
                <el-tooltip content="行数不够，点击再加一行" placement="right">
                  <span class="plus" @click="addRows()">+</span>
                </el-tooltip>
                <el-tooltip content="行数太多，点击减一行" placement="right"
                            v-if="formData.smeCompanyResearchDevActivities.length>5">
                  <span class="plus" @click="delRows()">-</span>
                </el-tooltip>
              </div>
            </el-collapse-item>

            <el-collapse-item :ref="(ref) => {techChildCollapse['3'] = ref}" name="3" v-if="isInnovateSmes">
              <template v-slot:title>
                <h2><img alt="" src="../../assets/project.png"><span>创新型中小企业认定</span></h2>
              </template>
              <div class="form-module">
<!--                <el-row :gutter="0">-->
<!--                  <el-col :span="24">-->
<!--                    <div class="tip custom-block">-->
<!--                      <p class="pdf-view" @click="handleClickPdf('创新型中小企业评价工作的通知.pdf')">查看：<span style="color:#409EFF">上海市经济和信息化委关于组织开展2023年创新型中小企业评价工作的通知.PDF</span></p>-->
<!--                    </div>-->
<!--                  </el-col>-->
<!--                </el-row>-->
                <el-row :gutter="50">
                  <el-col :span="8">
                    <el-form-item label="主导产品名称（中文）" required prop="productName">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            主导产品是指企业核心技术在产品中发挥重要作用，且产品收入之和占企业同期营业收入比重超过 50%。
                          </template>
                          <span>
                          <span>主导产品名称（中文）</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <el-input v-model="formData.productName"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="从事该产品领域的时间（单位：年）" required prop="productYear">
                      <el-input v-model="formData.productYear"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item v-if="false" label="主导产品类别（4位数代码及名称）" required prop="productCategory">
                      <el-input v-model="formData.productCategory"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="近3年是否获得国家级科技奖励" required prop="nationalLevelRewards">
                  <el-radio-group v-model="formData.nationalLevelRewards">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                  <div class="if-true-show" v-if="formData.nationalLevelRewards === 1">
                    如是，请填写：
                    年份：
                    <el-input size="small" v-model="formData.nationalLevelRewardsYear"/>
                    年，
                    名称：
                    <el-input size="small" v-model="formData.nationalLevelRewardsName"/>
                    ，
                    排名：
                    <input-number size="small" v-model="formData.nationalLevelRewardsRank"
                                  @update:modelValue="formData.nationalLevelRewardsRank = $event" :is-decimal="false"/>

                  </div>
                </el-form-item>
                <el-form-item label="近3年是否获得省级科技奖励" required prop="provincialLevelRewards">
                  <el-radio-group v-model="formData.provincialLevelRewards">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                  <div class="if-true-show" v-if="formData.provincialLevelRewards === 1">
                    如是，请填写：
                    年份：
                    <el-input size="small" v-model="formData.provincialLevelRewardsYear"/>
                    年，
                    名称：
                    <el-input size="small" v-model="formData.provincialLevelRewardsName"/>
                    ，
                    排名：
                    <input-number size="small" v-model="formData.provincialLevelRewardsRank"
                                  @update:modelValue="formData.provincialLevelRewardsRank = $event" :is-decimal="false"/>
                  </div>
                </el-form-item>
                <el-form-item label="获得有关荣誉情况（有效期内）" required prop="isHasHonor">
                  <el-radio-group v-model="formData.isHasHonor">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                  <div class="if-true-show" v-if="formData.isHasHonor === 1">
                    如是，请打钩:
                    <el-checkbox-group v-model="formData.hasHonor">
                      <el-checkbox :label="1">高新技术企业</el-checkbox>
                      <el-checkbox :label="2">国家级技术创新示范企业</el-checkbox>
                      <el-checkbox :label="3">知识产权优势企业</el-checkbox>
                      <el-checkbox :label="4">知识产权示范企业</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-form-item>
                <el-form-item label="是否拥有经认定的省部级以上研发机构" required prop="isHasInstitutions">
                  <el-radio-group v-model="formData.isHasInstitutions">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                  <div class="if-true-show" v-if="formData.isHasInstitutions === 1">
                    如是，请填写：
                    国家级：
                    <input-number size="small" v-model="formData.nationalCount"
                                  @update:modelValue="formData.nationalCount = $event" :is-decimal="false"/>
                    个，
                    省级：
                    <input-number size="small" v-model="formData.provincialCount"
                                  @update:modelValue="formData.provincialCount = $event" :is-decimal="false"/>
                    个。
                  </div>
                </el-form-item>
                <el-form-item label="近三年内新增股权融资额总额" v-if="false">
                  <div class="normal-show">
                    合格机构投资者的实缴额：
                    <input-number size="small" v-model="formData.equityFinancing"
                                  @update:modelValue="formData.equityFinancing = $event" :is-decimal="true" :digit="4"/>
                    万元以上，
                    最新企业估值：
                    <input-number size="small" v-model="formData.valuation"
                                  @update:modelValue="formData.valuation = $event" :is-decimal="true" :digit="4"/>
                    万元
                  </div>
                </el-form-item>
                <el-form-item label="所属领域及其他" required prop="isDomain">
                  <el-radio-group v-model="formData.isDomain">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                  <div class="if-true-show" v-if="formData.isDomain === 0">
                    其他领域请说明：
                    <el-input size="small" v-model="formData.domainOther"/>
                  </div>
                  <div class="if-true-show" style="margin-left: 0" v-if="formData.isDomain === 1">
                    如是，请打钩:
                    <el-checkbox-group v-model="formData.domain">
                      <el-checkbox :label="1">新一代信息技术</el-checkbox>
                      <el-checkbox :label="2">生物技术</el-checkbox>
                      <el-checkbox :label="3">新能源</el-checkbox>
                      <el-checkbox :label="4">新材料</el-checkbox>
                      <el-checkbox :label="5">高端装备</el-checkbox>
                      <el-checkbox :label="6">新能源汽车</el-checkbox>
                      <el-checkbox :label="7">绿色环保</el-checkbox>
                      <el-checkbox :label="8">航空航天</el-checkbox>
                      <el-checkbox :label="9">海洋装备</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-form-item>
              </div>
            </el-collapse-item>

            <el-collapse-item :ref="(ref) => {techChildCollapse['4'] = ref}" name="4" v-if="isSpecUltraNewSmes">
              <template v-slot:title>
                <h2><img alt="" src="../../assets/project.png"><span>"专精特新"中小企业认定</span></h2>
              </template>
              <div class="form-module">
<!--                <el-row :gutter="0">-->
<!--                  <el-col :span="24">-->
<!--                    <div class="tip custom-block">-->
<!--                      <p class="pdf-view" @click="handleClickPdf('2023年度上海市专精特新中小企业申请表.pdf')">查看：<span style="color:#409EFF">2023年度上海市专精特新中小企业申请表.PDF</span></p>-->
<!--                    </div>-->
<!--                  </el-col>-->
<!--                </el-row>-->
                <el-row :gutter="50">
                  <el-col :span="8">
                    <el-form-item label="主导产品名称（中文）" required prop="productName">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            主导产品是指企业核心技术在产品中发挥重要作用，且产品收入之和占企业同期营业收入比重超过 50%。
                          </template>
                          <span>
                          <span>主导产品名称（中文）</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <el-input v-model="formData.productName"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="从事该产品领域的时间（单位：年）" required prop="productYear">
                      <el-input v-model="formData.productYear"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item v-if="false" label="主导产品类别（4位数代码及名称）" required prop="productCategory">
                      <el-input v-model="formData.productCategory"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="近3年是否获得国家级科技奖励" required prop="nationalLevelRewards">
                  <el-radio-group v-model="formData.nationalLevelRewards">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                  <div class="if-true-show" v-if="formData.nationalLevelRewards === 1">
                    如是，请填写：
                    年份：
                    <el-input size="small" v-model="formData.nationalLevelRewardsYear"/>
                    年，
                    名称：
                    <el-input size="small" v-model="formData.nationalLevelRewardsName"/>
                    ，
                    排名：
                    <input-number size="small" v-model="formData.nationalLevelRewardsRank"
                                  @update:modelValue="formData.nationalLevelRewardsRank = $event" :is-decimal="false"/>

                  </div>
                </el-form-item>
                <el-form-item label="近3年是否获得省级科技奖励" required prop="provincialLevelRewards">
                  <el-radio-group v-model="formData.provincialLevelRewards">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                  <div class="if-true-show" v-if="formData.provincialLevelRewards === 1">
                    如是，请填写：
                    年份：
                    <el-input size="small" v-model="formData.provincialLevelRewardsYear"/>
                    年，
                    名称：
                    <el-input size="small" v-model="formData.provincialLevelRewardsName"/>
                    ，
                    排名：
                    <input-number size="small" v-model="formData.provincialLevelRewardsRank"
                                  @update:modelValue="formData.provincialLevelRewardsRank = $event" :is-decimal="false"/>
                  </div>
                </el-form-item>
                <el-form-item label="近3年进入“创客中国”中小企业创新创业大赛全国500强企业组名单" required
                              prop="isChinaMaker500">
                  <el-radio-group v-model="formData.isChinaMaker500">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                  <div class="if-true-show" v-if="formData.isChinaMaker500 === 1">
                    如是，请填写：
                    年份：
                    <el-input size="small" v-model="formData.chinaMaker500Year"/>
                    年，
                    排名：
                    <input-number size="small" v-model="formData.chinaMaker500Rank"
                                  @update:modelValue="formData.chinaMaker500Rank = $event" :is-decimal="false"/>
                  </div>
                </el-form-item>
                <el-form-item label="主导产品是否在产业链关键环节及关键领域“补短板”“锻长板”“填空白”取得实际成效" required
                              prop="isNiubi">
                  <el-radio-group v-model="formData.isNiubi">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                  <div class="if-true-show" style=" width: 100%; margin-left: 0" v-if="formData.isNiubi === 1">
                    如是，请填写：
                    “补短板”或“锻长板”的产品名称：
                    <el-input size="small" v-model="formData.niubiShort"/>
                    ，<br>
                    或填补国内（国际）空白的领域：
                    <el-input size="small" v-model="formData.niubiSpace"/>
                    ，或达到国内领先、国际先进水平的产品名称：
                    <el-input size="small" v-model="formData.niubiAdvanced"/>
                    。
                  </div>
                </el-form-item>
                <el-form-item label="主导产品是否属于工业“六基”领域" required prop="isDomanBasic6">
                  <el-radio-group v-model="formData.isDomanBasic6">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                  <div class="if-true-show" v-if="formData.isDomanBasic6 === 1">
                    如是，请打钩:
                    <el-checkbox-group v-model="formData.domanBasic6">
                      <el-checkbox :label="1">核心基础零部件</el-checkbox>
                      <el-checkbox :label="2">核心基础元器件</el-checkbox>
                      <el-checkbox :label="3">关键软件</el-checkbox>
                      <el-checkbox :label="4">先进基础工艺</el-checkbox>
                      <el-checkbox :label="5">关键基础材料</el-checkbox>
                      <el-checkbox :label="6">产业技术基础</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-form-item>
                <el-form-item label="主导产品是否属于中华老字号名录" required prop="isChinaBrand">
                  <el-radio-group v-model="formData.isChinaBrand">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="主导产品为知名大企业直接配套" required prop="isDirectMatch">
                  <el-radio-group v-model="formData.isDirectMatch">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="企业数字化转型水平" required prop="digitalLevel">
                  <template #label>
                    <el-tooltip placement="right">
                      <template #content>
                        数字化水平测试等级结果证明（工信部优质中小企业梯度培育平台自测获取）
                        <br>https://zjtx.miit.gov.cn/zxqySy/main
                      </template>
                      <span>
                          <span>企业数字化转型水平</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                    </el-tooltip>
                  </template>
                  <el-radio-group v-model="formData.digitalLevel">
                    <el-radio :label="1">一级</el-radio>
                    <el-radio :label="2">二级</el-radio>
                    <el-radio :label="3">三级及以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="获得省级以上质量奖荣誉" required prop="qualityHonorTotal">
                  <div class="normal-show">
                    国家级、省级质量奖
                    <input-number size="small" v-model="formData.qualityHonorTotal"
                                  @update:modelValue="formData.qualityHonorTotal = $event" :is-decimal="false"/>
                    项。<br>
                    其中：国家级质量奖
                    <input-number size="small" v-model="formData.qualityHonorNational"
                                  @update:modelValue="formData.qualityHonorNational = $event" :is-decimal="false"/>
                    项，省级质量奖
                    <input-number size="small" v-model="formData.qualityHonorProvincial"
                                  @update:modelValue="formData.qualityHonorProvincial = $event" :is-decimal="false"/>
                    项。
                  </div>
                </el-form-item>
                <el-form-item label="企业获得的管理体系认证情况（可多选）" required prop="qualityMangeCert">
                  <el-checkbox-group v-model="formData.qualityMangeCert">
                    <!--<el-checkbox :label="1">未建立质量管理体系</el-checkbox>-->
                    <el-checkbox :label="2">ISO9000质量管理体系认证</el-checkbox>
                    <el-checkbox :label="3">ISO14000环境管理体系认证</el-checkbox>
                    <el-checkbox :label="4">OHSAS18000职业安全健康管理体系认证</el-checkbox>
                    <el-checkbox :label="5">其他</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="企业自主品牌" required prop="ownBrandCount">
                  <template #label>
                    <el-tooltip placement="right">
                      <template #content>
                        所称拥有自主品牌是指主营业务产品或服务具有自主知识产权，且符合下列条件之一：
                        <br>1.产品或服务品牌已经国家知识产权局商标局正式注册。
                        <br>2.产品或服务已经实现收入。
                      </template>
                      <span>
                          <span>企业自主品牌</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                    </el-tooltip>
                  </template>
                  <div class="normal-show">
                    自有品牌
                    <input-number size="small" v-model="formData.ownBrandCount"
                                  @update:modelValue="formData.ownBrandCount = $event" :is-decimal="false"/>
                    项，名称：
                    <el-input size="small" v-model="formData.ownBrandName"/>
                    。<br>
                    省级以上著名品牌
                    <input-number size="small" v-model="formData.ownBrandProvCount"
                                  @update:modelValue="formData.ownBrandProvCount = $event" :is-decimal="false"/>
                    项，名称：
                    <el-input size="small" v-model="formData.ownBrandProvName"/>
                    。
                  </div>
                </el-form-item>
                <el-form-item label="作为主要起草单位制修订的已批准发布标准数量和名称" required prop="standardTotal">
                  <div class="normal-show">
                    国际、国家、行业标准总数
                    <input-number size="small" v-model="formData.standardTotal"
                                  @update:modelValue="formData.standardTotal = $event" :is-decimal="false"/>
                    项<br>
                    其中：国际标准
                    <input-number size="small" v-model="formData.standardInternational"
                                  @update:modelValue="formData.standardInternational = $event" :is-decimal="false"/>
                    项，国家标准
                    <input-number size="small" v-model="formData.standardNational"
                                  @update:modelValue="formData.standardNational = $event" :is-decimal="false"/>
                    项，行业标准
                    <input-number size="small" v-model="formData.standardIndustry"
                                  @update:modelValue="formData.standardIndustry = $event" :is-decimal="false"/>
                    项。
                  </div>
                </el-form-item>
                <el-form-item label="所属领域是否符合本市产业导向" required prop="isIndustrialOrientation">
                  <el-radio-group v-model="formData.isIndustrialOrientation"
                                  @change="handleChangeIsIndustrialOrientation">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                  <br/>
                  <div class="if-true-show" style="width: 100%; margin-left: 0"
                       v-if="formData.isIndustrialOrientation === 1">
                    属于“3+6”新型产业体系：
                    <el-checkbox-group v-model="formData.industrial36">
                      <el-checkbox :label="1">三大先导产业</el-checkbox>
                      <el-checkbox :label="2">六大重点产业</el-checkbox>
                    </el-checkbox-group>
                    <br>或属于战略性新兴产业领域（国家统计局令第23号）：
                    <el-checkbox-group v-model="formData.industrialStrategy">
                      <el-checkbox :label="1">新一代信息技术</el-checkbox>
                      <el-checkbox :label="2">高端装备制造</el-checkbox>
                      <el-checkbox :label="3">新材料</el-checkbox>
                      <el-checkbox :label="4">生物技术</el-checkbox>
                      <el-checkbox :label="5">新能源汽车</el-checkbox>
                      <el-checkbox :label="6">新能源</el-checkbox>
                      <el-checkbox :label="7">节能环保</el-checkbox>
                      <el-checkbox :label="8">数字创意</el-checkbox>
                      <el-checkbox :label="9">相关服务业</el-checkbox>
                    </el-checkbox-group>
                    <br>或属于区级重点导向产业：
                    <el-input size="small" v-model="formData.industrialDistrictKey"/>
                    <br>或属于本市其他重点产业：
                    <el-input size="small" v-model="formData.industrialLocalKey"/>
                  </div>
                </el-form-item>
                <el-form-item label="细分市场领先地位" required prop="occupancy">
                  <div class="normal-show">
                    主导产品或服务上年度国内市场占有率为
                    <input-number size="small" v-model="formData.occupancy"
                                  @update:modelValue="formData.occupancy = $event" :is-decimal="false"/>
                    % <br/>
                    本市排名：第
                    <input-number size="small" v-model="formData.localRank"
                                  @update:modelValue="formData.localRank = $event" :is-decimal="false"/>
                    名，
                    全国排名：第
                    <input-number size="small" v-model="formData.nationwideRank"
                                  @update:modelValue="formData.nationwideRank = $event" :is-decimal="false"/>
                    名，
                    国际排名：第
                    <input-number size="small" v-model="formData.internationalRank"
                                  @update:modelValue="formData.internationalRank = $event" :is-decimal="false"/>
                    名。
                  </div>
                </el-form-item>
                <el-form-item label="是否具有特色资源或技术进行研制生产，提供独具特色的产品或服务" required
                              prop="hasCharacteristic">
                  <el-radio-group v-model="formData.hasCharacteristic">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="是否实现绿色低碳发展" required prop="isGreenDev">
                  <el-radio-group v-model="formData.isGreenDev" @change="handleChangeIsGreenDev">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                  <br>
                  <div class="if-true-show" v-if="formData.isGreenDev === 1">
                    如是，请打钩:
                    <el-checkbox-group v-model="formData.titleAttestation">
                      <el-checkbox :label="1">绿色产品</el-checkbox>
                      <el-checkbox :label="2">绿色车间</el-checkbox>
                      <el-checkbox :label="3">绿色工厂</el-checkbox>
                      <el-checkbox :label="4">能源管理体系认证</el-checkbox>
                      <el-checkbox :label="5">碳排放管理体系认证</el-checkbox>
                      <el-checkbox :label="6">产品碳足迹认证</el-checkbox>
                      <el-checkbox :label="7">其他</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-form-item>
                <el-form-item label="是否获得区级及以上技术创新、品牌、质量、人才等奖项、资金支持、称号认定" required
                              prop="hasDistrictTitle">
                  <el-radio-group v-model="formData.hasDistrictTitle" @change="handleChangeHasDistrictTitle">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                  <div class="if-true-show" style="margin-left: 0" v-if="formData.hasDistrictTitle === 1">
                    如是，请打钩:
                    <el-checkbox-group v-model="formData.districtTitle">
                      <el-checkbox :label="1">高新技术企业</el-checkbox>
                      <el-checkbox :label="2">上海市科技小巨人企业(含培育)</el-checkbox>
                      <el-checkbox :label="3">上海市品牌引领示范企业（含品牌培育）</el-checkbox>
                      <el-checkbox :label="4">上海市专利工作试点示范单位</el-checkbox>
                      <el-checkbox :label="5">上海产学研合作优秀项目奖</el-checkbox>
                      <el-checkbox :label="6">“创客中国”中小企业创新创业大赛上海赛区100强</el-checkbox>
                      <el-checkbox :label="7">其他</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-form-item>
                <el-form-item label="企业有改制上市计划，已完成股份制改制" required prop="isPlanIpo">
                  <el-radio-group v-model="formData.isPlanIpo">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="建立研发机构级别" required prop="hasRdInstitutions">
                  <el-radio-group v-model="formData.hasRdInstitutions" @change="handleChangeHasRdInstitutions">
                    <el-radio :label="0">未建立研发机构</el-radio>
                    <el-radio :label="1">已建立研发机构</el-radio>
                  </el-radio-group>
                  <div class="if-true-show" style="width: 100%; margin-left: 0" v-if="formData.hasRdInstitutions === 1">
                    国家级
                    <input-number size="small" v-model="formData.instNational"
                                  @update:modelValue="formData.instNational = $event" :is-decimal="false"/>
                    个，
                    省级
                    <input-number size="small" v-model="formData.instProvincial"
                                  @update:modelValue="formData.instProvincial = $event" :is-decimal="false"/>
                    个，
                    市级
                    <input-number size="small" v-model="formData.instCity" @update:modelValue="formData.instCity = $event"
                                  :is-decimal="false"/>
                    个，
                    市级以下
                    <input-number size="small" v-model="formData.instCityDown"
                                  @update:modelValue="formData.instCityDown = $event" :is-decimal="false"/>
                    个。<br>
                    <span class="spanInline">其中：技术研究院</span>
                    <el-checkbox-group v-model="formData.researchInstitute">
                      <el-checkbox :label="1">国家级</el-checkbox>
                      <el-checkbox :label="2">市级</el-checkbox>
                      <el-checkbox :label="3">区级</el-checkbox>
                    </el-checkbox-group>
                    <br>
                    <span class="spanInline">其中：企业技术中心</span>
                    <el-checkbox-group v-model="formData.technologyCenter">
                      <el-checkbox :label="1">国家级</el-checkbox>
                      <el-checkbox :label="2">市级</el-checkbox>
                      <el-checkbox :label="3">区级</el-checkbox>
                    </el-checkbox-group>
                    <br>
                    <span class="spanInline">其中：企业工程中心</span>
                    <el-checkbox-group v-model="formData.engineeringCenter">
                      <el-checkbox :label="1">国家级</el-checkbox>
                      <el-checkbox :label="2">市级</el-checkbox>
                      <el-checkbox :label="3">区级</el-checkbox>
                    </el-checkbox-group>
                    <br>
                    <span class="spanInline">其中：工业设计中心</span>
                    <el-checkbox-group v-model="formData.designCenter">
                      <el-checkbox :label="1">国家级</el-checkbox>
                      <el-checkbox :label="2">市级</el-checkbox>
                      <el-checkbox :label="3">区级</el-checkbox>
                    </el-checkbox-group>
                    <br>
                    <span class="spanInline">其中：制造业创新中心</span>
                    <el-checkbox-group v-model="formData.innovationCenter">
                      <el-checkbox :label="1">国家级</el-checkbox>
                      <el-checkbox :label="2">市级</el-checkbox>
                      <el-checkbox :label="3">区级</el-checkbox>
                    </el-checkbox-group>
                    <br>
                    <span class="spanInline">其中：院士专家工作站</span>
                    <el-radio-group v-model="formData.expertsWorkstation">
                      <el-radio :label="1">有</el-radio>
                      <el-radio :label="0">无</el-radio>
                    </el-radio-group>
                    <br>
                    <span class="spanInline">其中：博士后工作站</span>
                    <el-radio-group v-model="formData.postdoctoralFellowWorkstation">
                      <el-radio :label="1">有</el-radio>
                      <el-radio :label="0">无</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>

              </div>
            </el-collapse-item>

            <el-collapse-item :ref="(ref) => {techChildCollapse['5'] = ref}" name="5" v-if="isShTechCenter">
              <template v-slot:title>
                <h2><img alt="" src="../../assets/project.png"><span>上海市企业技术中心认定</span></h2>
              </template>
              <div class="form-module">
                <el-row :gutter="0">
                  <el-col :span="24">
                    <div class="tip custom-block">
                      <p><strong style="color: #ff0000">注意：初次申报 市级企业技术中心 的前提是您的企业已具有 区级企业技术中心资质</strong>。</p>
                      <p>注意“企业研究开发仪器设备原值及研发用软件购置费”、“新产品销售收入”、“新产品销售利润”金额单位为 <strong style="color: #ff0000">千元</strong>。</p>
<!--                      <p class="pdf-view" @click="handleClickPdf('上海市企业技术中心管理办法.pdf')">查看：<span style="color:#409EFF">上海市企业技术中心管理办法.PDF</span></p>-->
<!--                      <p class="pdf-view" @click="handleClickPdf('市级企业技术中心评价标准.pdf')">查看：<span style="color:#409EFF">市级企业技术中心评价标准.PDF</span></p>-->
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="50">
                  <el-col :span="8">
                    <el-form-item required prop="highLevelRdPersonnel">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            技术中心高层次研发人数：指截至报告年度末全职在技
                            <br>术中心工作的高级专家和博士人数（包括在站博士后）。高级
                            <br>专家包括获得国家、省、部等政府部门认定的有突出贡献的专
                            <br>家，享受国家、省、部专项津贴的专家，具有高级职称的专家
                            <br>（含高级技师），以及纳入各级人才计划的专家。
                          </template>
                          <span>
                          <span>技术中心高层次研发人数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.highLevelRdPersonnel" @blur="handleRevenue"
                                    @update:modelValue="formData.highLevelRdPersonnel = $event" :is-decimal="false"
                                    :append="'人'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item required prop="externalExpertsWorkHours">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            来技术中心从事研发工作的外部专家工作时间：指截至
                            <br>报告年度末来技术中心从事研究、技术开发工作的具有较高科
                            <br>技开发能力的海内外专家累计人月。最小统计单位为：0.5 人
                            <br>月。（注：1 人月指一个人在报告年度内工作满 22 个工作日）。
                          </template>
                          <span>
                          <span>来技术中心从事研发工作的外部专家工作时间</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.externalExpertsWorkHours" @blur="handleRevenue"
                                    @update:modelValue="formData.externalExpertsWorkHours = $event" :is-decimal="false"
                                    :append="'人月'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item required prop="foreignCooperationProjects">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            企业对外合作项目数：指企业在报告年度当年立项并开
                            <br>展研发（制）工作、以前年份立项仍继续进行研发（制）的研
                            <br>究开发项目或课题中，与境内外高校、科研院所、产业链上下
                            <br>游企业等联合开展研发的项目数，但不包括完全委托外单位进
                            <br>行研发的项目。
                          </template>
                          <span>
                          <span>企业对外合作项目数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.foreignCooperationProjects" @blur="handleRevenue"
                                    @update:modelValue="formData.foreignCooperationProjects = $event" :is-decimal="false"
                                    :append="'项'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item required prop="changjiangDelta">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            长三角等区域合作项目数：是指企业对外合作项目中，
                            <br>合作对象是位于浙江省、江苏省和安徽省的企业、高校或研究
                            <br>机构。
                          </template>
                          <span>
                          <span>其中：长三角区域合作项目数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.changjiangDelta" @blur="handleRevenue"
                                    @update:modelValue="formData.changjiangDelta = $event" :is-decimal="false"
                                    :append="'项'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item required prop="basicApplication">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            基础研究和应用研究项目数：指企业在报告年度当年立
                            <br>项并开展研发（制）工作、以前年份立项仍继续进行研发（制）
                            <br>的研究开发项目或课题中，主要以科学原理的探索与发现、技
                            <br>术原理的研究为目标的项目数。
                          </template>
                          <span>
                          <span>基础研究和应用研究项目数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.basicApplication" @blur="handleRevenue"
                                    @update:modelValue="formData.basicApplication = $event" :is-decimal="false"
                                    :append="'项'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item required prop="acquisitionCosts">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            企业技术开发仪器设备原值及研发用软件购置费：指
                            <br>报告年度末企业用于研发的固定资产中的仪器和设备原价以
                            <br>及软件购置费用。其中，设备包括用于研发活动的各类机器和
                            <br>设备、试验测量仪器、运输工具、工装工具等，软件购置费用
                            <br>包括用于研发活动的开发软件、设计软件、测试软件等。
                          </template>
                          <span>
                          <span style="font-size: 12px">企业研究开发仪器设备原值及研发用软件购置费<strong style="color: #ff0000">（千元）</strong></span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.acquisitionCosts" @blur="handleRevenue"
                                    @update:modelValue="formData.acquisitionCosts = $event" :is-decimal="true" :digit="4"
                                    :append="'千元'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item required prop="institutionsNumber">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            拥有经认证的研发平台、实验室和检测机构数：指企
                            <br>业作为项目法人承担建设、国家或省级有关部门归口管理且已
                            <br>经获得批复的科技类、研究开发类平台数，以及通过中华人民
                            <br>共和国有关国家部门、国际组织企业认证认可的、仍在有效期
                            <br>内的实验室、检验检测机构数。
                          </template>
                          <span>
                          <span>拥有经认证的研发平台、实验室和检测机构数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.institutionsNumber" @blur="handleRevenue"
                                    @update:modelValue="formData.institutionsNumber = $event" :is-decimal="false"
                                    :append="'个'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item required prop="jointInstitutionsNumber">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            技术中心与其他组织合办研发机构数：指技术中心与
                            <br>高校、研究院所、其他企业联合设立的以科研开发设计为目的
                            <br>的组织机构数量，包括共建实验室、基地、院士工作站等。
                          </template>
                          <span>
                          <span>技术中心与其他组织合办研发机构数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.jointInstitutionsNumber" @blur="handleRevenue"
                                    @update:modelValue="formData.jointInstitutionsNumber = $event" :is-decimal="false"
                                    :append="'个'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item required prop="newsProdSalesRevenue">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            新产品销售收入：对于制造业企业，新产品销售收入
                            <br>指报告年度内企业销售采用新技术原理、新设计构思研制、生
                            <br>产的全新产品，或在结构、材质、工艺等某一方面比原有产品
                            <br>有明显改进，从而显著提高了产品性能或扩大了使用功能的产
                            <br>品实现的销售收入。新产品既包括经政府有关部门认定并在有
                            <br>效期内的新产品，也包括企业自行研制开发，未经政府有关部
                            <br>门认定，从投产之日起一年之内的新产品。
                            <br>对于建筑业企业，新产品销售收入指报告年度内企业采用
                            <br>新技术、新工艺、新结构、新材料等实现的营业收入。
                            <br>对于服务业企业，新产品销售收入指报告年度内企业通过
                            <br>提供在服务内容、服务方式、服务传递系统、服务技术手段等
                            <br>方面全新的、或者作出明显改进的服务实现的营业收入。
                          </template>
                          <span>
                          <span>新产品销售收入<strong style="color: #ff0000">（千元）</strong></span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.newsProdSalesRevenue" @blur="handleRevenue"
                                    @update:modelValue="formData.newsProdSalesRevenue = $event" :is-decimal="true"
                                    :digit="4" :append="'千元'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item required prop="newsProdProfit">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            新产品销售利润：指报告年度内企业通过销售新产品实现的销售（营业）利润。
                          </template>
                          <span>
                          <span>新产品销售利润<strong style="color: #ff0000">（千元）</strong></span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.newsProdProfit" @blur="handleRevenue"
                                    @update:modelValue="formData.newsProdProfit = $event" :is-decimal="true" :digit="4"
                                    :append="'千元'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item required prop="createStandardCount">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            最近三年主持和参加制定的标准数：指企业在报告年
                            <br>度、报告年度前一年、报告年度前二年主持或参加制定，目前
                            <br>仍有效执行的国际、国家、地方、行业、团体和企业标准的数
                            <br>量，包括工程建设工法数。
                          </template>
                          <span>
                          <span>最近三年主持和参加制定的标准</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.createStandardCount" @blur="handleRevenue"
                                    @update:modelValue="formData.createStandardCount = $event" :is-decimal="false"
                                    :append="'件'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item required prop="ipInternationalCount">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            企业拥有的全部国际专利数：指报告年度末企业作为
                            <br>专利权人拥有的、经国外知识产权行政部门授予且在有效期内
                            <br>的专利件数。
                          </template>
                          <span>
                          <span>企业拥有的全部国际专利数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.ipInternationalCount" @blur="handleRevenue"
                                    @update:modelValue="formData.ipInternationalCount = $event" :is-decimal="false"
                                    :append="'件'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item required prop="ipHighQualityCount">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            企业拥有高质量专利数：包括在海外有同族专利权的
                            <br>发明专利，维持年限超过 10 年以上的发明专利，获得国家科
                            <br>学技术奖或中国专利奖的发明专利。
                          </template>
                          <span>
                          <span>企业拥有高质量专利数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.ipHighQualityCount" @blur="handleRevenue"
                                    @update:modelValue="formData.ipHighQualityCount = $event" :is-decimal="false"
                                    :append="'件'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item required prop="overseasInstitutions">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            技术中心在境外设立的研发机构数：<br>指技术中心在港澳台地区及国外设立以科研开发、设计为目的的研发机构数量。
                          </template>
                          <span>
                          <span>技术中心在境外设立的研发机构数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.overseasInstitutions" @blur="handleRevenue"
                                    @update:modelValue="formData.overseasInstitutions = $event" :is-decimal="false"
                                    :append="'个'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="未提交快报材料次数">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            初次认定的企业默认填写0；
                            <br>如果您已具有市级企业技术中心资质，应当定期填报企业技术创新快报。
                            <br>市经济信息化委按照企业技术中心管理要求，定期发布技术创新快报填报通知。
                          </template>
                          <span>
                          <span>未提交快报材料次数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <input-number v-model="formData.unsubmitQuickReport" @blur="handleRevenue"
                                    @update:modelValue="formData.unsubmitQuickReport = $event" :is-decimal="false"
                                    :append="'次'"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item required prop="honorNationalCount">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            近两年年获国家、省部级或行业奖励数：指企业在报
                            <br>告年度、报告年度前一年度，报告年度前两年度获得的，由国
                            <br>务院或省级人民政府以及国家行业学会或协会颁发的奖励数。
                          </template>
                          <span>
                          <span>近两年获国家、省（市）部级或行业奖励数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <div class="normal-show">
                        国家奖励数：
                        <input-number size="small" v-model="formData.honorNationalCount" @blur="handleRevenue"
                                      @update:modelValue="formData.honorNationalCount = $event" :is-decimal="false"/>
                        项，
                        省部级奖励数：
                        <input-number size="small" v-model="formData.honorProvincialCount" @blur="handleRevenue"
                                      @update:modelValue="formData.honorProvincialCount = $event" :is-decimal="false"/>
                        项。
                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item required prop="ipNationalCount">
                      <template #label>
                        <el-tooltip placement="right">
                          <template #content>
                            近两年承担国家和省市专项数：指企业在报告年度、
                            <br>报告年度前一年度，报告年度前两年度承担的，由国家部委和
                            <br>地方政府部门批准立项并开展研发（制）工作的专项数量。
                          </template>
                          <span>
                          <span>近两年承担市级以上专项数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                        </el-tooltip>
                      </template>
                      <div class="normal-show">
                        国家专项：
                        <input-number size="small" v-model="formData.ipNationalCount" @blur="handleRevenue"
                                      @update:modelValue="formData.ipNationalCount = $event" :is-positive="true"
                                      :is-decimal="false"/>
                        项，
                        省市级专项：
                        <input-number size="small" v-model="formData.ipProvincialCount" @blur="handleRevenue"
                                      @update:modelValue="formData.ipProvincialCount = $event" :is-positive="true"
                                      :is-decimal="false"/>
                        项。
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-collapse-item>

            <el-collapse-item :ref="(ref) => {techChildCollapse['6'] = ref}" name="6" v-if="isHighNewTechTransform">
              <template v-slot:title>
                <h2><img alt="" src="../../assets/project.png"><span>高新技术成果转化项目认定</span></h2>
              </template>
              <div class="form-module">
<!--                <el-row :gutter="0">-->
<!--                  <el-col :span="24">-->
<!--                    <div class="tip custom-block">-->
<!--                      <p class="pdf-view" @click="handleClickPdf('上海市高新技术成果转化项目认定办法.pdf')">查看：<span style="color:#409EFF">上海市高新技术成果转化项目认定办法.PDF</span></p>-->
<!--                    </div>-->
<!--                  </el-col>-->
<!--                </el-row>-->
                <el-row :gutter="50">
                  <el-col :span="8">
                    <el-form-item label="申报转化项目名称" required prop="projectName">
                      <el-input v-model="formData.projectName"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="申报转化项目所属技术领域" required prop="techDomain">
                      <el-select v-model="formData.techDomain" clearable>
                        <el-option value="一、电子信息" label="一、电子信息"/>
                        <el-option value="二、生物与新医药" label="二、生物与新医药"/>
                        <el-option value="三、航空航天" label="三、航空航天"/>
                        <el-option value="四、新材料" label="四、新材料"/>
                        <el-option value="五、高技术服务" label="五、高技术服务"/>
                        <el-option value="六、新能源与节能" label="六、新能源与节能"/>
                        <el-option value="七、资源与环境" label="七、资源与环境"/>
                        <el-option value="八、先进制造与自动化" label="八、先进制造与自动化"/>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="申报项目属于(单选)" required prop="projectBelong">
                  <template #label>
                    <el-tooltip placement="right">
                      <template #content>
                        如果您申报的项目属于以下三种情况中任一种，将被视为不满足申报要求。
                      </template>
                      <span>
                          <span>申报项目属于(单选)</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                    </el-tooltip>
                  </template>
                  <el-radio-group v-model="formData.projectBelong">
                    <el-radio :label="1">一般性的工艺改进或产品改型所形成的产品（服务）</el-radio><br>
                    <el-radio :label="2">已形成规模销售（提供）的成熟产品（服务）</el-radio><br>
                    <el-radio :label="3">食品、保健品、饮料、化妆品、服装、家具、家用电器、工艺品、办公用品等常规日用产品</el-radio><br>
                    <el-radio :label="4">不属于以上三种</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="近两年无知识产权侵权行为，未发生重大安全、重大质量事故或严重环境违法、科研失信行为，且未列入严重违法失信名单"
                    required prop="notViolations">
                  <el-radio-group v-model="formData.notViolations">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="申报转化项目的高新技术成果须为基于核心技术研发，首次实现转化并形成样品、样机或服务的项目"
                    required prop="isCoreRd">
                  <el-radio-group v-model="formData.isCoreRd">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="申报转化项目的核心技术属于《国家重点支持的高新技术领域》规定的范围" required
                              prop="isFocusDomain">
                  <el-radio-group v-model="formData.isFocusDomain">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
  <!--              <el-conditionForm-item label="获得 1 类、2 类新药的药品注册证书，且在新药监测期的项目" required-->
  <!--                            prop="isNewDrugTesting">-->
  <!--                <el-radio-group v-model="formData.isNewDrugTesting">-->
  <!--                  <el-radio :label="1">是</el-radio>-->
  <!--                  <el-radio :label="0">否</el-radio>-->
  <!--                </el-radio-group>-->
  <!--              </el-conditionForm-item>-->
                <el-form-item required prop="hasIp">
                  <template #label>
                    <el-tooltip placement="right">
                      <template #content>知识产权的类型包括：近 3 年内获得授权的发明专利、登记的集成电路布图设计专有权；
                        <br/>近 2 年内获得授权的实用新型专利、登记的软件著作权。
                        <br>项目的知识产权应当是通过自主研发、受让等方式，获得在技术上发挥核心支持作用的知识产权所有权。
                        <br><br>获得1类、2类新药的药品注册证书，且在新药监测期的项目；或近一年内首次取得国内三类医疗器械注册证的项目，其核心技术获得发明专利授权的，可不受上述专利年限限制。
                      </template>
                      <span>
                          <span>项目的核心技术拥有受中国法律保护的知识产权</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                    </el-tooltip>
                  </template>
                  <el-radio-group v-model="formData.hasIp">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
  <!--              <el-conditionForm-item label="近一年内首次取得国内三类医疗器械注册证的项目，其核心技术获得发明专利授权" required-->
  <!--                            prop="hasCoreIp">-->
  <!--                <el-radio-group v-model="formData.hasCoreIp">-->
  <!--                  <el-radio :label="1">是</el-radio>-->
  <!--                  <el-radio :label="0">否</el-radio>-->
  <!--                </el-radio-group>-->
  <!--              </el-conditionForm-item>-->
                <el-form-item required prop="hasThirdReport">
                  <template #label>
                    <el-tooltip placement="right">
                      <template #content>属医药、医疗器械、农药、计量器具、压力容器、邮电通信等有特殊行业管理要求的，须具有相关行业主管部门批准颁发的产品生产许可。
                        <br>属国家实施强制性产品认证的产品，必须通过强制性产品认证。
                      </template>
                      <span>
                          <span>项目所形成的产品应有第三方出具的产品质量性能检测报告</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                    </el-tooltip>
                  </template>
                  <el-radio-group v-model="formData.hasThirdReport">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="项目所形成的产品（服务）属于工商营业执照、事业单位法人证书的经营范围内" required
                              prop="isWithinBusiness">
                  <el-radio-group v-model="formData.isWithinBusiness">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="项目的总体技术与其它同类产品（服务）相比具有良好的创新性和先进性，并具有潜在的经济效益和较好的市场前景"
                    required prop="isAdvanced">
                  <el-radio-group v-model="formData.isAdvanced">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-collapse-item>

            <el-collapse-item :ref="(ref) => {techChildCollapse['7'] = ref}" name="7" v-if="isShPatentPolitEnterprise">
              <template v-slot:title>
                <h2><img alt="" src="../../assets/project.png"><span>上海市企事业专利工作（试点）单位认定 【企业类】</span>
                </h2>
              </template>
              <div class="form-module">
<!--                <el-row :gutter="0">-->
<!--                  <el-col :span="24">-->
<!--                    <div class="tip custom-block">-->
<!--                      <p class="pdf-view" @click="handleClickPdf('上海市企事业专利工作试点示范单位认定和管理办法.pdf')">查看：<span style="color:#409EFF">上海市企事业专利工作试点示范单位认定和管理办法.PDF</span></p>-->
<!--                      <p class="pdf-view" @click="handleClickPdf('专利工作试点示范单位认定指南.pdf')">查看：<span style="color:#409EFF">上海市知识产权局关于申报2023年上海市企事业专利工作试点示范单位的通知.PDF</span></p>-->
<!--                    </div>-->
<!--                  </el-col>-->
<!--                </el-row>-->
                <el-form-item label="企业开展专利工作具有前瞻性和全局性，把专利工作融入企业总体发展战略" required
                              prop="politEnterprise.hasPatentStrategy">
                  <el-radio-group v-model="formData.politEnterprise.hasPatentStrategy">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="上海市公共信用信息服务平台查询无不良诚信记录" required
                              prop="politEnterprise.notBadIntegrityRecord">
                  <el-radio-group v-model="formData.politEnterprise.notBadIntegrityRecord">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="主营业务涉及集成电路、生物医药、人工智能、新能源汽车、高端装备、航空航天、信息通信、新材料、新兴数字产业"
                    required prop="politEnterprise.hasMainBusiness">
                  <el-radio-group v-model="formData.politEnterprise.hasMainBusiness">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="工作方案立足自身，目标设置量化可考核（制定简要试点期工作方案，包含工作任务、绩效目标、资金安排、保障措施等内容）"
                    required prop="politEnterprise.hasWorkProgramme">
                  <el-radio-group v-model="formData.politEnterprise.hasWorkProgramme">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="完善知识产权管理制度（单选）" required prop="politEnterprise.perfectIpSystem">
                  <el-radio-group v-model="formData.politEnterprise.perfectIpSystem">
                    <el-radio :label="1">制定知识产权管理制度并实施</el-radio>
                    <el-radio :label="2">提交贯标认证申请</el-radio>
                    <el-radio :label="3">通过第三方知识产权贯标认证</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="健全知识产权管理机构和人员配备（每项单选）" required>
                  <div class="normal-show">
                    ①
                    <el-radio-group v-model="formData.politEnterprise.perfectIpInstitution1">
                      <el-radio :label="1">知识产权工作由法务、科研等部门合并管理</el-radio>
                      <el-radio :label="2">设立独立知识产权部门</el-radio>
                    </el-radio-group>
                    <br>
                    ②
                    <el-radio-group v-model="formData.politEnterprise.perfectIpInstitution2">
                      <el-radio :label="1">至少配备 1 名专职知识产权管理人员</el-radio>
                      <el-radio :label="2">配备 2 名及以上专职知识产权管理人员</el-radio>
                    </el-radio-group>
                    <br>
                    ③
                    <el-radio-group v-model="formData.politEnterprise.perfectIpInstitution3">
                      <el-radio :label="1">具有知识产权工作者（专利工作者）证书</el-radio>
                      <el-radio :label="2">具有知识产权相关的职称证书</el-radio>
                      <el-radio :label="3">具有专利代理师（人）资格</el-radio>
                    </el-radio-group>
                    <br>
                  </div>
                </el-form-item>
                <el-form-item label="注重知识产权经费保障（每项单选）" required>
                  <div class="normal-show">
                    ①
                    <el-radio-group v-model="formData.politEnterprise.ipGuarantee1">
                      <el-radio :label="1">重视知识产权经费投入</el-radio>
                      <el-radio :label="2">三年知识产权经费投入保持增长</el-radio>
                      <el-radio :label="3">近三年年均知识产权经费投入超过 30 万元</el-radio>
                    </el-radio-group>
                    <br>
                    ②
                    <el-radio-group v-model="formData.politEnterprise.ipGuarantee2">
                      <el-radio :label="1">近三年企业用于员工知识产权工作业绩奖励的经费年均≥5 万元</el-radio>
                      <el-radio :label="2">近三年企业用于员工知识产权工作业绩奖励的经费年均≥10万元</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
                <el-form-item label="开展知识产权文化宣传和业务培训">
                  <div class="normal-show">
                    <el-checkbox-group v-model="formData.politEnterprise.ipPublicize"
                                       @change="handlePolitEnterpriseChangeIpPublicize">
                      <el-checkbox :label="1">自 2022 年迄今，企业高管参与市区两级知识产权管理部门组织的培训或相关活动不少于
                        3
                        次
                      </el-checkbox>
                      <br>
                      <el-checkbox :label="2">上年度知识产权培训不少于 2 次</el-checkbox>
                      <br>
                      <el-checkbox :label="3">积极参加市知识产权局组织的 4.26宣传周、专利调查、知识产权金融入园惠企、有关培训等重大活动,共
                        <input-number size="small" v-model="formData.politEnterprise.ipPublicizeCount"
                                      @update:modelValue="formData.politEnterprise.ipPublicizeCount = $event"
                                      :is-decimal="false"/>
                        项
                      </el-checkbox>
                    </el-checkbox-group>

                  </div>
                </el-form-item>
                <el-form-item label="通过 PCT 条约、海牙协定、马德里协定等途径进行海外知识产权布局" required
                              prop="politEnterprise.pctCount">
                  <div class="normal-show">
                    自 2022 年迄今，申请 PCT 专利、海牙协定外观设计或马德里商标，共
                    <input-number size="small" v-model="formData.politEnterprise.pctCount"
                                  @update:modelValue="formData.politEnterprise.pctCount = $event"
                                  :is-decimal="false"/>
                    件
                  </div>
                </el-form-item>
                <el-form-item label="注重高价值专利培育">
                  <div class="normal-show">
                    ①拥有有效发明专利
                    <input-number size="small" v-model="formData.politEnterprise.highValueCultivate1"
                                  @update:modelValue="formData.politEnterprise.highValueCultivate1 = $event"
                                  :is-decimal="false"/>
                    件<br>
                    ②拥有维持年限 10 年以上或获得过中国专利奖、上海知识产权创新奖、国家及市级科技奖等的高价值专利
                    <input-number size="small" v-model="formData.politEnterprise.highValueCultivate2"
                                  @update:modelValue="formData.politEnterprise.highValueCultivate2 = $event"
                                  :is-decimal="false"/>
                    件<br>
                    ③建立并有效实施专利申请前评估制度
                    <el-radio-group v-model="formData.politEnterprise.highValueCultivate3">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                    <br>
                    ④承担市级高价值专利升级培育项目
                    <el-radio-group v-model="formData.politEnterprise.highValueCultivate4">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>

                <el-form-item label="开展专利许可转让工作，并在国家知识产权局完成登记备案手续（可多选）">
                  <div class="normal-show">
                    <el-checkbox v-model="formData.politEnterprise.ipTransference1" :true-label="1" :false-label="0"
                                 @click="handleChangePolitEnterpriseIpTransference">自 2022
                      年迄今，开展专利许可转让工作，并在国家知识产权局完成登记备案手续
                      。从本市高校、科研机构受让专利
                      <input-number size="small" v-model="formData.politEnterprise.ipTransference1Count"
                                    @update:modelValue="formData.politEnterprise.ipTransference1Count = $event"
                                    :is-decimal="false"/>
                      次
                    </el-checkbox>
                    <br>
                    <el-checkbox v-model="formData.politEnterprise.ipTransference2" :true-label="1" :false-label="0">自
                      2022 年迄今，专利许可转让费实际支付（到账）额≥2万
                    </el-checkbox>
                    <br>
                    <el-checkbox-group v-model="formData.politEnterprise.ipTransference3">
                      <el-checkbox :label="1">进行专利开放许可声明</el-checkbox>
                      <el-checkbox :label="2">有通过专利开放许可成交案例</el-checkbox>
                    </el-checkbox-group>
                    <br>
                    <el-checkbox v-model="formData.politEnterprise.ipTransference4" :true-label="1" :false-label="0">
                      取得《国家专利密集型产品备案认定试点平台备案证明》
                    </el-checkbox>
                  </div>
                </el-form-item>
                <el-form-item label="开展专利商标质押融资、保险工作">
                  <div class="normal-show">
                    ①自 2022 年迄今，获得专利商标质押融资贷款并在国家知识产权局完成登记的，合同金额
                    <el-radio-group v-model="formData.politEnterprise.ipPledge1">
                      <el-radio :label="1"> 300 万元及以下</el-radio>
                      <el-radio :label="2">300 万元-500 万元</el-radio>
                      <el-radio :label="3">500 万元及以上</el-radio>
                    </el-radio-group>
                    <br>
                    ②自 2022 年迄今，
                    <el-checkbox-group v-model="formData.politEnterprise.ipPledge2">
                      <el-checkbox :label="1">开展专利保险</el-checkbox>
                      <el-checkbox :label="2">开展商标保险</el-checkbox>
                      <el-checkbox :label="3">投保的专利和商标件数合计达到 10 件（含 10 件）及以上</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-form-item>
                <el-form-item label="开展知识产权信息利用项目（可多选）" prop="politEnterprise.ipInfoUtilize"
                              style="text-align: left">
                  <el-checkbox-group v-model="formData.politEnterprise.ipInfoUtilize">
                    <el-checkbox :label="1">自 2022
                      年迄今，自主开展知识产权信息利用，形成专利导航、专利预警分析、知识产权分析评议、知识产权风险评估报告
                    </el-checkbox>
                    <br>
                    <el-checkbox :label="2">自 2022 年迄今，承担区级以上相关部门知识产权信息利用项目</el-checkbox>
                    <br>
                    <el-checkbox :label="3">在知识产权信息分析利用项目中建立研发人员、专利分析人员沟通协调机制
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="获得相关资质或奖励（可多选）" prop="politEnterprise.credentialsRewards"
                              style="text-align: left">
                  <el-checkbox-group v-model="formData.politEnterprise.credentialsRewards">
                    <el-checkbox :label="1">近 3 年内，企业有产品列入上海市创新产品推荐目录</el-checkbox>
                    <br>
                    <el-checkbox :label="2">被列入工信部、上海市“专精特新”企业</el-checkbox>
                    <br>
                    <el-checkbox :label="3">被列入上海市商务委认定或备案的外资研发中心</el-checkbox>
                    <br>
                    <el-checkbox :label="4">具有无效宣告请求维持、专利诉讼胜诉等专利维权典型案例</el-checkbox>
                    <br>
                    <el-checkbox :label="5">列入上海市重点商标保护名录</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </el-collapse-item>

            <el-collapse-item :ref="(ref) => {techChildCollapse['8'] = ref}" name="8" v-if="isShPatentPolitPublic">
              <template v-slot:title>
                <h2><img alt="" src="../../assets/project.png"><span>上海市企事业专利工作（试点）单位认定 【事业类】</span>
                </h2>
              </template>
              <div class="form-module">
<!--                <el-row :gutter="0">-->
<!--                  <el-col :span="24">-->
<!--                    <div class="tip custom-block">-->
<!--                      <p class="pdf-view" @click="handleClickPdf('上海市企事业专利工作试点示范单位认定和管理办法.pdf')">查看：<span style="color:#409EFF">上海市企事业专利工作试点示范单位认定和管理办法.PDF</span></p>-->
<!--                      <p class="pdf-view" @click="handleClickPdf('专利工作试点示范单位认定指南.pdf')">查看：<span style="color:#409EFF">上海市知识产权局关于申报2023年上海市企事业专利工作试点示范单位的通知.PDF</span></p>-->
<!--                    </div>-->
<!--                  </el-col>-->
<!--                </el-row>-->
                <el-form-item label="与企业建立以专利运用为导向的产学研合作机制（可多选）" required
                              prop="politPublic.iurInfoChoice">
                  <el-checkbox-group v-model="formData.politPublic.iurInfoChoice">
                    <el-checkbox :label="1">与企业建立产学研合作机制</el-checkbox>
                    <el-checkbox :label="2">开展知识产权产学研定制需求开发及应用并取得成效</el-checkbox>
                    <el-checkbox :label="3">被市知识产权局认定为上海市知识产权产学研运营联合体</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="单位开展专利工作具有前瞻性和全局性，把专利工作融入单位总体发展战略" required
                              prop="politPublic.hasPatentStrategy">
                  <el-radio-group v-model="formData.politPublic.hasPatentStrategy">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="主营业务涉及集成电路、生物医药、人工智能、新能源汽车、高端装备、航空航天、信息通信、新材料、新兴数字产业"
                    required prop="politPublic.hasMainBusiness">
                  <el-radio-group v-model="formData.politPublic.hasMainBusiness">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="工作方案立足自身，目标设置量化可考核（制定简要试点期工作方案，包含工作任务、绩效目标、资金安排、保障措施等内容）"
                    required prop="politPublic.hasWorkProgramme">
                  <el-radio-group v-model="formData.politPublic.hasWorkProgramme">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="完善知识产权管理制度（单选）" required prop="politPublic.perfectIpSystem">
                  <el-radio-group v-model="formData.politPublic.perfectIpSystem">
                    <el-radio :label="1">制定知识产权管理制度并实施</el-radio>
                    <el-radio :label="2">提交贯标认证申请</el-radio>
                    <el-radio :label="3">通过第三方知识产权贯标认证</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="健全知识产权管理机构和人员配备（每项单选）" required>
                  <div class="normal-show">
                    ①
                    <el-radio-group v-model="formData.politPublic.perfectIpInstitution1">
                      <el-radio :label="1">知识产权工作由法务、科研等部门合并管理</el-radio>
                      <el-radio :label="2">设立独立知识产权部门</el-radio>
                    </el-radio-group>
                    <br>
                    ②
                    <el-radio-group v-model="formData.politPublic.perfectIpInstitution2">
                      <el-radio :label="1">至少配备 1 名专职知识产权管理人员</el-radio>
                      <el-radio :label="2">配备 2 名及以上专职知识产权管理人员</el-radio>
                    </el-radio-group>
                    <br>
                    ③
                    <el-radio-group v-model="formData.politPublic.perfectIpInstitution3">
                      <el-radio :label="1">具有知识产权工作者（专利工作者）证书</el-radio>
                      <el-radio :label="2">具有知识产权相关的职称证书</el-radio>
                      <el-radio :label="3">具有专利代理师（人）资格</el-radio>
                    </el-radio-group>
                    <br>
                  </div>
                </el-form-item>
                <el-form-item label="注重知识产权经费保障（每项单选）" required>
                  <div class="normal-show">
                    ①
                    <el-radio-group v-model="formData.politPublic.ipGuarantee1">
                      <el-radio :label="1">重视知识产权经费投入</el-radio>
                      <el-radio :label="2">三年知识产权经费投入保持增长</el-radio>
                      <el-radio :label="3">近三年年均知识产权经费投入超过 30 万元</el-radio>
                    </el-radio-group>
                    <br>
                    ②
                    <el-radio-group v-model="formData.politPublic.ipGuarantee2">
                      <el-radio :label="1">近三年企业用于员工知识产权工作业绩奖励的经费年均≥5 万元</el-radio>
                      <el-radio :label="2">近三年企业用于员工知识产权工作业绩奖励的经费年均≥10万元</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
                <el-form-item label="开展知识产权文化宣传和业务培训">
                  <div class="normal-show">
                    <el-checkbox-group v-model="formData.politPublic.ipPublicize"
                                       @change="handlePolitPublicChangeIpPublicize">
                      <el-checkbox :label="1">自 2022
                        年迄今，单位领导参与市区两级知识产权管理部门组织的培训或相关活动不少于3次
                      </el-checkbox>
                      <br>
                      <el-checkbox :label="2">上年度知识产权培训不少于 2 次</el-checkbox>
                      <br>
                      <el-checkbox :label="3">积极参加市知识产权局组织的 4.26宣传周、专利调查、有关培训调研等重大活动,共
                        <input-number size="small" v-model="formData.politPublic.ipPublicizeCount"
                                      @update:modelValue="formData.politPublic.ipPublicizeCount = $event"
                                      :is-decimal="false"/>
                        项
                      </el-checkbox>
                    </el-checkbox-group>

                  </div>
                </el-form-item>
                <el-form-item label="通过 PCT 条约、海牙协定、马德里协定等途径进行海外知识产权布局" required
                              prop="politPublic.pctCount">
                  <div class="normal-show">
                    自 2022 年迄今，申请 PCT 专利、海牙协定外观设计或马德里商标，共
                    <input-number size="small" v-model="formData.politPublic.pctCount"
                                  @update:modelValue="formData.politPublic.pctCount = $event"
                                  :is-decimal="false"/>
                    件
                  </div>
                </el-form-item>
                <el-form-item label="注重高价值专利培育">
                  <div class="normal-show">
                    ①拥有有效发明专利
                    <input-number size="small" v-model="formData.politPublic.highValueCultivate1"
                                  @update:modelValue="formData.politPublic.highValueCultivate1 = $event"
                                  :is-decimal="false"/>
                    件<br>
                    ②拥有维持年限 10 年以上或获得过中国专利奖、上海知识产权创新奖、国家及市级科技奖等的高价值专利
                    <el-input size="small" v-model="formData.politPublic.highValueCultivate2"/>
                    件<br>
                    ③建立并有效实施专利申请前评估制度
                    <el-radio-group v-model="formData.politPublic.highValueCultivate3">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                    <br>
                    ④承担市级高价值专利升级培育项目
                    <el-radio-group v-model="formData.politPublic.highValueCultivate4">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>

                <el-form-item label="开展专利许可转让工作，并在国家知识产权局完成登记备案手续（可多选）">
                  <div class="normal-show">
                    <el-checkbox v-model="formData.politPublic.ipTransference1" :true-label="1" :false-label="0"
                                 @click="handleChangePolitPublicIpTransference">自 2022 年迄今，开展专利许可转让工作，并在国家知识产权局完成登记备案手续。
                      从本市高校、科研机构受让专利
                      <input-number size="small" v-model="formData.politPublic.ipTransference1Count"
                                    @update:modelValue="formData.politPublic.ipTransference1Count = $event"
                                    :is-decimal="false"/>
                      次
                    </el-checkbox>

                    <el-checkbox v-model="formData.politPublic.ipTransference2" :true-label="1" :false-label="0">自 2022
                      年迄今，专利许可转让费实际支付（到账）额≥50万
                    </el-checkbox>
                    <br>
                    <el-checkbox-group v-model="formData.politPublic.ipTransference3">
                      <el-checkbox :label="1">进行专利开放许可声明</el-checkbox>
                      <el-checkbox :label="2">有通过专利开放许可成交案例</el-checkbox>
                    </el-checkbox-group>
                    <br>
                    <el-checkbox v-model="formData.politPublic.ipTransference4" :true-label="1" :false-label="0">
                      取得《国家专利密集型产品备案认定试点平台备案证明》
                    </el-checkbox>
                  </div>
                </el-form-item>
                <el-form-item label="开展专利商标保险或知识产权作价入股">
                  <div class="normal-show">
                    <el-radio-group v-model="formData.politPublic.ipPledge1">
                      <el-radio :label="1"
                                @click.prevent="1 === formData.politPublic.ipPledge1 ? formData.politPublic.ipPledge1 = 0 : formData.politPublic.ipPledge1 = 1">
                        自 2022 年迄今，开展专利商标保险
                      </el-radio>
                    </el-radio-group>
                    <span><input-number size="small" v-model="formData.politPublic.ipPledge1Count"
                                        @update:modelValue="formData.politPublic.ipPledge1Count = $event"
                                        :is-decimal="false"/>单</span>
                    <br>
                    <el-radio-group v-model="formData.politPublic.ipPledge2Single">
                      <el-radio :label="1"
                                @click.prevent="1 === formData.politPublic.ipPledge2Single ? formData.politPublic.ipPledge2Single = 0 : formData.politPublic.ipPledge2Single = 1">
                        自 2022 年迄今，开展知识产权作价入股
                      </el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
                <el-form-item label="开展知识产权信息利用项目（可多选）" prop="politPublic.ipInfoUtilize"
                              style="text-align: left">
                  <el-checkbox-group v-model="formData.politPublic.ipInfoUtilize">
                    <el-checkbox :label="1">自 2022
                      年迄今，自主开展知识产权信息利用，形成专利导航、专利预警分析、知识产权分析评议、知识产权风险评估报告
                    </el-checkbox>
                    <br>
                    <el-checkbox :label="2">自 2022 年迄今，承担区级以上相关部门知识产权信息利用项目</el-checkbox>
                    <br>
                    <el-checkbox :label="3">在知识产权信息分析利用项目中建立研发人员、专利分析人员沟通协调机制
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="获得相关资质或奖励（可多选）" prop="politPublic.credentialsRewards"
                              style="text-align: left">
                  <el-checkbox-group v-model="formData.politPublic.credentialsRewards">
                    <el-checkbox :label="1">近 3 年内，企业有产品列入上海市创新产品推荐目录</el-checkbox>
                    <br>
                    <el-checkbox :label="2">列入上海市重点商标保护名录</el-checkbox>
                    <br>
                    <el-checkbox :label="3">具有无效宣告请求维持、专利诉讼胜诉等专利维权典型案例</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </el-collapse-item>

            <el-collapse-item :ref="(ref) => {techChildCollapse['9'] = ref}" name="9" v-if="isShPatentDemoEnterprise">
              <template v-slot:title>
                <h2><img alt="" src="../../assets/project.png"><span>上海市企事业专利工作（示范）单位认定 【企业类】</span>
                </h2>
              </template>
              <div class="form-module">
<!--                <el-row :gutter="0">-->
<!--                  <el-col :span="24">-->
<!--                    <div class="tip custom-block">-->
<!--                      <p class="pdf-view" @click="handleClickPdf('上海市企事业专利工作试点示范单位认定和管理办法.pdf')">查看：<span style="color:#409EFF">上海市企事业专利工作试点示范单位认定和管理办法.PDF</span></p>-->
<!--                      <p class="pdf-view" @click="handleClickPdf('专利工作试点示范单位认定指南.pdf')">查看：<span style="color:#409EFF">上海市知识产权局关于申报2023年上海市企事业专利工作试点示范单位的通知.PDF</span></p>-->
<!--                    </div>-->
<!--                  </el-col>-->
<!--                </el-row>-->
                <el-form-item label="企业实施专利战略思路清晰，措施得力，典型案例具有代表性" required
                              prop="demoEnterprise.hasPatentStrategy">
                  <el-radio-group v-model="formData.demoEnterprise.hasPatentStrategy">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="主营业务涉及集成电路、生物医药、人工智能、新能源汽车、高端装备、航空航天、信息通信、新材料、新兴数字产业"
                    required prop="demoEnterprise.hasMainBusiness">
                  <el-radio-group v-model="formData.demoEnterprise.hasMainBusiness">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="上海市公共信用信息服务平台查询无不良诚信记录" required
                              prop="demoEnterprise.notBadIntegrityRecord">
                  <el-radio-group v-model="formData.demoEnterprise.notBadIntegrityRecord">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="工作方案立足自身，目标设置量化可考核（制定简要试点期工作方案，包含工作任务、绩效目标、资金安排、保障措施等内容）"
                    required prop="demoEnterprise.hasWorkProgramme">
                  <el-radio-group v-model="formData.demoEnterprise.hasWorkProgramme">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="完善知识产权管理制度（单选）" required prop="demoEnterprise.perfectIpSystem">
                  <el-radio-group v-model="formData.demoEnterprise.perfectIpSystem">
                    <el-radio :label="1">提交贯标认证申请</el-radio>
                    <el-radio :label="2">通过第三方知识产权贯标认证</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="健全知识产权管理机构和人员配备（每项单选）" required>
                  <div class="normal-show">
                    ①
                    <el-radio-group v-model="formData.demoEnterprise.perfectIpInstitution1">
                      <el-radio :label="1">知识产权工作由法务、科研等部门合并管理</el-radio>
                      <el-radio :label="2">设立独立知识产权部门</el-radio>
                    </el-radio-group>
                    <br>
                    ②
                    <el-radio-group v-model="formData.demoEnterprise.perfectIpInstitution2">
                      <el-radio :label="1">至少配备 1 名专职知识产权管理人员</el-radio>
                      <el-radio :label="2">配备 2 名及以上专职知识产权管理人员</el-radio>
                    </el-radio-group>
                    <br>
                    ③
                    <el-radio-group v-model="formData.demoEnterprise.perfectIpInstitution3">
                      <el-radio :label="1">具有知识产权工作者（专利工作者）证书</el-radio>
                      <el-radio :label="2">具有知识产权相关的职称证书</el-radio>
                      <el-radio :label="3">具有专利代理师（人）资格</el-radio>
                    </el-radio-group>
                    <br>
                  </div>
                </el-form-item>
                <el-form-item label="注重知识产权经费保障（每项单选）">
                  <div class="normal-show">
                    ①
                    <el-radio-group v-model="formData.demoEnterprise.ipGuarantee1">
                      <el-radio :label="1">重视知识产权经费投入</el-radio>
                      <el-radio :label="2">三年知识产权经费投入保持增长</el-radio>
                      <el-radio :label="3">近三年年均知识产权经费投入超过 50 万元</el-radio>
                    </el-radio-group>
                    <br>
                    ②
                    <el-radio-group v-model="formData.demoEnterprise.ipGuarantee2">
                      <el-radio :label="1">近三年企业用于员工知识产权工作业绩奖励的经费年均≥10万元</el-radio>
                      <el-radio :label="2">近三年企业用于员工知识产权工作业绩奖励的经费年均≥20万元</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
                <el-form-item label="开展知识产权文化宣传和业务培训">
                  <div class="normal-show">
                    <el-checkbox-group v-model="formData.demoEnterprise.ipPublicize"
                                       @change="handleDemoEnterpriseChangeIpPublicize">
                      <el-checkbox :label="1">自 2022
                        年迄今，企业高管参与市区两级知识产权管理部门组织的培训或相关活动不少于3次
                      </el-checkbox>
                      <br>
                      <el-checkbox :label="2">上年度知识产权培训不少于 2 次</el-checkbox>
                      <br>
                      <el-checkbox :label="3">积极参加市知识产权局组织的 4.26宣传周、专利调查、知识产权金融入园惠企、有关培训等重大活动,共
                        <input-number size="small" v-model="formData.demoEnterprise.ipPublicizeCount"
                                      @update:modelValue="formData.demoEnterprise.ipPublicizeCount = $event"
                                      :is-decimal="false"/>
                        项
                      </el-checkbox>
                    </el-checkbox-group>

                  </div>
                </el-form-item>
                <el-form-item label="通过 PCT 条约、海牙协定、马德里协定等途径进行海外知识产权布局">
                  <div class="normal-show">
                    自 2022 年迄今，申请 PCT 专利、海牙协定外观设计或马德里商标，共
                    <input-number size="small" v-model="formData.demoEnterprise.pctCount"
                                  @update:modelValue="formData.demoEnterprise.pctCount = $event"
                                  :is-decimal="false"/>
                    件
                  </div>
                </el-form-item>
                <el-form-item label="注重高价值专利培育">
                  <div class="normal-show">
                    ①拥有有效发明专利
                    <input-number size="small" v-model="formData.demoEnterprise.highValueCultivate1"
                                  @update:modelValue="formData.demoEnterprise.highValueCultivate1 = $event"
                                  :is-decimal="false"/>
                    件<br>
                    ②拥有维持年限 10 年以上或获得过中国专利奖、上海知识产权创新奖、国家及市级科技奖等的高价值专利
                    <input-number size="small" v-model="formData.demoEnterprise.highValueCultivate2"
                                  @update:modelValue="formData.demoEnterprise.highValueCultivate2 = $event"
                                  :is-decimal="false"/>
                    件<br>
                    ③建立并有效实施专利申请前评估制度
                    <el-radio-group v-model="formData.demoEnterprise.highValueCultivate3">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                    <br>
                    ④承担市级高价值专利升级培育项目
                    <el-radio-group v-model="formData.demoEnterprise.highValueCultivate4">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>

                <el-form-item label="开展专利许可转让工作，并在国家知识产权局完成登记备案手续（可多选）">
                  <div class="normal-show">
                    <el-checkbox v-model="formData.demoEnterprise.ipTransference1" :true-label="1" :false-label="0"
                                 @click="handleChangeDemoEnterpriseIpTransference">自 2022
                      年迄今，开展专利许可转让工作，并在国家知识产权局完成登记备案手续。从本市高校、科研机构受让专利
                      <input-number size="small" v-model="formData.demoEnterprise.ipTransference1Count"
                                    @update:modelValue="formData.demoEnterprise.ipTransference1Count = $event"
                                    :is-decimal="false"/>
                      次
                    </el-checkbox>

                    <el-checkbox v-model="formData.demoEnterprise.ipTransference2" :true-label="1" :false-label="0">自
                      2022 年迄今，专利许可转让费实际支付（到账）额≥5万
                    </el-checkbox>
                    <br>
                    <el-checkbox-group v-model="formData.demoEnterprise.ipTransference3">
                      <el-checkbox :label="1">进行专利开放许可声明</el-checkbox>
                      <el-checkbox :label="2">有通过专利开放许可成交案例</el-checkbox>
                    </el-checkbox-group>
                    <br>
                    <el-checkbox v-model="formData.demoEnterprise.ipTransference4" :true-label="1" :false-label="0">
                      取得《国家专利密集型产品备案认定试点平台备案证明》
                    </el-checkbox>
                  </div>
                </el-form-item>
                <el-form-item label="开展专利商标质押融资、保险工作">
                  <div class="normal-show">
                    ①自 2022 年迄今，获得专利商标质押融资贷款并在国家知识产权局完成登记的，合同金额
                    <el-radio-group v-model="formData.demoEnterprise.ipPledge1">
                      <el-radio :label="1">300 万元及以下</el-radio>
                      <el-radio :label="2">300 万元-500 万元</el-radio>
                      <el-radio :label="3">500 万元及以上</el-radio>
                    </el-radio-group>
                    <br>
                    ②自 2022 年迄今，
                    <el-checkbox-group v-model="formData.demoEnterprise.ipPledge2">
                      <el-checkbox :label="1">开展专利保险</el-checkbox>
                      <el-checkbox :label="2">开展商标保险</el-checkbox>
                      <el-checkbox :label="3">投保的专利和商标件数合计达到 10 件（含 10 件）及以上</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-form-item>
                <el-form-item label="开展知识产权信息利用项目（可多选）" prop="ipInfoUtilize"
                              style="text-align: left">
                  <el-checkbox-group v-model="formData.demoEnterprise.ipInfoUtilize">
                    <el-checkbox :label="1">自 2022
                      年迄今，自主开展知识产权信息利用，形成专利导航、专利预警分析、知识产权分析评议、知识产权风险评估报告
                    </el-checkbox>
                    <br>
                    <el-checkbox :label="2">自 2022 年迄今，承担区级以上相关部门知识产权信息利用项目</el-checkbox>
                    <br>
                    <el-checkbox :label="3">在知识产权信息分析利用项目中建立研发人员、专利分析人员沟通协调机制
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="获得相关资质或奖励（可多选）" prop="credentialsRewards"
                              style="text-align: left">
                  <el-checkbox-group v-model="formData.demoEnterprise.credentialsRewards">
                    <el-checkbox :label="1">近 3 年内，企业有产品列入上海市创新产品推荐目录</el-checkbox>
                    <br>
                    <el-checkbox :label="2">被列入工信部、上海市“专精特新”企业</el-checkbox>
                    <br>
                    <el-checkbox :label="3">被列入上海市商务委认定或备案的外资研发中心</el-checkbox>
                    <br>
                    <el-checkbox :label="4">具有无效宣告请求维持、专利诉讼胜诉等专利维权典型案例</el-checkbox>
                    <br>
                    <el-checkbox :label="5">列入上海市重点商标保护名录</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </el-collapse-item>

            <el-collapse-item :ref="(ref) => {techChildCollapse['10'] = ref}" name="10" v-if="isShPatentDemoPublic">
              <template v-slot:title>
                <h2><img alt="" src="../../assets/project.png"><span>上海市企事业专利工作（示范）单位认定 【事业类】</span>
                </h2>
              </template>
              <div class="form-module">
<!--                <el-row :gutter="0">-->
<!--                  <el-col :span="24">-->
<!--                    <div class="tip custom-block">-->
<!--                      <p class="pdf-view" @click="handleClickPdf('上海市企事业专利工作试点示范单位认定和管理办法.pdf')">查看：<span style="color:#409EFF">上海市企事业专利工作试点示范单位认定和管理办法.PDF</span></p>-->
<!--                      <p class="pdf-view" @click="handleClickPdf('专利工作试点示范单位认定指南.pdf')">查看：<span style="color:#409EFF">上海市知识产权局关于申报2023年上海市企事业专利工作试点示范单位的通知.PDF</span></p>-->
<!--                    </div>-->
<!--                  </el-col>-->
<!--                </el-row>-->
                <el-form-item label="与企业建立以专利运用为导向的产学研合作机制（可多选）" required
                              prop="demoPublic.iurInfoChoice">
                  <el-checkbox-group v-model="formData.demoPublic.iurInfoChoice">
                    <el-checkbox :label="1">与企业建立以专利运用为导向的产学研合作机制</el-checkbox>
                    <el-checkbox :label="2">开展知识产权产学研定制需求开发及应用并取得成效</el-checkbox>
                    <el-checkbox :label="3">被市知识产权局认定为上海市知识产权产学研运营联合体</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="单位专利战略思路清晰，措施得力，典型案例具有代表性" required
                              prop="demoPublic.hasPatentStrategy">
                  <el-radio-group v-model="formData.demoPublic.hasPatentStrategy">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="主营业务涉及集成电路、生物医药、人工智能、新能源汽车、高端装备、航空航天、信息通信、新材料、新兴数字产业"
                    required prop="demoPublic.hasMainBusiness">
                  <el-radio-group v-model="formData.demoPublic.hasMainBusiness">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="工作方案立足自身，目标设置量化可考核（制定简要试点期工作方案，包含工作任务、绩效目标、资金安排、保障措施等内容）"
                    required prop="demoPublic.hasWorkProgramme">
                  <el-radio-group v-model="formData.demoPublic.hasWorkProgramme">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="完善知识产权管理制度（单选）" required prop="demoPublic.perfectIpSystem">
                  <el-radio-group v-model="formData.demoPublic.perfectIpSystem">
                    <el-radio :label="1">提交贯标认证申请</el-radio>
                    <el-radio :label="2">通过第三方知识产权贯标认证</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="健全知识产权管理机构和人员配备（每项单选）" required>
                  <div class="normal-show">
                    ①
                    <el-radio-group v-model="formData.demoPublic.perfectIpInstitution1">
                      <el-radio :label="1">知识产权工作由法务、科研等部门合并管理</el-radio>
                      <el-radio :label="2">设立独立知识产权部门</el-radio>
                    </el-radio-group>
                    <br>
                    ②
                    <el-radio-group v-model="formData.demoPublic.perfectIpInstitution2">
                      <el-radio :label="1">至少配备 1 名专职知识产权管理人员</el-radio>
                      <el-radio :label="2">配备 2 名及以上专职知识产权管理人员</el-radio>
                    </el-radio-group>
                    <br>
                    ③
                    <el-radio-group v-model="formData.demoPublic.perfectIpInstitution3">
                      <el-radio :label="1">具有知识产权工作者（专利工作者）证书</el-radio>
                      <el-radio :label="2">具有知识产权相关的职称证书</el-radio>
                      <el-radio :label="3">具有专利代理师（人）资格</el-radio>
                    </el-radio-group>
                    <br>
                  </div>
                </el-form-item>
                <el-form-item label="注重知识产权经费保障（每项单选）" required>
                  <div class="normal-show">
                    ①
                    <el-radio-group v-model="formData.demoPublic.ipGuarantee1">
                      <el-radio :label="1">重视知识产权经费投入</el-radio>
                      <el-radio :label="2">三年知识产权经费投入保持增长</el-radio>
                      <el-radio :label="3">近三年年均知识产权经费投入超过 50 万元</el-radio>
                    </el-radio-group>
                    <br>
                    ②
                    <el-radio-group v-model="formData.demoPublic.ipGuarantee2">
                      <el-radio :label="1">近三年企业用于员工知识产权工作业绩奖励的经费年均≥10万元</el-radio>
                      <el-radio :label="2">近三年企业用于员工知识产权工作业绩奖励的经费年均≥20万元</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
                <el-form-item label="开展知识产权文化宣传和业务培训">
                  <div class="normal-show">
                    <el-checkbox-group v-model="formData.demoPublic.ipPublicize"
                                       @change="handleDemoPublicChangeIpPublicize">
                      <el-checkbox :label="1">自 2022
                        年迄今，单位领导参与市区两级知识产权管理部门组织的培训或相关活动不少于3次
                      </el-checkbox>
                      <br>
                      <el-checkbox :label="2">上年度知识产权培训不少于 2 次</el-checkbox>
                      <br>
                      <el-checkbox :label="3">积极参加市知识产权局组织的 4.26宣传周、专利调查、有关培训调研等重大活动,共
                        <input-number size="small" v-model="formData.demoPublic.ipPublicizeCount"
                                      @update:modelValue="formData.demoPublic.ipPublicizeCount = $event"
                                      :is-decimal="false"/>
                        项
                      </el-checkbox>
                    </el-checkbox-group>

                  </div>
                </el-form-item>
                <el-form-item label="通过 PCT 条约、海牙协定、马德里协定等途径进行海外知识产权布局" required
                              prop="demoPublic.pctCount">
                  <div class="normal-show">
                    自 2022 年迄今，申请 PCT 专利、海牙协定外观设计或马德里商标，共
                    <input-number size="small" v-model="formData.demoPublic.pctCount"
                                  @update:modelValue="formData.demoPublic.pctCount = $event"
                                  :is-decimal="false"/>
                    件
                  </div>
                </el-form-item>
                <el-form-item label="注重高价值专利培育">
                  <div class="normal-show">
                    ①拥有有效发明专利
                    <input-number size="small" v-model="formData.demoPublic.highValueCultivate1"
                                  @update:modelValue="formData.demoPublic.highValueCultivate1 = $event"
                                  :is-decimal="false"/>
                    件<br>
                    ②拥有维持年限 10 年以上或获得过中国专利奖、上海知识产权创新奖、国家及市级科技奖等的高价值专利
                    <input-number size="small" v-model="formData.demoPublic.highValueCultivate2"
                                  @update:modelValue="formData.demoPublic.highValueCultivate2 = $event"
                                  :is-decimal="false"/>
                    件<br>
                    ③建立并有效实施专利申请前评估制度
                    <el-radio-group v-model="formData.demoPublic.highValueCultivate3">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                    <br>
                    ④承担市级高价值专利升级培育项目
                    <el-radio-group v-model="formData.demoPublic.highValueCultivate4">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>

                <el-form-item label="开展专利许可转让工作，并在国家知识产权局完成登记备案手续（可多选）">
                  <div class="normal-show">
                    <el-checkbox v-model="formData.demoPublic.ipTransference1" :true-label="1" :false-label="0"
                                 @click="handleChangeDemoPublicIpTransference">
                      自 2022 年迄今，开展专利许可转让工作，并在国家知识产权局完成登记备案手续。
                      从本市高校、科研机构受让专利
                      <input-number size="small" v-model="formData.demoPublic.ipTransference1Count"
                                    @update:modelValue="formData.demoPublic.ipTransference1Count = $event"
                                    :is-decimal="false"/>
                      次
                    </el-checkbox>
                    <el-checkbox v-model="formData.demoPublic.ipTransference2" :true-label="1" :false-label="0">自 2022
                      年迄今，专利许可转让费实际支付（到账）额≥100
                      万
                    </el-checkbox>
                    <br>
                    <el-checkbox-group v-model="formData.demoPublic.ipTransference3">
                      <el-checkbox :label="1">进行专利开放许可声明</el-checkbox>
                      <el-checkbox :label="2">有通过专利开放许可成交案例</el-checkbox>
                    </el-checkbox-group>
                    <br>
                    <el-checkbox v-model="formData.demoPublic.ipTransference4" :true-label="1" :false-label="0">
                      取得《国家专利密集型产品备案认定试点平台备案证明》
                    </el-checkbox>
                  </div>
                </el-form-item>
                <el-form-item label="开展专利商标保险或知识产权作价入股" required>
                  <div class="normal-show">
                    <el-radio-group v-model="formData.demoPublic.ipPledge1">
                      <el-radio :label="1"
                                @click.prevent="1 === formData.demoPublic.ipPledge1 ? formData.demoPublic.ipPledge1 = 0 : formData.demoPublic.ipPledge1 = 1">
                        自 2022 年迄今，开展专利商标保险
                      </el-radio>
                    </el-radio-group>
                    <span><input-number size="small" v-model="formData.demoPublic.ipPledge1Count"
                                        @update:modelValue="formData.demoPublic.ipPledge1Count = $event"
                                        :is-decimal="false"/>单</span><br>
                    <el-radio-group v-model="formData.demoPublic.ipPledge2Single">
                      <el-radio :label="1"
                                @click.prevent="1 === formData.demoPublic.ipPledge2Single ? formData.demoPublic.ipPledge2Single = 0 : formData.demoPublic.ipPledge2Single = 1">
                        自 2022 年迄今，开展知识产权作价入股
                      </el-radio>
                    </el-radio-group>
                  </div>
                </el-form-item>
                <el-form-item label="开展知识产权信息利用项目（可多选）" prop="demoPublic.ipInfoUtilize"
                              style="text-align: left">
                  <el-checkbox-group v-model="formData.demoPublic.ipInfoUtilize">
                    <el-checkbox :label="1">自 2022
                      年迄今，自主开展知识产权信息利用，形成专利导航、专利预警分析、知识产权分析评议、知识产权风险评估报告
                    </el-checkbox>
                    <br>
                    <el-checkbox :label="2">自 2022 年迄今，承担区级以上相关部门知识产权信息利用项目</el-checkbox>
                    <br>
                    <el-checkbox :label="3">在知识产权信息分析利用项目中建立研发人员、专利分析人员沟通协调机制
                    </el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="获得相关资质或奖励（可多选）" prop="demoPublic.credentialsRewards"
                              style="text-align: left">
                  <el-checkbox-group v-model="formData.demoPublic.credentialsRewards">
                    <el-checkbox :label="1">近 3 年内，企业有产品列入上海市创新产品推荐目录</el-checkbox>
                    <br>
                    <el-checkbox :label="2">列入上海市重点商标保护名录</el-checkbox>
                    <br>
                    <el-checkbox :label="3">具有无效宣告请求维持、专利诉讼胜诉等专利维权典型案例</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div>
            <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
              <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(authenticationForm)"
                         :icon="Finished" :loading="loading">
                临时保存
              </el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
              <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(authenticationForm)"
                         :icon="CircleCheckFilled" :loading="loading">
                提交并认定
              </el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
              <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
                <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
              </router-link>
            </el-tooltip>
          </div>
        </el-form>
      </div>
      <BottomView/>
    </div>
  </div>
</template>

<script setup>
import {nextTick, onBeforeMount, onMounted, ref} from "vue";
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import Schema from 'async-validator'
import router from "@/router";
import {getApply, getGbtType, getGbtTypeByCode, getResultCount, save, submit} from "@/api/authentication";
import {CircleCheckFilled, Finished, Promotion, QuestionFilled} from "@element-plus/icons-vue";
import {regionData} from 'element-china-area-data'
import InputNumber from '@/components/InputNumber'
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import useUserStore from "@/store/modules/user";
import { Base64 } from 'js-base64'
import {useRoute} from "vue-router";

const techCollapse = ref(null)
const techChildCollapse = ref({})

const route = useRoute()

// 设置报错提示信息
Schema.messages.required = fieldName => {
  return '该项为必填项'
}

const options = ref(regionData)
const selectedOptions = ref([])
const isTechSmes = ref(false)
const isHighNewTech = ref(false)
const isInnovateSmes = ref(false)
const isSpecUltraNewSmes = ref(false)
const isShTechCenter = ref(false)
const isHighNewTechTransform = ref(false)
const isShPatentPolitEnterprise = ref(false)
const isShPatentPolitPublic = ref(false)
const isShPatentDemoEnterprise = ref(false)
const isShPatentDemoPublic = ref(false)

const handleChange = function () {
  let loc = "";
  for (let i = 0; i < selectedOptions.value.length; i++) {
    console.log(selectedOptions.value[i])
    loc += selectedOptions.value[i];
  }
  console.log(loc)//打印区域码所对应的属性值即中文地址
}

const loading = ref(false)

const authenticationForm = ref()
const collapseVal = ref(['1', '2', '3', '4', '5', '6', '7', '8','9','10'])
const formData = ref({

  industry: '',
  detailIndustry: '',

  employeeNum1: 0,
  researcherNum1: 0,
  revenue1: 0,
  mainRevenue1: 0,
  revenueGrowthRate1: 0,
  mainRevenueGrowthRate1: 0,
  mainRevenuePercent1: 0,
  totalCost1: 0,
  netProfit1: 0,
  netProfitRate1: 0,
  netProfitGrowthRate1: 0,
  totalAssets1: 0,
  totalLiability1: 0,
  assetLiabilityRatio1: 0,
  paidTaxes1: 0,
  equityCapital1: 0,
  valuation1: 0,
  salesRevenue1: 0,
  salesRevenueGrowth1: 0,
  netAssets1: 0,
  researchFee1: 0,
  researcherRate1: 0,
  netAssetsGrowth1: 0,
  researchFeeRate1: 0,

  employeeNum2: 0,
  researcherNum2: 0,
  revenue2: 0,
  mainRevenue2: 0,
  revenueGrowthRate2: 0,
  mainRevenueGrowthRate2: 0,
  mainRevenuePercent2: 0,
  totalCost2: 0,
  netProfit2: 0,
  netProfitRate2: 0,
  netProfitGrowthRate2: 0,
  totalAssets2: 0,
  totalLiability2: 0,
  assetLiabilityRatio2: 0,
  paidTaxes2: 0,
  equityCapital2: 0,
  valuation2: 0,
  salesRevenue2: 0,
  salesRevenueGrowth2: 0,
  netAssets2: 0,
  researchFee2: 0,
  researcherRate2: 0,
  netAssetsGrowth2: 0,
  researchFeeRate2: 0,

  employeeNum3: 0,
  researcherNum3: 0,
  revenue3: 0,
  mainRevenue3: 0,
  revenueGrowthRate3: 0,
  mainRevenueGrowthRate3: 0,
  mainRevenuePercent3: 0,
  totalCost3: 0,
  netProfit3: 0,
  netProfitRate3: 0,
  netProfitGrowthRate3: 0,
  totalAssets3: 0,
  totalLiability3: 0,
  assetLiabilityRatio3: 0,
  paidTaxes3: 0,
  equityCapital3: 0,
  valuation3: 0,
  salesRevenue3: 0,
  salesRevenueGrowth3: 0,
  netAssets3: 0,
  researchFee3: 0,
  researcherRate3: 0,
  netAssetsGrowth3: 0,
  researchFeeRate3: 0,


  isInstitutions: 0,
  enterpriseSize: 4,
  enterpriseType: 1,
  registerProvince: '浦东新区',
  intellectualProperty1Num: 0,
  ownIntellectualProperty1Num: 0,
  highValueIntellectualProperty1Num: 0,
  pctPropertyAcceptNum: 0,
  intellectualProperty1AcceptNum: 0,
  patentNum: 0,
  internationalPatentNum: 0,
  nationalDefencePatentNum: 0,
  newPlantVariety: 0,
  countryCropVariety: 0,
  countryNewMedicine: 0,
  countryChineseMedicine: 0,
  integratedCircuitDesign: 0,
  intellectualProperty2Num: 0,
  newModel: 0,
  design: 0,
  copyright: 0,

  choiceItem: [],

  notObsolete: 0,
  notIllegal: 0,
  hasQualification: 0,
  hasInstitution: 0,
  gainTop3Prize: 0,
  createStandardIn5Years: 0,
  createStandard: 0,

  technologyExtent: 3,
  centralRole: 3,
  rdManageLevel: [],
  properties: [],
  smeCompanyResearchDevActivities: [],

  productName: '',
  productYear: '3',
  productCategory: '0000',
  nationalLevelRewards: 0,
  nationalLevelRewardsYear: '',
  nationalLevelRewardsName: '',
  nationalLevelRewardsRank: '',
  provincialLevelRewards: 0,
  provincialLevelRewardsYear: '',
  provincialLevelRewardsName: '',
  provincialLevelRewardsRank: '',
  isHasHonor: 0,
  hasHonor: [],
  isHasInstitutions: 0,
  nationalCount: 0,
  provincialCount: 0,
  equityFinancing: 0,
  valuation: 0,
  isDomain: 0,
  domain: [],
  domainOther: '',


  isChinaMaker500: 0,
  chinaMaker500Year: '',
  chinaMaker500Rank: '',
  isNiubi: 0,
  niubiShort: '',
  niubiSpace: '',
  niubiAdvanced: '',
  niubiInfo: '',
  isDomanBasic6: 0,
  domanBasic6: [],
  isChinaBrand: 0,
  isDirectMatch: 0,
  directMatch1: '',
  directMatch2: '',
  directMatch3: '',
  digitalLevel: 1,
  qualityHonorTotal: 0,
  qualityHonorNational: 0,
  qualityHonorProvincial: 0,
  qualityHonorName: 0,
  qualityMangeCert: [1],
  qualityMangeOther: 0,
  ownBrandCount: 0,
  ownBrandName: 0,
  ownBrandProvCount: 0,
  ownBrandProvName: 0,
  standardTotal: 0,
  standardInternational: 0,
  standardNational: 0,
  standardIndustry: 0,
  standardName: 0,
  isIndustrialOrientation: 0,
  industrial36: [],
  industrialStrategy: [],
  industrialDistrictKey: '',
  industrialLocalKey: '',
  occupancy: 0,
  localRank: 0,
  nationwideRank: 0,
  internationalRank: 0,
  hasCharacteristic: 0,
  characteristicInfo: 0,
  isGreenDev: 0,
  titleAttestation: [],
  titleAttestationOther: '',
  hasDistrictTitle: 0,
  districtTitle: [],
  districtTitleOther: 0,
  hasRdInstitutions: 0,
  instNational: 0,
  instProvincial: 0,
  instCity: 0,
  instCityDown: 0,
  researchInstitute: [],
  technologyCenter: [],
  engineeringCenter: [],
  designCenter: [],
  innovationCenter: [],
  expertsWorkstation: 0,
  postdoctoralFellowWorkstation: 0,
  otherWorkstation: 0,

  isPlanIpo: 0,

  highLevelRdPersonnel: 0,
  externalExpertsWorkHours: 0,
  foreignCooperationProjects: 0,
  changjiangDelta: 0,
  basicApplication: 0,
  acquisitionCosts: 0,
  institutionsNumber: 0,
  jointInstitutionsNumber: 0,
  newsProdSalesRevenue: 0,
  newsProdProfit: 0,
  createStandardCount: 0,
  honorNationalCount: 0,
  honorProvincialCount: 0,
  ipNationalCount: 0,
  ipProvincialCount: 0,
  ipInternationalCount: 0,
  ipHighQualityCount: 0,
  overseasInstitutions: 0,
  unsubmitQuickReport: 0,

  projectName: '',
  techDomain: '',
  projectBelong: 4,
  notViolations: 0,
  isCoreRd: 0,
  isFocusDomain: 0,
  isNewDrugTesting: 0,
  hasIp: 0,
  hasCoreIp: 0,
  hasThirdReport: 0,
  isWithinBusiness: 0,
  isAdvanced: 0,

  politEnterprise: {
    notBadIntegrityRecord: 0,
    hasPatentStrategy: 0,
    hasMainBusiness: 0,
    hasWorkProgramme: 0,
    perfectIpSystem: 2,
    perfectIpInstitution1: undefined,
    perfectIpInstitution2: undefined,
    perfectIpInstitution3: undefined,
    ipGuarantee1: undefined,
    ipGuarantee2: undefined,
    ipPublicize: [],
    ipPublicizeCount: 0,
    pctCount: 0,
    highValueCultivate1: 0,
    highValueCultivate2: 0,
    highValueCultivate3: 0,
    highValueCultivate4: 0,
    ipTransference1: 0,
    ipTransference1Count: 0,
    ipTransference2: 0,
    ipTransference3: [],
    ipTransference4: 0,
    ipPledge1: 1,
    ipPledge1Count: 0,
    ipPledge2: [],
    ipPledge2Single: 0,
    ipInfoUtilize: [],
    credentialsRewards: [],
  },
  politPublic: {
    iurInfoChoice: [],
    notBadIntegrityRecord: 0,
    hasPatentStrategy: 0,
    hasMainBusiness: 0,
    hasWorkProgramme: 0,
    perfectIpSystem: 2,
    perfectIpInstitution1: undefined,
    perfectIpInstitution2: undefined,
    perfectIpInstitution3: undefined,
    ipGuarantee1: undefined,
    ipGuarantee2: undefined,
    ipPublicize: [],
    ipPublicizeCount: 0,
    pctCount: 0,
    highValueCultivate1: 0,
    highValueCultivate2: 0,
    highValueCultivate3: 0,
    highValueCultivate4: 0,
    ipTransference1: 0,
    ipTransference1Count: 0,
    ipTransference2: 0,
    ipTransference3: [],
    ipTransference4: 0,
    ipPledge1: 1,
    ipPledge1Count: 0,
    ipPledge2: [],
    ipPledge2Single: 0,
    ipInfoUtilize: [],
    credentialsRewards: [],
  },
  demoEnterprise: {
    notBadIntegrityRecord: 0,
    hasPatentStrategy: 0,
    hasMainBusiness: 0,
    hasWorkProgramme: 0,
    perfectIpSystem: 2,
    perfectIpInstitution1: undefined,
    perfectIpInstitution2: undefined,
    perfectIpInstitution3: undefined,
    ipGuarantee1: undefined,
    ipGuarantee2: undefined,
    ipPublicize: [],
    ipPublicizeCount: 0,
    pctCount: 0,
    highValueCultivate1: 0,
    highValueCultivate2: 0,
    highValueCultivate3: 0,
    highValueCultivate4: 0,
    ipTransference1: 0,
    ipTransference1Count: 0,
    ipTransference2: 0,
    ipTransference3: [],
    ipTransference4: 0,
    ipPledge1: 1,
    ipPledge1Count: 0,
    ipPledge2: [],
    ipPledge2Single: 0,
    ipInfoUtilize: [],
    credentialsRewards: [],
  },
  demoPublic: {
    iurInfoChoice: [],
    notBadIntegrityRecord: 0,
    hasPatentStrategy: 0,
    hasMainBusiness: 0,
    hasWorkProgramme: 0,
    perfectIpSystem: 2,
    perfectIpInstitution1: undefined,
    perfectIpInstitution2: undefined,
    perfectIpInstitution3: undefined,
    ipGuarantee1: undefined,
    ipGuarantee2: undefined,
    ipPublicize: [],
    ipPublicizeCount: 0,
    pctCount: 0,
    highValueCultivate1: 0,
    highValueCultivate2: 0,
    highValueCultivate3: 0,
    highValueCultivate4: 0,
    ipTransference1: 0,
    ipTransference1Count: 0,
    ipTransference2: 0,
    ipTransference3: [],
    ipTransference4: 0,
    ipPledge1: 1,
    ipPledge1Count: 0,
    ipPledge2: [],
    ipPledge2Single: 0,
    ipInfoUtilize: [],
    credentialsRewards: [],
  }

})

const intPropType = ref([{
  value: '1',
  label: '发明专利'
}, {
  value: '2',
  label: '植物新品种'
}, {
  value: '3',
  label: '国家级农作物品种'
}, {
  value: '4',
  label: '国家一级中药保护品种'
}, {
  value: '5',
  label: '集成电路布图设计专有权'
}, {
  value: '6',
  label: '实用新型'
}, {
  value: '7',
  label: '外观设计'
}, {
  value: '8',
  label: '软件著作权'
}])

const accessType = ref([{
  value: 1,
  label: '自主研发'
}, {
  value: 2,
  label: '受让'
}, {
  value: 3,
  label: '受赠'
}, {
  value: 4,
  label: '并购'
}, {
  value: 5,
  label: '5年以上的独占许可'
}])

const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  return save(formData.value).then((resp) => {
    if (resp.code == 200) {
      successMsg(resp.msg)
      getApplyFn()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch()
}

const submitForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true

  if (formData.value.researcherRate1 > 100 || formData.value.researcherRate2 > 100 || formData.value.researcherRate3 > 100) {
    errorMsg("错误提示：您输入的 '研发人员数量' 超出了 '全职员工数量'，请检查并更改。")
    return
  }
  if (formData.value.mainRevenuePercent1 > 100 || formData.value.mainRevenuePercent2 > 100 || formData.value.mainRevenuePercent3 > 100) {
    errorMsg("错误提示：您输入的 '主营业务收入（或销售收入）' 超出了 '营业收入'，请检查并更改。")
    return
  }

  formEl.validate((valid, fields) => {
    if (valid) {
      return submit(formData.value).then((resp) => {
        submitBtn.value = false
        loading.value = false
        if (resp.code === 200)
          router.push("/authentication/result")
      }).catch()
    } else {

      Object.keys(fields).forEach((key, i) => {
        const propName = fields[key][0].field
        if (i === 0) {
          formEl.scrollToField(propName)
        }
      })
      console.log('error', fields)
      errorMsg("您有必填项没有填写，请仔细检查。")
      submitBtn.value = false
      loading.value = false
      return false
    }
  })


}
const isBtn = ref(false)
const bigTypes = ref([])
const smallTypes = ref([])
const selectSmallTypes = (val) => {
  getGbtTypeByCode(val).then((resp) => {
    formData.value.detailIndustry = ''
    smallTypes.value = resp
  })
}
const isResult = ref(false)
onBeforeMount(() => {
  showItem()
  getGbtType().then((resp) => {
    bigTypes.value = resp
  })
  getApplyFn()
  getResultCount().then((resp) => {
    isResult.value = resp.data > 0
  })
})
const getApplyFn = function () {
  const politEnterprise = formData.value.politEnterprise
  const politPublic = formData.value.politPublic
  const demoEnterprise = formData.value.demoEnterprise
  const demoPublic = formData.value.demoPublic

  getApply().then((resp) => {
    if (resp.data) {
      if (resp.data.id) {
        const choiceItem = [...resp.data.choiceItem]
        const routeType = route.query.t
        if (routeType) {
          if(!choiceItem.includes(parseInt(routeType))) {
            choiceItem.push(parseInt(routeType))
          }
        }
        formData.value = {
          ...resp.data,
          choiceItem
        }
      }

      if (!formData.value.politEnterprise) formData.value.politEnterprise = politEnterprise
      if (!formData.value.politPublic) formData.value.politPublic = politPublic
      if (!formData.value.demoEnterprise) formData.value.demoEnterprise = demoEnterprise
      if (!formData.value.demoPublic) formData.value.demoPublic = demoPublic

      const userStore = useUserStore()
      if (userStore.id != null && resp.data.companyName == null) {
        formData.value.companyName = userStore.companyName
      }

      isBtn.value = !!resp.data.id
      showItem()
      handleResearcherRate()
      handleRevenue()
      handleAssets()
      formData.value.properties = formData.value.properties || []
      formData.value.smeCompanyResearchDevActivities = formData.value.smeCompanyResearchDevActivities || []
      formData.value.rdManageLevel = formData.value.rdManageLevel || []

      createProperties()
      createRows()

      nextTick(() => {
        techChildCollapse.value[route.query.t]?.$el.scrollIntoView({behavior: 'smooth'})
      })

    }
  }).then(() => {
    getGbtTypeByCode(formData.value.industry).then((resp) => {
      smallTypes.value = resp
    })

  }).catch(() => {
  })
}

const proResult = function (res) {
  return isNaN(res) || res === Infinity || res === -Infinity ? "0" : res.toFixed(2);
}

const handleResearcherRate = function () { //研发人员占全部职工比重
  formData.value.researcherRate1 = proResult(formData.value.researcherNum1 / formData.value.employeeNum1 * 100);
  formData.value.researcherRate2 = proResult(formData.value.researcherNum2 / formData.value.employeeNum2 * 100);
  formData.value.researcherRate3 = proResult(formData.value.researcherNum3 / formData.value.employeeNum3 * 100);
  inputValidation()
}

const handleRevenue = function () {
  //主营业务收入总额占营业收入总额比重
  formData.value.mainRevenuePercent1 = proResult(formData.value.mainRevenue1 / formData.value.revenue1 * 100);
  formData.value.mainRevenuePercent2 = proResult(formData.value.mainRevenue2 / formData.value.revenue2 * 100);
  formData.value.mainRevenuePercent3 = proResult(formData.value.mainRevenue3 / formData.value.revenue3 * 100);
  inputValidation()

  //营业收入增长率
  formData.value.revenueGrowthRate1 = 0
  formData.value.revenueGrowthRate2 = proResult((formData.value.revenue2 - formData.value.revenue1) / formData.value.revenue1 * 100);
  formData.value.revenueGrowthRate3 = proResult((formData.value.revenue3 - formData.value.revenue2) / formData.value.revenue2 * 100);
  //主营业务收入增长率
  formData.value.mainRevenueGrowthRate1 = 0
  formData.value.mainRevenueGrowthRate2 = proResult((formData.value.mainRevenue2 - formData.value.mainRevenue1) / formData.value.mainRevenue1 * 100);
  formData.value.mainRevenueGrowthRate3 = proResult((formData.value.mainRevenue3 - formData.value.mainRevenue2) / formData.value.mainRevenue2 * 100);
  //研发费用总额占营业收入总额比重
  formData.value.researchFeeRate1 = proResult(formData.value.researchFee1 / formData.value.revenue1 * 100);
  formData.value.researchFeeRate2 = proResult(formData.value.researchFee2 / formData.value.revenue2 * 100);
  formData.value.researchFeeRate3 = proResult(formData.value.researchFee3 / formData.value.revenue3 * 100);

  //净利润总额=营业收入-成本费用总额-上缴税金

  //净利润率 净利润/营业收入×100%
  formData.value.netProfitRate1 = proResult(formData.value.netProfit1 / formData.value.revenue1 * 100);
  formData.value.netProfitRate2 = proResult(formData.value.netProfit2 / formData.value.revenue2 * 100);
  formData.value.netProfitRate3 = proResult(formData.value.netProfit3 / formData.value.revenue3 * 100);
  //净利润增长率
  formData.value.netProfitGrowthRate1 = 0
  formData.value.netProfitGrowthRate2 = proResult((formData.value.netProfit2 - formData.value.netProfit1) / formData.value.netProfit1 * 100);
  formData.value.netProfitGrowthRate3 = proResult((formData.value.netProfit3 - formData.value.netProfit2) / formData.value.netProfit2 * 100);
}

const handleAssets = function () {
  //资产负债率= (负债总额÷资产总额)×100%
  formData.value.assetLiabilityRatio1 = proResult(formData.value.totalLiability1 / formData.value.totalAssets1 * 100);
  formData.value.assetLiabilityRatio2 = proResult(formData.value.totalLiability2 / formData.value.totalAssets2 * 100);
  formData.value.assetLiabilityRatio3 = proResult(formData.value.totalLiability3 / formData.value.totalAssets3 * 100);
  //净资产 = 资产总额 - 负债总额
  formData.value.netAssets1 = proResult(formData.value.totalAssets1 - formData.value.totalLiability1);
  formData.value.netAssets2 = proResult(formData.value.totalAssets2 - formData.value.totalLiability2);
  formData.value.netAssets3 = proResult(formData.value.totalAssets3 - formData.value.totalLiability3);
  //净资产增长率
  formData.value.netAssetsGrowth1 = 0
  formData.value.netAssetsGrowth2 = proResult((formData.value.netAssets2 - formData.value.netAssets1) / formData.value.netAssets1 * 100);
  formData.value.netAssetsGrowth3 = proResult((formData.value.netAssets3 - formData.value.netAssets2) / formData.value.netAssets2 * 100);
}

const isOptionDisabled = function (optionValue) {
  formData.value.choiceItem = formData.value.choiceItem || []
  return formData.value.choiceItem.includes(7) && optionValue == '8' || formData.value.choiceItem.includes(8) && optionValue == '7';
}

const inputValidation = () => {
  if (formData.value.researcherRate1 > 100 || formData.value.researcherRate2 > 100 || formData.value.researcherRate3 > 100) {
    errorMsg("错误提示：您输入的 '研发人员数量' 超出了 '全职员工数量'，请检查并更改。")
  }
  if (formData.value.mainRevenuePercent1 > 100 || formData.value.mainRevenuePercent2 > 100 || formData.value.mainRevenuePercent3 > 100) {
    errorMsg("错误提示：您输入的 '主营业务收入（或销售收入）' 超出了 '营业收入'，请检查并更改。")
  }
}

const handleChangeIP2Num = function () {
  formData.value.intellectualProperty2Num = parseInt(formData.value.newModel) + parseInt(formData.value.design) + parseInt(formData.value.copyright)
}

const handleChoiceItemChange = function () {
  showItem()
}

const handleClickScroll=function(i) {
  showItem()
  nextTick(() => {
    let displayType = i;
    switch (i) {
      case 7:
        displayType = formData.value.isInstitutions ? 8 : 7
        break;
      case 8:
        displayType = formData.value.isInstitutions ? 10 : 9
        break;
    }
    console.log('handleClickScroll', { choiceItem: formData.value.choiceItem, displayType})
    if (formData.value.choiceItem.includes(i)) {
      nextTick(() => {
        techChildCollapse.value[displayType]?.$el.scrollIntoView({behavior: 'smooth'})
      })
    }
  })
}

const showItem = function () {
  const value = formData.value.choiceItem;
  isTechSmes.value = value.findIndex((v) => (v == "1")) !== -1;
  isHighNewTech.value = value.findIndex((v) => (v == "2")) !== -1;
  isInnovateSmes.value = value.findIndex((v) => (v == "3")) !== -1;
  isSpecUltraNewSmes.value = value.findIndex((v) => (v == "4")) !== -1;
  isShTechCenter.value = value.findIndex((v) => (v == "5")) !== -1;
  isHighNewTechTransform.value = value.findIndex((v) => (v == "6")) !== -1;
  isShPatentPolitEnterprise.value = value.findIndex((v) => (v == "7")) !== -1 && formData.value.isInstitutions == 0;
  isShPatentPolitPublic.value = value.findIndex((v) => (v == "7")) !== -1 && formData.value.isInstitutions == 1;
  isShPatentDemoEnterprise.value = value.findIndex((v) => (v == "8")) !== -1 && formData.value.isInstitutions == 0;
  isShPatentDemoPublic.value = value.findIndex((v) => (v == "8")) !== -1 && formData.value.isInstitutions == 1;
}

const createProperties = () => {
  const propertiesLength = formData.value.properties.length
  const rows = propertiesLength >= 5 ? propertiesLength : 5
  for (let i = propertiesLength; i < rows; i++) {
    formData.value.properties.push({})
  }
}

const addPropertiesRows = () => {
  formData.value.properties.push({})
}

const delPropertiesRows = () => {
  const n = formData.value.properties.length
  if (n > 5) {
    formData.value.properties.splice(n - 1, 1);
  }
}

const createRows = () => {
  const length = formData.value.smeCompanyResearchDevActivities.length
  const rows = length >= 5 ? length : 5
  for (let i = length; i < rows; i++) {
    formData.value.smeCompanyResearchDevActivities.push({})
  }
}

const addRows = () => {
  formData.value.smeCompanyResearchDevActivities.push({})
}

const delRows = () => {
  const n = formData.value.smeCompanyResearchDevActivities.length
  if (n > 5) {
    formData.value.smeCompanyResearchDevActivities.splice(n - 1, 1);
  }
}

const handleChangeIsIndustrialOrientation = () => {
  formData.value.industrial36 = []
  formData.value.industrialStrategy = []
}
const handleChangeIsGreenDev = () => {
  formData.value.titleAttestation = []
}
const handleChangeHasDistrictTitle = () => {
  formData.value.districtTitle = []
}
const handleChangeHasRdInstitutions = () => {
  formData.value.instNational = 0
  formData.value.instProvincial = 0
  formData.value.instCity = 0
  formData.value.instCityDown = 0
  formData.value.researchInstitute = []
  formData.value.technologyCenter = []
  formData.value.engineeringCenter = []
  formData.value.designCenter = []
  formData.value.innovationCenter = []
  formData.value.expertsWorkstation = 0
  formData.value.postdoctoralFellowWorkstation = 0
  formData.value.otherWorkstation = 0
}
const handlePolitEnterpriseChangeIpPublicize = () => {
  if (!formData.value.politEnterprise.ipPublicize.includes(3))
    formData.value.politEnterprise.ipPublicizeCount = 0
}
const handlePolitPublicChangeIpPublicize = () => {
  if (!formData.value.politPublic.ipPublicize.includes(3))
    formData.value.politPublic.ipPublicizeCount = 0
}
const handleDemoEnterpriseChangeIpPublicize = () => {
  if (!formData.value.demoEnterprise.ipPublicize.includes(3))
    formData.value.demoEnterprise.ipPublicizeCount = 0
}
const handleDemoPublicChangeIpPublicize = () => {
  if (!formData.value.demoPublic.ipPublicize.includes(3))
    formData.value.demoPublic.ipPublicizeCount = 0
}
const handleChangePolitEnterpriseIpTransference = () => {
  if (formData.value.politEnterprise.ipTransference1 === 1)
    formData.value.politEnterprise.ipTransference1Count = 0
}
const handleChangePolitPublicIpTransference = () => {
  if (formData.value.politPublic.ipTransference1 === 1)
    formData.value.politPublic.ipTransference1Count = 0
}
const handleChangeDemoEnterpriseIpTransference = () => {
  if (formData.value.demoEnterprise.ipTransference1 === 1)
    formData.value.demoEnterprise.ipTransference1Count = 0
}
const handleChangeDemoPublicIpTransference = () => {
  if (formData.value.demoPublic.ipTransference1 === 1)
    formData.value.demoPublic.ipTransference1Count = 0
}
const handleClickPdf = (pdf) => {
  const encodePwd = Base64.encode(pdf);//加密
  const routeData = router.resolve({
    path: '/single-page-condition',
    query: {
      n: encodePwd
    }
  })
  window.open(routeData.href, '_blank');
}
</script>

<style lang="scss" scoped>
.scroll-container {
  height: calc(100vh - var(--header-container-height));
  overflow-y: auto;
}
.form-container {
  width: 1140px;
  margin: 35px auto;

  // 单位基本信息
  .basic-info {
    h2 {
      text-align: left;
      font-size: 16px;
      color: #101010;
    }

    :deep .el-form-item__label {
      color: #101010;
    }

    .enterpriseOptions :deep .el-select .el-input__wrapper {
      width: 225px;
    }

    :deep .el-input__wrapper {
      width: 225px;
    }

    :deep .el-radio {
      margin-right: 10px;
    }
  }

  // 近三年经营与财务状况
  .financial-info {
    margin-bottom: 25px;

    h2 {
      text-align: left;
      font-size: 16px;
      color: #101010;
    }

    .table-data {
      width: 99%;
      height: 100%;
      border: 1px solid #ebeef5;
      $odd-color: #F2F2F2;
      $even-color: #FFFFFF;

      .table-header {
        background-color: $odd-color;
      }

      .table-header, .table-row {
        height: 40px;
        line-height: 38px;
        //border-bottom: 1px solid $odd-color;

        :deep .el-input-group__append {
          width: 45px;
        }
      }

      .table-header {
        .table-cell {
          display: inline-block;
          text-align: center;
          color: #101010;
          font-weight: 600;
          font-size: 16px;
        }
      }

      .table-row {
        &:nth-child(odd) {
          background-color: $odd-color;
        }

        &:nth-child(even) {
          background-color: $even-color;
        }
      }

      .table-row {
        .table-cell {
          display: inline-block;
          text-align: left;
          font-size: 14px;

          span {
            font-size: 14px;
            display: inline-block;
            margin-right: 10px;
            color: #ff0000;
          }
        }
      }

      .table-cell {
        &:nth-child(1) {
          width: 39%;
          padding-left: 1%;
        }

        &:nth-child(2) {
          width: 16%;
          padding: 0 2%
        }

        &:nth-child(3) {
          width: 16%;
          padding: 0 2%
        }

        &:nth-child(4) {
          width: 16%;
          padding: 0 2%
        }
      }
    }
  }

  // 知识产权信息
  .int-prop-info {
    h2 {
      text-align: left;
      font-size: 16px;
      color: #101010;
    }

    :deep .el-form-item__label {
      color: #101010;
    }
  }

  // 申报项目选择
  .choice-project {
    position: fixed;
    left: 20px;
    top: 150px;
    width: 270px;
    padding-left: 10px;
    background: #FFFFFF;
    z-index: 9999;

    h2 {
      text-align: left;
      font-size: 16px;
      color: #b51313;
      border-top:4px solid #409eff ;
      padding-top: 5px;
    }

    .prompt {
      text-align: left;
      font-size: 14px;
      color: #FF0000;
      padding-bottom: 10px;
    }

    .choice-item {
      text-align: left;
      padding-bottom: 20px;

      :deep .el-checkbox__inner {
        border: 1px solid #1ab394;
      }

      :deep .el-checkbox {
        color: #101010;
      }

      :deep .el-checkbox__label {
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  // 知识产权明细
  .int-prop-list, .int-prop-act-list {
    margin-bottom: 25px;

    h2 {
      text-align: left;
      font-size: 16px;
      color: #101010;
    }

    .plus {
      display: inline-block;
      padding: 3px 20px;
      border: 1px solid #ebeef5;
      border-top: none;
      border-radius: 0 0 5px 5px;
      cursor: pointer;
      margin: 0 5px;
    }

    .table-data {
      width: 99%;
      height: 100%;
      border: 1px solid #ebeef5;
      $odd-color: #F2F2F2;
      $even-color: #FFFFFF;

      .table-header {
        background-color: $odd-color;
      }

      .table-header, .table-row {
        height: 40px;
        line-height: 38px;
      }

      .table-header {
        .table-cell {
          display: inline-block;
          text-align: center;
          color: #101010;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .table-row {
        .table-cell {
          display: inline-block;
          text-align: left;
          font-size: 14px;
        }
      }

      .table-cell {
        &:nth-child(1) {
          width: 14.6%;
          padding: 0 1%
        }

        &:nth-child(2) {
          width: 14.6%;
          padding: 0 1%
        }

        &:nth-child(3) {
          width: 14.6%;
          padding: 0 1%
        }

        &:nth-child(4) {
          width: 14.6%;
          padding: 0 1%
        }

        &:nth-child(5) {
          width: 14.6%;
          padding: 0 1%
        }

        &:nth-child(6) {
          width: 14.6%;
          padding: 0 1%
        }
      }
    }
  }

  .int-prop-act-list {
    .table-data {
      .table-cell {
        &:nth-child(1) {
          width: 12%;
          padding: 0 1%
        }

        &:nth-child(2) {
          width: 12.2%;
          padding: 0 1%
        }

        &:nth-child(3) {
          width: 12.7%;
          padding: 0 1%
        }

        &:nth-child(4) {
          width: 12.7%;
          padding: 0 1%
        }

        &:nth-child(5) {
          width: 12.2%;
          padding: 0 1%
        }

        &:nth-child(6) {
          width: 12.2%;
          padding: 0 1%
        }

        &:nth-child(7) {
          width: 12%;
          padding: 0 1%
        }
      }
    }
  }

  // 人力资源明细
  .human-resource-list {
    margin-bottom: 25px;

    h2 {
      text-align: left;
      font-size: 16px;
      color: #101010;
    }

    .table-data {
      width: 99%;
      height: 100%;
      border: 1px solid #ebeef5;
      $odd-color: #F2F2F2;
      $even-color: #FFFFFF;

      .table-header {
        background-color: $odd-color;
      }

      .table-row {
        &:nth-child(odd) {
          background-color: $odd-color;
        }

        &:nth-child(even) {
          background-color: $even-color;
        }
      }

      .table-header, .table-row {
        height: 40px;
        line-height: 38px;
      }

      .table-header {
        .table-cell {
          display: inline-block;
          text-align: center;
          color: #101010;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .table-row {
        .table-cell {
          display: inline-block;
          font-size: 14px;
        }
      }

      .cell-header {
        width: 18%;
        padding: 0 1%;
        text-align: center;
        font-weight: bold
      }

      .cell-input {
        width: 16%;
        padding: 0 2%;
        text-align: center;
      }

    }
  }

  :deep .el-collapse-item__header h2 {
    font-size: 16px;

    img {
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      margin-right: 5px;
    }

    span {
      color: #1ab394;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .el-collapse {
    .form-module {
      width: 100%;
      margin: 15px auto;
    }

    :deep .el-icon {
      margin-left: 3px;
      vertical-align: middle;
    }

    :deep .el-collapse-item__arrow {
      font-size: 20px;
      color: #1ab394;
    }

    :deep .el-form-item__label {
      color: #101010;
      font-weight: 700;
    }

    .if-true-show, .normal-show {
      text-align: left;

      :deep .el-form-item .el-form-item {
        display: inline-block;
      }

      :deep .el-input--small {
        display: inline-block;
        width: 100px;
        padding-bottom: 4px;
      }

      :deep .el-radio-group {
        vertical-align: middle;
      }

      :deep .el-checkbox-group {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .if-true-show {
      margin-left: 20px;
    }

  }

  .checkbox-label-left {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    margin-right: 10px;
  }

  .btn-submit {
    margin-top: 20px;
  }

}

.form-result {
  div {
    text-align: center;
    width: 50%;
    margin: auto;
  }
}

.calculation {
  :deep .el-input__wrapper {
    background: none;
    border: none;
    box-shadow: none;
  }

  :deep .el-input-group__append {
    background: none;
    box-shadow: none;
  }

}

.custom-block {
  text-align: left;
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 18px;
  border-left: 5px solid var(--el-color-primary);
  background-color: rgba(64, 158, 255, .1);
}

.spanInline {
  display: inline-block;
  width: 150px;
}

.bold {
  font-weight: bold;
}

.custom-block .custom-block-title {
  font-weight: 700
}

.pdf-view{
  cursor: pointer;
}
</style>
