<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/policy-interpretation"/>
    <div class='details-main-container'>
      <h1 class="details-title">{{ detail.name }}</h1>
      <div class="details-tag">
        <span>发布日期：{{ detail.publishDate }}</span>
      </div>
      <div class='details-content'>
        <div class="ql-editor" style="min-height: auto; max-height: none">
          <article v-html="detail.content"/>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import {onMounted, ref} from "vue";
import {useRoute} from 'vue-router';
import apiPolicyInfo from "@/api/policyInfo";

let route = useRoute();

let detail = ref({});

// const policyVideo = ref({})
// const policyVideoAvailable = ref(false)

onMounted(() => {
  let code = route.query.code;
  apiPolicyInfo.getDetail(code).then(response => {
    detail.value = response.data
    // policyVideo.value = `/video/policy_${response.data.id}.mp4`
    // apiPolicyInfo.checkVideo(policyVideo.value).then(ret => policyVideoAvailable.value = ret)
  })
})
</script>

<style lang="scss" scoped>
.video-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 30px;

  .video-player {
    height: 320px;
    aspect-ratio: 16 / 9;
  }
}
</style>
