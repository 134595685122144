<template>
  <div class="home">
    <HeaderView title=""/>

    <div class='main-container'>
      <div class="title">加入智狐易企通VIP</div>
      <span class="span-block"></span>
      <span class="vip-more">更多特权 超值服务</span>
      <span class="span-block"></span>

      <div style="color:#fff; font-size: 18px; font-weight: bold;margin-top: 45px">
        <el-badge :value="`立即享受${vipDayNum}天的VIP服务`" class="badge">
          <el-button class="join" @click="handleGetFreeVIP">限时领取VIP特权</el-button>
        </el-badge>

      </div>
      <div><img src="../assets/vip-split.png" style="width: 1200px;margin-top: 60px"></div>
      <div class="vip-desc-content"><br/>
        <el-row>
          <el-col :sm="12" :lg="12" style="text-align: center;">
            普通会员
          </el-col>
          <el-col :sm="12" :lg="12" style="text-align: center;color: #CBB486;">
            VIP会员
          </el-col>
          <el-divider style="background-color:#CBB486"></el-divider>
          <el-col :sm="12" :lg="12" style="text-align: center;margin-bottom: 45px">
           定时开放部分优惠政策
          </el-col>
          <el-col :sm="12" :lg="12" style="text-align: center;margin-bottom: 45px;color: #CBB486;">
            第一时间获取最新的优惠政策
          </el-col>
          <el-col :sm="12" :lg="12" style="text-align: center;margin-bottom: 45px">
            仅支持查看测评评分结果
          </el-col>
          <el-col :sm="12" :lg="12" style="text-align: center;margin-bottom: 45px;color: #CBB486;">
            可查看测评报告的详细解读
          </el-col>
          <el-col :sm="12" :lg="12" style="text-align: center;margin-bottom: 45px">
            仅支持政策列表查看
          </el-col>
          <el-col :sm="12" :lg="12" style="text-align: center;margin-bottom: 45px;color: #CBB486;">
            提供【AI慧测】功能，自动匹配符合企业的政策（即将开放）
          </el-col>
          <el-col :sm="12" :lg="12" style="text-align: center;margin-bottom: 45px">
            无法享受政策自主申报指南，指导
          </el-col>
          <el-col :sm="12" :lg="12" style="text-align: center;margin-bottom: 45px;color: #CBB486;">
            提供图文、视频指导企业自主申报（即将开放）
          </el-col>
        </el-row>
      </div>
    </div>

    <BottomView/>
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from "@/components/Bottom.vue";
import { getVipDayNum,membership } from "@/api/account";
import {onMounted, ref} from "vue";
import {ElMessageBox} from "element-plus";

const vipDayNum = ref(0)

onMounted(() => {
  getVipDayNum().then(res => {
    vipDayNum.value = res.data
  });
})

function handleGetFreeVIP() {
  membership().then(response => {
    if (response.code === 200) {
      ElMessageBox.alert("恭喜您成功获得免费" + vipDayNum.value + "天的VIP服务", "系统提示", {
        dangerouslyUseHTMLString: true,
        type: "success",
      })
    }else {
      ElMessageBox.alert("您已是免费会员，不能重复领取", "系统提示", {
        dangerouslyUseHTMLString: true,
        type: "success",
      })
    }
  })
}



</script>

<style lang="scss" scoped>
.home {

  background-size: cover; /* 背景图片覆盖整个容器 */

  background-image: url("../assets/vip-desc-bg-2.png");


  .main-container {
    margin-top: 80px;


    .title {
      color: #CBB486;
      font-size: 60px;
      font-weight: bold;
      margin-bottom: 45px;
    }

    .join {
      border-radius: 30px;
      height: 60px;
      line-height: 60px;
      width: 280px;
      background-color: #CBB486;
      color: #ffffff;
      border: none;
      font-size: 24px;
    }

    .span-block {
      display: inline-block;
      width: 42px;
      height: 2px;
      background-color: #ffffff;
      vertical-align: middle;
    }

    .vip-more {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      margin-top: 15px;
      vertical-align: middle;
    }

    .vip-desc-content {
      color: #FFFFFF;
      box-shadow: 14px 14px 30px 0 rgba(0, 0, 0, 0.4);
      background: #2d2d2d;
      min-width: 1200px;
      width: 1200px;
      margin: 0 auto;
    }

    .badge {
      top: 0;
    }

    ::v-deep .el-badge__content.is-fixed {
      border: none;
    }
    ::v-deep .el-badge__content--danger{
      background: #c3040f;
      height: 30px;
    }
  }
}

</style>
