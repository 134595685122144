<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition carrier-construction-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">支持创新创业载体建设操作口径</div>
      <BasicInfo ref="basicInfo" :is-financial="false" :is-int-prop="false"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="创新创业载体经浦东新区科经委登记备案" required
                            prop="type">
                <el-radio-group v-model="formData.type">
                  <el-radio :label="1">创新型孵化器</el-radio>
                  <el-radio :label="2">科技企业加速器</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-collapse-item>
          <el-collapse-item name="2" v-if="formData.type == 1">
            <template v-slot:title>
              <div class="item-title">创新型孵化器绩效评估指标</div>
            </template>
            <div class="form-module">
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内新招商引进的新注册企业</el-col>
                <el-col :span="6">
                  <el-form-item label="引进企业" required prop="newCompanyNumInTerm">
                    <input-number v-model="formData.newCompanyNumInTerm"
                                  @update:modelValue="formData.newCompanyNumInTerm = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6" required>
                  <el-form-item label="引进高企、专精特新企业" required prop="highTechCompanyNumInTerm">
                    <input-number v-model="formData.highTechCompanyNumInTerm"
                                  @update:modelValue="formData.highTechCompanyNumInTerm = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6" required>
                  <el-form-item label="引进科技小巨人（培育）企业" required prop="smallGiantCompanyNumInTerm">
                    <input-number v-model="formData.smallGiantCompanyNumInTerm"
                                  @update:modelValue="formData.smallGiantCompanyNumInTerm = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">在孵企业能级提升情况</el-col>
                <el-col :span="6">
                  <el-form-item label="高企、专精特新企业" required prop="levelAddedCompanyNum">
                    <input-number v-model="formData.levelAddedCompanyNum"
                                  @update:modelValue="formData.levelAddedCompanyNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内在孵企业获得重大投融资</el-col>
                <el-col :span="6">
                  <el-form-item label="实到融资额100-500万" required prop="financing100To500CompanyNum">
                    <input-number v-model="formData.financing100To500CompanyNum"
                                  @update:modelValue="formData.financing100To500CompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="实到融资额500-1000万" required prop="financing500To1000CompanyNum">
                    <input-number v-model="formData.financing500To1000CompanyNum"
                                  @update:modelValue="formData.financing500To1000CompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="实到融资额1000-3000万" required prop="financing1000To3000CompanyNum">
                    <input-number v-model="formData.financing1000To3000CompanyNum"
                                  @update:modelValue="formData.financing1000To3000CompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="实到融资额3000-5000万" required prop="financing3000To5000CompanyNum">
                    <input-number v-model="formData.financing3000To5000CompanyNum"
                                  @update:modelValue="formData.financing3000To5000CompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="实到融资额5000万以上" required prop="financing5000MoreCompanyNum">
                    <input-number v-model="formData.financing5000MoreCompanyNum"
                                  @update:modelValue="formData.financing5000MoreCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="孵化器自有资金或基金投资" required prop="ownCapacityOrFundCompanyNum">
                    <input-number v-model="formData.ownCapacityOrFundCompanyNum"
                                  @update:modelValue="formData.ownCapacityOrFundCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="为在孵企业购买保险" required prop="buyInsuranceCompanyNum">
                    <input-number v-model="formData.buyInsuranceCompanyNum"
                                  @update:modelValue="formData.buyInsuranceCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内毕业企业情况</el-col>
                <el-col :span="6">
                  <el-form-item label="考核期内毕业企业" required prop="graduationCompanyNum">
                    <input-number v-model="formData.graduationCompanyNum"
                                  @update:modelValue="formData.graduationCompanyNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="留在新区的" required prop="stayCompanyNum">
                    <input-number v-model="formData.stayCompanyNum"
                                  @update:modelValue="formData.stayCompanyNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="毕业企业获得科技小巨人（培育）或专精特新小巨人" required prop="sdriGiantCompanyNum">
                    <input-number v-model="formData.sdriGiantCompanyNum"
                                  @update:modelValue="formData.sdriGiantCompanyNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="毕业企业科创板、主板上市" required prop="ipoCompanyNum">
                    <input-number v-model="formData.ipoCompanyNum"
                                  @update:modelValue="formData.ipoCompanyNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内国际化取得重大进展</el-col>
                <el-col :span="6">
                  <el-form-item label="举办具有国际影响力论坛会议等活动" required prop="internationalForumCompanyNum">
                    <input-number v-model="formData.internationalForumCompanyNum"
                                  @update:modelValue="formData.internationalForumCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="在海外设点并实际运营的" required prop="inForeignCompanyNum">
                    <input-number v-model="formData.inForeignCompanyNum"
                                  @update:modelValue="formData.inForeignCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="引进海外项目并实际落地注册的" required prop="introductionProjectCompanyNum">
                    <input-number v-model="formData.introductionProjectCompanyNum"
                                  @update:modelValue="formData.introductionProjectCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">推动揭榜挂帅项目成功对接</el-col>
                <el-col :span="6">
                  <el-form-item label="每在浦东新区揭榜挂帅公共服务平台上对接成功" required prop="abutSuccessfullyCompanyNum">
                    <input-number v-model="formData.abutSuccessfullyCompanyNum"
                                  @update:modelValue="formData.abutSuccessfullyCompanyNum = $event"
                                  :is-decimal="false" :append="'次'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内孵化载体技术平台服务能力提升</el-col>
                <el-col :span="6">
                  <el-form-item label="平台建设：自建" required prop="selfPlatformCompanyNum">
                    <input-number v-model="formData.selfPlatformCompanyNum"
                                  @update:modelValue="formData.selfPlatformCompanyNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>

                </el-col>
                <el-col :span="6">
                  <el-form-item label="平台建设：联合建设" required prop="coownPlatformCompanyNum">
                    <input-number v-model="formData.coownPlatformCompanyNum"
                                  @update:modelValue="formData.coownPlatformCompanyNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="平台资质：市、区创新券服务机构" required prop="innovationCompanyNum">
                    <input-number v-model="formData.innovationCompanyNum"
                                  @update:modelValue="formData.innovationCompanyNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内孵化企业新增的知识产权</el-col>
                <el-col :span="6">
                  <el-form-item label="授权PCT" required prop="pctComanpyNum">
                    <input-number v-model="formData.pctComanpyNum"
                                  @update:modelValue="formData.pctComanpyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>

                </el-col>
                <el-col :span="6">
                  <el-form-item label="一类的" required prop="levelOneComanpyNum">
                    <input-number v-model="formData.levelOneComanpyNum"
                                  @update:modelValue="formData.levelOneComanpyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="二类的" required prop="levelTwoComanpyNum">
                    <input-number v-model="formData.levelTwoComanpyNum"
                                  @update:modelValue="formData.levelTwoComanpyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内推动企业开展产业资源对接、产学研合作、市场拓展等</el-col>
                <el-col :span="12">
                  <el-form-item label="推动孵化企业与大企业形成重大技术合作的" required prop="importantCooperationCompanyNum">
                    <input-number v-model="formData.importantCooperationCompanyNum"
                                  @update:modelValue="formData.importantCooperationCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="推动孵化企业与国内外知名高校、研究院所实现技术合作的" required prop="foreignCooperationCompanyNum">
                    <input-number v-model="formData.foreignCooperationCompanyNum"
                                  @update:modelValue="formData.foreignCooperationCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="推动孵化企业签订合作订单，订单金额超1000万的" required prop="order1000MoreCompanyNum">
                    <input-number v-model="formData.order1000MoreCompanyNum"
                                  @update:modelValue="formData.order1000MoreCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内与特色园区、街镇、开发区建立联动招商合作机制</el-col>
                <el-col :span="12">
                  <el-form-item label="签订具有工作目标及内容的合作协议有实际案例" required prop="signAgreementCompanyNum">
                    <input-number v-model="formData.signAgreementCompanyNum"
                                  @update:modelValue="formData.signAgreementCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内孵化企业引进的高端人才</el-col>
                <el-col :span="6">
                  <el-form-item label="国家级" required prop="nationTalentNum">
                    <input-number v-model="formData.nationTalentNum"
                                  @update:modelValue="formData.nationTalentNum = $event"
                                  :is-decimal="false" :append="'人'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="市级" required prop="cityTalentNum">
                    <input-number v-model="formData.cityTalentNum"
                                  @update:modelValue="formData.cityTalentNum = $event"
                                  :is-decimal="false" :append="'人'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="区级" required prop="districtTalentNum">
                    <input-number v-model="formData.districtTalentNum"
                                  @update:modelValue="formData.districtTalentNum = $event"
                                  :is-decimal="false" :append="'人'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="海外人才" required prop="foreignerTalentNum">
                    <input-number v-model="formData.foreignerTalentNum"
                                  @update:modelValue="formData.foreignerTalentNum = $event"
                                  :is-decimal="false" :append="'人'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="博士" required prop="doctorNum">
                    <input-number v-model="formData.doctorNum"
                                  @update:modelValue="formData.doctorNum = $event"
                                  :is-decimal="false" :append="'人'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30">
                <el-col :span="6">
                  <el-form-item label="专职服务人员与孵化企业总数的比例" required prop="servicePeopleCompanyRate">
                    <input-number v-model="formData.servicePeopleCompanyRate"
                                  @update:modelValue="formData.servicePeopleCompanyRate = $event"
                                  :is-decimal="true" :append="'%'"/>
                  </el-form-item>
                </el-col></el-row>
              <el-row :gutter="30">
                <el-col :span="6">
                  <el-form-item label="服务人员接受专业培训" required prop="professionalTrainingPeopleRate">
                    <input-number v-model="formData.professionalTrainingPeopleRate"
                                  @update:modelValue="formData.professionalTrainingPeopleRate = $event"
                                  :is-decimal="true" :append="'%'"/>
                  </el-form-item>
                </el-col></el-row>
              <el-row :gutter="30">
                <el-col :span="6">
                  <el-form-item label="考核期实际在孵企业数量" required prop="companyInFactNum">
                    <input-number v-model="formData.companyInFactNum"
                                  @update:modelValue="formData.companyInFactNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col></el-row>
              <el-row :gutter="30">
                <el-col :span="6">
                  <el-form-item label="可自主支配使用的孵化场地面积" required prop="placeArea">
                    <input-number v-model="formData.placeArea"
                                  @update:modelValue="formData.placeArea = $event"
                                  :is-decimal="false" :append="'平方'"/>
                  </el-form-item>
                </el-col></el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内在孵企业参加各项创业比赛</el-col>
                <el-col :span="6">
                  <el-form-item label="在孵企业中参加比赛" required prop="companyNumInMatch">
                    <input-number v-model="formData.companyNumInMatch"
                                  @update:modelValue="formData.companyNumInMatch = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="获得市级奖项" required prop="cityRewardCompanyNum">
                    <input-number v-model="formData.cityRewardCompanyNum"
                                  @update:modelValue="formData.cityRewardCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="获得国家奖项" required prop="nationalRewardCompanyNum">
                    <input-number v-model="formData.nationalRewardCompanyNum"
                                  @update:modelValue="formData.nationalRewardCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">创业基础配套服务种类（工商注册、法律咨询、财务管理、人力资源、知识产权、政策和管理咨询等）</el-col>
                <el-col :span="6">
                  <el-form-item label="具有并实际开展服务" required prop="serviceTypeNum">
                    <input-number v-model="formData.serviceTypeNum"
                                  @update:modelValue="formData.serviceTypeNum = $event"
                                  :is-decimal="false" :append="'项'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内举办的创新创业活动</el-col>
                <el-col :span="6">
                  <el-form-item label="常规活动" required prop="newEntrepreneurshipEvent">
                    <input-number v-model="formData.newEntrepreneurshipEvent"
                                  @update:modelValue="formData.newEntrepreneurshipEvent = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">企业服务数据收集与报送</el-col>
                <el-col :span="6">
                  <el-form-item label="及时汇总提交每季度营运数据" required prop="submittedEnterpriseDate">
                    <input-number v-model="formData.submittedEnterpriseDate"
                                  @update:modelValue="formData.submittedEnterpriseDate = $event"
                                  :is-decimal="false" :append="'次'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="未及时填报双创信息动态" required prop="unsubmittedEnterpriseDate">
                    <input-number v-model="formData.unsubmittedEnterpriseDate"
                                  @update:modelValue="formData.unsubmittedEnterpriseDate = $event"
                                  :is-decimal="false" :append="'次'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内孵化载体或孵化企业综合能级提升的(获得市级及以上资质荣誉或重大专项等)</el-col>
                <el-col :span="6">
                  <el-form-item label="孵化载体：国家级" required prop="nationalIncubatorNum">
                    <input-number v-model="formData.nationalIncubatorNum"
                                  @update:modelValue="formData.nationalIncubatorNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="孵化载体：市级" required prop="cityIncubatorNum">
                    <input-number v-model="formData.cityIncubatorNum"
                                  @update:modelValue="formData.cityIncubatorNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="孵化企业：国家级" required prop="nationalIncubatedCompanyNum">
                    <input-number v-model="formData.nationalIncubatedCompanyNum"
                                  @update:modelValue="formData.nationalIncubatedCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="孵化企业：市级" required prop="cityIncubatedCompanyNum">
                    <input-number v-model="formData.cityIncubatedCompanyNum"
                                  @update:modelValue="formData.cityIncubatedCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="孵化器管理不规范，被培育企业或社会主体投诉经查实" required
                                prop="isImproperManagement">
                    <el-radio-group v-model="formData.isImproperManagement">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="不及时配合区重大工作部署" required
                                prop="isFailureToCooperate">
                    <el-radio-group v-model="formData.isFailureToCooperate">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="报送虚假信息经查实或者被依法判定欺诈行为属实" required
                                prop="isSubmitFalseInformation">
                    <el-radio-group v-model="formData.isSubmitFalseInformation">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="孵化载体及所属孵化企业发生重大安全生产问题" required
                                prop="isSafetyIssuesOccur">
                    <el-radio-group v-model="formData.isSafetyIssuesOccur">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="对新区双创形象造成较为严重影响" required
                                prop="isSeriousInfluence">
                    <el-radio-group v-model="formData.isSeriousInfluence">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
          <el-collapse-item name="3" v-if="formData.type == 2">
            <template v-slot:title>
              <div class="item-title">企业加速器绩效评估指标</div>
            </template>
            <div class="form-module">
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">加速场地</el-col>
                <el-col :span="8">
                  <el-form-item label="可自主支配场地面积" required prop="acceleratorPlaceArea">
                    <input-number v-model="formData.acceleratorPlaceArea"
                                  @update:modelValue="formData.acceleratorPlaceArea = $event"
                                  :is-decimal="false" :append="'平方米'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="用于高成长科技企业使用的场地不少于总面积的" required prop="acceleratorHighGrowthArea">
                    <input-number v-model="formData.acceleratorHighGrowthArea"
                                  @update:modelValue="formData.acceleratorHighGrowthArea = $event"
                                  :is-decimal="true" :append="'%'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="作为加速器用途使用期限" required prop="acceleratorYear">
                    <input-number v-model="formData.acceleratorYear"
                                  @update:modelValue="formData.acceleratorYear = $event"
                                  :is-decimal="false" :append="'年以上'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30">
                <el-col :span="8">
                  <el-form-item label="参照登记管理办法企业标准，入驻企业" required prop="acceleratorCompanyNum">
                    <input-number v-model="formData.acceleratorCompanyNum"
                                  @update:modelValue="formData.acceleratorCompanyNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">制度规范</el-col>
                <el-col :span="24">
                  <el-form-item label="管理服务规范、制度健全" required prop="isAcceleratorRuleSound">
                    <el-radio-group v-model="formData.isAcceleratorRuleSound">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="制定科技企业加速器服务规范和中长期发展规划" required prop="isAcceleratorServiceRule">
                    <el-radio-group v-model="formData.isAcceleratorServiceRule">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="30">
                <el-col :span="8">
                  <el-form-item label="拥有___人以上的高素质、专业化管理团队" required prop="acceleratorServicePeopleNum">
                    <input-number v-model="formData.acceleratorServicePeopleNum"
                                  @update:modelValue="formData.acceleratorServicePeopleNum = $event"
                                  :is-decimal="false" :append="'人'"/>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="30">
                <el-col :span="8">
                  <el-form-item label="加速器内以及与加速器紧密合作的中介服务机构" required prop="acceleratorAgencyNum">
                    <input-number v-model="formData.acceleratorAgencyNum"
                                  @update:modelValue="formData.acceleratorAgencyNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">自有资金投资或成立相关的投资基金</el-col>
                <el-col :span="8">
                  <el-form-item label="配备自有投贷金（基金）或合作基金" required prop="isAcceleratorOwnFund">
                    <el-radio-group v-model="formData.isAcceleratorOwnFund">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="在加速器内投资案例有" required prop="acceleratorInvestmentCase">
                    <input-number v-model="formData.acceleratorInvestmentCase"
                                  @update:modelValue="formData.acceleratorInvestmentCase = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内引进高成长企业</el-col>
                <el-col :span="8">
                  <el-form-item label="新增孵化器外高成长企业" required prop="acceleratorHighGrowthCompanyNum">
                    <input-number v-model="formData.acceleratorHighGrowthCompanyNum"
                                  @update:modelValue="formData.acceleratorHighGrowthCompanyNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="新增孵器毕业企业" required prop="acceleratorGraduationCompanyNum">
                    <input-number v-model="formData.acceleratorGraduationCompanyNum"
                                  @update:modelValue="formData.acceleratorGraduationCompanyNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内入驻企业营业收入</el-col>
                <el-col :span="8">
                  <el-form-item label="加速器加速企业营业收入增长较上年度增长" required prop="acceleratorAnnualGrowth">
                    <input-number v-model="formData.acceleratorAnnualGrowth"
                                  @update:modelValue="formData.acceleratorAnnualGrowth = $event"
                                  :is-decimal="true" :append="'%'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="年度营收增加额" required prop="acceleratorAnnualRevenueIncrease">
                    <input-number v-model="formData.acceleratorAnnualRevenueIncrease"
                                  @update:modelValue="formData.acceleratorAnnualRevenueIncrease = $event"
                                  :is-decimal="false" :append="'万元'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30">
                <el-col :span="8">
                  <el-form-item label="考核期内入驻企业融资，风投实到资本（万元）" required prop="acceleratorFinancing">
                    <input-number v-model="formData.acceleratorFinancing"
                                  @update:modelValue="formData.acceleratorFinancing = $event"
                                  :is-decimal="false" :append="'万元'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内加速服务企业上市成果</el-col>
                <el-col :span="6">
                  <el-form-item label="科创板上市" required prop="acceleratorStibCompanyNum">
                    <input-number v-model="formData.acceleratorStibCompanyNum"
                                  @update:modelValue="formData.acceleratorStibCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="主板上市" required prop="acceleratorMotherboardCompanyNum">
                    <input-number v-model="formData.acceleratorMotherboardCompanyNum"
                                  @update:modelValue="formData.acceleratorMotherboardCompanyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内入驻企业高端人才聚集</el-col>
                <el-col :span="6">
                  <el-form-item label="国家级" required prop="acceleratorNationalTalent">
                    <input-number v-model="formData.acceleratorNationalTalent"
                                  @update:modelValue="formData.acceleratorNationalTalent = $event"
                                  :is-decimal="false" :append="'人'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="市级" required prop="acceleratorCityTalent">
                    <input-number v-model="formData.acceleratorCityTalent"
                                  @update:modelValue="formData.acceleratorCityTalent = $event"
                                  :is-decimal="false" :append="'人'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="区级" required prop="acceleratorDistrictTalent">
                    <input-number v-model="formData.acceleratorDistrictTalent"
                                  @update:modelValue="formData.acceleratorDistrictTalent = $event"
                                  :is-decimal="false" :append="'人'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内入驻企业新增知识产权量</el-col>
                <el-col :span="6">
                  <el-form-item label="PCT：授权" required prop="acceleratorPctNum">
                    <input-number v-model="formData.acceleratorPctNum"
                                  @update:modelValue="formData.acceleratorPctNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="发明专利：授权" required prop="acceleratorIntellectualPropertyNum">
                    <input-number v-model="formData.acceleratorIntellectualPropertyNum"
                                  @update:modelValue="formData.acceleratorIntellectualPropertyNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内入驻企业获市级以上重大专项或资质荣誉的</el-col>
                <el-col :span="6">
                  <el-form-item label="市级" required prop="acceleratorCityNum">
                    <input-number v-model="formData.acceleratorCityNum"
                                  @update:modelValue="formData.acceleratorCityNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="国家级" required prop="acceleratorNationalNum">
                    <input-number v-model="formData.acceleratorNationalNum"
                                  @update:modelValue="formData.acceleratorNationalNum = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="30">
                <el-col :span="24">
                  <el-form-item label="入驻企业达到 10 家以上，聚焦于一个产业的集中度达到80%。" required prop="acceleratorIndustrialConcentration">
                    <el-radio-group v-model="formData.acceleratorIndustrialConcentration">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">加速载体自建或弓箭公共技术服务平台的</el-col>
                <el-col :span="6">
                  <el-form-item label="平台建设：自建" required prop="acceleratorSelfPlatformNum">
                    <input-number v-model="formData.acceleratorSelfPlatformNum"
                                  @update:modelValue="formData.acceleratorSelfPlatformNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="平台建设：联合建设" required prop="acceleratorCoownPlatformNum">
                    <input-number v-model="formData.acceleratorCoownPlatformNum"
                                  @update:modelValue="formData.acceleratorCoownPlatformNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="平台资质：市、区创新券服务机构备案" required prop="acceleratorInnovationNum">
                    <input-number v-model="formData.acceleratorInnovationNum"
                                  @update:modelValue="formData.acceleratorInnovationNum = $event"
                                  :is-decimal="false" :append="'家'"/>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="30" class="input-style">
                <el-col :span="24" class="title-tips">考核期内加速载体开展产业资源对接、产学研合作、推动大中小融通等方面的成效</el-col>
                <el-col :span="6">
                  <el-form-item label="推动入驻企业与大企业在浦东重点发展领域形成重大技术合作" required prop="acceleratorLargeEnterpriseCooperation">
                    <input-number v-model="formData.acceleratorLargeEnterpriseCooperation"
                                  @update:modelValue="formData.acceleratorLargeEnterpriseCooperation = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="推动入驻企业与国内外知名高校、研究院所实现技术合作" required prop="acceleratorFamousInstituionCooperation">
                    <input-number v-model="formData.acceleratorFamousInstituionCooperation"
                                  @update:modelValue="formData.acceleratorFamousInstituionCooperation = $event"
                                  :is-decimal="false" :append="'个'"/>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="24">
                  <el-form-item label="报送虚假信息经查实或者被依法判定欺诈行为属实" required
                                prop="isAcceleratorSubmitFalseInformation">
                    <el-radio-group v-model="formData.isAcceleratorSubmitFalseInformation">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="加速器管理不规范，被培育企业或社会主体投诉经查实" required
                                prop="isAcceleratorImproperManagement">
                    <el-radio-group v-model="formData.isAcceleratorImproperManagement">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="不能及时配合区重大工作部署" required
                                prop="isAcceleratorFailureToCooperate">
                    <el-radio-group v-model="formData.isAcceleratorFailureToCooperate">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="对新区双创形象造成较为严重影响" required
                                prop="isAcceleratorSeriousInfluence">
                    <el-radio-group v-model="formData.isAcceleratorSeriousInfluence">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "CarrierConstruction"
}
</script>

<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import BasicInfo from '@/components/BasicInfo'
import Breadcrumb from '@/components/Breadcrumb'
import InputNumber from '@/components/InputNumber'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1','2','3'])
const basicInfo = ref()
const urlPath = 'incubatorAndAccelerator'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({
  type: 0,

  newCompanyNumInTerm: 0,
  highTechCompanyNumInTerm: 0,
  smallGiantCompanyNumInTerm: 0,

  levelAddedCompanyNum: 0,

  financing100To500CompanyNum: 0,
  financing500To1000CompanyNum: 0,
  financing1000To3000CompanyNum: 0,
  financing3000To5000CompanyNum: 0,
  financing5000MoreCompanyNum: 0,
  ownCapacityOrFundCompanyNum: 0,
  buyInsuranceCompanyNum: 0,

  graduationCompanyNum: 0,
  stayCompanyNum: 0,
  sdriGiantCompanyNum: 0,
  ipoCompanyNum: 0,

  internationalForumCompanyNum: 0,
  inForeignCompanyNum: 0,
  introductionProjectCompanyNum: 0,

  abutSuccessfullyCompanyNum: 0,

  selfPlatformCompanyNum: 0,
  coownPlatformCompanyNum: 0,
  innovationCompanyNum: 0,

  pctComanpyNum: 0,
  levelOneComanpyNum: 0,
  levelTwoComanpyNum: 0,

  importantCooperationCompanyNum: 0,
  foreignCooperationCompanyNum: 0,
  order1000MoreCompanyNum: 0,

  signAgreementCompanyNum: 0,

  nationTalentNum: 0,
  cityTalentNum: 0,
  districtTalentNum: 0,
  foreignerTalentNum: 0,
  doctorNum: 0,

  servicePeopleCompanyRate: 0,
  professionalTrainingPeopleRate: 0,
  companyInFactNum: 0,
  placeArea: 0,
  companyNumInMatch: 0,
  cityRewardCompanyNum: 0,
  nationalRewardCompanyNum: 0,

  serviceTypeNum: 0,

  newEntrepreneurshipEvent: 0,

  submittedEnterpriseDate: 0,
  unsubmittedEnterpriseDate: 0,

  nationalIncubatorNum: 0,
  cityIncubatorNum: 0,
  nationalIncubatedCompanyNum: 0,
  cityIncubatedCompanyNum: 0,

  isImproperManagement: 0,
  isFailureToCooperate: 0,
  isSubmitFalseInformation: 0,
  isSafetyIssuesOccur: 0,
  isSeriousInfluence: 0,


  acceleratorPlaceArea:0,
  acceleratorHighGrowthArea:0,
  acceleratorYear:0,

  acceleratorCompanyNum:0,

  isAcceleratorRuleSound:0,

  isAcceleratorServiceRule:0,

  acceleratorServicePeopleNum:0,
  acceleratorAgencyNum:0,

  isAcceleratorOwnFund:0,
  acceleratorInvestmentCase:0,

  acceleratorHighGrowthCompanyNum:0,
  acceleratorGraduationCompanyNum:0,

  acceleratorAnnualGrowth:0,
  acceleratorAnnualRevenueIncrease:0,

  acceleratorFinancing:0,

  acceleratorStibCompanyNum:0,
  acceleratorMotherboardCompanyNum:0,

  acceleratorNationalTalent:0,
  acceleratorCityTalent:0,
  acceleratorDistrictTalent:0,

  acceleratorPctNum:0,
  acceleratorIntellectualPropertyNum:0,

  acceleratorCityNum:0,
  acceleratorNationalNum:0,

  acceleratorIndustrialConcentration:0,

  acceleratorSelfPlatformNum:0,
  acceleratorCoownPlatformNum:0,
  acceleratorInnovationNum:0,

  acceleratorLargeEnterpriseCooperation:0,
  acceleratorFamousInstituionCooperation:0,

  isAcceleratorSubmitFalseInformation: 0,
  isAcceleratorImproperManagement: 0,
  isAcceleratorFailureToCooperate: 0,
  isAcceleratorSeriousInfluence: 0,

})
onMounted(() => {
  getConditionByPath()
})
const getConditionByPath = () => {
  companyApi.getConditionByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveConditionByPath(urlPath, company).then((resp) => {
    if (resp.code == 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getConditionByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitConditionByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

</script>
<style scoped lang="scss">
.carrier-construction-container {
  .normal-show {
    text-align: left;

    :deep .el-form-item .el-form-item {
      display: inline-block;
    }

    :deep .el-input--small {
      display: inline-block;
      width: 100px;
      padding-bottom: 4px;
    }

    :deep .el-radio-group {
      vertical-align: middle;
    }

    :deep .el-checkbox-group {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }

  .input-style{
    :deep .el-form-item__label {
      font-weight: unset;
    }
    .title-tips{
      color: #101010;
      font-weight: 700;
      text-align: left;
      padding: 10px 0;
      span{
        color: red;
      }
    }
  }
}
</style>
