<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>浦东新区科技发展基金产学研专项（未来车）</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>工商注册、税收户管均在浦东新区，且经营状态正常、信用记录良好、符合产业发展方向, 具有承担项目建设能力的企业。</td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>专题一：智能网联汽车关键零部件
                <br>研究目标：支持产学研用联合攻关，加强智能网联汽车共性关键技术研发，提升智能网联汽车的示范应用及产业化能级。
                <br>研究内容：车载激光雷达、4D毫米波雷达、深度智能图像传感器等复杂环境融合感知系统部件，车用操作系统、智能计算平台、跨域融合域控制器、算法和基础软件等决策与控制系统部件，特定应用场景无人驾驶车辆，智能座舱，C-V2X通信终端，边缘计算设备，人机交互终端，汽车数据安全和网络安全关键技术、高精地图和定位、车路云一体化系统架构、智能网联汽车数据安全和隐私保护相关技术、大模型赋能智驾算法和数据挖掘等应用技术。
                <br>执行期限：不超过2年，项目结束时间不得晚于2025年7月31日前完成。
                <br>专题二：新能源汽车关键零部件
                <br>研究目标：支持产学研用联合攻关，加强新能源汽车核心技术攻关，提升新能源汽车关键零部件国产化率和技术能级。
                <br>研究内容：新型电子电气架构、固态电池、半固态电池、钠离子电池等新型动力电池系统、BMS热管理系统、动力电池与管理系统、新一代动力电池成组技术、电池盒技术、高功率密度驱动电机及控制系统、大功率无线充电桩、底盘线控执行系统（转向、制动）、高性能试验检测设备与系统等。
                <br>执行期限：不超过2年，项目结束时间不得晚于2025年7月31日前完成。
                <br>专题三：燃料电池汽车关键零部件
                <br>研究目标：支持产学研用联合攻关，推动燃料电池汽车核心技术加快突破，提升燃料汽车关键零部件国产化率和技术能级。
                <br>研究内容：电堆、双极板、质子交换膜、膜电极、催化剂、空压机、氢气循环系统、碳纸、站用压缩机、储氢瓶阀、制氢储氢一体化设备等关键零部件研发。
                <br>执行期限：不超过2年，项目结束时间不得晚于2025年7月31日前完成。
              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>项目责任人应承诺所提交材料真实性，不含涉密内容；申报单位应当对申请材料的真实性进行审核。
              </td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>项目申报主体须由新区企业牵头，联合1-2家上海地区高校或科研院所共同申报。项目承担方需要有较强的项目研究基础、人员配备和经费保障，合作单位必须为一级法人单位且在合作项目相关技术领域达到国际国内领先水平。
              </td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>申报单位需提交正式合作协议等附件证明材料，合作协议应是2022年8月1日后签署。
              </td>
              <td v-html='(result[4] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>项目资助资金不超过项目总经费的30%，单个项目最高资助额度不超过200万元，项目经评审后择优立项。资助资金应保障合作单位的各项经费支出。
              </td>
              <td v-html='(result[5] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>已经获得国家、上海市和区级其他财政资金资助的项目不得申报本专项。
              </td>
              <td v-html='(result[6] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>同一企业原则上只能申报一个项目。同一申报企业承担浦东科技发展基金产学研专项尚未完成验收的，不能申报。
              </td>
              <td v-html='(result[7] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>产学研合作方项目牵头人原则上只能合作申报一个产学研项目。同一合作方牵头人承担浦东科技发展基金产学研专项尚未完成验收的，不再予以支持。
              </td>
              <td v-html='(result[8] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>企业应在生产经营中做好安全生产工作。
              </td>
              <td v-html='(result[9] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongTecholonyDevelopmentSpecialFund"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
