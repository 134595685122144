<template>
  <div class='login-container'>
    <div class='login-form'>
      <h2>企业用户登录</h2>
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="账号登陆" name="first">
          <el-form ref="loginRef"
                   :model="loginForm"
                   :rules="loginRules"
                   class="el-form"
                   style="margin-top: 10px"
                   label-position='top'
                   label-width="120px"
          >
            <el-form-item label="用户名（手机号）" prop="username">
              <el-input v-model="loginForm.username"
                        type="text"
                        placeholder="请输入手机号码"
                        @keyup.enter="handleLogin">
                <template #prefix>
                  <el-icon class="el-input__icon">
                    <User/>
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="密 码">
              <el-input v-model="loginForm.password"
                        type="password"
                        placeholder="请输入密码"
                        @keyup.enter="handleLogin">
                <template #prefix>
                  <el-icon class="el-input__icon">
                    <Lock/>
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code" v-if="captchaEnabled">
              <el-input
                  v-model="loginForm.code"
                  size="large"
                  auto-complete="off"
                  placeholder="请输入验证码"
                  style="width: 50%"
                  @keyup.enter="handleLogin"
              >
                <template #prefix>
                  <el-icon class="el-input__icon">
                    <CircleCheck/>
                  </el-icon>
                </template>
              </el-input>
              <div class="login-code">
                <img :src="codeUrl" @click="getCode" alt="" class="login-code-img"/>
              </div>
            </el-form-item>
            <p style="text-align: left; padding: 0">
              <el-checkbox v-model="loginForm.rememberMe">记住密码</el-checkbox>
            </p>
            <el-form-item>
              <el-button
                  :loading="loading"
                  size="large"
                  class="submit"
                  @click.prevent="handleLogin"
              >
                <span v-if="!loading">登 录</span>
                <span v-else>登 录 中...</span>
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="短信验证登录" name="second">
          <el-form ref="loginMobileRef"
                   :model="loginMobileForm"
                   :rules="loginMobileRules"
                   class="el-form"
                   style="margin-top: 10px"
                   label-position='top'
                   label-width="120px"
          >
            <el-form-item label="用户名（手机号）" prop="mobile">
              <el-input v-model="loginMobileForm.mobile"
                        type="text"
                        placeholder="请输入手机号码"
                        @keyup.enter="handleLoginByMobile">
                <template #prefix>
                  <el-icon class="el-input__icon">
                    <User/>
                  </el-icon>
                </template>
              </el-input>
            </el-form-item>

            <el-form-item label="验证码" prop="code">
              <el-input
                  v-model="loginMobileForm.code"
                  size="large"
                  auto-complete="off"
                  placeholder="请输入验证码"
                  @keyup.enter="handleLoginByMobile"
              >
                <template #prefix>
                  <el-icon class="el-input__icon">
                    <CircleCheck/>
                  </el-icon>
                </template>
              </el-input>
              <div class="sms-code" style="position: absolute;right: 20px;top: 7px;">
                <span style="color: #dcdfe6;padding-right: 5px">|</span>
                <el-button v-if="!isSending" @click="sendMobileCode" type="text">发送验证码</el-button>
                <span v-if="isSuccess">{{ successMessage }}</span>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button
                  :loading="loading"
                  size="large"
                  class="submit"
                  @click.prevent="handleLoginByMobile"
              >
                <span v-if="!loading">登 录</span>
                <span v-else>登 录 中...</span>
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>

      <p>没有账号？
        <router-link to='#' @click="goRegister">去注册</router-link>
      </p>
    </div>
  </div>
</template>

<script setup>
import {CircleCheck, Lock, User} from '@element-plus/icons-vue'

import {getCurrentInstance, ref, defineEmits} from "vue";
import {getCodeImg, sendCode} from "@/api/login";
import Cookies from "js-cookie";
import {decrypt, encrypt} from "@/utils/jsencrypt";
import useUserStore from '@/store/modules/user'
import router from "@/router";
import cache from "@/plugins/cache";
import {ElMessage} from "element-plus";

const emits = defineEmits(['showRegister'])
const userStore = useUserStore()
const {proxy} = getCurrentInstance();

const isSending = ref(false);
const isSuccess = ref(false);
const successMessage = ref('60秒后重新发送');
const countdown = ref(60);
const timer = ref(null);

const loginForm = ref({
  username: "",
  password: "",
  rememberMe: false,
  code: "",
  uuid: ""
});

const loginMobileForm = ref({
  mobile: "",
  code: "",
});

const loginRules = {
  username: [
    {required: true, trigger: "blur", message: "请输入您的账号"},
    {pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur'}],
  password: [{required: true, trigger: "blur", message: "请输入您的密码"}],
  code: [{required: true, trigger: "change", message: "请输入验证码"}]
};

const loginMobileRules = {
  mobile: [
    {required: true, trigger: "blur", message: "请输入您的手机号码"},
    {pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur'}],
  code: [{required: true, trigger: "change", message: "请输入短信验证码"}]
};

const codeUrl = ref("");
const loading = ref(false);
// 验证码开关
const captchaEnabled = ref(true);
// 注册开关
// const redirect = ref(undefined);

const activeName = ref('first')

function handleLogin() {
  proxy.$refs.loginRef.validate(valid => {
    if (valid) {
      loading.value = true;
      // 勾选了需要记住密码设置在 cookie 中设置记住用户名和密码
      if (loginForm.value.rememberMe) {
        Cookies.set("username", loginForm.value.username, {expires: 30});
        Cookies.set("password", encrypt(loginForm.value.password), {expires: 30});
        Cookies.set("rememberMe", loginForm.value.rememberMe, {expires: 30});
      } else {
        // 否则移除
        Cookies.remove("username");
        Cookies.remove("password");
        Cookies.remove("rememberMe");
      }
      // 调用action的登录方法
      userStore.login(loginForm.value).then(() => {
        cache.session.setJSON('sessionObj', '')
        userStore.hideLogin()
        // router.push({ path: redirect.value || "/" });
        router.go(0);
      }).catch(() => {
        loading.value = false;
        // 重新获取验证码
        if (captchaEnabled.value) {
          getCode();
        }
      });
    }
  });
}

function getCode() {
  getCodeImg().then(res => {
    captchaEnabled.value = res.captchaEnabled === undefined ? true : res.captchaEnabled;
    if (captchaEnabled.value) {
      codeUrl.value = "data:image/gif;base64," + res.img;
      loginForm.value.uuid = res.uuid;
    }
  });
}


function sendMobileCode() {
  let mobile = loginMobileForm.value.mobile;
  if (!mobile) {
    ElMessage({
      showClose: true,
      message: '请输入手机号码',
      type: 'error',
      offset:300
    })
    return;
  }
  const mobileRegex = /^1[3-9]\d{9}$/;
  if (!mobileRegex.test(mobile)) {
    // ElMessage({
    //   showClose: true,
    //   message: '手机号码格式不正确',
    //   type: 'error',
    //   offset:300
    // })
    return;
  }
  sendCode(mobile).then(res => {
    if (res.code === 200) {
      isSending.value = true;
      isSuccess.value = true;
      startCountdown();
    }
  });
}

function startCountdown() {
  if (timer.value) {
    clearTimeout(timer.value);
  }

  timer.value = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
      successMessage.value = `${countdown.value}秒后重新发送`;
    } else {
      stopCountdown();
    }
  }, 1000);
}
function stopCountdown() {
  clearInterval(timer.value);
  timer.value = null;
  isSending.value = false;
  isSuccess.value = false;
  countdown.value = 60;
}

function handleLoginByMobile() {
  proxy.$refs.loginMobileRef.validate(valid => {
    if (valid) {
      loading.value = true;
      // 调用action的登录方法
      userStore.loginByMobile(loginMobileForm.value).then(() => {
        cache.session.setJSON('sessionObj', '')
        userStore.hideLogin()
        // router.push({ path: redirect.value || "/" });
        router.go(0);
      }).catch(() => {
        loading.value = false;
      });
    }
  });
}


function getCookie() {
  const username = Cookies.get("username");
  const password = Cookies.get("password");
  const rememberMe = Cookies.get("rememberMe");
  loginForm.value = {
    username: username === undefined ? loginForm.value.username : username,
    password: password === undefined ? loginForm.value.password : decrypt(password),
    rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
  };
}

getCode();
getCookie();

const goRegister = () => {
  userStore.hideLogin()
  emits('showRegister')
}

</script>

<style lang="scss" scoped>

.login-container {
  margin: 0 auto;

  .login-form {
    margin: 0 auto;
    width: 350px;
    height: 510px;
    line-height: 20px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 100);
    text-align: center;
    padding: 0 50px 50px;

    h2 {
      margin: 0;
      font-size: 36px;
      color: #101010;
      font-weight: 400;
      text-align: left;
      padding-bottom: 30px;
    }


    :deep(.el-input__wrapper) {
      height: 44px;
    }

    .el-input__icon {
      font-size: 18px;
    }

    .login-code-img {
      height: 44px;
      margin-left: 52px;
      vertical-align: middle;
    }

    .submit {
      width: 355px;
      height: 52px;
      line-height: 20px;
      border-radius: 5px;
      background: linear-gradient(264.2deg, rgba(185, 145, 107, 1) 1.7%, rgba(228, 200, 149, 1) 93.74%);
      color: rgba(255, 255, 255, 100);
      font-size: 18px;
      text-align: center;
      margin-top: 20px;
    }

    p {
      margin: 0;
      padding: 20px 0 0 0;
      color: #101010;
      font-size: 16px;

      a {
        color: #BDA26D;
        text-decoration: none;
      }
    }
  }
}
</style>
