<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>创新型中小企业-认定评分明细</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>满足下列条件之一，即符合条件</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 120px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>1、近三年内获得过国家级、省级科技奖励</td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>2、获得高新技术企业、国家级技术创新示范企业、知识产权优势企业和知识产权示范企业等荣誉（均为有效期内）</td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>3、近三年新增股权融资总额（合格机构投资者的实缴额）500万元以上</td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>4、拥有经认定的省部级以上研发机构</td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="result-score">
        <div class="title"><span>*</span>除满足上述四项任一条件外，评价得分达到<span>60</span>分以上的企业也符合公告条件
        </div>
        <div class="subtitle">（包括创新能力、成长性、专业化三类六个指标，评价结果依分值计算，满分为 100 分。其中创新能力指标得分不低于 20 分、成长性指标及专业化指标得分均不低于 15 分）</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th style="width: 330px">评价分类</th>
              <th>评定项目</th>
              <th style="width: 124px;">得分</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td rowspan="2">创新能力指标（满分40分，不低于20分）</td>
              <td>1．与企业主导产品相关的有效知识产权数量（满分20分）
                <br>A. Ⅰ类高价值知识产权1项以上（20分）
                <br>B. 自主研发的Ⅰ类知识产权1项以上（15分）
                <br>C. Ⅰ类知识产权1项以上（10分）
                <br>D. Ⅱ类知识产权1项以上（5分）
                <br>E. 无（0分）</td>
              <td class="core">{{ (result[4] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>2．上年度研发费用总额占营业收入总额比重（满分20分）
                <br>A. 5%以上（20分）
                <br>B. 3%-5%（15分）
                <br>C. 2%-3%（10分）
                <br>D. 1%-2%（5分）
                <br>E. 1%以下（0分）</td>
              <td class="core">{{ (result[5] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="2">成长性指标（满分30分，不低于15分）</td>
              <td>3．上年度主营业务收入增长率（满分20分）
                <br>A. 15%以上（20分）
                <br>B. 10%-15%（15分）
                <br>C. 5%-10%（10分）
                <br>D. 0%-5%（5分）
                <br>E. 0%以下（0分）</td>
              <td class="core">{{ (result[6] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>4．上年度资产负债率（满分10分）
                <br>A. 55%以下（10分）
                <br>B. 55%-75%（5分）
                <br>C. 75%以上（0分）</td>
              <td class="core">{{ (result[7] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="2">专业化（满分30分，不低于15分）</td>
              <td>5．主导产品所属领域情况（满分10分）
                <br>A.属于《战略性新兴产业分类》（10分）
                <br>B.属于其他领域（5分）</td>
              <td class="core">{{ (result[8] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>6．上年度主营业务收入总额占营业收入总额比重（满分20分）
                <br>A. 70%以上（20分）
                <br>B. 60%-70%（15分）
                <br>C. 55%-60%（10分）
                <br>D. 50%-55%（5分）
                <br>E. 50%以下（0分）</td>
              <td class="core">{{ (result[9] || {}).score || 0 }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="result-total">评价总得分： <span>{{ total }}</span> 分</div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "result_1"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score
  getResultDetail({"evaluationType": query.evaluationType}).then((res)=>{
    result.value = res.data
  }).catch(()=>{})
})
</script>


<style scoped lang="scss">
.header {
  width: 1140px;
  margin: 35px auto 0;

  .title {
    font-size: 75px;
    color: #F2F2F2;
    font-weight: bold;
    position: relative;
    text-align: left;
    margin: 0;

    .text {
      font-size: 32px;
      color: #2C3E50;
      position: absolute;
      left: 50px;
      top: 25px;
    }
  }
}

.main-container {
  width: 1140px;
  margin: auto;
  padding-bottom: 100px;
  text-align: left;
  color: #101010;

  .container-title {
    padding: 50px 0 40px 0;
    font-size: 20px;
    color: #101010;

    span {
      display: inline-block;
      width: 8px;
      height: 20px;
      background: #5C7EF4;
      vertical-align: middle;
    }
  }

  .result-boolean {
    .title {
      font-size: 18px;

      span {
        color: red;
      }
    }

    .table {
      width: 100%;
      margin: 20px 0 40px;

      table {
        width: 100%;
        border-collapse: collapse;
      }

      table, th, td {
        border: 1px solid #378DD3;
        padding: 10px;
        line-height: 25px;
        font-size: 14px;
        font-weight: bold;
      }

      th {
        font-size: 16px;
        font-weight: bolder;
        text-align: center;
        color: #ffffff;
        background-color: #378DD3;
      }

      td:nth-child(2) {
        text-align: center;
      }
    }
  }

  .result-score {
    .title {
      font-size: 16px;

      span {
        color: red;
      }
    }

    .subtitle {
      margin-top: 10px;
      font-size: 14px;
    }

    .table {
      width: 100%;
      margin: 20px 0 40px;

      table {
        width: 100%;
        border-collapse: collapse;
      }

      table, th, td {
        border: 1px solid #DDDDDD;
        padding: 10px;
        line-height: 30px;
        color: #031A40;
      }

      th {
        font-size: 16px;
        font-weight: bolder;
        text-align: center;
        background-color: #DDDDDD;
      }

      .core {
        text-align: center;
      }
    }
  }

  .result-total {
    font-weight: bold;
    span {
      color: red;
    }
  }

  .td-red {
    color: red;
  }
}
</style>
