<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='project-main-container'>
      <div class="details-container">
        <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'项目详情',link:''}]"/>
        <h1 class="details-title">{{ detail.name }}</h1>
        <div class="details-tag">
            <span>主管部门：{{ officesEnum[detail.office] }}
            </span>
          <span>申报状态：
              <el-tag :class="`progress-${detail.progress}`">{{ progressEnum[detail.progress] }}</el-tag>
            </span>
          <span>政策分类：{{ policyClassificationEnum[detail.type] }}
            </span>
          <span>补贴金额：{{ detail.maxCapital || "" }} {{ detail.maxCapital ? "万元" : "未定" }}
            </span>
          <span>申报倒计时：{{ detail.daysLeft }}
            </span>
          <span>浏览量：{{ detail.visitCount }}
            </span>
          <el-button v-if="detail.isShow" class="operation" @click.stop="gotoVerifyRouter(`${detail.router}?code=${detail.id}`)">立即测评</el-button>
        </div>
        <div class='details-content'>
          <div class="content-title">申报流程</div>
          <div class="ql-editor" style="min-height: auto; max-height: none">
            <article v-html="detail.content"/>
          </div>
          <div class="content-title">奖励补贴</div>
          <div class="ql-editor" style="min-height: auto; max-height: none">
            <article v-html="detail.condition"/>
          </div>
          <div class="content-title">相关附件</div>
          <div class="content-files">
            <p v-for="(item, index) in detail.files" :key="index"><a :href="`${item.url}`" download="">{{index+1}}.{{item.originalFilename}}</a></p>
          </div>
        </div>
      </div>
      <div class="hot-project">
        <h2>热门测评项目
        </h2>
        <div class="project-item" v-for="(item,index) in hotList" :key="index">
          <h3>{{ item.name }}</h3>
          <el-link v-if="item.isShow" :underline="false" class="operation"
                   @click.stop="gotoVerifyRouter(`${item.router}?code=${item.id}`)">立即测评</el-link>
        </div>
      </div>

    </div>
    <BottomView/>
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, onMounted, ref} from "vue";
import {useRoute,useRouter} from 'vue-router';
import apiProject from "@/api/project";
import {ElMessageBox} from "element-plus";
import {isRelogin} from "@/utils/request";
import useUserStore from "@/store/modules/user";

const userStore = useUserStore()
const router = useRouter()
const progressEnum = ref({})
const officesEnum = ref({})
const districtEnum = ref({})
const policyClassificationEnum = ref({})

let route = useRoute();

let detail = ref({});
const hotList = ref([])

onBeforeMount(() => {
  progressEnum.value = JSON.parse(sessionStorage.getItem('progressEnum'))
  officesEnum.value = JSON.parse(sessionStorage.getItem('officesEnum'))
  districtEnum.value = JSON.parse(sessionStorage.getItem('districtEnum'))
  policyClassificationEnum.value = JSON.parse(sessionStorage.getItem('policyTypesEnum'))
})

onMounted(() => {
  let code = route.query.code;
  apiProject.getDetail(code).then(response => {
    detail.value = response.data
  })
  apiProject.getHotList().then(resp => {
    hotList.value = resp.data || []
  })
})

const gotoVerifyRouter = (path) => {
  event.stopPropagation(); // 显式阻止事件冒泡
  if (userStore.id != null) {
    router.push(path);
  } else {
    ElMessageBox.confirm('请您先登录系统，再继续操作!', '系统提示', {
      confirmButtonText: '登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      isRelogin.show = false;
      useUserStore().logOut().then(() => {
        useUserStore().showLogin()
      })
    }).catch(() => {
      isRelogin.show = false;
      router.push("/project-declaration");
    });
  }
}
</script>

<style lang="scss" scoped>
.project-main-container {
  width: 1200px;
  margin: 30px auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  .details-container {
    width: 875px;
    text-align: left;
    background-color: rgba(255, 255, 255, 1);
    padding: 15px 30px 35px 30px ;
    box-sizing: border-box;

    .details-title {
      height: 33px;
      line-height: 33px;
      color: rgba(16, 16, 16, 1);
      font-size: 24px;
      margin: 20px 0 40px 0;
    }

    .details-tag {
      width: 829px;
      height: 130px;
      padding-top: 30px;
      background-color: rgba(250, 250, 250, 1);
      position: relative;

      .operation {
        position: absolute;
        top: 70px;
        right: 40px;
        width: 100px;
        height: 36px;
        line-height: 15px;
        border-radius: 4px;
        background-color: #CBB486;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        border: none;
      }

      span {
        display: inline-block;
        width: 46%;
        margin-left: 4%;
        height: 23px;
        margin-bottom: 20px;
        line-height: 23px;
        color: rgba(51, 51, 51, 1);
        font-size: 16px;
        text-align: left;

        .el-tag {
          width: 75px;
          height: 24px;
          line-height: 22px;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          vertical-align: middle;
          margin: 0;
          padding: 0;
        }

        .progress-1 {
          //border: 1px solid rgba(153, 153, 153, 1);
          background-color: #D5DDE8;
          color: #398AFD;
        }

        .progress-2 {
          //border: 1px solid rgba(13, 97, 166, 1);
          background-color: #D5DDE8;
          color: #398AFD;
        }

        .progress-3 {
          //border: 1px solid rgba(46, 90, 166, 1);
          background-color: #D5DDE8;
          color: #398AFD;
        }
      }
    }

    .details-content {
      width: 829px;

      .content-title {
        width: 814px;
        height: 39px;
        border-left: 5px solid #CBB486;
        line-height: 39px;
        background-color: rgba(250, 250, 250, 1);
        text-align: left;
        color: rgba(51, 51, 51, 1);
        font-size: 16px;
        margin-top: 30px;
        padding-left: 15px;
      }

      .content-files {
        padding: 20px 0;

        p{ margin: 0;padding: 0; line-height: 25px}

        a {
          display: block;
          line-height: 30px;
          color: rgba(48, 139, 216, 1);
          font-size: 14px;
          text-align: left;
        }
      }
    }
  }

  .hot-project {
    width: 299px;

    h2 {
      width: 284px;
      padding-left: 15px;
      height: 56px;
      line-height: 56px;
      background-color: rgba(255, 255, 255, 1);
      color: #CBB486;
      font-size: 16px;
      text-align: left;
      margin: 0;
    }

    .project-item {
      width: 279px;
      padding: 10px;
      background-color: rgba(255, 255, 255, 1);
      margin-top: 10px;

      h3 {
        color: rgba(51, 51, 51, 1);
        font-size: 16px;
        font-weight: normal;
        margin: 0 0 15px 0;
      }

      .operation {
        width: 269px;
        height: 36px;
        line-height: 15px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 1);
        color: #CBB486;
        font-size: 14px;
        text-align: center;
        border: 1px solid #CBB486;
      }
      .operation:hover {
        background-color: #CBB486;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
</style>
