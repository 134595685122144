<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评报告',link:''}]"/>
      <div>
        <div class='container-title'>以下是您参与的各项认定项目的系统初审结果</div>
        <el-row class="el-row">
          <el-col :span="6" v-for="(item,index) in result" :key="index">
            <div class="box">
              <div class="name">
                {{ item.title }}
                <p style="margin-top: 5px">测评结果</p>
              </div>
              <div class="box-score">
                <span v-if="notShow('total',item.evaluationType)">
                   {{ Number(item.total) !== Math.round(item.total) ? item.total.toFixed(1) : item.total }}分
                </span>
              </div>
              <div v-if="notShow('success',item.evaluationType)">
                <div v-if="item.success" class="box-score-en">符合申请标准</div>
                <div v-else class="box-score-de">不符合申请标准</div>
              </div>
              <div v-else-if="notShow('comment',item.evaluationType)">
                <div class="box-score-de">{{ item.comment }}</div>
              </div>
              <div v-else>
                <div class="box-score-en"></div>
              </div>
              <div class="box-btn">
                <el-button class="btn"
                           @click="goNumber(item.evaluationType,
                           Number(item.total) !== Math.round(item.total) ? item.total.toFixed(1)  : item.total,
                           item.type)"
                           round>查看测评报告
                </el-button>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="un-item">
        <div class='container-title'>您还可以参与以下政策的测评</div>
        <el-row class="el-row">
          <el-col :span="6" v-for="(item,index) in unResult" :key="index">
            <div class="box">
              <div class="name">
                {{ item.name }}
              </div>
              <div class="box-filter">
                <div class="un-box-btn">
                  <router-link :to="`${item.router}?code=${item.id}`">
                    <el-button round>去测评</el-button>
                  </router-link>
                </div>
              </div>
            </div>

          </el-col>
        </el-row>
      </div>

    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "result"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import router from "@/router";
import {getResultList} from "@/api/authentication";

const hotline = ref(process.env.VUE_APP_HOTLINE)
const result = ref([])
const unResult = ref([])

onBeforeMount(() => {
  getResultList().then((res) => {
    result.value = res.data.testedProjects
    unResult.value = res.data.unTestedProjects
  }).catch(() => {
  })
})

const urlMap = {
  '4': 'condition_tech_smes',
  '5': 'condition_high_new_tech',
  '8': 'condition_innovate_smes',
  '9': 'condition_spec_ultra_new_smes',
  '10': 'condition_sh_tech_center',
  '11': 'condition_high_new_tech_transform',
  '12': 'condition_sh_patent_polit_enterprise',
  '13': 'condition_sh_patent_polit_public',
  '14': 'condition_sh_patent_demo_enterprise',
  '15': 'condition_sh_patent_demo_public',
  '16': 'carrier-construction',
  '17': 'incubator',
  '18': 'reduce-costs',
  '19': 'competency-projects',
  '20': 'high-growth-projects',
  '21': 'acceptance-project',
  '22': 'shanghai-innovation-fund',
  '23': 'shanghai-advanced-service',
  '24': 'shanghai-innovation-voucher',
  '25': 'shanghai-new-research-institution',
  '26': 'shanghai-integrated-circuit-prize',
  '27': 'shanghai-entrepreneurial-internship',
  '28': 'shanghai-start-up-insurance-guide',
  '29': 'shanghai-entrepreneurial-teams-venue-fee',
  '30': 'shanghai-first-time-entrepreneurship-once-subsidy',
  '31': 'shanghai-park-intellectual-property',

  '32': 'techolony-development-special',
  '33': 'techolony-development-special-fund',
  '34': 'technolony-development-special-electronic',
  '35': 'promote-development-of-ic-and-communication',
  '36': 'promote-development-of-medicine',
  '37': 'promote-development-of-ai',
  '38': 'promote-development-of-highend-equipment',
  '39': 'big-enterprise-innovation-center',
  '40': 'enterprise-research-center',
  '41': 'technology-development-fund-digit',
  '42': 'techolony-innovation-vouncher',
  '43': 'techolony-development-fund-loan',
  '44': 'pearl-plan-talent',
  '45': 'pearl-plan-leading-talent',
  '46': 'pearl-plan-elite-talent',
  '47': 'pearl-plan-engineer',
  '48': 'support-overseas-returnee',
  '49': 'pearl-plan-technician',

  '50': 'zhangjiang-science-city-special-growth',
  '51': 'zhangjiang-science-city-special-new-equity-capital',
  '52': 'zhangjiang-science-city-special-equity-capital',

  '53': 'promote-investment',
}
const notShow = (type, typeCode) => {
  let flag = true
  const typeName = urlMap[typeCode]
  switch (type) {
    case 'total' : {
      const total = [
        'condition_high_new_tech_transform',
        'incubator',
        'reduce-costs',
        'competency-projects',
        'high-growth-projects',
        'acceptance-project',
        'shanghai-innovation-fund',
        'shanghai-advanced-service',
        'shanghai-innovation-voucher',
        'shanghai-new-research-institution',
        'shanghai-integrated-circuit-prize',
        'shanghai-entrepreneurial-internship',
        'shanghai-start-up-insurance-guide',
        'shanghai-entrepreneurial-teams-venue-fee',
        'shanghai-first-time-entrepreneurship-once-subsidy',
        'shanghai-park-intellectual-property',

        'techolony-development-special',
        'techolony-development-special-fund',
        'technolony-development-special-electronic',
        'promote-development-of-ic-and-communication',
        'promote-development-of-medicine',
        'promote-development-of-ai',
        'promote-development-of-highend-equipment',
        'big-enterprise-innovation-center',
        'enterprise-research-center',
        'technology-development-fund-digit',
        'techolony-innovation-vouncher',
        'techolony-development-fund-loan',
        'pearl-plan-talent',
        'pearl-plan-leading-talent',
        'pearl-plan-elite-talent',
        'pearl-plan-engineer',
        'support-overseas-returnee',
        'pearl-plan-technician',

        'zhangjiang-science-city-special-growth',
        'zhangjiang-science-city-special-new-equity-capital',
        'zhangjiang-science-city-special-equity-capital',

        'promote-investment',
      ]
      flag = !total.includes(typeName)
      break
    }
    case 'comment':
      flag = typeName === 'condition_sh_tech_center'
      break
    case 'success': {
      const success = [
        'condition_sh_tech_center',
        'condition_sh_patent_polit_enterprise',
        'condition_sh_patent_polit_public',
        'condition_sh_patent_demo_enterprise',
        'condition_sh_patent_demo_public',
      ]
      flag = !success.includes(typeName)
      break
    }

  }
  return flag
}
const goNumber = (type, score, carrierConstructionType) => {
  let push = `/authentication/${urlMap[type]}?score=${score}&evaluationType=${type}`
  if (urlMap[type] == 'carrier-construction') {
    push = `/authentication/${urlMap[type]}?score=${score}&evaluationType=${type}&carrierConstructionType=${carrierConstructionType}`
  }
  router.push(push);
}
</script>


<style scoped lang="scss">
.result-main-container {
  text-align: center;

  .title {
    font-size: 75px;
    color: #F2F2F2;
    font-weight: bold;
    position: relative;
    text-align: left;
    margin: 0;

    .text {
      font-size: 32px;
      color: #2C3E50;
      position: absolute;
      left: 50px;
      top: 25px;
    }
  }

  .el-col {
    margin-bottom: 50px;
  }

  .box {
    width: 240px;
    height: 256px;
    background-image: url("@/assets/image/result-background.png");
    padding: 40px 0 20px;
    color: #ffffff;
    font-size: 16px;
    background-repeat: no-repeat;
    background-size: cover;

    .name {
      width: 220px;
      height: 110px;
      margin: 0 auto;
    }

    .box-score {
      margin: 0 0 25px 0;
      font-size: 23px;
      min-height: 29px;
    }

    .box-score-en {
      color: #ABF287;
      height: 22px;
    }

    .box-score-de {
      color: #F7A6A6;
    }

    .box-btn {
      margin-top: 20px;
      color: #3170FD;

      .btn {
        font-size: 12px;
        height: 25px;
        cursor: pointer;
        border: none;
      }
      .btn:hover{
        color: rgba(255, 255, 255, 1);
        background: rgba(49, 112, 253, 1);
        border:none;
      }

    }
  }

  .container-title {
    text-align: left;
    font-size: 20px;
    height: 32px;
    line-height: 32px;
    color: #333333;
    border-left: 6px solid #308bd8;
    padding-left: 10px;
    margin-bottom: 32px;
  }

  .un-item {
    .box {
      width: 240px;
      height: 256px;
      background-image: url("@/assets/image/result-background.png");
      background-repeat: no-repeat;
      background-size: cover;
      padding: 40px 0 20px;
      color: #ffffff;
      font-size: 16px;
      position: relative;
      z-index: 1;
    }

    .box-filter {
      width: 227px;
      height: 318px;
      top: 4px;
      left: 6px;
      background-image: url("@/assets/image/result-background-filter.png");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      z-index: 2;
      padding-top: 205px;
      box-sizing: border-box;

      .un-box-btn {
        .el-button {
          width: 98px;
          height: 34px;
          border-radius: 14px;
          background-color: rgba(255, 255, 255, 1);
          color: rgba(49, 112, 253, 1);
          font-size: 12px;
        }

        .el-button:hover{
          color: rgba(255, 255, 255, 1);
          background: rgba(49, 112, 253, 1);
          border:none;
        }
      }
    }
  }
}
</style>
