<template>
  <div class="header-container">
    <div class="header">
      <div class="logo" @click='$router.push({path:"/"})'></div>



      <div class="un-login" v-if='userStore.id===null'>
        <span> <img src="../assets/avatar.png" height="48" width="48" style="padding-top: 12px"/></span>
        <router-link to="#" @click="userStore.showLogin()">登录</router-link>
        <span>|</span>
        <router-link to="#" @click="dialogRegister = true">注册</router-link>

      </div>
      <div class='user-info' v-if='userStore.id!=null'>
        <p>{{ userStore.companyName }}</p>
        <p class="title">
          <router-link to="/account/"> 用户中心</router-link>
          |<span @click="logout">退出</span></p>
      </div>
    </div>
  </div>
  <div class="fill"></div>

  <el-dialog v-model="userStore.dialogLogin" width="500" z-index="999">
    <Login @showRegister="showRegister"/>
  </el-dialog>

  <el-dialog v-model="dialogRegister" width="500" z-index="999">
    <Register @showLogin="showLogin" @showRegister="showRegister" @cancelRegister="dialogRegister = false"/>
  </el-dialog>
</template>
<script>

</script>
<script setup>
import {ElMessageBox} from 'element-plus'
import useUserStore from '@/store/modules/user'
import {toRefs, defineProps, onMounted, ref} from 'vue'
import Login from '@/components/Login'
import Register from '@/components/Register'
import {User} from "@element-plus/icons-vue";

const props = defineProps({
  //子组件接收父组件传递过来的值
  title: String,
})
//使用父组件传递过来的值
const {title} = toRefs(props)
const userStore = useUserStore()

function logout() {
  ElMessageBox.confirm('确定退出系统吗？', '系统提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    userStore.logOut().then(() => {
      location.href = '/';
    })
  }).catch(() => {
  });
}

onMounted(() => {
  // console.log(title)
})

const dialogRegister = ref(false)
const showLogin = () => {
  userStore.showLogin()
  dialogRegister.value = false
}

const showRegister = () => {
  userStore.hideLogin()
  dialogRegister.value = true
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
:root {
  --header-container-height: 72px;
}
</style>
<style lang="scss" scoped>
:deep .el-overlay {
  z-index: 999999 !important;
}
@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.scrolling-text {
  animation: scroll 2s linear infinite;
}

.header-container {
  width: 100%;
  height: var(--header-container-height);
  background-color: #FFFFFF;
  border-bottom: 1px solid #E4C895;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  color: #101010;

  .header {
    width: 1200px;
    height: var(--header-container-height);
    margin: 0 auto;

    .logo {
      width: 224px;
      height: var(--header-container-height);
      float: left;
      margin-top: 10px;
      cursor: pointer;
      background-size: contain;
      background-image: url("../assets/image/logo.png");
      background-repeat: no-repeat;

      span {
        height: 28px;
        width: 224px;
        display: inline-block;
        background: #E4C895;
        color: #101010;
        line-height: 28px;
        font-size: 14px;
        text-align: center;
      }

      h1 {
        margin: 0;
        padding: 0;
        height: 39px;
        color: #BDA26D;
        line-height: 39px;
        font-size: 28px;
      }
    }

    .title {

      //width: 500px;
      float: left;
      //margin-top: 18px;
      text-align: left;
      //padding-left: 180px;

      span.line {
        float: left;
        padding: 0;
        width: 18px;
        height: 18px;
        margin-top: 29px;
        background-image: url("../assets/image/icon/vip.png");
        background-size: contain;
        background-repeat: no-repeat;

      }

      span.text {
        //height: 18px;
        padding: 0 18px 2px 2px;
        //background-color: #FFB419;
        display: inline-block;
        color: #FFB419;
        font-size: 18px;
        //vertical-align: top;
        //margin-left: 10px;

      }
    }

    .un-login {
      float: right;
      line-height: var(--header-container-height);

      a {
        font-size: 16px;
        color: #101010;
        text-decoration: none;
        //display: inline-block;
        //line-height: 25px;
      }

      span {
        display: inline-block;
        padding: 0 15px;
        vertical-align: top;
      }
    }

    .user-info {
      float: right;
      text-align: right;
      margin-top: 8px;

      p {
        font-size: 16px;
        color: #101010;
        margin: 0;
        padding: 3px 0;
        width: 100%;
        text-align: right;

        a {
          font-size: 16px;
          color: #101010;
          display: inline-block;
          margin-right: 8px;
          text-decoration: none;
        }

        a:hover {
          color: #BDA26D;
        }

        span {
          font-size: 16px;
          margin-left: 8px;
          color: #101010;
          cursor: pointer;
        }

        span:hover {
          color: #BDA26D;
        }
      }

    }

  }
}

.fill {
  width: 100%;
  height: var(--header-container-height);
}


@keyframes blink-red {
  0% {
    width: 0
  }

  100% {
    width: 100%
  }
}


.blinking-text-red {
  font-weight: bold;
  color: #ff6600;
  overflow: hidden;
  white-space: nowrap;
  animation: blink-red 3s infinite;
}

</style>
