<template>
  <div class="common-layout fixed">
    <el-container>
      <HeaderView title=""/>
      <el-main>
        <div class="mobile-news">
          <div class="mobile-news-title">News Headlines<span class="text">资讯头条</span></div>
          <div style='clear: both'></div>
          <div class='mobile-news-list'>
            <router-link v-for="(item) in newsTopList" :key="item.id" :to="`/mobile/news-details?code=${item.id}`">
              <el-row>
                <el-col :span="18"><span class="mobile-news-list-tit">{{ item.title }}</span> </el-col>
                <el-col :span="6"><span class="mobile-news-list-date">{{ item.releaseDate.substring(0, 10) }}</span></el-col>
              </el-row>
            </router-link>
          </div>
          <div style='clear: both'></div>
          <div class="mobile-news-tab">
            <span :class='`${newsType===1?"active":""}`' @click='newTypeChange(1)'>产业政策</span>
            <span :class='`${newsType===2?"active":""}`' @click='newTypeChange(2)'>活动宣传</span>
          </div>
          <div style='clear: both'></div>
          <div class="mobile-news-list">
            <router-link v-for="(item) in pageData" :key="item.id" :to="`/mobile/news-details?code=${item.id}`" >
              <el-row>
                <el-col :span="18"><span class="mobile-news-list-tit">{{ item.title }}</span> </el-col>
                <el-col :span="6"><span class="mobile-news-list-date">{{ item.releaseDate.substring(0, 10) }}</span></el-col>
              </el-row>
            </router-link>
          </div>
          <el-pagination
              v-model:current-page="pagination.currentPage"
              v-model:page-size="pagination.pageSize"
              :page-sizes="pagination.pageSizes"
              :small="pagination.small"
              :disabled="pagination.disabled"
              :background="pagination.background"
              popper-class=""
              layout="prev,pager,next"
              :total="pagination.total"
              pager-count="5"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"/>
        </div>
      </el-main>
      <el-footer>
        <FooterView/>
      </el-footer>
    </el-container>
  </div>
</template>

<script setup>
import HeaderView from '@/components/mobile/Header.vue'
import FooterView from '@/components/mobile/Footer.vue'

import {onMounted, reactive, ref} from 'vue'
// 引入样式
import {Swiper, SwiperSlide} from 'swiper/vue' // swiper 所需组件
import {Autoplay, Navigation, A11y} from 'swiper'// 分页器
// import 'swiper/swiper-bundle.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import router from "@/router";
import {getNewsCarousel, getNewsPage, getNewsTop} from "@/api/news";

const modules = [Autoplay, Navigation, A11y]
const navigation = ref({
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev'
})

const newsType = ref(1)


const baseUrl = process.env.VUE_APP_BASE_API
const carouselList = ref()
const newsTopList = ref()
const pageData = ref()

const onClickCart = function (id) {
  router.push('news-details?code=' + id);
}

const pagination = reactive({
  currentPage: 1,
  pageSize: 20,
  small: false,
  background: true,
  disabled: false,
  total: 0,
  pageSizes: [10, 20, 30]
})

onMounted(() => {
  //document.title = "企业综合服务系统"

  getNewsCarousel().then(response => {
    carouselList.value = response.data
  })

  getNewsTop().then(response => {
    newsTopList.value = response.data
  })
  searchList()
})

const newTypeChange = (type) => {
  newsType.value = type
  searchList()
}

const handleSizeChange = () => {
  searchList()
};
const handleCurrentChange = () => {
  searchList()
};

const searchList = () => {
  getNewsPage(pagination.pageSize, pagination.currentPage, newsType.value).then(response => {
    pageData.value = response.rows;
    pagination.total = response.total;
  });
}

</script>

<style lang="scss" scoped>

.common-layout {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;

  .el-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.fixed {
    .el-container {
      flex: 1;
    }

    .el-footer {
      --el-footer-height: unset;
    }
  }

  .el-footer {
    --el-footer-padding: 0;
    margin-top: 100px;
  }

  .el-main {
    padding: 0 10px;


    .mobile-news {
      .mobile-news-title {
        font-size: 40px;
        color: #F2F2F2;
        font-weight: bold;
        position: relative;
        text-align: left;
        margin: 15px 0;

        .text {
          font-size: 28px;
          color: #BDA26D;
          position: absolute;
          left: 27px;
          top: 0;
        }
      }

      .mobile-news-list {
        text-align: left;
        width: 100%;
        font-size: 16px;

        a {
          padding: 10px 0;
          display: inline-block;
          text-decoration: none;
          color: #101010;
          width: 100%;

          span.mobile-news-list-tit {
            display: inline-block;
            /*强制文本在一行内显示*/
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          }

          span.mobile-news-list-date {
            float: right;
            color: #8C9093;
          }
        }
      }

      .mobile-news-tab{
        text-align: left;
        padding: 50px 0 30px 0;

        span{
          font-size: 18px;
          width: 135px;
          height: 35px;
          line-height: 35px;
          color: #101010;
          border:#E4C895 1px dashed;
          background: #FFFFFF;
          text-align: center;
          display: inline-block;
          font-weight: bold;
          cursor: pointer;
        }

        .active{
          color: #FFFFFF;
          background-color: rgba(228, 200, 149, 100);
        }
      }

    }
  }
}
.el-pagination{
  justify-content: center;
}
</style>
