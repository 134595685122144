<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>浦东新区支持生物医药产业高质量发展专项</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>工商注册、税收户管地均在浦东新区，且经营状态正常、信用记录良好的生物医药、新材料企业。企业应在生产经营过程中做好安全生产工作。
              </td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>
                （一）支持创新药械研发
                <br>1、对新进入临床Ⅱ期、Ⅲ期研究的一类新药（含中医药）研发项
                目，分别给予 100 万元、200 万元一次性奖励。
                2、对获批上市的一类新药（含中医药）给予 1000 万元一次性奖
                励（包括临床Ⅱ期、Ⅲ期奖励），对获批上市的二类新药（含中医药）
                给予 500 万元一次性奖励。
                3、对在全国同类仿制药中首个通过一致性评价的，给予 200 万
                元的一次性奖励。
                4、对获得注册证的三类医疗器械、三类体外诊断试剂分别给予
                100 万元、50 万元一次性奖励。
                <br>（二）支持产业链协同联动
                <br>1、对药品、医疗器械上市前，委托区内企业生产临床用样品（药
                物）和临床用样机（器械），委托与受委托方无关联的，分别给予双
                方试生产合同金额各15%的支持，其中药品每家企业不超过100万元，
                医疗器械不超过 50 万元。
                2、对药品获批上市或医疗器械获得注册证后一年内委托区内企
                业生产，委托与受委托方无关联的，分别给予双方生产合同金额各
                10%的支持，单个药品上市许可持有人、医疗器械注册人企业年度最
                高资助 500 万元，单个生产企业年度最高资助 1000 万元。
                3、对药品获批上市或医疗器械获得注册证后一年内委托区外企
                业生产的，药品、医疗器械注册证以及税收、统计均落地浦东的，给
                予委托方合同金额 10%的支持，单个药品上市许可持有人、医疗器械
                注册人企业年度最高资助 500 万元。
                4、对获得 FDA（美国食品药品监督管理局）、EMA（欧洲药品
                管理局）、WHO（世界卫生组织）等国际药品生产质量管理规范认证
                的企业，给予 200 万元一次性奖励。
                <br>（三）配套支持
                <br>对获得上海市级政策支持的生物医药及新材料项目，可在项目验
                收完成后，给予一定配套奖励。
                对明确配套要求的，按市级部门立项时明确的区级支持要求给予
                配套支持。对未明确配套要求的，经区相关部门协商一致，按照企业
                获得的上海市级项目扶持资金总额，给予不超过企业获得上海市级项
                目扶持资金 50%的奖励。同一项目各级政府奖励和资助总额不超过企
                业总投入的 50%，最高 5000 万元。

              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPromoteDevelopmentOfMedicine"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
