<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition high-growth-projects-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">浦东新区关于促进技能人才发展的若干意见（加大企业首席技师、技能大师工作室支持力度）</div>
      <BasicInfo ref="basicInfo" :is-financial="false" :is-int-prop="false"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="申报类型："
                            required prop="applyType">
                <el-radio-group v-model="formData.applyType">
                  <el-radio :label="1">首席技师</el-radio>
                  <el-radio :label="2">技能大师工作室</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="在一线岗位上直接从事技术技能性工作或技能培训工作，掌握关键技术，能够带领团队解决生产过程中的实际问题，或擅长推广新技术新工艺、总结先进操作法等，或在传技带徒、培养技能人才等方面成绩显著。"
                            required prop="isSkillful" v-if="formData.applyType===1">
                <el-radio-group v-model="formData.isSkillful">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="具有国家职业资格或技能等级评价二级（技师）2年、一级（高级技师）1年以上。对于从事职业（工种）无国家职业资格标准或未进行技能等级认定评价的，如在本岗位长期工作、贡献突出、技能在同行业中处于领先水平并有较高声誉，也可聘为首席技师。上述情况需在申请报告中重点说明其个人技能水平及在行业中的影响、业绩等情况。"
                            required prop="hasNationalCertification" v-if="formData.applyType===1">
                <el-radio-group v-model="formData.hasNationalCertification">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="首席技师由所在单位根据上述条件自主选聘。"
                            required prop="isOwnHired" v-if="formData.applyType===1">
                <el-radio-group v-model="formData.isOwnHired">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="工作室有明晰的管理制度、工作职责、目标任务，具备一定数量技能人才，一般不少于3名。有实际工作场所进行技能攻关、带徒传技等活动，为企业技术革新、提升经济效益等起到积极作用。"
                            required prop="hasEnoughTalents" v-if="formData.applyType===2">
                <el-radio-group v-model="formData.hasEnoughTalents">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="工作室带头人是本行业（领域）中技能拔尖、技艺精湛的高技能人才，具有较强创新创造能力和社会影响力，在带徒传技方面经验丰富，身体健康，能够承担工作室日常工作"
                            required prop="isLeaderSkillful" v-if="formData.applyType===2">
                <el-radio-group v-model="formData.isLeaderSkillful">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="建立健全首席技师或技能大师工作室工作制度，明确具体的选拔聘用程序和办法，落实专门机构和专人组织实施相关工作。"
                            required prop="hasSoundSystem">
                <el-radio-group v-model="formData.hasSoundSystem">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="规范首席技师选拔聘用，坚持德才兼备、好中选优、公开透明的原则，严格按照既定条件及程序选拔、聘用首席技师。"
                            required prop="hasGoodEmployment">
                <el-radio-group v-model="formData.hasGoodEmployment">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="为首席技师或技能大师工作室承担技术革新、技术攻关等提供资金、场地、设备、设施、人员等方面的支持。"
                            required prop="hasSupport">
                <el-radio-group v-model="formData.hasSupport">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="落实高技能人才待遇，实行首席技师岗位津贴或给予其它薪酬待遇激励，并为首席技师交流学习、培训进修、体检休养等创造条件。"
                            required prop="hasGoodPayment">
                <el-radio-group v-model="formData.hasGoodPayment">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="重视高技能人才队伍建设，按规定提取职工教育经费并对职工开展培训，积极开展企业内高技能人才培养和评价、高师带徒、技能竞赛、技师继续培训等活动。"
                            required prop="hasGoodTraining">
                <el-radio-group v-model="formData.hasGoodTraining">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPearlPlanTechnician"
}
</script>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import MenuBar from '@/components/MenuBar.vue'
import BasicInfo from '@/components/BasicInfo.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'pudongPearlPlanTechnician'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({
})
onMounted(() => {
  getProjectPudongByPath()
})
const getProjectPudongByPath = () => {
  companyApi.getProjectPudongByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveProjectPudongByPath(urlPath, company).then((resp) => {
    if (resp.code === 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getProjectPudongByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitProjectPudongByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

</script>
<style scoped lang="scss">
.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
}
</style>
