<template>
  <div class="breadcrumb-container">
    <el-breadcrumb :separator-icon="ArrowRight" class="flex-flex-start">
      <el-breadcrumb-item>
        <div class="flex-flex-start">
          <img src="../assets/image/icon/icon-address.png" alt=""/>
          您所在的位置：
          <router-link to="/" style="font-weight: normal">首页</router-link>
        </div>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-for="(item,index) in items" :key="index">
        <router-link v-if="item.link" :to="item.link" style="font-weight: normal">{{ item.name }}</router-link>
        <span v-else>{{ item.name }}</span></el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider/>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb"
}
</script>
<script setup>
import {ArrowRight} from '@element-plus/icons-vue'
import {defineProps} from "vue";

defineProps({
  //子组件接收父组件传递过来的值
  items: {
    type: Array,
    default: function () {
      return []
    }
  }
})
</script>

<style scoped>

</style>
