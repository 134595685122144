<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/declaration-announcement"/>
    <div class='details-main-container'>
      <Breadcrumb :items="[{name:'申报公告',link:'/declaration-announcement'},{name:'公告详情',link:''}]"/>
      <h1 class="details-title">{{ newsDetail.title }}</h1>
      <div class="details-tag">
        <span>信息来源：{{ newsDetail.source }}</span>
        <span>发布日期：{{ parseTime(newsDetail.releaseDate,'{y}-{m}-{d}') }}</span>
      </div>
      <div class='details-content'>
        <div class="ql-editor" style="min-height: auto; max-height: none;">
          <article v-html="newsDetail.detail"/>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import {onMounted, ref} from "vue";
import {useRoute} from 'vue-router';
import {getNewsDetail} from "@/api/news";
import Breadcrumb from "@/components/Breadcrumb";
import {parseTime} from "@/utils/coreyee";

let route = useRoute();

let newsDetail = ref({});

onMounted(() => {
  // //document.title = "企业综合服务系统"

  let code = route.query.code;
  getNewsDetail(code).then(response => {
    newsDetail.value = response.data
  })

})
</script>

<style lang="scss" scoped>

</style>
