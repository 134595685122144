<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>张江科学城专项政策（对获得股权融资的企业给予支持）</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>申报企业符合张江科学城产业导向，股权融资事件完成于2023年1月1日至2024年12月31日，其中生物医药企业为2021年1月1日至2022年12月31日
              </td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>申报企业须为2014年1月1日以后注册成立的企业
              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>申报企业应在张江加大投资、强化布局，原则上实际经营地须在张江科学城
              </td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>投资机构须在中国证券投资基金业协会备案，或经营范围含股权投资、创业投资、股权投资管理、创业投资管理，以国家市场监督管理部门核发的营业执照为准
              </td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>是否首轮融资
              </td>
              <td v-html='(result[4] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
          <br/>
          <div>
            <p v-if="(result[4] || {}).satisfy===1">对获得首轮股权融资的企业，经综合评定，按照不超过实际到位资金的5%给予支持，最高不超过<span style="color: #ff0000;font-weight: bold"> 200万元 </span>；</p>
            <p v-else>对获得后续轮股权融资的企业，经综合评定，按照不超过实际到位资金的2%给予支持，最高不超过<span style="color: #ff0000;font-weight: bold"> 400万元 </span>；</p>
            <p>每家企业年度资助额最高不超过 <span style="color: #ff0000;font-weight: bold">600</span> 万元。根据资金到位情况，给予事后支持。</p>
          </div>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "zhangjiangScienceCitySpecialEquityCapital"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
