<template>
  <el-input v-model="inputValue"
            @keyup.prevent="inputValue = oninput(inputValue)">
    <template v-if="append!==''" #append>{{ append }}</template>
  </el-input>
</template>

<script setup>
import {ref, watch} from "vue";

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  /* 编辑器的内容 */
  modelValue: {
    type: String,
    default: ''
  },
  append: {
    type: String,
    default: ''
  },
  isDecimal: { // 默认小数
    type: Boolean,
    default: true
  },
  isPositive: { // 默认正数
    type: Boolean,
    default: true
  },
  digit: { // 小数位默认 2
    type: Number,
    default: 2
  }
})
const inputValue = ref(props.modelValue);
watch( () => {
  inputValue.value = props.modelValue;
},()=>{});
const oninput = (num) => {
  const initNumber = `${num}`
  const initLen1 = initNumber.substr(0, 1)
  const initLen2 = initNumber.substr(1, 1)
  const negative = !props.isPositive && initNumber.includes('-')
  const addMinusSign = negative && (initLen1 === '-' || initLen1 === '0' && initLen2 === '-')
  let integerReg = /[^0-9]/g
  let decimalReg = /[^\d^\\.]+/g
  const numberReg = props.isDecimal ? decimalReg : integerReg
  const removeZero = negative && initNumber.length === 2 && initNumber.substr(0, 1) !== '-'
  let str = `${num}`
  //正则替换
  str = str.replace(numberReg, '') // 保留数字和小数点
  let len1 = str.substr(0, 1)
  let len2 = str.substr(1, 1)

  if (props.isDecimal) {
    //如果第一位是0，第二位不是点，就用数字把点替换掉
    if (str.length > 1 && len1 == 0 && len2 !== ".") {
      str = str.substr(1, str.length)
    } else if (len1 == 0 && removeZero) {
      str = str.substr(1, str.length)
    }
    //第一位不能是.
    if (len1 === ".") {
      str = ""
    }
    //限制只能输入一个小数点
    if (str.indexOf(".") !== -1) {
      let str_ = str.substr(str.indexOf(".") + 1)
      if (str_.indexOf(".") !== -1) {
        str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1)
      }
    }
    if (str.indexOf('.') !== -1) {// 小数点后只能输两位
      let two = str.substr(str.indexOf("."), str.length)
      if (two.length >= 4) {
        str = str.substr(0, str.indexOf(".") + props.digit + 1)
      }

    }
  } else {
    if (len1 == 0)
      str = str.substr(1, str.length)
  }

  let length = addMinusSign ? 2 : 1
  str = (addMinusSign ? '-' : '') + str
  if (str.length === 0) {
    str = 0
  } else if (str.length === length && len1 == 0) {
    str += ''
  }
  emit('update:modelValue', str)
  return str
}
</script>
<style scoped>

</style>
