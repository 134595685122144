<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition high-growth-projects-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">浦东新区大企业开放创新中心登记申报</div>
      <BasicInfo ref="basicInfo" :is-financial="true" :is-int-prop="false"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="创新中心发起主体须为行业龙头企业或细分领域领军企业。行业龙头企业包括世界500强企业、中国500强企业的前100名、中央企业；细分领域领军企业包括上市公司、胡润全球独角兽活跃投资机构百强、规模居前的细分领域领军企业等。"
                            required prop="isLead">
                <el-radio-group v-model="formData.isLead">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="具备明确的建设方案、赋能规划和专业团队。建设方案须有明确的建设目标、建设主体、资金来源、投入方向等；赋能规划须有清晰的赋能目标、赋能机制、招募计划等；专业团队须有不少于5人的技术、运营、管理等专业人员。"
                            required prop="isProfessional">
                <el-radio-group v-model="formData.isProfessional">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongBigEnterpriseInnovationCenter"
}
</script>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import MenuBar from '@/components/MenuBar.vue'
import BasicInfo from '@/components/BasicInfo.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'pudongBigEnterpriseInnovationCenter'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({
})
onMounted(() => {
  getProjectPudongByPath()
})
const getProjectPudongByPath = () => {
  companyApi.getProjectPudongByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveProjectPudongByPath(urlPath, company).then((resp) => {
    if (resp.code === 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getProjectPudongByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitProjectPudongByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

</script>
<style scoped lang="scss">
.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
}
</style>


copy ShanghaiInnovationFund1.vue PudongTecholonyDevelopmentSpecial.vue
copy ShanghaiInnovationFund1.vue PudongTecholonyDevelopmentSpecialFund.vue
copy ShanghaiInnovationFund1.vue PudongTechnolonyDevelopmentSpecialElectronic.vue
copy ShanghaiInnovationFund1.vue PudongPromoteDevelopmentOfIcAndCommunication.vue
copy ShanghaiInnovationFund1.vue PudongPromoteDevelopmentOfMedicine.vue
copy ShanghaiInnovationFund1.vue PudongPromoteDevelopmentOfAi.vue
copy ShanghaiInnovationFund1.vue PudongPromoteDevelopmentOfHighendEquipment.vue
copy ShanghaiInnovationFund1.vue PudongBigEnterpriseInnovationCenter.vue
copy ShanghaiInnovationFund1.vue PudongEnterpriseResearchCenter.vue
copy ShanghaiInnovationFund1.vue PudongTechnologyDevelopmentFundDigit.vue
copy ShanghaiInnovationFund1.vue PudongTecholonyInnovationVouncher.vue
copy ShanghaiInnovationFund1.vue PudongTecholonyDevelopmentFundLoan.vue
copy ShanghaiInnovationFund1.vue PudongPearlPlanTalent.vue
copy ShanghaiInnovationFund1.vue PudongPearlPlanLeadingTalent.vue
copy ShanghaiInnovationFund1.vue PudongPearlPlanEliteTalent.vue
copy ShanghaiInnovationFund1.vue PudongPearlPlanEngineer.vue
copy ShanghaiInnovationFund1.vue PudongSupportOverseasReturnee.vue
copy ShanghaiInnovationFund1.vue PudongPearlPlanTechnician.vue
