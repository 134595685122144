<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>创业团队孵化场地费补贴</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>经认定的市级创业孵化示范基地成功孵化的创业团队（含个人）
              </td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>创业团队入驻市级创业孵化示范基地孵化，签订书面孵化协议，并支付工位费用
              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>创业团队负责人在孵化协议期内成功创办小微企业、个体工商户、民办非企业、农民专业合作社四类创业组织，且担任创业组织法定代表人（负责人）或合伙人
              </td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ShanghaiInnovationFund"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
