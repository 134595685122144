import PudongTecholonyDevelopmentSpecial from "@/views/conditionForm/pudong/PudongTecholonyDevelopmentSpecial";
import PudongTecholonyDevelopmentSpecialFund from "@/views/conditionForm/pudong/PudongTecholonyDevelopmentSpecialFund";
import PudongTechnolonyDevelopmentSpecialElectronic from "@/views/conditionForm/pudong/PudongTechnolonyDevelopmentSpecialElectronic";
import PudongPromoteDevelopmentOfIcAndCommunication from "@/views/conditionForm/pudong/PudongPromoteDevelopmentOfIcAndCommunication";
import PudongPromoteDevelopmentOfMedicine from "@/views/conditionForm/pudong/PudongPromoteDevelopmentOfMedicine";
import PudongPromoteDevelopmentOfAi from "@/views/conditionForm/pudong/PudongPromoteDevelopmentOfAi";
import PudongPromoteDevelopmentOfHighendEquipment from "@/views/conditionForm/pudong/PudongPromoteDevelopmentOfHighendEquipment";
import PudongBigEnterpriseInnovationCenter from "@/views/conditionForm/pudong/PudongBigEnterpriseInnovationCenter";
import PudongEnterpriseResearchCenter from "@/views/conditionForm/pudong/PudongEnterpriseResearchCenter";
import PudongTechnologyDevelopmentFundDigit from "@/views/conditionForm/pudong/PudongTechnologyDevelopmentFundDigit";
import PudongTecholonyInnovationVouncher from "@/views/conditionForm/pudong/PudongTecholonyInnovationVouncher";
import PudongTecholonyDevelopmentFundLoan from "@/views/conditionForm/pudong/PudongTecholonyDevelopmentFundLoan";
import PudongPearlPlanTalent from "@/views/conditionForm/pudong/PudongPearlPlanTalent";
import PudongPearlPlanLeadingTalent from "@/views/conditionForm/pudong/PudongPearlPlanLeadingTalent";
import PudongPearlPlanEliteTalent from "@/views/conditionForm/pudong/PudongPearlPlanEliteTalent";
import PudongPearlPlanEngineer from "@/views/conditionForm/pudong/PudongPearlPlanEngineer";
import PudongSupportOverseasReturnee from "@/views/conditionForm/pudong/PudongSupportOverseasReturnee";
import PudongPearlPlanTechnician from "@/views/conditionForm/pudong/PudongPearlPlanTechnician";
import PudongPromoteInvestment from "@/views/conditionForm/pudong/PudongPromoteInvestment";

//张江
import ZhangjiangScienceCitySpecialGrowth from "@/views/conditionForm/pudong/zhangjiang/ZhangjiangScienceCitySpecialGrowth";
import zhangjiangScienceCitySpecialNewEquityCapital from "@/views/conditionForm/pudong/zhangjiang/ZhangjiangScienceCitySpecialNewEquityCapital";
import ZhangjiangScienceCitySpecialEquityCapital from "@/views/conditionForm/pudong/zhangjiang/ZhangjiangScienceCitySpecialEquityCapital";


const parentPath = '/condition-form/pudong'

export default [
    {path: `${parentPath}/techolony-development-special`, name: 'techolony-development-special', component: PudongTecholonyDevelopmentSpecial,},
    {path: `${parentPath}/techolony-development-special-fund`, name: 'techolony-development-special-fund', component: PudongTecholonyDevelopmentSpecialFund,},
    {path: `${parentPath}/technolony-development-special-electronic`, name: 'technolony-development-special-electronic', component: PudongTechnolonyDevelopmentSpecialElectronic,},
    {path: `${parentPath}/promote-development-of-ic-and-communication`, name: 'promote-development-of-ic-and-communication', component: PudongPromoteDevelopmentOfIcAndCommunication,},
    {path: `${parentPath}/promote-development-of-medicine`, name: 'promote-development-of-medicine', component: PudongPromoteDevelopmentOfMedicine,},
    {path: `${parentPath}/promote-development-of-ai`, name: 'promote-development-of-ai', component: PudongPromoteDevelopmentOfAi,},
    {path: `${parentPath}/promote-development-of-highend-equipment`, name: 'promote-development-of-highend-equipment', component: PudongPromoteDevelopmentOfHighendEquipment,},
    {path: `${parentPath}/big-enterprise-innovation-center`, name: 'big-enterprise-innovation-center', component: PudongBigEnterpriseInnovationCenter,},
    {path: `${parentPath}/enterprise-research-center`, name: 'enterprise-research-center', component: PudongEnterpriseResearchCenter,},
    {path: `${parentPath}/technology-development-fund-digit`, name: 'technology-development-fund-digit', component: PudongTechnologyDevelopmentFundDigit,},
    {path: `${parentPath}/techolony-innovation-vouncher`, name: 'techolony-innovation-vouncher', component: PudongTecholonyInnovationVouncher,},
    {path: `${parentPath}/techolony-development-fund-loan`, name: 'techolony-development-fund-loan', component: PudongTecholonyDevelopmentFundLoan,},
    {path: `${parentPath}/pearl-plan-talent`, name: 'pearl-plan-talent', component: PudongPearlPlanTalent,},
    {path: `${parentPath}/pearl-plan-leading-talent`, name: 'pearl-plan-leading-talent', component: PudongPearlPlanLeadingTalent,},
    {path: `${parentPath}/pearl-plan-elite-talent`, name: 'pearl-plan-elite-talent', component: PudongPearlPlanEliteTalent,},
    {path: `${parentPath}/pearl-plan-engineer`, name: 'pearl-plan-engineer', component: PudongPearlPlanEngineer,},
    {path: `${parentPath}/support-overseas-returnee`, name: 'support-overseas-returnee', component: PudongSupportOverseasReturnee,},
    {path: `${parentPath}/pearl-plan-technician`, name: 'pearl-plan-technician', component: PudongPearlPlanTechnician,},
    {path: `${parentPath}/promote-investment`, name: 'promote-investment', component: PudongPromoteInvestment,},


    //张江
    {path: `${parentPath}/zhangjiang/zhangjiang-science-city-special-growth`, name: 'zhangjiang-science-city-special-growth', component: ZhangjiangScienceCitySpecialGrowth,},
    {path: `${parentPath}/zhangjiang/zhangjiang-science-city-special-new-equity-capital`, name: 'zhangjiang-science-city-special-new-equity-capital', component: zhangjiangScienceCitySpecialNewEquityCapital,},
    {path: `${parentPath}/zhangjiang/zhangjiang-science-city-special-equity-capital`, name: 'zhangjiang-science-city-special-equity-capital', component: ZhangjiangScienceCitySpecialEquityCapital,},

]
