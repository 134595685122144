//第一批政策拆解
import HighNewTech from "@/views/conditionForm/HighNewTech";
import HighNewTechTransform from "@/views/conditionForm/HighNewTechTransform";
import InnovateSmes from "@/views/conditionForm/InnovateSmes";
import ShPatentDemoEnterprise from "@/views/conditionForm/ShPatentDemoEnterprise";
import ShPatentDemoPublic from "@/views/conditionForm/ShPatentDemoPublic";
import ShPatentPolitEnterprise from "@/views/conditionForm/ShPatentPolitEnterprise";
import ShPatentPolitPublic from "@/views/conditionForm/ShPatentPolitPublic";
import ShTechCenter from "@/views/conditionForm/ShTechCenter";
import SpecUltraNewSmes from "@/views/conditionForm/SpecUltraNewSmes";
import TechSmes from "@/views/conditionForm/TechSmes";

//第四批政策拆解
import ShanghaiInnovationFund from "@/views/conditionForm/ShanghaiInnovationFund.vue";
import ShanghaiAdvancedService from "@/views/conditionForm/ShanghaiAdvancedService.vue";
import ShanghaiInnovationVoucher from "@/views/conditionForm/ShanghaiInnovationVoucher.vue";
import ShanghaiNewResearchInstitution from "@/views/conditionForm/ShanghaiNewResearchInstitution.vue";
import ShanghaiIntegratedCircuitPrize from "@/views/conditionForm/ShanghaiIntegratedCircuitPrize.vue";
import ShanghaiEntrepreneurialInternship from "@/views/conditionForm/ShanghaiEntrepreneurialInternship.vue";
import ShanghaiStartUpInsuranceGuide from "@/views/conditionForm/ShanghaiStartUpInsuranceGuide.vue";
import ShanghaiEntrepreneurialTeamsVenueFee from "@/views/conditionForm/ShanghaiEntrepreneurialTeamsVenueFee.vue";
import ShanghaiFirstTimeEntrepreneurshipOnceSubsidy from "@/views/conditionForm/ShanghaiFirstTimeEntrepreneurshipOnceSubsidy.vue";
import ShanghaiParkIntellectualProperty from "@/views/conditionForm/ShanghaiParkIntellectualProperty.vue";



export default [
    //第一批政策拆解
    {path: '/condition-form/high-new-tech', name: 'high-new-tech', component: HighNewTech,},
    {path: '/condition-form/high-new-tech-transform', name: 'high-new-tech-transform', component: HighNewTechTransform,},
    {path: '/condition-form/innovate-smes', name: 'innovate-smes', component: InnovateSmes,},
    {path: '/condition-form/sh-patent-demo-enterprise', name: 'sh-patent-demo-enterprise', component: ShPatentDemoEnterprise,},
    {path: '/condition-form/sh-patent-demo-public', name: 'sh-patent-demo-public', component: ShPatentDemoPublic,},
    {path: '/condition-form/sh-patent-polit-enterprise', name: 'sh-patent-polit-enterprise', component: ShPatentPolitEnterprise,},
    {path: '/condition-form/sh-patent-polit-public', name: 'sh-patent-polit-public', component: ShPatentPolitPublic,},
    {path: '/condition-form/sh-tech-center', name: 'sh-tech-center', component: ShTechCenter,},
    {path: '/condition-form/spec-ultra-new-smes', name: 'spec-ultra-new-smes', component: SpecUltraNewSmes,},
    {path: '/condition-form/tech-smes', name: 'tech-smes', component: TechSmes,},

    //第四批政策拆解
    {path: '/condition-form/shanghai-innovation-fund', name: 'shanghai-innovation-fund', component: ShanghaiInnovationFund,},
    {path: '/condition-form/shanghai-advanced-service', name: 'shanghai-advanced-service', component: ShanghaiAdvancedService,},
    {path: '/condition-form/shanghai-innovation-voucher', name: 'shanghai-innovation-voucher', component: ShanghaiInnovationVoucher,},
    {path: '/condition-form/shanghai-new-research-institution', name: 'shanghai-new-research-institution', component: ShanghaiNewResearchInstitution,},
    {path: '/condition-form/shanghai-integrated-circuit-prize', name: 'shanghai-integrated-circuit-prize', component: ShanghaiIntegratedCircuitPrize,},
    {path: '/condition-form/shanghai-entrepreneurial-internship', name: 'shanghai-entrepreneurial-internship', component: ShanghaiEntrepreneurialInternship,},
    {path: '/condition-form/shanghai-start-up-insurance-guide', name: 'shanghai-start-up-insurance-guide', component: ShanghaiStartUpInsuranceGuide,},
    {path: '/condition-form/shanghai-entrepreneurial-teams-venue-fee', name: 'shanghai-entrepreneurial-teams-venue-fee', component: ShanghaiEntrepreneurialTeamsVenueFee,},
    {path: '/condition-form/shanghai-first-time-entrepreneurship-once-subsidy', name: 'shanghai-first-time-entrepreneurship-once-subsidy', component: ShanghaiFirstTimeEntrepreneurshipOnceSubsidy,},
    {path: '/condition-form/shanghai-park-intellectual-property', name: 'shanghai-park-intellectual-property', component: ShanghaiParkIntellectualProperty,},

]
