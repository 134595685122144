<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/help"/>
    <div class='help-main-container'>
      <div class="help-title">
         <p v-for="(item,index) in singlePageList" :key="index" @click="handelShow(item)">
          {{ item.title }}
        </p>

      </div>
      <div class="help-content">
        <div class="content-detail">
          <div class="ql-editor">
            <article v-html="singlePage.content"/>
          </div>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from "@/components/Bottom.vue";
import {onMounted, ref} from "vue";
import {getSinglePageList} from "@/api/news";
import MenuBar from "@/components/MenuBar.vue";

const singlePageList = ref([])

let singlePage = ref({});
onMounted(() => {
  getSinglePageList(2).then(response => {
    if (response.data) {
      singlePageList.value = response.data
    }
  }).then(() => {
    singlePage.value = singlePageList.value[0]
  })
})
const handelShow = (item) => {
  singlePage.value = item
}
</script>




<style lang="scss" scoped>
.ql-align-center {
  img {
    max-width: 640px !important;
    height: auto;
  }
}
.ql-editor{
  height: 100% !important;
  max-height: 100% !important;
  min-height:100% !important;
}


.help-main-container {
  width: 1200px;
  margin: 30px auto;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px 30px 35px 30px;
  box-sizing: border-box;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  .help-title {
    width: 200px;
    text-align: left;
    background-color: rgba(255, 255, 255, 1);

    box-sizing: border-box;

    p {
      cursor: pointer;
      padding: 15px;
    }


    p:hover {
      background-color:rgba(63, 133, 255, 0.1);
      color: #3f85ff;
    }
    p:focus {
      background-color:rgba(63, 133, 255, 0.1);
      color: #3f85ff;
    }
  }

  .help-content {
    background-color: rgba(255, 255, 255, 1);
    border-left: 1px solid #f5f6fa;
    box-sizing: border-box;
    width: 970px;
    overflow: hidden;
    text-align: center;

    .content-detail {
      padding: 30px;
      margin: auto;
    }

  }

}


</style>
