<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class="condition innovate-smes-transform-container">
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">创新型中小企业认定</div>
      <BasicInfo ref="basicInfo" :is-financial="true" :is-int-prop="true"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'">
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-row :gutter="50">
                <el-col :span="8">
                  <el-form-item label="主导产品名称（中文）" required prop="productName">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          主导产品是指企业核心技术在产品中发挥重要作用，且产品收入之和占企业同期营业收入比重超过 50%。
                        </template>
                        <span>
                          <span>主导产品名称（中文）</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <el-input v-model="formData.productName"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="从事该产品领域的时间（单位：年）" required prop="productYear">
                    <el-input v-model="formData.productYear"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item v-if="false" label="主导产品类别（4位数代码及名称）" required prop="productCategory">
                    <el-input v-model="formData.productCategory"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="近3年是否获得国家级科技奖励" required prop="nationalLevelRewards">
                <el-radio-group v-model="formData.nationalLevelRewards">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
                <div class="if-true-show" v-if="formData.nationalLevelRewards === 1">
                  如是，请填写：
                  年份：
                  <el-input size="small" v-model="formData.nationalLevelRewardsYear"/>
                  年，
                  名称：
                  <el-input size="small" v-model="formData.nationalLevelRewardsName"/>
                  ，
                  排名：
                  <input-number size="small" v-model="formData.nationalLevelRewardsRank"
                                @update:modelValue="formData.nationalLevelRewardsRank = $event" :is-decimal="false"/>

                </div>
              </el-form-item>
              <el-form-item label="近3年是否获得省级科技奖励" required prop="provincialLevelRewards">
                <el-radio-group v-model="formData.provincialLevelRewards">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
                <div class="if-true-show" v-if="formData.provincialLevelRewards === 1">
                  如是，请填写：
                  年份：
                  <el-input size="small" v-model="formData.provincialLevelRewardsYear"/>
                  年，
                  名称：
                  <el-input size="small" v-model="formData.provincialLevelRewardsName"/>
                  ，
                  排名：
                  <input-number size="small" v-model="formData.provincialLevelRewardsRank"
                                @update:modelValue="formData.provincialLevelRewardsRank = $event" :is-decimal="false"/>
                </div>
              </el-form-item>
              <el-form-item label="获得有关荣誉情况（有效期内）" required prop="isHasHonor">
                <el-radio-group v-model="formData.isHasHonor">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
                <div class="if-true-show" v-if="formData.isHasHonor === 1">
                  如是，请打钩:
                  <el-checkbox-group v-model="formData.hasHonor">
                    <el-checkbox :label="1">高新技术企业</el-checkbox>
                    <el-checkbox :label="2">国家级技术创新示范企业</el-checkbox>
                    <el-checkbox :label="3">知识产权优势企业</el-checkbox>
                    <el-checkbox :label="4">知识产权示范企业</el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
              <el-form-item label="是否拥有经认定的省部级以上研发机构" required prop="isHasInstitutions">
                <el-radio-group v-model="formData.isHasInstitutions">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
                <div class="if-true-show" v-if="formData.isHasInstitutions === 1">
                  如是，请填写：
                  国家级：
                  <input-number size="small" v-model="formData.nationalCount"
                                @update:modelValue="formData.nationalCount = $event" :is-decimal="false"/>
                  个，
                  省级：
                  <input-number size="small" v-model="formData.provincialCount"
                                @update:modelValue="formData.provincialCount = $event" :is-decimal="false"/>
                  个。
                </div>
              </el-form-item>
              <el-form-item label="近三年内新增股权融资额总额" v-if="false">
                <div class="normal-show">
                  合格机构投资者的实缴额：
                  <input-number size="small" v-model="formData.equityFinancing"
                                @update:modelValue="formData.equityFinancing = $event" :is-decimal="true" :digit="4"/>
                  万元以上，
                  最新企业估值：
                  <input-number size="small" v-model="formData.valuation"
                                @update:modelValue="formData.valuation = $event" :is-decimal="true" :digit="4"/>
                  万元
                </div>
              </el-form-item>
              <el-form-item label="所属领域及其他" required prop="isDomain">
                <el-radio-group v-model="formData.isDomain">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
                <div class="if-true-show" v-if="formData.isDomain === 0">
                  其他领域请说明：
                  <el-input size="small" v-model="formData.domainOther"/>
                </div>
                <div class="if-true-show" style="margin-left: 0" v-if="formData.isDomain === 1">
                  如是，请打钩:
                  <el-checkbox-group v-model="formData.domain">
                    <el-checkbox :label="1">新一代信息技术</el-checkbox>
                    <el-checkbox :label="2">生物技术</el-checkbox>
                    <el-checkbox :label="3">新能源</el-checkbox>
                    <el-checkbox :label="4">新材料</el-checkbox>
                    <el-checkbox :label="5">高端装备</el-checkbox>
                    <el-checkbox :label="6">新能源汽车</el-checkbox>
                    <el-checkbox :label="7">绿色环保</el-checkbox>
                    <el-checkbox :label="8">航空航天</el-checkbox>
                    <el-checkbox :label="9">海洋装备</el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并认定
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "InnovateSmes"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import BasicInfo from '@/components/BasicInfo'
import Breadcrumb from '@/components/Breadcrumb'
import {CircleCheckFilled, Finished, Promotion, QuestionFilled} from "@element-plus/icons-vue";
import InputNumber from '@/components/InputNumber'
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'innovateSmes'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({
  productName: '',
  productYear: '3',
  productCategory: '0000',
  nationalLevelRewards: 0,
  nationalLevelRewardsYear: '',
  nationalLevelRewardsName: '',
  nationalLevelRewardsRank: '',
  provincialLevelRewards: 0,
  provincialLevelRewardsYear: '',
  provincialLevelRewardsName: '',
  provincialLevelRewardsRank: '',
  isHasHonor: 0,
  hasHonor: [],
  isHasInstitutions: 0,
  nationalCount: 0,
  provincialCount: 0,
  equityFinancing: 0,
  valuation: 0,
  isDomain: 0,
  domain: [],
  domainOther: '',
})
onMounted(() => {
  getConditionByPath()
})
const getConditionByPath = () => {
  companyApi.getConditionByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
      formData.value.hasHonor = formData.value.hasHonor || []
      formData.value.domain = formData.value.domain || []
    }
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveConditionByPath(urlPath, company).then((resp) => {
    if (resp.code == 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getConditionByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(()=>{
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitConditionByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(()=>{
            submitBtn.value = false
            loading.value = false
          })
        } else {

          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}
</script>
<style scoped lang="scss">
.innovate-smes-transform-container {

  .if-true-show, .normal-show {
    text-align: left;

    :deep .el-form-item .el-form-item {
      display: inline-block;
    }

    :deep .el-input--small {
      display: inline-block;
      width: 100px;
      padding-bottom: 4px;
    }

    :deep .el-radio-group {
      vertical-align: middle;
    }

    :deep .el-checkbox-group {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .if-true-show {
    margin-left: 20px;
  }
}

.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
}
</style>
