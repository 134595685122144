<template>
  <div class="home">
    <HeaderView title="用户中心" />
    <div class='account-container'>
      <div class='left'>
        <div class="user-avatar ">
          <div class="avatar">
            <img src="../../assets/avatar.png" height="64" width="64"/></div>
          <div class="nike-name">
            {{ maskPhoneNumber(userStore.name) }}
          </div>
        </div>
        <ul>
          <li v-on:click='$router.push({path:"/account"})'>企业信息</li>
          <li v-on:click='$router.push({path:"/account/myreport"})'>测评报告</li>
          <li v-on:click='$router.push({path:"/account/applied"})' class='active'>我的会员</li>
          <li v-on:click='$router.push({path:"/account/password"})'>修改密码</li>
        </ul>
      </div>
      <div class='right'>
        <div class='title'><span></span>我的会员</div>
        <p style="padding-left: 20px" v-if="days>0">当前是会员</p>
        <p style="padding-left: 20px" v-else>当前非会员</p>
        <p style="padding-left: 20px" v-if="days>0">剩余天数: {{ days }}</p>
      </div>
      <div style='clear: both'></div>
    </div>
    <BottomView />
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import {onMounted, ref} from 'vue'
import useUserStore from "@/store/modules/user";
const userStore = useUserStore()

const days = ref(0)
const daysUntilDeadline = () => {
  const deadlineDate = new Date(userStore.memberDeadline);
  const today = new Date();
  if (today > deadlineDate) {
    days.value = 0; // 如果今天已经超过截止日期，则返回0天
  }
  const differenceInMilliseconds = deadlineDate - today;
  days.value = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
}
function maskPhoneNumber(phoneNumber) {
  // 确保手机号码长度为11位
  if (phoneNumber.length === 11) {
    // 使用模板字符串和slice方法替换中间四位
    return `${phoneNumber.slice(0, 3)}****${phoneNumber.slice(-4)}`;
  } else {
    return phoneNumber;
  }
}

onMounted(() => {
  daysUntilDeadline()
})
</script>

<style lang="scss" scoped>
.account-container {
  width: 1200px;
  margin: 0 auto;
  padding: 0 0 100px 0;

  .left {
    width: 200px;
    min-height: 600px;
    height: 1000px;
    float: left;
    display: inline-block;
    border-right: 1px;
    text-align: left;
    background-color: #ffffff;
    padding: 20px 0 0 0;
    box-sizing: border-box;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        height: 50px;
        text-align: center;
        line-height: 50px;

        color: #000;
        margin-bottom: 15px;
        font-size: 14px;
        cursor: pointer;

      }

      .active {
        border-left: 2px solid #E4C895;
        background: #F8F8F8;
        font-size: 18px;
      }
    }

    .user-avatar {
      position: relative;
      margin: 0 auto;
      text-align: center;
      width: 100%;

      .avatar {

      }

      .nike-name {
        margin: 15px 0 15px 0;
      }
    }
  }
  .right{
    width: 1000px;
    min-height: 600px;
    height: 1000px;
    float: right;
    display: inline-block;
    text-align: left;
    background-color: rgba(255, 255, 255, 1);

    border-left: 2px solid #f5f6fa;
    box-sizing: border-box;


    .title {
      padding: 20px;
      font-size: 21px;
      color: #000000;

      span {
        display: inline-block;
        width: 4px;
        height: 22px;
        background: #BDA26D;
        vertical-align: middle;
        margin-right: 10px;
      }
    }

    p{
      color: #ff0000;
      font-size: 13px;
      margin: 0;
      padding: 0 0 20px 0;
    }
  }

}
</style>
