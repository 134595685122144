<template>
  <div style="width: 100%">
    <PDF
        :page="page"
        :pdf-width="isMobile ? '100%' : '768'"
        :row-gap="isMobile ? 4 : 8"
        :src="pdfUrl"
        @on-pdf-init="handlePdfInit"
        @on-page-change="handlePageChange"
    >
    </PDF>
    <div class="tool-bar">
      <p>跳转到</p>
      <input type="number" v-model="page"
             style="width: 80px;height: 25px; border-radius: 5px; border: #777 1px solid;"/>
    </div>
  </div>
</template>

<script setup>
import {ref, watchEffect, onMounted} from "vue"
import PDF from "pdf-vue3"

import { Base64 } from 'js-base64'

// const encodePwd = Base64.encode(password);//加密
// const decodePwd = Base64.decode(encodePwd);//解密

const isMobile = ref(false)
const page = ref(1)
const pdfUrl = ref('')

const handlePageChange = (newPage) => {
  console.log(`new page: ${newPage}`)
  page.value = newPage
}

const resize = () => {
  isMobile.value = window.innerWidth < 768
}

watchEffect(() => {
  resize()
  window.addEventListener("resize", resize)
  return () => {
    window.removeEventListener("resize", resize)
  };
});

onMounted(() => {
  const queryString = window.location.hash.split("?")[1]
  const params = new URLSearchParams(queryString)
  const num = params.get('n')
  // pdfUrl.value = './报告.pdf'
   const fileUrl = Base64.decode(num);//解密
  pdfUrl.value = 'pdf/'+fileUrl
})

/**
 *
 * @param {import('pdf-vue3').PDFDocumentProxy} pdf - The PDF document proxy object.
 * @returns {void}
 */
const handlePdfInit = (pdf) => {
  console.log(pdf);
};
</script>

<style scoped>
@media (min-width: 768px) {
  ::v-deep(.pdf-vue3-backToTopBtn) {
    right: 32px !important;
  }
}

.tool-bar {
  position: fixed;
  left: 16px;
  bottom: 16px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 2px #777;
  border-radius: 4px;
  line-height: 1em;
  padding: 8px;
}

.tool-bar > p {
  margin: 0 0 8px;
}
:deep .ql-editor{
  max-height: unset;
}
</style>
