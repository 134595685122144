<template>
  <div class="home">
    <div class="basic-info-container">
      <el-form
          ref="basicInfoForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1" v-if="isBasicInfo">
            <template v-slot:title>
              <div class="item-title">企业基本信息</div>
            </template>
            <div class="basic-info">
              <el-row :gutter="50">
                <el-col :span="6">
                  <el-form-item label="企/事业单位名称" required prop="company.companyName">
                    <el-input v-model="formData.company.companyName"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="统一社会信用代码" required prop="company.creditCode">
                    <el-input v-model="formData.company.creditCode"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="注册资本（万元）" required prop="company.registerCapital">
                    <el-input v-model="formData.company.registerCapital"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="是否事业单位" required prop="company.isInstitutions">
                    <el-radio-group v-model="formData.company.isInstitutions">
                      <el-radio :label="0">否</el-radio>
                      <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="50">
                <el-col :span="6">
                  <el-form-item label="法定代表人" required prop="company.ceo">
                    <el-input v-model="formData.company.ceo"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="企业类型|经济性质" required prop="company.enterpriseType"
                                class="enterpriseOptions">
                    <el-select v-model="formData.company.enterpriseType" placeholder="请选择">
                      <el-option :value="1" label="民营"></el-option>
                      <el-option :value="2" label="国有"></el-option>
                      <el-option :value="3" label="合资"></el-option>
                      <el-option :value="4" label="外资"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="注册日期" required prop="company.registerTime">
                    <el-date-picker clearable
                                    style="width:100%"
                                    v-model="formData.company.registerTime"
                                    type="date"
                                    value-format="YYYY-MM-DD"
                                    placeholder="请选择注册日期">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="企业规模" required prop="company.enterpriseSize">
                    <el-radio-group v-model="formData.company.enterpriseSize">
                      <el-radio :label="1">大型</el-radio>
                      <el-radio :label="2">中型</el-radio>
                      <el-radio :label="3">小型</el-radio>
                      <el-radio :label="4">微型</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="联系人姓名" required prop="company.contactName">
                    <el-input v-model="formData.company.contactName"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="联系人手机" required prop="company.contactMobile">
                    <el-input v-model="formData.company.contactMobile"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="联系人邮箱" required prop="company.email">
                    <el-input v-model="formData.company.email"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="邮编" prop="company.postCode" v-if="false">
                    <el-input v-model="formData.postCode"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="企业注册地" required prop="company.registerProvince">
                    <el-select v-model="formData.company.registerProvince">
                      <el-option label="浦东新区" value="浦东新区"/>
                      <el-option label="黄浦区" value="黄浦区"/>
                      <el-option label="静安区" value="静安区"/>
                      <el-option label="徐汇区" value="徐汇区"/>
                      <el-option label="长宁区" value="长宁区"/>
                      <el-option label="虹口区" value="虹口区"/>
                      <el-option label="杨浦区" value="杨浦区"/>
                      <el-option label="普陀区" value="普陀区"/>
                      <el-option label="闵行区" value="闵行区"/>
                      <el-option label="宝山区" value="宝山区"/>
                      <el-option label="嘉定区" value="嘉定区"/>
                      <el-option label="金山区" value="金山区"/>
                      <el-option label="松江区" value="松江区"/>
                      <el-option label="青浦区" value="青浦区"/>
                      <el-option label="奉贤区" value="奉贤区"/>
                      <el-option label="崇明区" value="崇明区"/>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="通讯地址" required prop="company.address">
                    <el-input v-model="formData.company.address"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item required prop="company.industry">
                    <template #label>
                      <el-tooltip content="按照《国民经济行业分类(GB/T 4754-2017)》的大类行业填写所属行业."
                                  placement="right">
                        <span>
                          <span>所属行业</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <el-select v-model="formData.company.industry" :filterable="true" style="width: 100%;">
                      <el-option v-for="item in bigTypes" :key="item.id" :label="item.name" :value="item.industryCode"/>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
          <el-collapse-item name="2" v-if="isFinancial">
            <template v-slot:title>
              <div class="item-title">近三年经营与财务状况</div>
            </template>
            <div class="financial-info">
              <div class="table-data">
                <div class="table-header">
                  <div class="table-cell">重要指标</div>
                  <div class="table-cell">第一年
                    <el-tooltip placement="right">
                      <template #content>2021年
                      </template>
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">第二年
                    <el-tooltip placement="right">
                      <template #content>2022年
                      </template>
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">第三年
                    <el-tooltip placement="right">
                      <template #content>2023年
                      </template>
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell bold"><span>*</span>全职员工数量</div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.employeeNum1">
                      <input-number v-model="formData.economics.employeeNum1" @blur="handleResearcherRate"
                                    @update:modelValue="formData.economics.employeeNum1 = $event" :is-decimal="false"
                                    :is-positive="true" :append="'人'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.employeeNum2">
                      <input-number v-model="formData.economics.employeeNum2" @blur="handleResearcherRate"
                                    @update:modelValue="formData.economics.employeeNum2 = $event" :is-decimal="false"
                                    :is-positive="true" :append="'人'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.employeeNum3">
                      <input-number v-model="formData.economics.employeeNum3" @blur="handleResearcherRate"
                                    @update:modelValue="formData.economics.employeeNum3 = $event" :is-decimal="false"
                                    :is-positive="true" :append="'人'"/>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell bold"><span>*</span>其中：研发人员数量
                    <el-tooltip placement="right">
                      <template #content>
                        研究开发人员数：指报告年度内企业内部直接参加研发
                        <br>项目人员，以及研发活动的管理和直接服务的人员。不包括全
                        <br>年累计从事研发活动时间占制度工作时间 10%以下的人员。
                      </template>
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.researcherNum1">
                      <input-number v-model="formData.economics.researcherNum1" @blur="handleResearcherRate"
                                    @update:modelValue="formData.economics.researcherNum1 = $event" :is-decimal="false"
                                    :is-positive="true" :append="'人'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.researcherNum2">
                      <input-number v-model="formData.economics.researcherNum2" @blur="handleResearcherRate"
                                    @update:modelValue="formData.economics.researcherNum2 = $event" :is-decimal="false"
                                    :is-positive="true" :append="'人'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.researcherNum3">
                      <input-number v-model="formData.economics.researcherNum3" @blur="handleResearcherRate"
                                    @update:modelValue="formData.economics.researcherNum3 = $event" :is-decimal="false"
                                    :is-positive="true" :append="'人'"/>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row calculation"
                     v-if="formData.economics.researcherRate1!=0||formData.economics.researcherRate2!=0||formData.economics.researcherRate3!=0">
                  <div class="table-cell"><i></i>研发人员占全部职工比重</div>
                  <div class="table-cell">
                    <el-form-item prop="economics.researcherRate1">
                      <el-input v-model="formData.economics.researcherRate1" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.researcherRate2" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.researcherRate3" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell bold"><span>*</span>营业收入
                    <el-tooltip placement="right">
                      <template #content>
                        请以《财务报表》中 利润表 的 '营业收入' 本期金额数值为准。
                      </template>
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.revenue1">
                      <input-number v-model="formData.economics.revenue1" @blur="handleRevenue"
                                    @update:modelValue="formData.economics.revenue1 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.revenue2">
                      <input-number v-model="formData.economics.revenue2" @blur="handleRevenue"
                                    @update:modelValue="formData.economics.revenue2 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.revenue3">
                      <input-number v-model="formData.economics.revenue3" @blur="handleRevenue"
                                    @update:modelValue="formData.economics.revenue3 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell bold"><span>*</span>其中：主营业务收入（或销售收入）
                    <el-tooltip placement="right">
                      <template #content>
                        主营业务收入：指报告年度内企业确认的销售商品、提
                        <br>供劳务等主营业务的收入。根据会计“主营业务收入”科目的
                        <br>期末贷方余额填报。若会计报告和会计报表中未设置该科目，
                        <br>填报企业年度财务报表附注中“主营业务收入”数据。
                      </template>
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.mainRevenue1">
                      <input-number v-model="formData.economics.mainRevenue1" @blur="handleRevenue"
                                    @update:modelValue="formData.economics.mainRevenue1 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.mainRevenue2">
                      <input-number v-model="formData.economics.mainRevenue2" @blur="handleRevenue"
                                    @update:modelValue="formData.economics.mainRevenue2 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.mainRevenue3">
                      <input-number v-model="formData.economics.mainRevenue3" @blur="handleRevenue"
                                    @update:modelValue="formData.economics.mainRevenue3 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row calculation"
                     v-if="formData.economics.mainRevenuePercent1!=0||formData.economics.mainRevenuePercent2!=0||formData.economics.mainRevenuePercent3!=0">
                  <div class="table-cell"><i></i>主营业务收入总额占营业收入总额比重</div>
                  <div class="table-cell">
                    <el-form-item prop="economics.mainRevenuePercent1">
                      <el-input v-model="formData.economics.mainRevenuePercent1" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.mainRevenuePercent2" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.mainRevenuePercent3" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row calculation"
                     v-if="formData.economics.revenueGrowthRate1!=0||formData.economics.revenueGrowthRate2!=0||formData.economics.revenueGrowthRate3!=0">
                  <div class="table-cell"><i></i>营业收入增长率</div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.revenueGrowthRate1" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.revenueGrowthRate2" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.revenueGrowthRate3" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row calculation"
                     v-if="formData.economics.mainRevenueGrowthRate1!=0||formData.economics.mainRevenueGrowthRate2!=0||formData.economics.mainRevenueGrowthRate3!=0">
                  <div class="table-cell"><i></i>主营业务收入(或销售收入)增长率</div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.mainRevenueGrowthRate1" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.mainRevenueGrowthRate2" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.mainRevenueGrowthRate3" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell bold"><span>*</span>研发费用总额（即研发费用投入）
                    <el-tooltip placement="right">
                      <template #content>
                        研究开发（简称“研发”）经费支出：指报告年度内企
                        <br>业研发活动的经费支出合计，包括企业内部的日常研发经费支
                        <br>出，当年形成用于研发的固定资产支出和委托外单位开展研发的经费支出。
                        <br>
                        <br>请以《财务报表》中 利润表 的 '营业收入：（减项）研发费用' 本期金额数值为准。
                      </template>
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.researchFee1">
                      <input-number v-model="formData.economics.researchFee1" @blur="handleRevenue"
                                    @update:modelValue="formData.economics.researchFee1 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.researchFee2">
                      <input-number v-model="formData.economics.researchFee2" @blur="handleRevenue"
                                    @update:modelValue="formData.economics.researchFee2 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.researchFee3">
                      <input-number v-model="formData.economics.researchFee3" @blur="handleRevenue"
                                    @update:modelValue="formData.economics.researchFee3 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row calculation"
                     v-if="formData.economics.researchFeeRate1!=0||formData.economics.researchFeeRate2!=0||formData.economics.researchFeeRate3!=0">
                  <div class="table-cell"><i></i>研发费用总额占营业收入总额比重</div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.researchFeeRate1" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.researchFeeRate2" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.researchFeeRate3" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell bold"><span>*</span>成本费用总额
                    <el-tooltip placement="right">
                      <template #content>
                        计算公式：成本费用总额=营业成本+营业税金及附加+销售费用+管理费用+财务费用+资产减值损失。
                        <br>请以《财务报表》中 利润表 的 '营业收入：各类减项' 本期金额累加数值为准。
                      </template>
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.totalCost1">
                      <input-number v-model="formData.economics.totalCost1"
                                    @update:modelValue="formData.economics.totalCost1 = $event"
                                    :is-decimal=true :digit="4" :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.totalCost2">
                      <input-number v-model="formData.economics.totalCost2"
                                    @update:modelValue="formData.economics.totalCost2 = $event"
                                    :is-decimal=true :digit="4" :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.totalCost3">
                      <input-number v-model="formData.economics.totalCost3"
                                    @update:modelValue="formData.economics.totalCost3 = $event"
                                    :is-decimal=true :digit="4" :append="'万元'"/>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell bold"><span>*</span>上缴税金
                    <el-tooltip placement="right">
                      <template #content>
                        请以《财务报表》中 利润表 的 '减：所得税费用' 项本期金额数值为准。
                      </template>
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.paidTaxes1">
                      <input-number v-model="formData.economics.paidTaxes1" @blur="handleRevenue"
                                    @update:modelValue="formData.economics.paidTaxes1 = $event"
                                    :is-decimal=true :digit="4" :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.paidTaxes2">
                      <input-number v-model="formData.economics.paidTaxes2" @blur="handleRevenue"
                                    @update:modelValue="formData.economics.paidTaxes2 = $event"
                                    :is-decimal=true :digit="4" :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.paidTaxes3">
                      <input-number v-model="formData.economics.paidTaxes3" @blur="handleRevenue"
                                    @update:modelValue="formData.economics.paidTaxes3 = $event"
                                    :is-decimal=true :digit="4" :append="'万元'"/>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell bold"><span>*</span>净利润总额
                    <el-tooltip placement="right">
                      <template #content>
                        请以《财务报表》中 利润表 的 '净利润' 项本期金额数值为准。
                      </template>
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.netProfit1">
                      <input-number v-model="formData.economics.netProfit1"
                                    @update:modelValue="formData.economics.netProfit1 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.netProfit2">
                      <input-number v-model="formData.economics.netProfit2"
                                    @update:modelValue="formData.economics.netProfit2 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.netProfit3">
                      <input-number v-model="formData.economics.netProfit3"
                                    @update:modelValue="formData.economics.netProfit3 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row calculation"
                     v-if="formData.economics.netProfitRate1!=0||formData.economics.netProfitRate2!=0||formData.economics.netProfitRate3!=0">
                  <div class="table-cell"><i></i>净利润率</div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.netProfitRate1" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.netProfitRate2" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.netProfitRate3" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row calculation"
                     v-if="formData.economics.netProfitGrowthRate1!=0||formData.economics.netProfitGrowthRate2!=0||formData.economics.netProfitGrowthRate3!=0">
                  <div class="table-cell"><i></i>净利润增长率</div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.netProfitGrowthRate1" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.netProfitGrowthRate2" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.netProfitGrowthRate3" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell bold"><span>*</span>资产总额
                    <el-tooltip placement="right">
                      <template #content>
                        请以《财务报表》中 资产负债表 的 '资产总计' 项本期金额数值为准。
                      </template>
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.totalAssets1">
                      <input-number v-model="formData.economics.totalAssets1" @blur="handleAssets"
                                    @update:modelValue="formData.economics.totalAssets1 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.totalAssets2">
                      <input-number v-model="formData.economics.totalAssets2" @blur="handleAssets"
                                    @update:modelValue="formData.economics.totalAssets2 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.totalAssets3">
                      <input-number v-model="formData.economics.totalAssets3" @blur="handleAssets"
                                    @update:modelValue="formData.economics.totalAssets3 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell bold"><span>*</span>负债总额
                    <el-tooltip placement="right">
                      <template #content>
                        请以《财务报表》中 资产负债表 的 '负债合计' 项本期金额数值为准。
                      </template>
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.totalLiability1">
                      <input-number v-model="formData.economics.totalLiability1" @blur="handleAssets"
                                    @update:modelValue="formData.economics.totalLiability1 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.totalLiability2">
                      <input-number v-model="formData.economics.totalLiability2" @blur="handleAssets"
                                    @update:modelValue="formData.economics.totalLiability2 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.totalLiability3">
                      <input-number v-model="formData.economics.totalLiability3" @blur="handleAssets"
                                    @update:modelValue="formData.economics.totalLiability3 = $event" :is-decimal=true
                                    :digit="4"
                                    :append="'万元'"/>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row calculation"
                     v-if="formData.economics.assetLiabilityRatio1!=0||formData.economics.assetLiabilityRatio2!=0||formData.economics.assetLiabilityRatio3!=0">
                  <div class="table-cell"><i></i>资产负债率</div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.assetLiabilityRatio1" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.assetLiabilityRatio2" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.assetLiabilityRatio3" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row calculation"
                     v-if="formData.economics.netAssets1!=0||formData.economics.netAssets2!=0||formData.economics.netAssets3!=0">
                  <div class="table-cell">净资产
                    <el-tooltip content="净资产 = 资产总额 - 负债总额" placement="right">
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">
                    <el-form-item prop="economics.netAssets1">
                      <input-number v-model="formData.economics.netAssets1" :append="'万元'" readonly/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item prop="economics.netAssets2">
                      <input-number v-model="formData.economics.netAssets2" :append="'万元'" readonly/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item prop="economics.netAssets3">
                      <input-number v-model="formData.economics.netAssets3" :append="'万元'" readonly/>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row calculation"
                     v-if="formData.economics.netAssetsGrowth1!=0||formData.economics.netAssetsGrowth2!=0||formData.economics.netAssetsGrowth3!=0">
                  <div class="table-cell"><i></i>净资产增长率</div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.netAssetsGrowth1" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.netAssetsGrowth2" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item>
                      <el-input v-model="formData.economics.netAssetsGrowth3" readonly>
                        <template #append>%</template>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell">当年新增股权融资额总额（合格机构投资者的实缴额）
                    <el-tooltip placement="right">
                      <template #content>
                        注意：参与《创新型中小企业认定》 或 《“专精特新”中小企业认定》 请填写。
                      </template>
                      <el-icon color="#1ab394">
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.equityCapital1">
                      <input-number v-model="formData.economics.equityCapital1"
                                    @update:modelValue="formData.economics.equityCapital1 = $event"
                                    :is-decimal=true :digit="4" :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.equityCapital2">
                      <input-number v-model="formData.economics.equityCapital2"
                                    @update:modelValue="formData.economics.equityCapital2 = $event"
                                    :is-decimal=true :digit="4" :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.equityCapital3">
                      <input-number v-model="formData.economics.equityCapital3"
                                    @update:modelValue="formData.economics.equityCapital3 = $event"
                                    :is-decimal=true :digit="4" :append="'万元'"/>
                    </el-form-item>
                  </div>
                </div>
                <div class="table-row" v-if="false">
                  <div class="table-cell"><i></i>对应估值</div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.valuation1">
                      <input-number v-model="formData.economics.valuation1"
                                    @update:modelValue="formData.economics.valuation1 = $event"
                                    :is-decimal=true :digit="4" :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.valuation2">
                      <input-number v-model="formData.economics.valuation2"
                                    @update:modelValue="formData.economics.valuation2 = $event"
                                    :is-decimal=true :digit="4" :append="'万元'"/>
                    </el-form-item>
                  </div>
                  <div class="table-cell">
                    <el-form-item required prop="economics.valuation3">
                      <input-number v-model="formData.economics.valuation3"
                                    @update:modelValue="formData.economics.valuation3 = $event"
                                    :is-decimal=true :digit="4" :append="'万元'"/>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item name="3" v-if="isIntProp">
            <template v-slot:title>
              <div class="item-title">知识产权情况</div>
            </template>
            <div class="int-prop-info">
              <el-row :gutter="50">
                <el-col :span="6">
                  <el-form-item label="I类知识产权数量" required prop="patent.intellectualProperty1Num">
                    <template #label>
                      <el-tooltip>
                        <template #content>
                          Ⅰ类知识产权包括：
                          <br>1、发明专利（含国防专利）
                          <br>2、植物新品种
                          <br>3、国家级农作物品种
                          <br>4、国家新药
                          <br>5、国家一级中药保护品种
                          <br>6、集成电路布图设计专有权
                          <br>（不包含转让未满1年的知识产权）
                        </template>
                        <span>
                          <span>I类知识产权数量</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.patent.intellectualProperty1Num"
                                  @update:modelValue="formData.patent.intellectualProperty1Num = $event"
                                  :is-decimal="false"
                                  :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="formData.patent.intellectualProperty1Num>0">
                  <el-form-item label="发明专利">
                    <input-number v-model="formData.patent.patentNum"
                                  @update:modelValue="formData.patent.patentNum = $event"
                                  :is-decimal="false" :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="formData.patent.intellectualProperty1Num>0">
                  <el-form-item label="植物新品种">
                    <input-number v-model="formData.patent.newPlantVariety"
                                  @update:modelValue="formData.patent.newPlantVariety = $event"
                                  :is-decimal="false" :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="formData.patent.intellectualProperty1Num>0">
                  <el-form-item label="国家级农作物品种">
                    <input-number v-model="formData.patent.countryCropVariety"
                                  @update:modelValue="formData.patent.countryCropVariety = $event" :is-decimal="false"
                                  :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="formData.patent.intellectualProperty1Num>0">
                  <el-form-item label="国家新药">
                    <input-number v-model="formData.patent.countryNewMedicine"
                                  @update:modelValue="formData.patent.countryNewMedicine = $event" :is-decimal="false"
                                  :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="formData.patent.intellectualProperty1Num>0">
                  <el-form-item label="国家一级中药保护品种">
                    <input-number v-model="formData.patent.countryChineseMedicine"
                                  @update:modelValue="formData.patent.countryChineseMedicine = $event"
                                  :is-decimal="false"
                                  :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="formData.patent.intellectualProperty1Num>0">
                  <el-form-item label="集成电路布图设计专有权">
                    <input-number v-model="formData.patent.integratedCircuitDesign"
                                  @update:modelValue="formData.patent.integratedCircuitDesign = $event"
                                  :is-decimal="false"
                                  :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="50" v-if="formData.patent.intellectualProperty1Num>0">
                <el-col :span="6">
                  <el-form-item label="自主研发数量" required prop="patent.ownIntellectualProperty1Num">
                    <input-number v-model="formData.patent.ownIntellectualProperty1Num"
                                  @update:modelValue="formData.patent.ownIntellectualProperty1Num = $event"
                                  :is-decimal="false"
                                  :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="属于I类高价值知识产权数量" required
                                prop="patent.highValueIntellectualProperty1Num">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          “Ⅰ类高价值知识产权”须符合以下条件之一：
                          <br>（1）在海外有同族专利权的发明专利或在海外取得收入的其他Ⅰ类知识产权，其中专利限G20成员、新加坡以及欧洲专利局经实质审查后获得授权的发明专利。
                          <br>（2）维持年限超过10年的Ⅰ类知识产权。
                          <br>（3）实现较高质押融资金额的Ⅰ类知识产权。
                          <br>（4）获得国家科学技术奖或中国专利奖的Ⅰ类知识产权。
                        </template>
                        <span>
                          <span>属于I类高价值知识产权数量</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.patent.highValueIntellectualProperty1Num"
                                  @update:modelValue="formData.patent.highValueIntellectualProperty1Num = $event"
                                  :is-decimal="false"
                                  :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="当年被受理的Ⅰ类知识产权数申请数" required
                                prop="patent.intellectualProperty1AcceptNum">
                    <input-number v-model="formData.patent.intellectualProperty1AcceptNum"
                                  @update:modelValue="formData.patent.intellectualProperty1AcceptNum = $event"
                                  :is-decimal="false"
                                  :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="当年被受理的PCT专利申请数 " required prop="patent.pctPropertyAcceptNum">
                    <template #label>
                      <el-tooltip
                          content="PCT专利是指通过PCT（专利合作条约，Patent Cooperation Treaty）途径提交的国际专利申请。"
                          placement="right">
                        <span>
                          <span>当年被受理的PCT专利申请数</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.patent.pctPropertyAcceptNum"
                                  @update:modelValue="formData.patent.pctPropertyAcceptNum = $event" :is-decimal="false"
                                  :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="false">
                  <el-form-item label="其中：全部国际专利数">
                    <input-number v-model="formData.patent.internationalPatentNum"
                                  @update:modelValue="formData.patent.internationalPatentNum = $event"
                                  :is-decimal="false"
                                  :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="false">
                  <el-form-item label="其中：国防专利">
                    <input-number v-model="formData.patent.nationalDefencePatentNum"
                                  @update:modelValue="formData.patent.nationalDefencePatentNum = $event"
                                  :is-decimal="false"
                                  :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="50">
                <el-col :span="6">
                  <el-form-item label="II类类知识产权数量" required prop="patent.intellectualProperty2Num">
                    <template #label>
                      <el-tooltip
                          content="Ⅱ类知识产权包括与主导产品相关的软件著作权（不含商标）、授权后维持超过2年的实用新型专利或外观设计专利（均不包含转让未满1年的知识产权）"
                          placement="right">
                        <span>
                          <span>II类类知识产权数量</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <input-number v-model="formData.patent.intellectualProperty2Num"
                                  @update:modelValue="formData.patent.intellectualProperty2Num = $event"
                                  :is-decimal="false"
                                  :is-positive="true" :append="'件'"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="formData.patent.intellectualProperty2Num>0">
                  <el-form-item label="实用新型">
                    <input-number v-model="formData.patent.newModel"
                                  @update:modelValue="formData.patent.newModel = $event"
                                  :is-decimal="false" :is-positive="true" :append="'件'" @blur="handleChangeIP2Num"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="formData.patent.intellectualProperty2Num>0">
                  <el-form-item label="外观设计">
                    <input-number v-model="formData.patent.design" @update:modelValue="formData.patent.design = $event"
                                  :is-decimal="false" :is-positive="true" :append="'件'" @blur="handleChangeIP2Num"/>
                  </el-form-item>
                </el-col>
                <el-col :span="6" v-if="formData.patent.intellectualProperty2Num>0">
                  <el-form-item label="软件著作权">
                    <input-number v-model="formData.patent.copyright"
                                  @update:modelValue="formData.patent.copyright = $event"
                                  :is-decimal="false" :is-positive="true" :append="'件'" @blur="handleChangeIP2Num"/>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicInfo"
}
</script>
<script setup>
import {defineProps, ref, defineExpose, onMounted, toRefs} from "vue";
import InputNumber from '@/components/InputNumber'
import {QuestionFilled} from "@element-plus/icons-vue";
import Schema from "async-validator";
import {errorMsg} from "@/utils/customizeMsg";
import {getGbtType} from "@/api/authentication";
import company from "@/api/company";

const props = defineProps({
  //子组件接收父组件传递过来的值
  isBasicInfo: {
    type: Boolean,
    default: true
  },
  isFinancial: {
    type: Boolean,
    default: false
  },
  isIntProp: {
    type: Boolean,
    default: false
  },
  isInputValidation: {
    type: Boolean,
    default: true
  }
})
const {isInputValidation} = toRefs(props)

// 设置报错提示信息
Schema.messages.required = () => {
  return '该项为必填项'
}
const formData = ref({
  company: {
    companyName: '',
    creditCode: '',
    registerCapital: '',
    isInstitutions: 0,
    ceo: '',
    enterpriseType: 1,
    registerTime: '',
    enterpriseSize: 4,
    contactName: '',
    contactMobile: '',
    email: '',
    postCode: '',
    registerProvince: '浦东新区',
    address: '',
    industry: '',
  },
  economics: {
    employeeNum1: 0,
    researcherNum1: 0,
    revenue1: 0,
    mainRevenue1: 0,
    revenueGrowthRate1: 0,
    mainRevenueGrowthRate1: 0,
    mainRevenuePercent1: 0,
    totalCost1: 0,
    netProfit1: 0,
    netProfitRate1: 0,
    netProfitGrowthRate1: 0,
    totalAssets1: 0,
    totalLiability1: 0,
    assetLiabilityRatio1: 0,
    paidTaxes1: 0,
    equityCapital1: 0,
    valuation1: 0,
    salesRevenue1: 0,
    salesRevenueGrowth1: 0,
    netAssets1: 0,
    researchFee1: 0,
    researcherRate1: 0,
    netAssetsGrowth1: 0,
    researchFeeRate1: 0,

    employeeNum2: 0,
    researcherNum2: 0,
    revenue2: 0,
    mainRevenue2: 0,
    revenueGrowthRate2: 0,
    mainRevenueGrowthRate2: 0,
    mainRevenuePercent2: 0,
    totalCost2: 0,
    netProfit2: 0,
    netProfitRate2: 0,
    netProfitGrowthRate2: 0,
    totalAssets2: 0,
    totalLiability2: 0,
    assetLiabilityRatio2: 0,
    paidTaxes2: 0,
    equityCapital2: 0,
    valuation2: 0,
    salesRevenue2: 0,
    salesRevenueGrowth2: 0,
    netAssets2: 0,
    researchFee2: 0,
    researcherRate2: 0,
    netAssetsGrowth2: 0,
    researchFeeRate2: 0,

    employeeNum3: 0,
    researcherNum3: 0,
    revenue3: 0,
    mainRevenue3: 0,
    revenueGrowthRate3: 0,
    mainRevenueGrowthRate3: 0,
    mainRevenuePercent3: 0,
    totalCost3: 0,
    netProfit3: 0,
    netProfitRate3: 0,
    netProfitGrowthRate3: 0,
    totalAssets3: 0,
    totalLiability3: 0,
    assetLiabilityRatio3: 0,
    paidTaxes3: 0,
    equityCapital3: 0,
    valuation3: 0,
    salesRevenue3: 0,
    salesRevenueGrowth3: 0,
    netAssets3: 0,
    researchFee3: 0,
    researcherRate3: 0,
    netAssetsGrowth3: 0,
    researchFeeRate3: 0,
  },
  patent: {
    intellectualProperty1Num: 0,
    patentNum: 0,
    newPlantVariety: 0,
    countryCropVariety: 0,
    countryNewMedicine: 0,
    countryChineseMedicine: 0,
    integratedCircuitDesign: 0,
    ownIntellectualProperty1Num: 0,
    highValueIntellectualProperty1Num: 0,
    intellectualProperty1AcceptNum: 0,
    pctPropertyAcceptNum: 0,
    internationalPatentNum: 0,
    nationalDefencePatentNum: 0,
    intellectualProperty2Num: 0,
    newModel: 0,
    design: 0,
    copyright: 0,
  }
})
const collapseVal = ['1', '2', '3']
const bigTypes = ref()

onMounted(() => {
  getGbtType().then((resp) => {
    bigTypes.value = resp
  })
  getBasicInfo()
})
const getBasicInfo = () => {
  company.getBasicInfo().then((resp) => {
    const company = resp.data.company
    if (company.id) {
      formData.value = resp.data
      handleResearcherRate()
      handleRevenue()
      handleAssets()
    }
  }, () => {
  })
}


const handleChangeIP2Num = function () {
  formData.value.patent.intellectualProperty2Num = parseInt(formData.value.patent.newModel)
      + parseInt(formData.value.patent.design) + parseInt(formData.value.patent.copyright)
}
const proResult = function (res) {
  return isNaN(res) || res === Infinity || res === -Infinity ? "0" : res.toFixed(2);
}
const handleResearcherRate = function () { //研发人员占全部职工比重
  formData.value.economics.researcherRate1 = proResult(formData.value.economics.researcherNum1 / formData.value.economics.employeeNum1 * 100);
  formData.value.economics.researcherRate2 = proResult(formData.value.economics.researcherNum2 / formData.value.economics.employeeNum2 * 100);
  formData.value.economics.researcherRate3 = proResult(formData.value.economics.researcherNum3 / formData.value.economics.employeeNum3 * 100);
  inputValidation()
}
const inputValidation = () => {
  if (formData.value.economics.researcherRate1 > 100 || formData.value.economics.researcherRate2 > 100 || formData.value.economics.researcherRate3 > 100) {
    errorMsg("错误提示：您输入的 '研发人员数量' 超出了 '全职员工数量'，请检查并更改。")
    return false
  }
  if (formData.value.economics.mainRevenuePercent1 > 100 || formData.value.economics.mainRevenuePercent2 > 100 || formData.value.economics.mainRevenuePercent3 > 100) {
    errorMsg("错误提示：您输入的 '主营业务收入（或销售收入）' 超出了 '营业收入'，请检查并更改。")
    return false
  }
  return true
}
const handleAssets = function () {
  //资产负债率= (负债总额÷资产总额)×100%
  formData.value.economics.assetLiabilityRatio1 = proResult(formData.value.economics.totalLiability1 / formData.value.economics.totalAssets1 * 100);
  formData.value.economics.assetLiabilityRatio2 = proResult(formData.value.economics.totalLiability2 / formData.value.economics.totalAssets2 * 100);
  formData.value.economics.assetLiabilityRatio3 = proResult(formData.value.economics.totalLiability3 / formData.value.economics.totalAssets3 * 100);
  //净资产 = 资产总额 - 负债总额
  formData.value.economics.netAssets1 = proResult(formData.value.economics.totalAssets1 - formData.value.economics.totalLiability1);
  formData.value.economics.netAssets2 = proResult(formData.value.economics.totalAssets2 - formData.value.economics.totalLiability2);
  formData.value.economics.netAssets3 = proResult(formData.value.economics.totalAssets3 - formData.value.economics.totalLiability3);
  //净资产增长率
  formData.value.economics.netAssetsGrowth1 = 0
  formData.value.economics.netAssetsGrowth2 = proResult((formData.value.economics.netAssets2 - formData.value.economics.netAssets1) / formData.value.economics.netAssets1 * 100);
  formData.value.economics.netAssetsGrowth3 = proResult((formData.value.economics.netAssets3 - formData.value.economics.netAssets2) / formData.value.economics.netAssets2 * 100);
}
const handleRevenue = function () {
  //主营业务收入总额占营业收入总额比重
  formData.value.economics.mainRevenuePercent1 = proResult(formData.value.economics.mainRevenue1 / formData.value.economics.revenue1 * 100);
  formData.value.economics.mainRevenuePercent2 = proResult(formData.value.economics.mainRevenue2 / formData.value.economics.revenue2 * 100);
  formData.value.economics.mainRevenuePercent3 = proResult(formData.value.economics.mainRevenue3 / formData.value.economics.revenue3 * 100);
  inputValidation()

  //营业收入增长率
  formData.value.economics.revenueGrowthRate1 = 0
  formData.value.economics.revenueGrowthRate2 = proResult((formData.value.economics.revenue2 - formData.value.economics.revenue1) / formData.value.economics.revenue1 * 100);
  formData.value.economics.revenueGrowthRate3 = proResult((formData.value.economics.revenue3 - formData.value.economics.revenue2) / formData.value.economics.revenue2 * 100);
  //主营业务收入增长率
  formData.value.economics.mainRevenueGrowthRate1 = 0
  formData.value.economics.mainRevenueGrowthRate2 = proResult((formData.value.economics.mainRevenue2 - formData.value.economics.mainRevenue1) / formData.value.economics.mainRevenue1 * 100);
  formData.value.economics.mainRevenueGrowthRate3 = proResult((formData.value.economics.mainRevenue3 - formData.value.economics.mainRevenue2) / formData.value.economics.mainRevenue2 * 100);
  //研发费用总额占营业收入总额比重
  formData.value.economics.researchFeeRate1 = proResult(formData.value.economics.researchFee1 / formData.value.economics.revenue1 * 100);
  formData.value.economics.researchFeeRate2 = proResult(formData.value.economics.researchFee2 / formData.value.economics.revenue2 * 100);
  formData.value.economics.researchFeeRate3 = proResult(formData.value.economics.researchFee3 / formData.value.economics.revenue3 * 100);

  //净利润总额=营业收入-成本费用总额-上缴税金

  //净利润率 净利润/营业收入×100%
  formData.value.economics.netProfitRate1 = proResult(formData.value.economics.netProfit1 / formData.value.economics.revenue1 * 100);
  formData.value.economics.netProfitRate2 = proResult(formData.value.economics.netProfit2 / formData.value.economics.revenue2 * 100);
  formData.value.economics.netProfitRate3 = proResult(formData.value.economics.netProfit3 / formData.value.economics.revenue3 * 100);
  //净利润增长率
  formData.value.economics.netProfitGrowthRate1 = 0
  formData.value.economics.netProfitGrowthRate2 = proResult((formData.value.economics.netProfit2 - formData.value.economics.netProfit1) / formData.value.economics.netProfit1 * 100);
  formData.value.economics.netProfitGrowthRate3 = proResult((formData.value.economics.netProfit3 - formData.value.economics.netProfit2) / formData.value.economics.netProfit2 * 100);
}
const basicInfoForm = ref('')
const saveFormValid = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return new Promise(() => {})
  if (isInputValidation) {
    if (formData.value.economics.researcherRate1 > 100 || formData.value.economics.researcherRate2 > 100 || formData.value.economics.researcherRate3 > 100) {
      errorMsg("错误提示：您输入的 '研发人员数量' 超出了 '全职员工数量'，请检查并更改。")
      formEl.scrollToField('economics.researcherRate1')
      return new Promise(() => {})
    }
    if (formData.value.economics.mainRevenuePercent1 > 100 || formData.value.economics.mainRevenuePercent2 > 100 || formData.value.economics.mainRevenuePercent3 > 100) {
      errorMsg("错误提示：您输入的 '主营业务收入（或销售收入）' 超出了 '营业收入'，请检查并更改。")
      formEl.scrollToField('economics.mainRevenuePercent1')
      return new Promise(() => {})
    }
  }

  return formEl.validate((valid, fields) => {
    saveFormValid.value = valid
    if (!valid) {
      Object.keys(fields).forEach((key, i) => {
        const propName = fields[key][0].field
        if (i === 0) {
          formEl.scrollToField(propName)
        }
      })
      errorMsg("您有必填项没有填写，请仔细检查。")
      return new Promise(() => {})
    }
  })
}
defineExpose({
  formData,
  basicInfoForm,
  saveForm,
  handleResearcherRate,
  handleRevenue,
  handleAssets,
  saveFormValid,
  inputValidation,
  getBasicInfo
})
</script>

<style scoped lang="scss">
.basic-info-container {
  width: 100%;
  margin: 0 auto;
  background-color: #FFFFFF;

  // 单位基本信息
  .basic-info {
    h2 {
      text-align: left;
      font-size: 16px;
      color: #101010;
    }

    :deep .el-form-item__label {
      color: #101010;
    }

    .enterpriseOptions :deep .el-select .el-input__wrapper {
      width: 225px;
    }

    :deep .el-input__wrapper {
      width: 225px;
    }

    :deep .el-radio {
      margin-right: 10px;
    }
  }

  // 近三年经营与财务状况
  .financial-info {
    margin-bottom: 25px;

    h2 {
      text-align: left;
      font-size: 16px;
      color: #101010;
    }

    .table-data {
      width: 99%;
      height: 100%;
      border: 1px solid #ebeef5;
      $odd-color: #F2F2F2;
      $even-color: #FFFFFF;

      .table-header {
        background-color: $odd-color;
      }

      .table-header, .table-row {
        height: 40px;
        line-height: 38px;
        //border-bottom: 1px solid $odd-color;

        :deep .el-input-group__append {
          width: 45px;
        }
      }

      .table-header {
        .table-cell {
          display: inline-block;
          text-align: center;
          color: #101010;
          font-weight: 600;
          font-size: 16px;
        }
      }

      .table-row {
        &:nth-child(odd) {
          background-color: $odd-color;
        }

        &:nth-child(even) {
          background-color: $even-color;
        }
      }

      .table-row {
        .table-cell {
          display: inline-block;
          text-align: left;
          font-size: 14px;

          span {
            font-size: 14px;
            display: inline-block;
            margin-right: 10px;
            color: #ff0000;
          }
        }
      }

      .table-cell {
        &:nth-child(1) {
          width: 39%;
          padding-left: 1%;
        }

        &:nth-child(2) {
          width: 16%;
          padding: 0 2%
        }

        &:nth-child(3) {
          width: 16%;
          padding: 0 2%
        }

        &:nth-child(4) {
          width: 16%;
          padding: 0 2%
        }
      }
    }
  }

  // 知识产权信息
  .int-prop-info {
    h2 {
      text-align: left;
      font-size: 16px;
      color: #101010;
    }

    :deep .el-form-item__label {
      color: #101010;
    }
  }

  .condition-el-collapse {
    .item-title {
      border-left: 7px solid #308bd8;
      line-height: 40px;
      font-size: 16px;
      color: #333333;
      padding-left: 15px;
      width: 100%;
      text-align: left;
    }

    :deep .el-collapse-item__header {
      background-color: #fafafa;
      height: 40px;
      margin-bottom: 40px;
    }

    :deep .el-collapse-item__arrow:before {
      content: "展开";
    }

    :deep .el-collapse-item__arrow.is-active:before {
      content: "收起";
    }

    :deep .el-collapse-item__arrow.is-active {
      transform: rotate(0deg);
    }

    :deep .el-collapse-item__arrow {
      color: rgba(48, 139, 216, 1);
      font-size: 16px;
      text-align: left;
      width: 45.09px;
      height: 23px;
      line-height: 23px;
      font-style: unset;

      svg {
        display: none;
      }
    }
  }
}
</style>
