<template>
  <div class="common-layout fixed">
    <el-container>
      <HeaderView title=""/>
      <el-main>
        <div class="single-page-container">
          <h1>{{ singlePage.title }}</h1>
          <div class="ql-editor">
            <article v-html="singlePage.content"/>
          </div>
        </div>
      </el-main>
      <el-footer>
        <FooterView/>
      </el-footer>
    </el-container>
  </div>
</template>

<script setup>
import HeaderView from '@/components/mobile/Header'
import FooterView from '@/components/mobile/Footer'
import {onMounted, ref} from "vue";
import {getSinglePage} from "@/api/news";

let singlePage = ref({});

onMounted(() => {
  //document.title = "企业综合服务系统"

  getSinglePage(3).then(response => {
    if (response.data){
      singlePage.value = response.data
    }
  }).catch(()=>{
  })

})
</script>

<style lang="scss" scoped>
.common-layout {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;

  .el-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.fixed {
    .el-container {
      flex: 1;
    }

    .el-footer {
      --el-footer-height: unset;
    }
  }

  .el-footer {
    --el-footer-padding: 0;
    margin-top: 100px;
  }

  .el-main {
    padding: 0 10px;
  }
  :deep .ql-editor{
    min-height: unset;
    max-height: unset;
    padding: 0;
    img{
      width: 100%;
    }
  }
  :deep .el-popup-parent--hidden{
    width: 100%;
  }
}
</style>
