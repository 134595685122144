<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class="condition tech-smes-container">
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">科技型中小企业认定</div>
      <BasicInfo ref="basicInfo" :is-financial="true" :is-int-prop="true"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'">
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="企业提供的产品和服务不属于国家规定的禁止、限制和淘汰类" required prop="notObsolete">
                <el-radio-group v-model="formData.notObsolete">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                  label="企业在填报上一年及当年内未发生重大安全、重大质量事故和严重环境违法、科研严重失信行为，且企业未列入经营异常名录和严重违法失信企业名单"
                  required prop="notIllegal">
                <el-radio-group v-model="formData.notIllegal">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="企业拥有有效期内高新技术企业资格证书" required prop="hasQualification">
                <el-radio-group v-model="formData.hasQualification">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="企业拥有经认定的省部级以上研发机构" required prop="hasInstitution">
                <el-radio-group v-model="formData.hasInstitution">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="企业近五年内获得过国家级科技奖励，并在获奖单位中排在前三名" required
                            prop="gainTop3Prize">
                <el-radio-group v-model="formData.gainTop3Prize">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="企业近五年内主导制定过国际标准、国家标准、或行业标准" required
                            prop="createStandardIn5Years">
                <el-radio-group v-model="formData.createStandardIn5Years">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ShPatentDemoEnterprise"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import BasicInfo from '@/components/BasicInfo'
import Breadcrumb from '@/components/Breadcrumb'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref(null)
const urlPath = 'techSmes'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref('')
const formData = ref({
  notObsolete: 0,
  notIllegal: 0,
  hasQualification: 0,
  hasInstitution: 0,
  gainTop3Prize: 0,
  createStandardIn5Years: 0,
  createStandard: 0,
})
onMounted(() => {
  getConditionByPath()
})
const getConditionByPath = () => {
  companyApi.getConditionByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  })
}
const isResult = ref(true)
const loading = ref(false)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveConditionByPath(urlPath, company).then((resp) => {
    if (resp.code == 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getConditionByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitConditionByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {

          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })

}
</script>
<style scoped lang="scss">
.tech-smes-container {
  .if-true-show, .normal-show {
    text-align: left;

    :deep .el-form-item .el-form-item {
      display: inline-block;
    }

    :deep .el-input--small {
      display: inline-block;
      width: 100px;
      padding-bottom: 4px;
    }

    :deep .el-radio-group {
      vertical-align: middle;
    }

    :deep .el-checkbox-group {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .if-true-show {
    margin-left: 20px;
  }
}

.condition-el-collapse {
  border-bottom: unset;

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }
}
</style>
