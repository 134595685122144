<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar/>
    <el-divider/>
    <div class="declaration-filling-container">
      <el-steps active="1">
        <el-step title="第一步" description="请输入企业名称"/>
        <el-step title="第二步" description="完成政策匹配"/>
      </el-steps>
      <el-input placeholder="请输入企业名称，进行政策匹配" v-model="search">
        <template #append>
          <el-button @click="query">政策匹配</el-button>
        </template>
      </el-input>

    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "DeclarationFilling"
}
</script>

<script setup>

import MenuBar from '@/components/MenuBar';
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import {ref} from "vue";

const search = ref('')
const query = () => {
  console.log(search)
}
</script>

<style scoped lang="scss">
.el-divider {
  margin: 0;
}

.declaration-filling-container {
  width: 1200px;
  height: 992px;
  margin: 0 auto;
  background-color: #FFFFFF;
  padding-top: 80px;
  box-sizing: border-box;

  .el-steps {
    width: 710px;
    margin: 0 auto;
    text-align: left;

    :deep .is-text {
      color: #FFFFFF;
      background-color: #BBBBBB;
      border-color: #BBBBBB;
    }

    :deep .el-step__main {
      color: #BBBBBB;

      .is-finish {
        color: #2E5AA6;
      }
    }

    :deep .el-step__head.is-finish {
      .is-text {
        color: #FFFFFF;
        background-color: #2E5AA6;
        border-color: #2E5AA6;
      }
    }
  }

  .el-input {
    width: 710px;
    margin: 74px auto;
    height: 55px;

    :deep .el-input-group__append {
      background-color: rgba(46, 90, 166, 1);
      color: rgba(255, 255, 255, 1);
      box-shadow: unset;
      width: 80px;
    }
  }

}
</style>
