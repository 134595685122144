import CarrierConstruction from "@/views/pudongxinqu/fiscalPolicy/operatingCaliber/CarrierConstruction";
import Incubator from "@/views/pudongxinqu/fiscalPolicy/operatingCaliber/Incubator";
import ReduceCosts from "@/views/pudongxinqu/fiscalPolicy/operatingCaliber/ReduceCosts";
import CompetencyProjects from "@/views/pudongxinqu/fiscalPolicy/operatingCaliber/CompetencyProjects";
import HighGrowthProjects from "@/views/pudongxinqu/fiscalPolicy/operatingCaliber/HighGrowthProjects";
import AcceptanceProject from "@/views/pudongxinqu/fiscalPolicy/operatingCaliber/AcceptanceProject";

const parentPath = '/operating-caliber'

export default [
    {path: `${parentPath}/carrier-construction`, name: 'carrier-construction', component: CarrierConstruction,},
    {path: `${parentPath}/incubator`, name: 'incubator', component: Incubator,},
    {path: `${parentPath}/reduce-costs`, name: 'reduce-costs', component: ReduceCosts,},
    {path: `${parentPath}/competency-projects`, name: 'competency-projects', component: CompetencyProjects,},
    {path: `${parentPath}/high-growth-projects`, name: 'high-growth-projects', component: HighGrowthProjects,},
    {path: `${parentPath}/acceptance-project`, name: 'acceptance-project', component: AcceptanceProject,},
]
