<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>上海市新型研发机构备案与绩效评价</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>具有公共研发属性：以开展基础研究、应用基础研究、关键核心技术攻关、产业共性技术研发与服务为核心业务（不包括主要从事生产制造、研发服务外包、教学培训、园区管理、生产性检验检测等活动的单位）；</td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>具有稳定的研发投入能力：年度研发投入占主营业务收入比例不低于20%或达到500万元，尚未产生主营业务收入的，研发投入占总支出比例不低于50%；</td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>具有稳定的人才队伍：固定研发人员20人以上，占员工总数比例不低于30%，其中全职研发人员不低于10人，且具有硕士、博士学位或高级职称的研发人员占固定研发人员比例不低于30%；</td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>具备进行研究、开发和试验所需的科研仪器设备及固定场地等研发基础条件：拥有科研仪器设备原价总值不低于200万元或上年度委托研发测试金额不低于200万元，固定场地面积不低于600平方米；</td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>具有完善的管理制度：设立机构章程，并在研发投入、机构运行、人才队伍建设、科研管理和成果转化等方面，建立相关内部管理制度。</td>
              <td v-html='(result[4] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ShanghaiInnovationFund"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
