<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition high-growth-projects-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">浦东新区科技发展基金产学研专项（生物医药）</div>
      <BasicInfo ref="basicInfo" :is-financial="false" :is-int-prop="false"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="选择符合条件的专题："
                            required prop="meetRange">
                <el-radio-group v-model="formData.meetRange">
                  <el-radio :label="1" style="width: 1000px; height: 135px;text-align: left">
                    <strong>专题一、创新药品医疗器械临床研究</strong>
                    <br>支持方式和内容：对生物医药企业的创新药物（一类新药、二类新药）在符合条件的合作单位开展I期临床研究的，对项目给予30%，最高不超过100万的支持。
                    <br>对生物医药企业的医疗器械（三类医疗器械或创新医疗器械）在符合条件的合作单位开展临床研究的，对项目给予30%，最高不超过50万的支持。
                    <br>执行期限：不超过2年，项目结束时间不得晚于2025年6月30日。
                  </el-radio>
                  <el-radio :label="2" style="width: 1000px; height: 135px;text-align: left">
                    <strong>专题二、创新药品医疗器械临床前研究</strong>
                    <br>支持内容和方式：对生物医药企业联合符合条件的合作单位开展以产品上市为目的研究者发起的临床研究（IIT），重点支持细胞基因、合成生物等新领域新赛道项目。
                    <br>对项目总投入给予30%，最高不超过80万的支持。
                    <br>执行期限：不超过2年，项目结束时间不得晚于2025年6月30日。
                  </el-radio>
                  <el-radio :label="0">以上均不符合
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="项目责任人应承诺所提交材料真实性，不含涉密内容；申报单位应当对申请材料的真实性进行审核。"
                            required prop="isReal">
                <el-radio-group v-model="formData.isReal">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="项目申报须由新区企业牵头，联合1-2家医疗机构，或1家医疗机构与1家上海市的高校院所共同申报。其中临床研究（专题一）的医疗机构范围为浦东区属二级以上医院、上海市级及其他区属三级乙等（含）以上医院、江浙皖三省卫健委直属三甲医院以及三省范围内的知名医科大学（医学院）直属附属医院（江浙皖三省医院名单附后）。临床前研究（专题二）的医疗机构范围为浦东区属二级以上医院、上海市级及其他区属三级乙等（含）以上医院。"
                            required prop="isLead">
                <el-radio-group v-model="formData.isLead">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="申报单位需提交卫生主管部门批准立项材料、临床批件、正式合作协议等附件证明材料。其中合作协议应是2022年7月1日后签署。"
                            required prop="isProved">
                <el-radio-group v-model="formData.isProved">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="项目经评审后择优立项。资助资金应保障专款专用。已经获得国家、上海市和区级其他财政资金资助的项目不得申报本专项。"
                            required prop="isSpecialUse">
                <el-radio-group v-model="formData.isSpecialUse">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="目前承担浦东科技发展基金产学研专项并未结题的企业不能申报。"
                            required prop="hasNoUnfinishedItem">
                <el-radio-group v-model="formData.hasNoUnfinishedItem">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="企业应在生产经营过程中做好安全生产工作。"
                            required prop="isSafe">
                <el-radio-group v-model="formData.isSafe">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongTecholonyDevelopmentSpecialFund"
}
</script>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import MenuBar from '@/components/MenuBar.vue'
import BasicInfo from '@/components/BasicInfo.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'pudongTecholonyDevelopmentSpecialFund'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({
})
onMounted(() => {
  getProjectPudongByPath()
})
const getProjectPudongByPath = () => {
  companyApi.getProjectPudongByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveProjectPudongByPath(urlPath, company).then((resp) => {
    if (resp.code === 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getProjectPudongByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitProjectPudongByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

</script>
<style scoped lang="scss">
.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
}
</style>
