<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>浦东新区“明珠计划”菁英人才项目</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>申报类型：创业类 / 创新类
              </td>
              <td v-html='(result[0] || {}).option'></td>
            </tr>
            <tr v-if="(result[0] || {}).option==='创业类'">
              <td>一般应取得知名高校硕士学位。年龄一般不超过40周岁。
              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr v-if="(result[0] || {}).option==='创业类'">
              <td>有知名高校、科研机构或知名企业正式职位并工作36个月以上。
              </td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr v-if="(result[0] || {}).option==='创业类'">
              <td>须为企业的主要创办人。其中，企业成立1年及以上5年以下的，申报人须为第一大股东或景大自然人股东，原则上雪已获得风险投资爱计超过10万元;成立5年及以上的，申报人须为企业创始人，新增投资额一般不低于3000万元。
              </td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>

            <tr v-if="(result[0] || {}).option==='创新类'">
              <td>一般应取得博士学位，年龄一般不超过40周岁。时间认定截至申报当年1月1日。
              </td>
              <td v-html='(result[4] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr v-if="(result[0] || {}).option==='创新类'">
              <td>在知名高校、科研机构或知名企业自然科学、工程技术等科研领域工作36个月以上。时间认定截至申报当年1月1日。
              </td>
              <td v-html='(result[5] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr v-if="(result[0] || {}).option==='创新类'">
              <td>近5年在国内外知名刊物发表过重要论文或为重要科技成果发明专利发明人。时间认定截至申报当年1月1日.
              </td>
              <td v-html='(result[6] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>

            <tr>
              <td>支持对象须已在用人单位全职工作或已与用人单位签订全职工作合同或意向协议，并承诺入选后在用人单位全职工作不少于二年
              </td>
              <td v-html='(result[7] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPearlPlanEliteTalent"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([
  {option: ''}
])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
