<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>浦东新区促进集成电路和新一代通信产业高质量发展专项</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>工商注册、税收户管均在浦东新区，经营状态正常、信用记录良好、财务制度健全的企业
              </td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>
                （一）鼓励产业链协同联动
                <br>1、支持 IP 购买。对购买 IP（硅知识产权）开发高端芯
                片的集成电路设计企业，给予不超过合同金额 20%的奖励，
                单个企业年度最高 300 万元。
                2、支持 IP 授权服务。对开展 IP 产品授权服务的集成电
                路设计企业，给予不超过合同金额 20%的奖励，单个企业年
                度最高 300 万元。
                3、支持 EDA 软件购买。对购买 EDA 设计工具软件(含
                软件升级费用)的集成电路企业，给予不超过合同金额 20%
                的奖励，单个企业年度最高 200 万元。
                4、支持首轮流片。对开展工程产品首轮流片的集成电
                路设计企业，按照该款产品首轮流片合同金额，给予不超过
                合同金额 30%的奖励，单个企业年度最高 200 万元。对承接
                以上首轮流片服务的制造企业，给予不超过合同金额 30%的
                奖励，单个制造企业年度最高 500 万元。
                5、支持 MPW（多项目晶圆）。对开展 MPW（多项目
                晶圆）项目的集成电路设计企业，给予不超过合同金额 30%
                的奖励，单个企业年度最高 200 万元；对承接以上 MPW 项
                目的制造企业，给予不超过合同金额 30%的奖励，单个制造
                企业年度最高 500 万元。
                <br>（二）支持首次示范推广应用
                <br>1、在集成电路领域，对集成电路设计企业通过自主开
                发或者合作开发实现功能或性能重大突破的高端产品，在首
                次流片后 2 年内销售额达到 500 万元以上，按不超过销售额
                20%的比例给予企业奖励。单个产品最高奖励 200 万元，单
                个企业年度最高 500 万元。
                2、在新一代通信领域，支持企业开展新一代通信技术
                关键元器件、模组、设备的产业化，对相关产品年度销售金
                额达到 3000 万元的企业，按不超过相关产品年度销售金额
                10%给予奖励，单个企业年度最高 500 万元。培育新一代通
                信技术解决方案集成商，加速推进新一代通信技术与垂直行
                业的融合创新应用，对于年度销售收入超过 1000 万元的企
                业，按不超过其年度销售额的 10%给予奖励，单个企业年度
                最高 500 万元。
                <br>（三）配套支持
                <br>对获得上海市级政策支持的集成电路及新一代通信项
                目，可在项目验收完成后，给予一定配套奖励。
                对明确配套要求的，按市级部门立项时明确的区级支持
                要求给予配套支持。对未明确配套要求的，经区相关部门协
                商一致，按照企业获得的上海市级项目扶持资金总额，给予
                不超过企业获得上海市级项目扶持资金 50%的奖励。同一项
                目各级政府奖励和资助总额不超过企业总投入的 50%，最高
                5000 万元。
              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
          <br>
          <div class="condition-form-tips">*注：同一项目、产品已经获得国家、上海市和区级其他财政资金资助的，不再予以支持；根据项目情况，择优资助。</div>

        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPromoteDevelopmentOfIcAndCommunication"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
