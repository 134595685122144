<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition high-growth-projects-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">浦东新区支持生物医药产业高质量发展专项</div>
      <BasicInfo ref="basicInfo" :is-financial="false" :is-int-prop="false"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="请选择符合您企业的专题类型："
                            required prop="meetType">
                <el-radio-group v-model="formData.meetType" style="text-align: left">
                  <el-radio :label="1" style="height: 180px;">
                    （一）支持创新药械研发
                    <p class="radio-p"> 1、对新进入临床Ⅱ期、Ⅲ期研究的一类新药（含中医药）研发项目，分别给予 100 万元、200
                      万元一次性奖励。
                      <br/>2、对获批上市的一类新药（含中医药）给予 1000 万元一次性奖励（包括临床Ⅱ期、Ⅲ期奖励），对获批上市的二类新药（含中医药）
                      给予 500 万元一次性奖励。
                      <br/>3、对在全国同类仿制药中首个通过一致性评价的，给予 200 万元的一次性奖励。
                      <br/>4、对获得注册证的三类医疗器械、三类体外诊断试剂分别给予100 万元、50 万元一次性奖励。</p>
                  </el-radio>
                  <el-radio :label="2" style="height: 180px;">
                    （二）支持产业链协同联动
                    <p class="radio-p">1、对药品、医疗器械上市前，委托区内企业生产临床用样品（药物）和临床用样机（器械），委托与受委托方无关联的，分别给予双
                      方试生产合同金额各15%的支持，其中药品每家企业不超过100万元，医疗器械不超过 50 万元。
                      <br/>2、对药品获批上市或医疗器械获得注册证后一年内委托区内企业生产，委托与受委托方无关联的，分别给予双方生产合同金额各10%的支持，单个药品上市许可持有人、医疗器械注册人企业年度最
                      高资助 500 万元，单个生产企业年度最高资助 1000 万元。
                      <br/>3、对药品获批上市或医疗器械获得注册证后一年内委托区外企 业生产的，药品、医疗器械注册证以及税收、统计均落地浦东的，给
                      予委托方合同金额 10%的支持，单个药品上市许可持有人、医疗器械 注册人企业年度最高资助 500 万元。
                      <br/>4、对获得 FDA（美国食品药品监督管理局）、EMA（欧洲药品 管理局）、WHO（世界卫生组织）等国际药品生产质量管理规范认证
                      的企业，给予 200 万元一次性奖励。</p>
                  </el-radio>
                  <el-radio :label="3" style="height: 150px;">
                    （三）配套支持
                    <p class="radio-p">对获得上海市级政策支持的生物医药及新材料项目，可在项目验 收完成后，给予一定配套奖励。
                      对明确配套要求的，按市级部门立项时明确的区级支持要求给予 配套支持。对未明确配套要求的，经区相关部门协商一致，按照企业
                      获得的上海市级项目扶持资金总额，给予不超过企业获得上海市级项 目扶持资金 50%的奖励。同一项目各级政府奖励和资助总额不超过企
                      业总投入的 50%，最高 5000 万元。</p>
                  </el-radio>
                  <el-radio :label="0">以上均不符合</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPromoteDevelopmentOfMedicine"
}
</script>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import MenuBar from '@/components/MenuBar.vue'
import BasicInfo from '@/components/BasicInfo.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'pudongPromoteDevelopmentOfMedicine'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({})
onMounted(() => {
  getProjectPudongByPath()
})
const getProjectPudongByPath = () => {
  companyApi.getProjectPudongByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveProjectPudongByPath(urlPath, company).then((resp) => {
    if (resp.code === 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getProjectPudongByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitProjectPudongByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

</script>
<style scoped lang="scss">
.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }

  :deep .el-radio__label {
    white-space: normal; /* 换行 */
  }

  :deep .el-checkbox__label {
    white-space: normal; /* 换行 */
  }

  .radio-p {
    width: 1080px;
    word-wrap: break-word;
    background: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
    line-height: 21px;
    margin-top: 0;
    text-align: left;
  }
}
</style>
