<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>上海市企事业专利工作示范单位认定（企业类）-认定评分明细</div>

      <div class="result-score">
        <div class="table">
          <table>
            <thead>
            <tr>
              <th style="width: 200px">指标类型</th>
              <th style="width: 50px">分值</th>
              <th style="width: 100px">指标名称</th>
              <th style="width: 180px">评审要求</th>
              <th>评分标准</th>
              <th style="width: 100px;">得分</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td rowspan="6">单位基本情况（20 分）</td>
              <td>4</td>
              <td>专利战略</td>
              <td>具有专利战略思维</td>
              <td>企业实施专利战略思路清晰，措施得力，典型案例具有代表性的，得 1-4 分。</td>
              <td class="core">{{ (result[0] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>4</td>
              <td>研发投入</td>
              <td>研发经费投入占比较高</td>
              <td>近三年企业研发经费占企业营业收入比例：3%≤投入比≤5%，得 1 分；5%≤投入比≤8%，得 2 分；投入比≥8%，得 4 分。</td>
              <td class="core">{{ (result[1] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>3</td>
              <td>经营状况</td>
              <td>盈利和纳税情况良好</td>
              <td>近三年保持盈利和纳税，得 1-2 分；持续增长得 3 分。</td>
              <td class="core">{{ (result[2] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>产业领域</td>
              <td>主营业务涉及战略性新兴产业</td>
              <td>主营业务涉及集成电路、生物医药、人工智能、新能源汽车、高端装备、航空航天、信息通信、新材料、新兴数字产业的，得2 分。
              </td>
              <td class="core">{{ (result[3] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>诚信经营</td>
              <td>无不良诚信记录</td>
              <td>无不良诚信记录得 2 分。</td>
              <td class="core">{{ (result[4] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>5</td>
              <td>工作方案</td>
              <td>结合自身实际，制定目标明确、措施完善的试点工作方案</td>
              <td>工作方案立足自身，目标设置量化可考核的，得 1-5 分。</td>
              <td class="core">{{ (result[5] || {}).score || 0 }}</td>
            </tr>

            <tr>
              <td rowspan="4">知识产权制度建设（20 分）</td>
              <td>6</td>
              <td>规范管理</td>
              <td>完善知识产权管理制度</td>
              <td>提交贯标认证申请的，得 3 分；通过第三方知识产权贯标认证的，得 6 分。
              </td>
              <td class="core">{{ (result[6] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>5</td>
              <td>机构设置</td>
              <td>健全知识产权管理机构和人员配备</td>
              <td>
                ①知识产权工作由法务、科研等部门合并管理的，得 1 分；设立独立知识产权部门的，得 2 分；
                <br>②至少配备 1名专职知识产权管理人员的，得 1 分；配备 2 名及以上专职知识产权管理人员的，得 2 分；
                <br>③具有知识产权相关的职称证书的，得 1 分；具有专利代理师（人）资格的，得 2 分。
                <br>上述分值累计，合计不超过 5 分。
              </td>
              <td class="core">{{ (result[7] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>5</td>
              <td>经费投入</td>
              <td>注重知识产权经费保障</td>
              <td>
                ①重视知识产权经费投入的，得 1 分；近三年知识产权经费投入保持增长的，得 2 分；近三年年均知识产权经费投入超过50 万元的，得 3 分；
                <br>②近三年企业用于员工知识产权工作业绩奖励的经费年均≥10 万元，得 1 分；年均≥20万元，得 2分。
                <br>上述分值累计，合计不超过 5 分。
              </td>
              <td class="core">{{ (result[8] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>4</td>
              <td>宣传培训</td>
              <td>开展知识产权文化宣传和业务培训</td>
              <td>
                ①自 2022 年迄今，企业高管参与市区两级知识产权管理部门组织的培训或相关活动不少于 3 次，得 2分；
                <br>②上年度知识产权培训不少于 2 次，得 2 分；
                <br>③积极参加市知识产权局组织的 4.26宣传周、专利调查、知识产权金融入园惠企、有关培训等重大活动，有 1 项得 1 分。
                <br>上述分值累计，合计不超过 4 分。
              </td>
              <td class="core">{{ (result[9] || {}).score || 0 }}</td>
            </tr>

            <tr>
              <td rowspan="2">知识产权创造（20 分）</td>
              <td>12</td>
              <td>海外布局</td>
              <td>通过 PCT 条约、海牙协定、马德里协定等途径进行海外知识产权布局</td>
              <td>自 2022 年迄今，申请 PCT 专利、海牙协定外观设计或马德里商标的，每 1 件得 1 分，最高得 12 分。</td>
              <td class="core">{{ (result[10] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>8</td>
              <td>高质量创造</td>
              <td>注重高价值专利培育</td>
              <td>
                ①拥有有效发明专利 10-20 件的，得 2 分；拥有有效发明专利 20-30 件的，得3分；拥有有效发明专利30件以上的，得4分；
               <br>②拥有维持年限 10 年以上或获得过中国专利奖、上海知识产权创新奖、国家及市级科技奖等的高价值专利，每 1 件得1分；
               <br>③建立并有效实施专利申请前评估制度的，得 2 分；
               <br>④承担市级高价值专利升级培育项目的，得 2分。
               <br>上述分值累计，合计不超过8 分。
              </td>
              <td class="core">{{ (result[11] || {}).score || 0 }}</td>
            </tr>

            <tr>
              <td rowspan="2">知识产权运营（25 分）</td>
              <td>13</td>
              <td>专利转化</td>
              <td>开展专利许可转让工作，并在国家知识产权局完成登记备案手续</td>
              <td>
                ①自 2022 年迄今，开展专利许可转让工作，并在国家知识产权局完成登记备案手续的，得2分；从本市高校、科研机构受让专利的，每增加1次，得2分；
                <br>②自 2022 年迄今，专利许可转让费实际支付（到账）额≥5万的，得3分。
                <br>③进行专利开放许可声明的，得1分；有通过专利开放许可成交案例的，得2分；
                <br>④取得《国家专利密集型产品备案认定试点平台备案证明》的，得1分。
                <br>上述分值累计，合计不超过 13 分。
              </td>
              <td class="core">{{ (result[12] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>12</td>
              <td>知识产权金融</td>
              <td>开展专利商标质押融资、保险工作</td>
              <td>
                ①自2022年迄今，获得专利商标质押融资贷款并在国家知识产权局完成登记的，合同金额300万元及以下得4分，300万元-500万元得6分，500万元及以上得8分；
                <br>②自2022年迄今，开展专利保险的，得2分，开展商标保险的，得2分，投保的专利和商标件数合计达到10件（含10件）及以上的，得2分，以上合计，最多得6分。
                <br>上述分值累计，合计不超过12分。
              </td>
              <td class="core">{{ (result[13] || {}).score || 0 }}</td>
            </tr>

            <tr>
              <td>知识产权信息利用（15 分）</td>
              <td>15</td>
              <td>知识产权信息分析利用</td>
              <td>开展知识产权信息利用项目</td>
              <td>
                ①自2022年迄今，自主开展知识产权信息利用，形成专利导航、专利预警分析、知识产权分析评议、知识产权风险评估报告的，得5分；发现“一键生成式”报告的，得 0 分；
                <br>②自2022年迄今，承担区级以上相关部门知识产权信息利用项目的，得5分；
                <br>③在知识产权信息分析利用项目中建立研发人员、专利分析人员沟通协调机制的，得1-5分；
                <br>上述分值累计，合计不超过15分。
              </td>
              <td class="core">{{ (result[14] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>附加分（10 分）</td>
              <td>10</td>
              <td>附加分</td>
              <td>获得相关资质或奖励</td>
              <td>
                ①近 3年内，企业有产品列入上海市创新产品推荐目录；
                <br>②被列入工信部、上海市“专精特新”企业；
                <br>③被列入上海市商务委认定或备案的外资研发中心；
                <br>④具有无效宣告请求维持、专利诉讼胜诉等专利维权典型案例；
                <br>⑤列入上海市重点商标保护名录。
                <br>有1 项得 2 分，分值累计，最高得 10 分。
              </td>
              <td class="core">{{ (result[15] || {}).score || 0 }}</td>
            </tr>


            </tbody>
          </table>
        </div>
      </div>
      <div class="result-total">评价总得分： <span>{{ total }}</span> 分</div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "result_1"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score
  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>


<style scoped lang="scss">
.header {
  width: 1140px;
  margin: 35px auto 0;

  .title {
    font-size: 75px;
    color: #F2F2F2;
    font-weight: bold;
    position: relative;
    text-align: left;
    margin: 0;

    .text {
      font-size: 32px;
      color: #2C3E50;
      position: absolute;
      left: 50px;
      top: 25px;
    }
  }
}

.main-container {
  width: 1140px;
  margin: auto;
  padding-bottom: 100px;
  text-align: left;
  color: #101010;

  .container-title {
    padding: 50px 0 40px 0;
    font-size: 20px;
    color: #101010;

    span {
      display: inline-block;
      width: 8px;
      height: 20px;
      background: #5C7EF4;
      vertical-align: middle;
    }
  }

  .result-score {
    .title {
      font-size: 16px;
      line-height: 25px;

      span {
        color: red;
      }
    }

    .subtitle {
      margin-top: 10px;
      font-size: 14px;
    }

    .table {
      width: 100%;
      margin: 20px 0 40px;

      table {
        width: 100%;
        border-collapse: collapse;
      }

      table, th, td {
        border: 1px solid #DDDDDD;
        padding: 10px;
        line-height: 30px;
        color: #031A40;
      }

      th {
        font-size: 16px;
        font-weight: bolder;
        text-align: center;
        background-color: #DDDDDD;
      }

      .core {
        text-align: center;
      }
    }
  }

  .result-total {
    font-weight: bold;
    span {
      color: red;
    }
  }

  .td-red {
    color: red;
  }
}
</style>
