import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Cookies from 'js-cookie'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import './permission' // permission control
// import './mobile_permission' // permission control
import plugins from './plugins' // plugins

import './assets/css/my-style.scss'

createApp(App)
    .use(router)
    .use(store)
    .use(plugins)
    .use(ElementPlus, {locale: zhCn,size: Cookies.get('size') || 'default'})
    .mount('#app')
