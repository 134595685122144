<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class="condition sh-patent-polit-enterprise-transform-container">
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">上海市企事业专利工作（试点）单位认定 【企业类】</div>
      <BasicInfo ref="basicInfo" :is-financial="true" :is-int-prop="true"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'">
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="企业开展专利工作具有前瞻性和全局性，把专利工作融入企业总体发展战略" required
                            prop="politEnterprise.hasPatentStrategy">
                <el-radio-group v-model="formData.politEnterprise.hasPatentStrategy">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="上海市公共信用信息服务平台查询无不良诚信记录" required
                            prop="politEnterprise.notBadIntegrityRecord">
                <el-radio-group v-model="formData.politEnterprise.notBadIntegrityRecord">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                  label="主营业务涉及集成电路、生物医药、人工智能、新能源汽车、高端装备、航空航天、信息通信、新材料、新兴数字产业"
                  required prop="politEnterprise.hasMainBusiness">
                <el-radio-group v-model="formData.politEnterprise.hasMainBusiness">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                  label="工作方案立足自身，目标设置量化可考核（制定简要试点期工作方案，包含工作任务、绩效目标、资金安排、保障措施等内容）"
                  required prop="politEnterprise.hasWorkProgramme">
                <el-radio-group v-model="formData.politEnterprise.hasWorkProgramme">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="完善知识产权管理制度（单选）" required prop="politEnterprise.perfectIpSystem">
                <el-radio-group v-model="formData.politEnterprise.perfectIpSystem">
                  <el-radio :label="1">制定知识产权管理制度并实施</el-radio>
                  <el-radio :label="2">提交贯标认证申请</el-radio>
                  <el-radio :label="3">通过第三方知识产权贯标认证</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="健全知识产权管理机构和人员配备（每项单选）" required>
                <div class="normal-show">
                  ①
                  <el-radio-group v-model="formData.politEnterprise.perfectIpInstitution1">
                    <el-radio :label="1">知识产权工作由法务、科研等部门合并管理</el-radio>
                    <el-radio :label="2">设立独立知识产权部门</el-radio>
                  </el-radio-group>
                  <br>
                  ②
                  <el-radio-group v-model="formData.politEnterprise.perfectIpInstitution2">
                    <el-radio :label="1">至少配备 1 名专职知识产权管理人员</el-radio>
                    <el-radio :label="2">配备 2 名及以上专职知识产权管理人员</el-radio>
                  </el-radio-group>
                  <br>
                  ③
                  <el-radio-group v-model="formData.politEnterprise.perfectIpInstitution3">
                    <el-radio :label="1">具有知识产权工作者（专利工作者）证书</el-radio>
                    <el-radio :label="2">具有知识产权相关的职称证书</el-radio>
                    <el-radio :label="3">具有专利代理师（人）资格</el-radio>
                  </el-radio-group>
                  <br>
                </div>
              </el-form-item>
              <el-form-item label="注重知识产权经费保障（每项单选）" required>
                <div class="normal-show">
                  ①
                  <el-radio-group v-model="formData.politEnterprise.ipGuarantee1">
                    <el-radio :label="1">重视知识产权经费投入</el-radio>
                    <el-radio :label="2">三年知识产权经费投入保持增长</el-radio>
                    <el-radio :label="3">近三年年均知识产权经费投入超过 30 万元</el-radio>
                  </el-radio-group>
                  <br>
                  ②
                  <el-radio-group v-model="formData.politEnterprise.ipGuarantee2">
                    <el-radio :label="1">近三年企业用于员工知识产权工作业绩奖励的经费年均≥5 万元</el-radio>
                    <el-radio :label="2">近三年企业用于员工知识产权工作业绩奖励的经费年均≥10万元</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item label="开展知识产权文化宣传和业务培训">
                <div class="normal-show">
                  <el-checkbox-group v-model="formData.politEnterprise.ipPublicize"
                                     @change="handlePolitEnterpriseChangeIpPublicize">
                    <el-checkbox :label="1">自 2022 年迄今，企业高管参与市区两级知识产权管理部门组织的培训或相关活动不少于
                      3
                      次
                    </el-checkbox>
                    <br>
                    <el-checkbox :label="2">上年度知识产权培训不少于 2 次</el-checkbox>
                    <br>
                    <el-checkbox :label="3">积极参加市知识产权局组织的 4.26宣传周、专利调查、知识产权金融入园惠企、有关培训等重大活动,共
                      <input-number size="small" v-model="formData.politEnterprise.ipPublicizeCount"
                                    @update:modelValue="formData.politEnterprise.ipPublicizeCount = $event"
                                    :is-decimal="false"/>
                      项
                    </el-checkbox>
                  </el-checkbox-group>

                </div>
              </el-form-item>
              <el-form-item label="通过 PCT 条约、海牙协定、马德里协定等途径进行海外知识产权布局" required
                            prop="politEnterprise.pctCount">
                <div class="normal-show">
                  自 2022 年迄今，申请 PCT 专利、海牙协定外观设计或马德里商标，共
                  <input-number size="small" v-model="formData.politEnterprise.pctCount"
                                @update:modelValue="formData.politEnterprise.pctCount = $event"
                                :is-decimal="false"/>
                  件
                </div>
              </el-form-item>
              <el-form-item label="注重高价值专利培育">
                <div class="normal-show">
                  ①拥有有效发明专利
                  <input-number size="small" v-model="formData.politEnterprise.highValueCultivate1"
                                @update:modelValue="formData.politEnterprise.highValueCultivate1 = $event"
                                :is-decimal="false"/>
                  件<br>
                  ②拥有维持年限 10 年以上或获得过中国专利奖、上海知识产权创新奖、国家及市级科技奖等的高价值专利
                  <input-number size="small" v-model="formData.politEnterprise.highValueCultivate2"
                                @update:modelValue="formData.politEnterprise.highValueCultivate2 = $event"
                                :is-decimal="false"/>
                  件<br>
                  ③建立并有效实施专利申请前评估制度
                  <el-radio-group v-model="formData.politEnterprise.highValueCultivate3">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                  <br>
                  ④承担市级高价值专利升级培育项目
                  <el-radio-group v-model="formData.politEnterprise.highValueCultivate4">
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>

              <el-form-item label="开展专利许可转让工作，并在国家知识产权局完成登记备案手续（可多选）">
                <div class="normal-show">
                  <el-checkbox v-model="formData.politEnterprise.ipTransference1" :true-label="1" :false-label="0"
                               @click="handleChangePolitEnterpriseIpTransference">自 2022
                    年迄今，开展专利许可转让工作，并在国家知识产权局完成登记备案手续
                    。从本市高校、科研机构受让专利
                    <input-number size="small" v-model="formData.politEnterprise.ipTransference1Count"
                                  @update:modelValue="formData.politEnterprise.ipTransference1Count = $event"
                                  :is-decimal="false"/>
                    次
                  </el-checkbox>
                  <br>
                  <el-checkbox v-model="formData.politEnterprise.ipTransference2" :true-label="1" :false-label="0">自
                    2022 年迄今，专利许可转让费实际支付（到账）额≥2万
                  </el-checkbox>
                  <br>
                  <el-checkbox-group v-model="formData.politEnterprise.ipTransference3">
                    <el-checkbox :label="1">进行专利开放许可声明</el-checkbox>
                    <el-checkbox :label="2">有通过专利开放许可成交案例</el-checkbox>
                  </el-checkbox-group>
                  <br>
                  <el-checkbox v-model="formData.politEnterprise.ipTransference4" :true-label="1" :false-label="0">
                    取得《国家专利密集型产品备案认定试点平台备案证明》
                  </el-checkbox>
                </div>
              </el-form-item>
              <el-form-item label="开展专利商标质押融资、保险工作">
                <div class="normal-show">
                  ①自 2022 年迄今，获得专利商标质押融资贷款并在国家知识产权局完成登记的，合同金额
                  <el-radio-group v-model="formData.politEnterprise.ipPledge1">
                    <el-radio :label="1"> 300 万元及以下</el-radio>
                    <el-radio :label="2">300 万元-500 万元</el-radio>
                    <el-radio :label="3">500 万元及以上</el-radio>
                  </el-radio-group>
                  <br>
                  ②自 2022 年迄今，
                  <el-checkbox-group v-model="formData.politEnterprise.ipPledge2">
                    <el-checkbox :label="1">开展专利保险</el-checkbox>
                    <el-checkbox :label="2">开展商标保险</el-checkbox>
                    <el-checkbox :label="3">投保的专利和商标件数合计达到 10 件（含 10 件）及以上</el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
              <el-form-item label="开展知识产权信息利用项目（可多选）" prop="politEnterprise.ipInfoUtilize"
                            style="text-align: left">
                <el-checkbox-group v-model="formData.politEnterprise.ipInfoUtilize">
                  <el-checkbox :label="1">自 2022
                    年迄今，自主开展知识产权信息利用，形成专利导航、专利预警分析、知识产权分析评议、知识产权风险评估报告
                  </el-checkbox>
                  <br>
                  <el-checkbox :label="2">自 2022 年迄今，承担区级以上相关部门知识产权信息利用项目</el-checkbox>
                  <br>
                  <el-checkbox :label="3">在知识产权信息分析利用项目中建立研发人员、专利分析人员沟通协调机制
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="获得相关资质或奖励（可多选）" prop="politEnterprise.credentialsRewards"
                            style="text-align: left">
                <el-checkbox-group v-model="formData.politEnterprise.credentialsRewards">
                  <el-checkbox :label="1">近 3 年内，企业有产品列入上海市创新产品推荐目录</el-checkbox>
                  <br>
                  <el-checkbox :label="2">被列入工信部、上海市“专精特新”企业</el-checkbox>
                  <br>
                  <el-checkbox :label="3">被列入上海市商务委认定或备案的外资研发中心</el-checkbox>
                  <br>
                  <el-checkbox :label="4">具有无效宣告请求维持、专利诉讼胜诉等专利维权典型案例</el-checkbox>
                  <br>
                  <el-checkbox :label="5">列入上海市重点商标保护名录</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并认定
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评认定的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ShPatentDemoEnterprise"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import BasicInfo from '@/components/BasicInfo'
import Breadcrumb from '@/components/Breadcrumb'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import InputNumber from '@/components/InputNumber'
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'shPatentPolitEnterprise'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref('')
const formData = ref({
  politEnterprise: {
    notBadIntegrityRecord: 0,
    hasPatentStrategy: 0,
    hasMainBusiness: 0,
    hasWorkProgramme: 0,
    perfectIpSystem: 2,
    perfectIpInstitution1: undefined,
    perfectIpInstitution2: undefined,
    perfectIpInstitution3: undefined,
    ipGuarantee1: undefined,
    ipGuarantee2: undefined,
    ipPublicize: [],
    ipPublicizeCount: 0,
    pctCount: 0,
    highValueCultivate1: 0,
    highValueCultivate2: 0,
    highValueCultivate3: 0,
    highValueCultivate4: 0,
    ipTransference1: 0,
    ipTransference1Count: 0,
    ipTransference2: 0,
    ipTransference3: [],
    ipTransference4: 0,
    ipPledge1: 1,
    ipPledge1Count: 0,
    ipPledge2: [],
    ipPledge2Single: 0,
    ipInfoUtilize: [],
    credentialsRewards: [],
  },
})
onMounted(() => {
  getConditionByPath()
})
const getConditionByPath = () => {
  companyApi.getConditionByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value.politEnterprise = resp.data
      formData.value.politEnterprise.ipPublicize = formData.value.politEnterprise.ipPublicize || []
      formData.value.politEnterprise.ipTransference3 = formData.value.politEnterprise.ipTransference3 || []
      formData.value.politEnterprise.ipPledge2 = formData.value.politEnterprise.ipPledge2 || []
      formData.value.politEnterprise.ipInfoUtilize = formData.value.politEnterprise.ipInfoUtilize || []
      formData.value.politEnterprise.credentialsRewards = formData.value.politEnterprise.credentialsRewards || []
    }
  })
}
const isResult = ref(true)
const loading = ref(false)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {
    ...basicInfo.value.formData, condition: formData.value.politEnterprise, evaluationType: evaluationType.value
  }
  return companyApi.saveConditionByPath(urlPath, company).then((resp) => {
    if (resp.code == 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getConditionByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {
      condition: formData.value.politEnterprise, ...basicInfo.value.formData, evaluationType: evaluationType.value
    }
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitConditionByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {

          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

const handlePolitEnterpriseChangeIpPublicize = () => {
  if (!formData.value.politEnterprise.ipPublicize.includes(3))
    formData.value.politEnterprise.ipPublicizeCount = 0
}
const handleChangePolitEnterpriseIpTransference = () => {
  if (formData.value.politEnterprise.ipTransference1 === 1)
    formData.value.politEnterprise.ipTransference1Count = 0
}
</script>
<style scoped lang="scss">
.sh-patent-polit-enterprise-transform-container {

  .if-true-show, .normal-show {
    text-align: left;

    :deep .el-form-item .el-form-item {
      display: inline-block;
    }

    :deep .el-input--small {
      display: inline-block;
      width: 100px;
      padding-bottom: 4px;
    }

    :deep .el-radio-group {
      vertical-align: middle;
    }

    :deep .el-checkbox-group {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .if-true-show {
    margin-left: 20px;
  }
}

.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
}
</style>
