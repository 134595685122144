<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class="condition spec-ultra-new-smes-container">
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">"专精特新"中小企业认定</div>
      <BasicInfo ref="basicInfo" :is-financial="true" :is-int-prop="true"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'">
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-row :gutter="50">
                <el-col :span="8">
                  <el-form-item label="主导产品名称（中文）" required prop="productName">
                    <template #label>
                      <el-tooltip placement="right">
                        <template #content>
                          主导产品是指企业核心技术在产品中发挥重要作用，且产品收入之和占企业同期营业收入比重超过 50%。
                        </template>
                        <span>
                          <span>主导产品名称（中文）</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                      </el-tooltip>
                    </template>
                    <el-input v-model="formData.productName"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="从事该产品领域的时间（单位：年）" required prop="productYear">
                    <el-input v-model="formData.productYear"/>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item v-if="false" label="主导产品类别（4位数代码及名称）" required prop="productCategory">
                    <el-input v-model="formData.productCategory"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="近3年是否获得国家级科技奖励" required prop="nationalLevelRewards">
                <el-radio-group v-model="formData.nationalLevelRewards">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
                <div class="if-true-show" v-if="formData.nationalLevelRewards === 1">
                  如是，请填写：
                  年份：
                  <el-input size="small" v-model="formData.nationalLevelRewardsYear"/>
                  年，
                  名称：
                  <el-input size="small" v-model="formData.nationalLevelRewardsName"/>
                  ，
                  排名：
                  <input-number size="small" v-model="formData.nationalLevelRewardsRank"
                                @update:modelValue="formData.nationalLevelRewardsRank = $event" :is-decimal="false"/>

                </div>
              </el-form-item>
              <el-form-item label="近3年是否获得省级科技奖励" required prop="provincialLevelRewards">
                <el-radio-group v-model="formData.provincialLevelRewards">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
                <div class="if-true-show" v-if="formData.provincialLevelRewards === 1">
                  如是，请填写：
                  年份：
                  <el-input size="small" v-model="formData.provincialLevelRewardsYear"/>
                  年，
                  名称：
                  <el-input size="small" v-model="formData.provincialLevelRewardsName"/>
                  ，
                  排名：
                  <input-number size="small" v-model="formData.provincialLevelRewardsRank"
                                @update:modelValue="formData.provincialLevelRewardsRank = $event" :is-decimal="false"/>
                </div>
              </el-form-item>
              <el-form-item label="近3年进入“创客中国”中小企业创新创业大赛全国500强企业组名单" required
                            prop="isChinaMaker500">
                <el-radio-group v-model="formData.isChinaMaker500">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
                <div class="if-true-show" v-if="formData.isChinaMaker500 === 1">
                  如是，请填写：
                  年份：
                  <el-input size="small" v-model="formData.chinaMaker500Year"/>
                  年，
                  排名：
                  <input-number size="small" v-model="formData.chinaMaker500Rank"
                                @update:modelValue="formData.chinaMaker500Rank = $event" :is-decimal="false"/>
                </div>
              </el-form-item>
              <el-form-item label="主导产品是否在产业链关键环节及关键领域“补短板”“锻长板”“填空白”取得实际成效" required
                            prop="isNiubi">
                <el-radio-group v-model="formData.isNiubi">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
                <div class="if-true-show" style=" width: 100%; margin-left: 0" v-if="formData.isNiubi === 1">
                  如是，请填写：
                  “补短板”或“锻长板”的产品名称：
                  <el-input size="small" v-model="formData.niubiShort"/>
                  ，<br>
                  或填补国内（国际）空白的领域：
                  <el-input size="small" v-model="formData.niubiSpace"/>
                  ，或达到国内领先、国际先进水平的产品名称：
                  <el-input size="small" v-model="formData.niubiAdvanced"/>
                  。
                </div>
              </el-form-item>
              <el-form-item label="主导产品是否属于工业“六基”领域" required prop="isDomanBasic6">
                <el-radio-group v-model="formData.isDomanBasic6">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
                <div class="if-true-show" v-if="formData.isDomanBasic6 === 1">
                  如是，请打钩:
                  <el-checkbox-group v-model="formData.domanBasic6">
                    <el-checkbox :label="1">核心基础零部件</el-checkbox>
                    <el-checkbox :label="2">核心基础元器件</el-checkbox>
                    <el-checkbox :label="3">关键软件</el-checkbox>
                    <el-checkbox :label="4">先进基础工艺</el-checkbox>
                    <el-checkbox :label="5">关键基础材料</el-checkbox>
                    <el-checkbox :label="6">产业技术基础</el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
              <el-form-item label="主导产品是否属于中华老字号名录" required prop="isChinaBrand">
                <el-radio-group v-model="formData.isChinaBrand">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="主导产品为知名大企业直接配套" required prop="isDirectMatch">
                <el-radio-group v-model="formData.isDirectMatch">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="企业数字化转型水平" required prop="digitalLevel">
                <template #label>
                  <el-tooltip placement="right">
                    <template #content>
                      数字化水平测试等级结果证明（工信部优质中小企业梯度培育平台自测获取）
                      <br>https://zjtx.miit.gov.cn/zxqySy/main
                    </template>
                    <span>
                          <span>企业数字化转型水平</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                  </el-tooltip>
                </template>
                <el-radio-group v-model="formData.digitalLevel">
                  <el-radio :label="1">一级</el-radio>
                  <el-radio :label="2">二级</el-radio>
                  <el-radio :label="3">三级及以上</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="获得省级以上质量奖荣誉" required prop="qualityHonorTotal">
                <div class="normal-show">
                  国家级、省级质量奖
                  <input-number size="small" v-model="formData.qualityHonorTotal"
                                @update:modelValue="formData.qualityHonorTotal = $event" :is-decimal="false"/>
                  项。<br>
                  其中：国家级质量奖
                  <input-number size="small" v-model="formData.qualityHonorNational"
                                @update:modelValue="formData.qualityHonorNational = $event" :is-decimal="false"/>
                  项，省级质量奖
                  <input-number size="small" v-model="formData.qualityHonorProvincial"
                                @update:modelValue="formData.qualityHonorProvincial = $event" :is-decimal="false"/>
                  项。
                </div>
              </el-form-item>
              <el-form-item label="企业获得的管理体系认证情况（可多选）" required prop="qualityMangeCert">
                <el-checkbox-group v-model="formData.qualityMangeCert">
                  <!--<el-checkbox :label="1">未建立质量管理体系</el-checkbox>-->
                  <el-checkbox :label="2">ISO9000质量管理体系认证</el-checkbox>
                  <el-checkbox :label="3">ISO14000环境管理体系认证</el-checkbox>
                  <el-checkbox :label="4">OHSAS18000职业安全健康管理体系认证</el-checkbox>
                  <el-checkbox :label="5">其他</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="企业自主品牌" required prop="ownBrandCount">
                <template #label>
                  <el-tooltip placement="right">
                    <template #content>
                      所称拥有自主品牌是指主营业务产品或服务具有自主知识产权，且符合下列条件之一：
                      <br>1.产品或服务品牌已经国家知识产权局商标局正式注册。
                      <br>2.产品或服务已经实现收入。
                    </template>
                    <span>
                          <span>企业自主品牌</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                  </el-tooltip>
                </template>
                <div class="normal-show">
                  自有品牌
                  <input-number size="small" v-model="formData.ownBrandCount"
                                @update:modelValue="formData.ownBrandCount = $event" :is-decimal="false"/>
                  项，名称：
                  <el-input size="small" v-model="formData.ownBrandName"/>
                  。<br>
                  省级以上著名品牌
                  <input-number size="small" v-model="formData.ownBrandProvCount"
                                @update:modelValue="formData.ownBrandProvCount = $event" :is-decimal="false"/>
                  项，名称：
                  <el-input size="small" v-model="formData.ownBrandProvName"/>
                  。
                </div>
              </el-form-item>
              <el-form-item label="作为主要起草单位制修订的已批准发布标准数量和名称" required prop="standardTotal">
                <div class="normal-show">
                  国际、国家、行业标准总数
                  <input-number size="small" v-model="formData.standardTotal"
                                @update:modelValue="formData.standardTotal = $event" :is-decimal="false"/>
                  项<br>
                  其中：国际标准
                  <input-number size="small" v-model="formData.standardInternational"
                                @update:modelValue="formData.standardInternational = $event" :is-decimal="false"/>
                  项，国家标准
                  <input-number size="small" v-model="formData.standardNational"
                                @update:modelValue="formData.standardNational = $event" :is-decimal="false"/>
                  项，行业标准
                  <input-number size="small" v-model="formData.standardIndustry"
                                @update:modelValue="formData.standardIndustry = $event" :is-decimal="false"/>
                  项。
                </div>
              </el-form-item>
              <el-form-item label="所属领域是否符合本市产业导向" required prop="isIndustrialOrientation">
                <el-radio-group v-model="formData.isIndustrialOrientation"
                                @change="handleChangeIsIndustrialOrientation">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
                <br/>
                <div class="if-true-show" style="width: 100%; margin-left: 0"
                     v-if="formData.isIndustrialOrientation === 1">
                  属于“3+6”新型产业体系：
                  <el-checkbox-group v-model="formData.industrial36">
                    <el-checkbox :label="1">三大先导产业</el-checkbox>
                    <el-checkbox :label="2">六大重点产业</el-checkbox>
                  </el-checkbox-group>
                  <br>或属于战略性新兴产业领域（国家统计局令第23号）：
                  <el-checkbox-group v-model="formData.industrialStrategy">
                    <el-checkbox :label="1">新一代信息技术</el-checkbox>
                    <el-checkbox :label="2">高端装备制造</el-checkbox>
                    <el-checkbox :label="3">新材料</el-checkbox>
                    <el-checkbox :label="4">生物技术</el-checkbox>
                    <el-checkbox :label="5">新能源汽车</el-checkbox>
                    <el-checkbox :label="6">新能源</el-checkbox>
                    <el-checkbox :label="7">节能环保</el-checkbox>
                    <el-checkbox :label="8">数字创意</el-checkbox>
                    <el-checkbox :label="9">相关服务业</el-checkbox>
                  </el-checkbox-group>
                  <br>或属于区级重点导向产业：
                  <el-input size="small" v-model="formData.industrialDistrictKey"/>
                  <br>或属于本市其他重点产业：
                  <el-input size="small" v-model="formData.industrialLocalKey"/>
                </div>
              </el-form-item>
              <el-form-item label="细分市场领先地位" required prop="occupancy">
                <div class="normal-show">
                  主导产品或服务上年度国内市场占有率为
                  <input-number size="small" v-model="formData.occupancy"
                                @update:modelValue="formData.occupancy = $event" :is-decimal="false"/>
                  % <br/>
                  本市排名：第
                  <input-number size="small" v-model="formData.localRank"
                                @update:modelValue="formData.localRank = $event" :is-decimal="false"/>
                  名，
                  全国排名：第
                  <input-number size="small" v-model="formData.nationwideRank"
                                @update:modelValue="formData.nationwideRank = $event" :is-decimal="false"/>
                  名，
                  国际排名：第
                  <input-number size="small" v-model="formData.internationalRank"
                                @update:modelValue="formData.internationalRank = $event" :is-decimal="false"/>
                  名。
                </div>
              </el-form-item>
              <el-form-item label="是否具有特色资源或技术进行研制生产，提供独具特色的产品或服务" required
                            prop="hasCharacteristic">
                <el-radio-group v-model="formData.hasCharacteristic">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="是否实现绿色低碳发展" required prop="isGreenDev">
                <el-radio-group v-model="formData.isGreenDev" @change="handleChangeIsGreenDev">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
                <br>
                <div class="if-true-show" v-if="formData.isGreenDev === 1">
                  如是，请打钩:
                  <el-checkbox-group v-model="formData.titleAttestation">
                    <el-checkbox :label="1">绿色产品</el-checkbox>
                    <el-checkbox :label="2">绿色车间</el-checkbox>
                    <el-checkbox :label="3">绿色工厂</el-checkbox>
                    <el-checkbox :label="4">能源管理体系认证</el-checkbox>
                    <el-checkbox :label="5">碳排放管理体系认证</el-checkbox>
                    <el-checkbox :label="6">产品碳足迹认证</el-checkbox>
                    <el-checkbox :label="7">其他</el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
              <el-form-item label="是否获得区级及以上技术创新、品牌、质量、人才等奖项、资金支持、称号认定" required
                            prop="hasDistrictTitle">
                <el-radio-group v-model="formData.hasDistrictTitle" @change="handleChangeHasDistrictTitle">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
                <div class="if-true-show" style="margin-left: 0" v-if="formData.hasDistrictTitle === 1">
                  如是，请打钩:
                  <el-checkbox-group v-model="formData.districtTitle">
                    <el-checkbox :label="1">高新技术企业</el-checkbox>
                    <el-checkbox :label="2">上海市科技小巨人企业(含培育)</el-checkbox>
                    <el-checkbox :label="3">上海市品牌引领示范企业（含品牌培育）</el-checkbox>
                    <el-checkbox :label="4">上海市专利工作试点示范单位</el-checkbox>
                    <el-checkbox :label="5">上海产学研合作优秀项目奖</el-checkbox>
                    <el-checkbox :label="6">“创客中国”中小企业创新创业大赛上海赛区100强</el-checkbox>
                    <el-checkbox :label="7">其他</el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
              <el-form-item label="企业有改制上市计划，已完成股份制改制" required prop="isPlanIpo">
                <el-radio-group v-model="formData.isPlanIpo">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="建立研发机构级别" required prop="hasRdInstitutions">
                <el-radio-group v-model="formData.hasRdInstitutions" @change="handleChangeHasRdInstitutions">
                  <el-radio :label="0">未建立研发机构</el-radio>
                  <el-radio :label="1">已建立研发机构</el-radio>
                </el-radio-group>
                <div class="if-true-show" style="width: 100%; margin-left: 0" v-if="formData.hasRdInstitutions === 1">
                  国家级
                  <input-number size="small" v-model="formData.instNational"
                                @update:modelValue="formData.instNational = $event" :is-decimal="false"/>
                  个，
                  省级
                  <input-number size="small" v-model="formData.instProvincial"
                                @update:modelValue="formData.instProvincial = $event" :is-decimal="false"/>
                  个，
                  市级
                  <input-number size="small" v-model="formData.instCity" @update:modelValue="formData.instCity = $event"
                                :is-decimal="false"/>
                  个，
                  市级以下
                  <input-number size="small" v-model="formData.instCityDown"
                                @update:modelValue="formData.instCityDown = $event" :is-decimal="false"/>
                  个。<br>
                  <span class="spanInline">其中：技术研究院</span>
                  <el-checkbox-group v-model="formData.researchInstitute">
                    <el-checkbox :label="1">国家级</el-checkbox>
                    <el-checkbox :label="2">市级</el-checkbox>
                    <el-checkbox :label="3">区级</el-checkbox>
                  </el-checkbox-group>
                  <br>
                  <span class="spanInline">其中：企业技术中心</span>
                  <el-checkbox-group v-model="formData.technologyCenter">
                    <el-checkbox :label="1">国家级</el-checkbox>
                    <el-checkbox :label="2">市级</el-checkbox>
                    <el-checkbox :label="3">区级</el-checkbox>
                  </el-checkbox-group>
                  <br>
                  <span class="spanInline">其中：企业工程中心</span>
                  <el-checkbox-group v-model="formData.engineeringCenter">
                    <el-checkbox :label="1">国家级</el-checkbox>
                    <el-checkbox :label="2">市级</el-checkbox>
                    <el-checkbox :label="3">区级</el-checkbox>
                  </el-checkbox-group>
                  <br>
                  <span class="spanInline">其中：工业设计中心</span>
                  <el-checkbox-group v-model="formData.designCenter">
                    <el-checkbox :label="1">国家级</el-checkbox>
                    <el-checkbox :label="2">市级</el-checkbox>
                    <el-checkbox :label="3">区级</el-checkbox>
                  </el-checkbox-group>
                  <br>
                  <span class="spanInline">其中：制造业创新中心</span>
                  <el-checkbox-group v-model="formData.innovationCenter">
                    <el-checkbox :label="1">国家级</el-checkbox>
                    <el-checkbox :label="2">市级</el-checkbox>
                    <el-checkbox :label="3">区级</el-checkbox>
                  </el-checkbox-group>
                  <br>
                  <span class="spanInline">其中：院士专家工作站</span>
                  <el-radio-group v-model="formData.expertsWorkstation">
                    <el-radio :label="1">有</el-radio>
                    <el-radio :label="0">无</el-radio>
                  </el-radio-group>
                  <br>
                  <span class="spanInline">其中：博士后工作站</span>
                  <el-radio-group v-model="formData.postdoctoralFellowWorkstation">
                    <el-radio :label="1">有</el-radio>
                    <el-radio :label="0">无</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>

            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统认定" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并认定
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交认定的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看认定结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ShPatentDemoEnterprise"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import BasicInfo from '@/components/BasicInfo'
import Breadcrumb from '@/components/Breadcrumb'
import {CircleCheckFilled, Finished, Promotion, QuestionFilled} from "@element-plus/icons-vue";
import InputNumber from '@/components/InputNumber'
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'specUltraNewSmes'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref('')
const formData = ref({
  productName: '',
  productYear: '3',
  productCategory: '0000',
  nationalLevelRewards: 0,
  nationalLevelRewardsYear: '',
  nationalLevelRewardsName: '',
  nationalLevelRewardsRank: '',
  provincialLevelRewards: 0,
  provincialLevelRewardsYear: '',
  provincialLevelRewardsName: '',
  provincialLevelRewardsRank: '',

  isChinaMaker500: 0,
  chinaMaker500Year: '',
  chinaMaker500Rank: '',
  isNiubi: 0,
  niubiShort: '',
  niubiSpace: '',
  niubiAdvanced: '',
  niubiInfo: '',
  isDomanBasic6: 0,
  domanBasic6: [],
  isChinaBrand: 0,
  isDirectMatch: 0,
  directMatch1: '',
  directMatch2: '',
  directMatch3: '',
  digitalLevel: 1,
  qualityHonorTotal: 0,
  qualityHonorNational: 0,
  qualityHonorProvincial: 0,
  qualityHonorName: 0,
  qualityMangeCert: [1],
  qualityMangeOther: 0,
  ownBrandCount: 0,
  ownBrandName: 0,
  ownBrandProvCount: 0,
  ownBrandProvName: 0,
  standardTotal: 0,
  standardInternational: 0,
  standardNational: 0,
  standardIndustry: 0,
  standardName: 0,
  isIndustrialOrientation: 0,
  industrial36: [],
  industrialStrategy: [],
  industrialDistrictKey: '',
  industrialLocalKey: '',
  occupancy: 0,
  localRank: 0,
  nationwideRank: 0,
  internationalRank: 0,
  hasCharacteristic: 0,
  characteristicInfo: 0,
  isGreenDev: 0,
  titleAttestation: [],
  titleAttestationOther: '',
  hasDistrictTitle: 0,
  districtTitle: [],
  isPlanIpo: 0,
  hasRdInstitutions: 0,
  instNational: 0,
  instProvincial: 0,
  instCity: 0,
  instCityDown: 0,
  researchInstitute: [],
  technologyCenter: [],
  engineeringCenter: [],
  designCenter: [],
  innovationCenter: [],
  expertsWorkstation: 0,
  postdoctoralFellowWorkstation: 0
})
onMounted(() => {
  getConditionByPath()
})
const getConditionByPath = () => {
  companyApi.getConditionByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
      formData.value.domanBasic6 = formData.value.domanBasic6 || []
      formData.value.qualityMangeCert = formData.value.qualityMangeCert || [1]
      formData.value.industrial36 = formData.value.industrial36 || []
      formData.value.industrialStrategy = formData.value.industrialStrategy || []
      formData.value.titleAttestation = formData.value.titleAttestation || []
      formData.value.districtTitle = formData.value.districtTitle || []
      formData.value.researchInstitute = formData.value.researchInstitute || []
      formData.value.technologyCenter = formData.value.technologyCenter || []
      formData.value.engineeringCenter = formData.value.engineeringCenter || []
      formData.value.designCenter = formData.value.designCenter || []
      formData.value.innovationCenter = formData.value.innovationCenter || []
    }
  })
}

const isResult = ref(true)
const loading = ref(false)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveConditionByPath(urlPath, company).then((resp) => {
    if (resp.code == 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getConditionByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitConditionByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {

          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          console.log('error', fields)
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

const handleChangeIsIndustrialOrientation = () => {
  formData.value.industrial36 = []
  formData.value.industrialStrategy = []
}
const handleChangeIsGreenDev = () => {
  formData.value.titleAttestation = []
}
const handleChangeHasDistrictTitle = () => {
  formData.value.districtTitle = []
}
const handleChangeHasRdInstitutions = () => {
  formData.value.instNational = 0
  formData.value.instProvincial = 0
  formData.value.instCity = 0
  formData.value.instCityDown = 0
  formData.value.researchInstitute = []
  formData.value.technologyCenter = []
  formData.value.engineeringCenter = []
  formData.value.designCenter = []
  formData.value.innovationCenter = []
  formData.value.expertsWorkstation = 0
  formData.value.postdoctoralFellowWorkstation = 0
}
</script>
<style scoped lang="scss">
.spec-ultra-new-smes-container {

  .if-true-show, .normal-show {
    text-align: left;

    :deep .el-form-item .el-form-item {
      display: inline-block;
    }

    :deep .el-input--small {
      display: inline-block;
      width: 100px;
      padding-bottom: 4px;
    }

    :deep .el-radio-group {
      vertical-align: middle;
    }

    :deep .el-checkbox-group {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .if-true-show {
    margin-left: 20px;
  }
}

.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
}
</style>
