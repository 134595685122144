import request from '@/utils/request'

// 轮播图资讯
export function getNewsCarousel() {
    return request({
        url: '/sme/information/qd/carousel',
        method: 'get',
        params: {}
    })
}

// 置顶资讯
export function getNewsTop() {
    return request({
        url: '/sme/information/qd/toplist',
        method: 'get',
        params: {}
    })
}

// 申报公告 type = 3
export function getNewsPage(pageSize, pageNum, type) {
    return request({
        url: '/sme/information/qd/page',
        method: 'get',
        params: {pageSize, pageNum, type}
    })
}


//资讯详细
export function getNewsDetail(code) {
    return request({
        url: '/sme/information/qd/' + code,
        method: 'get',
        params: {}
    })
}


//单页面列表
export function getSinglePageList(type) {
    return request({
        url: `/sme/onepage/qds/list/`,
        method: 'get',
        params: {type}
    })
}

//单页面详情
export function getSinglePage(code) {
    return request({
        url: `/sme/onepage/qd/${code}`,
        method: 'get',
        params: {}
    })
}

export default {
    getNewsPage, getSinglePage, getNewsDetail, getNewsTop, getNewsCarousel
}
