<template>
  <div class="common-layout fixed">
    <el-container>
      <HeaderView title=""/>
      <el-main>
        <div class="mobile-news">
          <div class="mobile-news-carousel">
            <swiper
                class="mobile-news-swiper"
                :modules='modules'
                :loop="true"
                :slides-per-view="1"
                :space-between="10"
                :navigation="navigation"
                :autoplay="true"
                :pagination="{ clickable: true }"
                :scrollbar="{ draggable: true }"
            >
              <swiper-slide v-for="(item) in carouselList" :key="item.id" @click='onClickCart(item.id)'>
                <img class="swiper-img" :src='`${baseUrl+ item.image}`'>
                <div class='news-title'>
                  <p>{{ item.title }}</p>
                </div>
              </swiper-slide>
              <div class="swiper-button-prev"/>
              <div class="swiper-button-next"/>
            </swiper>
          </div>
          <div class="mobile-news-title">News Headlines<span class="text">资讯头条</span></div>
          <div class='mobile-news-list'>
            <router-link v-for="(item) in newsTopList" :key="item.id" :to="`/mobile/news-details?code=${item.id}`">
              <el-row>
                <el-col :span="18"><span class="mobile-news-list-tit">{{ item.title }}</span></el-col>
                <el-col :span="6"><span class="mobile-news-list-date">{{ item.releaseDate.substring(0, 10) }}</span></el-col>
              </el-row>
            </router-link>
          </div>
          <div class="mobile-news-list-more">
            <router-link to="/mobile/news" class="more"><span>--</span>查看更多<span>--</span></router-link>
          </div>
        </div>
        <div style='clear: both'></div>
        <div class="mobile-services-single-page">
          <div class="mobile-services-item">
            <a class="mobile-services-item-evaluation" href='https://zjtx.sme.com.cn/' target='_blank'></a>
          </div>
          <div class="mobile-services-item">
            <router-link class="mobile-services-item-benefit" :to="`/mobile/single-page-benefit`"></router-link>
          </div>
        </div>
        <div style='clear: both'></div>
        <div class="mobile-services">
          <div class="mobile-services-title">Enterprise Service<span class="text">企业综合服务项目</span></div>
          <div class="mobile-services-list">
            <el-row :gutter="20">
              <el-col :span="12" v-for="(item) in serviceItemList" :key="item.id">
                <div class="mobile-services-item">
                  <router-link :to="`/mobile/service-details?code=${item.id}`">
                    <img :src='`${baseUrl+ item.image}`'>
                    <div class="mask">
                      <h3>{{ item.name }}</h3>
                      <router-link @click.prevent="ApplyFor(item.id)" :to="`#`">立即申请</router-link>
                    </div>
                  </router-link>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-main>
      <el-footer>
        <FooterView/>
      </el-footer>
    </el-container>
    <div class='mobile-message-box' v-show='isShow'>
      <div class='shade'></div>
      <div class='content'>
        <img src='@/assets/fa5-check-circle-fas.svg' >
        <h4>您的服务申请已提交成功</h4>
        <p>*系统后台已收到您的服务申请，我们的服务人员将尽快与您取得联系，为您提供后续的服务支持。</p>
      </div>
    </div>
  </div>
</template>

<script setup>

import HeaderView from '@/components/mobile/Header.vue'
import FooterView from '@/components/mobile/Footer.vue'
import {onMounted, ref} from "vue";
import router from '@/router'

import {Swiper, SwiperSlide} from 'swiper/vue' // swiper 所需组件
import {Autoplay, Navigation, A11y} from 'swiper'// 分页器
// import 'swiper/swiper-bundle.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import {getNewsCarousel, getNewsTop} from "@/api/news";
import {getServiceItemDetails, getServiceItems, serviceApplication} from "@/api/service";
import {ElMessageBox} from "element-plus";

const modules = [Autoplay, Navigation, A11y]
const navigation = ref({
  nextEl: '.swiper-button-next',
  prevEl: '.swiper-button-prev'
})
const baseUrl = process.env.VUE_APP_BASE_API
const carouselList = ref()
const newsTopList = ref()
const serviceItemList = ref()

const onClickCart = function (id) {
  router.push('mobile/news-details?code=' + id);
}

const randomNumber = () => {
  let array = ["01", "02", "03", "04", "05"]
  let i = Math.floor(Math.random() * array.length + 1)
  return i;
}

onMounted(() => {
  //document.title = "企业综合服务系统"
  getNewsCarousel().then(response => {
    carouselList.value = response.data
  })

  getNewsTop().then(response => {
    newsTopList.value = response.data
  })

  getServiceItems().then(response => {
    serviceItemList.value = response.data
  })

})
const loading = ref(false)
const isShow=ref(false)
const ApplyFor = function (code) {
  loading.value = true;
  getServiceItemDetails(code).then(response => {
    serviceApplication(response.data.id).then((res) => {
      loading.value = false;
      switch (res.code) {
        case 200:
          isShow.value = true
          setTimeout(function () {
            isShow.value = false
          }, 3000)
          break
        case 9001:
          ElMessageBox.confirm("您的企业信息尚未完善，请前往“个人中心”完善信息", "系统提示", {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: "warning",
          }).then(() => {
            router.push("/mobile/account");
          }).catch(() => {
          });
          break;
        case 9002:
          ElMessageBox.alert("您已申请过该服务项目，无需重复申请!", "系统提示", {
            type: "warning",
          }).catch(()=>{})
          break;
      }

    }).catch(() => {
      loading.value = false;
    });
  }).then(() => {

  })
}
</script>

<style lang="scss" scoped>
.common-layout {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;

  .el-container {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .el-footer {
    --el-footer-padding: 0;
    margin-top: 100px;
  }

  .el-main {
    padding: 0;

    .mobile-news {
      .mobile-news-title {
        font-size: 40px;
        color: #F2F2F2;
        font-weight: bold;
        position: relative;
        text-align: left;
        margin: 15px 0;
        padding: 0 10px;

        .text {
          font-size: 28px;
          color: #BDA26D;
          position: absolute;
          left: 27px;
          top: 5px;
        }
      }

      .mobile-news-carousel {
        height: 234px;
        background-color: #E4C895;

        .mobile-news-swiper {
          height: 234px;
          background-color: antiquewhite;

          .swiper-slide {
            cursor: pointer;

            .swiper-img {
              height: 234px;
              width: 100%;
            }
             image{
               height: 234px;
               width: 100%;
             }

            .news-title {
              background-color: rgba(217,212,202, 1);
              width: 100%;
              height: 46px;
              position: absolute;
              bottom: 0;
              left: 0;
              color: #000000;
              text-align: center;

              p {
                font-size: 16px;
                font-weight: normal;
                line-height: 46px;
                margin: 0;
                padding: 0 20px;
              }
            }
          }

          .swiper-button-next,
          .swiper-button-prev {
            --swiper-theme-color: #F6F6F6;
            --swiper-navigation-size: 20px;
            background: rgb(228, 200, 149);
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
          }

          .swiper-button-prev {
            top: 92px;
            left: 0;
          }

          .swiper-button-next {
            top: 92px;
            right: 0;
          }
        }
      }

      .mobile-news-list {
        text-align: left;
        width: 100%;
        font-size: 16px;

        a {
          padding: 10px 0;
          display: inline-block;
          text-decoration: none;
          color: #101010;
          width: 100%;

          .el-row{
            padding: 0 10px;
          }

          span.mobile-news-list-tit {
            display: inline-block;
            /*强制文本在一行内显示*/
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          }

          span.mobile-news-list-date {
            float: right;
            color: #8C9093;
          }
        }
      }

      .mobile-news-list-more {
        margin-top: 10px;
        text-align: center;

        .more {
          font-size: 14px;
          color: #101010;
          text-decoration: none;
        }
      }
    }

    .mobile-services-single-page {
      margin-top: 30px;

      .mobile-services-item {
        padding: 0 10px 10px 10px;
        border-radius: 4px;

        .mobile-services-item-benefit {
          display: block;
          width: 100%;
          aspect-ratio: 520 / 200;
          background: url("@/assets/benefit.png") center no-repeat;
          background-size: contain;
        }

        .mobile-services-item-evaluation {
          display: block;
          width: 100%;
          aspect-ratio: 520 / 200;
          background: url("@/assets/evaluation.png") center no-repeat;
          background-size: contain;

        }
      }
    }

    .mobile-services {
      .mobile-services-title {
        font-size: 39px;
        color: #F2F2F2;
        font-weight: bold;
        position: relative;
        text-align: left;
        margin: 15px 0;
        padding: 0 10px;
        .text {
          font-size: 28px;
          color: #BDA26D;
          position: absolute;
          left: 20px;
          top: -5px;
        }
      }

      .mobile-services-list {
        padding: 0 10px;

        .mobile-services-item {
          display: inline-block;
          width: 100%;
          height: 240px;
          overflow: hidden;
          margin-bottom: 20px;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
          position: relative;

          img {
            display: block;
            width: 100%;
            height: 240px;
            vertical-align: top;
            transition: all 0.5s;
          }

          img:hover {
            transform: scale(1.2);
          }

          .mask {
            position: absolute;
            width: 100%;
            height: 113px;
            left: 0;
            bottom: 0;
            text-align: center;
            color: #FFFFFF;

            h3 {
              font-size: 20px;
              margin: 0;
              padding: 30px 0 10px 0;
            }

            a {
              display: inline-block;
              width: 50%;
              height: 20px;
              line-height: 20px;
              text-align: center;
              text-decoration: none;
              color: #FFFFFF;
              font-size: 18px;
            }

            background-color: rgba(0, 0, 0, 0.2);
          }
        }

        .services-item:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }

  .mobile-message-box{

    .shade{
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.5);
    }

    .content{
      background: #ffffff;
      text-align: center;
      width: 75%;
      padding: 15px 25px;
      position: fixed;
      left: 8%;
      top: 26%;
      border-radius: 10px;
      color: rgba(16, 16, 16, 100);
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);

      img{
        width: 49px;
        height: 49px;
      }

      h4{
        font-size: 18px;
        color: #1F0757;
        margin: 15px 0;
      }

      p{
        margin: 0 auto;
        font-size: 12px;
        color: #151515;
        text-align: left;
        line-height: 21px;
      }

    }

  }

  &.fixed {
    .el-container {
      flex: 1;
    }

    .el-footer {
      --el-footer-height: unset;
    }
  }
}
</style>
