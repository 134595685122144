import CarrierConstruction from "@/views/pudongxinqu/fiscalPolicy/operatingCaliberResult/CarrierConstruction";
import Incubator from "@/views/pudongxinqu/fiscalPolicy/operatingCaliberResult/Incubator";
import ReduceCosts from "@/views/pudongxinqu/fiscalPolicy/operatingCaliberResult/ReduceCosts";
import CompetencyProjects from "@/views/pudongxinqu/fiscalPolicy/operatingCaliberResult/CompetencyProjects";
import HighGrowthProjects from "@/views/pudongxinqu/fiscalPolicy/operatingCaliberResult/HighGrowthProjects";
import AcceptanceProject from "@/views/pudongxinqu/fiscalPolicy/operatingCaliberResult/AcceptanceProject";

const parentPath = '/authentication'

export default [
    {path: `${parentPath}/carrier-construction`, name: 'carrier-construction-result', component: CarrierConstruction,},
    {path: `${parentPath}/incubator`, name: 'incubator-result', component: Incubator,},
    {path: `${parentPath}/reduce-costs`, name: 'reduce-costs-result', component: ReduceCosts,},
    {path: `${parentPath}/competency-projects`, name: 'competency-projects-result', component: CompetencyProjects,},
    {path: `${parentPath}/high-growth-projects`, name: 'high-growth-projects-result', component: HighGrowthProjects,},
    {path: `${parentPath}/acceptance-project`, name: 'acceptance-project-result', component: AcceptanceProject,},
]
