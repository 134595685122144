<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition high-growth-projects-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">浦东新区促进集成电路和新一代通信产业高质量发展专项</div>
      <BasicInfo ref="basicInfo" :is-financial="false" :is-int-prop="false"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="请选择符合您企业的专题类型："
                            required prop="meetType">
                <el-radio-group v-model="formData.meetType" style="text-align: left">
                  <el-radio :label="1" style="height: 200px;">
                    （一）鼓励产业链协同联动
                    <p class="radio-p">1、支持 IP 购买。对购买 IP（硅知识产权）开发高端芯片的集成电路设计企业，给予不超过合同金额 20%的奖励，
                    单个企业年度最高 300 万元。
                      <br>2、支持 IP 授权服务。对开展 IP 产品授权服务的集成电路设计企业，给予不超过合同金额 20%的奖励，单个企业年
                    度最高 300 万元。
                      <br>3、支持 EDA 软件购买。对购买 EDA 设计工具软件(含软件升级费用)的集成电路企业，给予不超过合同金额 20%
                    的奖励，单个企业年度最高 200 万元。
                      <br>4、支持首轮流片。对开展工程产品首轮流片的集成电路设计企业，按照该款产品首轮流片合同金额，给予不超过
                    合同金额 30%的奖励，单个企业年度最高 200 万元。对承接以上首轮流片服务的制造企业，给予不超过合同金额 30%的
                    奖励，单个制造企业年度最高 500 万元。
                      <br>5、支持 MPW（多项目晶圆）。对开展 MPW（多项目晶圆）项目的集成电路设计企业，给予不超过合同金额 30%
                    的奖励，单个企业年度最高 200 万元；对承接以上 MPW 项目的制造企业，给予不超过合同金额 30%的奖励，单个制造
                      企业年度最高 500 万元。</p>
                  </el-radio>
                  <el-radio :label="2" style="height: 160px;">
                    （二）支持首次示范推广应用
                    <p class="radio-p">1、在集成电路领域，对集成电路设计企业通过自主开发或者合作开发实现功能或性能重大突破的高端产品，在首
                    次流片后 2 年内销售额达到 500 万元以上，按不超过销售额20%的比例给予企业奖励。单个产品最高奖励 200 万元，单
                    个企业年度最高 500 万元。
                      <br>2、在新一代通信领域，支持企业开展新一代通信技术关键元器件、模组、设备的产业化，对相关产品年度销售金
                    额达到 3000 万元的企业，按不超过相关产品年度销售金额10%给予奖励，单个企业年度最高 500 万元。培育新一代通信技术解决方案集成商，加速推进新一代通信技术与垂直行
                    业的融合创新应用，对于年度销售收入超过 1000 万元的企业，按不超过其年度销售额的 10%给予奖励，单个企业年度最高 500 万元。</p>
                  </el-radio>
                  <el-radio :label="3" style="height: 120px;">
                    （三）配套支持
                    <p class="radio-p">对获得上海市级政策支持的集成电路及新一代通信项目，可在项目验收完成后，给予一定配套奖励。
                    对明确配套要求的，按市级部门立项时明确的区级支持要求给予配套支持。对未明确配套要求的，经区相关部门协
                    商一致，按照企业获得的上海市级项目扶持资金总额，给予
                    不超过企业获得上海市级项目扶持资金 50%的奖励。同一项
                    目各级政府奖励和资助总额不超过企业总投入的 50%，最高5000 万元。</p>
                  </el-radio>
                  <el-radio :label="0">以上均不符合</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="condition-form-tips">*注：同一项目、产品已经获得国家、上海市和区级其他财政资金资助的，不再予以支持；根据项目情况，择优资助。</div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPromoteDevelopmentOfIcAndCommunication"
}
</script>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import MenuBar from '@/components/MenuBar.vue'
import BasicInfo from '@/components/BasicInfo.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'pudongPromoteDevelopmentOfIcAndCommunication'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({
})
onMounted(() => {
  getProjectPudongByPath()
})
const getProjectPudongByPath = () => {
  companyApi.getProjectPudongByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveProjectPudongByPath(urlPath, company).then((resp) => {
    if (resp.code === 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getProjectPudongByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitProjectPudongByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

</script>
<style scoped lang="scss">
.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
  :deep .el-radio__label{
    white-space: normal;  /* 换行 */
  }

  :deep .el-checkbox__label{
    white-space: normal;  /* 换行 */
  }

  .radio-p {
    width: 1080px;
    word-wrap: break-word;
    background: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
    line-height: 21px;
    margin-top: 0;
    text-align: left;
  }
}
</style>
