<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>浦东新区科技发展基金产学研专项（电子信息）</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>工商注册、税收户管均在浦东新区，且经营状态正常、信用记录良好的企业
              </td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>专题1、集成电路
                <br>研究目标：加快集成电路关键领域核心技术联合攻关和产品开发，提升集成电路领域上下游企业协同创新能力，支持创新成果在浦东新区实施产业化。
                <br>研究内容：
                <br>1、面向汽车电子、工业控制等多层次行业应用领域的高端和大宗芯片产品研发。支持处理器芯片、传感芯片、通信芯片、存储芯片、功率芯片等芯片产品。
                <br>2、集成电路前后道工艺关键设备零部件和配套材料的研发。
                <br>3、具有自主知识产权的EDA和IP的研发。
                <br>4、支持基于RISC-V指令集架构的芯片研发；硅光、量子科技等前沿领域芯片研发；第三代半导体材料和器件研发；Chiplet等先进封装和测试技术研发。
                <br>执行期限：不超过2年，2025年6月30日前完成。
                <br>专题2、新一代通信和新型显示
                <br>研究目标：加快新一代通信和新型显示关键领域核心技术联合攻关和产品开发，支持创新成果在浦东新区实施产业化。
                <br>研究内容：
                <br>1、新一代通信支持基于5G及新一代通信技术(包括低轨卫星互联网、光通信、量子通信等) 前沿领域核心元器件（包括功率放大器、滤波器、大规模天线阵列等）、模块、终端，测试设备和测试方案的集成研发。
                <br>2、新型显示支持有机发光显示（AMOLED）、微型发光显示（MicroLED，MiniLED）、全息显示等新兴技术研发。
                <br>执行期限：不超过2年，2025年6月30日前完成。
              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>项目责任人应承诺所提交材料真实性，不含涉密内容；申报单位应当对申请材料的真实性进行审核。
              </td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>项目申报主体须由新区企业牵头，联合1-2家高校、科研院所、相关功能性平台等共同申报。项目承担方需要有较强的项目研究基础、人员配备和经费保障，合作单位为长三角区域内微电子、通信等领域的高校、科研院所、相关功能性平台，在合作项目相关技术领域达到国际国内领先水平。合作协议应是2022年7月1日后签署的协议。
              </td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>项目资助资金不超过项目总经费的30%，单个项目最高资助额度不超过200万元，项目经评审后择优立项。资助资金应保障合作机构的各项经费支出。
              </td>
              <td v-html='(result[4] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>已经获得国家、上海市和区级其他财政资金资助的项目不得申报本专项。
              </td>
              <td v-html='(result[5] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>同一企业原则上只能申报一个项目。同一申报企业承担本专项资金支持的项目尚未完成验收的，不再予以支持。
              </td>
              <td v-html='(result[6] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>产学研合作方项目牵头人原则上只能合作申报一个产学研项目。同一合作方牵头人承担本专项资金支持的项目尚未完成验收的，不再予以支持。
              </td>
              <td v-html='(result[7] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>企业应在生产经营中做好安全生产工作。
              </td>
              <td v-html='(result[8] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongTechnolonyDevelopmentSpecialElectronic"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
