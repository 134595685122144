<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>张江科学城专项政策（对成长型科技企业给予支持）</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>申报企业主营业务符合张江科学城产业主导方向，且原则上实地经营在张江科学城
              </td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>2023年营收较2022年营收增长率为正
              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>企业近三年研发投入和经营状况良好
              </td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>

          <p style="background: #e4e7ed; border-radius: 10px; padding: 20px; font-size: 14px;line-height: 23px;">
            1 、对2023年度营收500万元-2000万元（含）之间的企业，前三年营 收复合增长率25%以上，或前两年营收连续增长率35%以上，给予 补贴最高不超过30万元；
            <br>2 、对2023年度营收2000万元-5000万元（含）之间的企业，前三年 营收复合增长率20%以上，或前两年营收连续增长率30%以上，给 予补贴最高不超过50万元；
            <br>3 、对2023年度营收5000万元-2亿元（含）之间的企业，前三年营收 复合增长率15%以上，或前两年营收连续增长率25%以上，给予补 贴最高不超过100万元；
            <br>4 、对2023年度营收2亿元-5亿元（含）之间的企业，前三年营收复 合增长率10%以上，或前两年营收连续增长率20%以上，给予补贴 最高不超过200万元。
            <br>5、不满足营业收入要求。
          </p>
          <p>
            结果：<span style="color: #ff0000;font-weight: bold">{{(result[3] || {option:''}).option}}</span>
          </p>

          <div>
            {{}}
          </div>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ZhangjiangScienceCitySpecialGrowth"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
