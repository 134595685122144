import request from '@/utils/request'

// 临时保存
export function save(data) {
    return request({
        url: '/sme/company/save',
        method: 'post',
        data
    })
}

// 提交申请表
export function submit(data) {
    return request({
        url: '/sme/company/apply',
        method: 'post',
        data
    })
}

// 查询申请表
export function getApply() {
    return request({
        url: '/sme/company/getApply',
        method: 'get'
    })
}

// 查询是否认定
export function getResultCount() {
    return request({
        url: '/sme/result/count',
        method: 'get'
    })
}

// 结果详情
export function getResultDetail(data) {
    return request({
        url: '/sme/result/listResults',
        method: 'post',
        data: data
    })
}

// 诊断结果
export function getResultList() {
    return request({
        url: '/sme/result/evaResults',
        method: 'get'
    })
}

// 小巨人评分结果列表
export function getResultGiants() {
    return request({
        url: '/sme/result/giants',
        method: 'get'
    })
}

export function getGbtType() {
    return request({
        url: '/system/gbt4754/bigTypes',
        method: 'post'
    })
}

export function getGbtTypeByCode(industryCode) {
    return request({
        url: '/system/gbt4754/smallTypes',
        method: 'post',
        data: {industryCode}
    })
}
