<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>上海市集成电路和软件企业核心团队专项奖励办法</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>企业在本市设立两年以上（新引进企业除外），依法纳税，信用记录良好；</td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>核心团队成员申报年度社保需在上海缴纳，且个人信用记录良好；</td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>企业从事业务领域需符合产业发展导向，同时具有较强的创新能力，近两年知识产权拥有量稳定增长； </td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>企业2022年经审计的主营业务收入需达到“奖励办法”的规定，且为首次:
                <br>首次突破200亿元、100亿元、50亿元、10亿元的集成电路设计企业；
                <br>首次突破30亿元、20亿元、10亿元、5亿元的集成电路装备企业；
                <br>首次突破30亿元、20亿元、10亿元、5亿元的集成电路材料企业；
                <br>首次突破15亿元、10亿元、5亿元、2.5亿元的集成电路电子设计自动化（EDA）工具开发企业；
                <br>首次突破200亿元、100亿元、50亿元、10亿元的软件企业；
                <br>首次突破15亿元、10亿元、5亿元、2.5亿元的基础软件、工业软件和信息安全软件企业。
              </td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ShanghaiInnovationFund"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
