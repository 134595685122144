<template>
  <div class="home">
    <HeaderView title="服务详情" />
<!--    <div class="banner">-->
<!--    </div>-->
    <div class='main-container'>
      <div class="services">
        <div class="title">Enterprise Services<span class="text">{{ serviceDetail.name }}</span><span class="hotline">客服热线：<i>66886688</i></span></div>
      </div>
      <div class="service-details">
        <div class='base-info'>
            <img src='../assets/item02.png'>
            <div class='info'>
<!--              <div class='flag'>-->
<!--                <i v-for="(item) in tags" :key="item.index">{{item}}</i>-->
<!--              </div>-->
<!--              <p>贷款金额：<span>{{ serviceDetail.amount }}</span></p>-->
<!--              <p>贷款年限：<span>{{ serviceDetail.yearLimit }}</span></p>-->
<!--              <p>贷款利率：<span>{{ serviceDetail.interestRate }}</span></p>-->
<!--              <p>还款方式：<span>{{ serviceDetail.returnMethod }}</span></p>-->
<!--              <p>担保方式：<span>{{ serviceDetail.warrantMethod }}</span></p>-->
              <p>{{ serviceDetail.briefIntro }}</p>
            </div>
            <el-button
                :loading="loading"
                size="large"
                class="button"
                @click.prevent='ApplyFor()'>
              <span v-if="!loading">立即申请</span>
              <span v-else>申请提交中...</span>
              </el-button>
            <div style='clear: both'></div>
        </div>
<!--        <div class='other-info'>-->
<!--          <div class='title'><span></span>产品特点</div>-->
<!--          <div class='content'>-->
<!--&lt;!&ndash;            1、成本优势：高学历人才创新创业，低成本融资；<br>&ndash;&gt;-->
<!--&lt;!&ndash;            2、担保优势：初创及轻资产企业无抵押纯信用；<br>&ndash;&gt;-->
<!--&lt;!&ndash;            3、政策优势：享受成都市及各区的信用贷款利息补贴；<br>&ndash;&gt;-->
<!--&lt;!&ndash;            4、贷款效率高：符合条件的企业，最快3个工作日可以放款<br>&ndash;&gt;-->
<!--            <article v-html="serviceDetail.feature"/>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class='other-info'>-->
<!--          <div class='title'><span></span>申请条件</div>-->
<!--          <div class='content'>-->
<!--            <article v-html="serviceDetail.conditions"/>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class='other-info'>-->
<!--          <div class='title'><span></span>贷款服务流程</div>-->
<!--          <div class='content'>-->
<!--            <article v-html="serviceDetail.procedure"/>-->
<!--            <img src='../assets/process.png'>-->
<!--          </div>-->
<!--        </div>-->
        <div class='other-info'>
          <div class='title'><span></span>业务范围</div>
          <div class='content'>
            <!--            1、成本优势：高学历人才创新创业，低成本融资；<br>-->
            <!--            2、担保优势：初创及轻资产企业无抵押纯信用；<br>-->
            <!--            3、政策优势：享受成都市及各区的信用贷款利息补贴；<br>-->
            <!--            4、贷款效率高：符合条件的企业，最快3个工作日可以放款<br>-->
            <article v-html="serviceDetail.businessRange"/>
          </div>
        </div>
        <div class='other-info'>
          <div class='title'><span></span>业务介绍</div>
          <div class='content'>
            <article v-html="serviceDetail.introduction"/>
          </div>
        </div>
      </div>
    </div>
    <BottomView />

    <div class='message-box' v-show='isShow'>
      <div class='shade'></div>
      <div class='content'>
        <img src='../assets/fa5-check-circle-fas.svg'>
        <h4>您的服务申请已提交成功</h4>
        <p>*系统后台已收到您的服务申请，我们的服务人员将尽快与您取得联系，为您提供后续的服务支持。</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import {onMounted, ref} from "vue";
import {useRoute} from 'vue-router';
import {getServiceItemDetails, serviceApplication} from "@/api/service";
import {ElMessageBox} from "element-plus";
import router from "@/router";

const route = useRoute()
const loading=ref(false)
const isShow=ref(false)
const serviceItemId=ref(0)
const serviceDetail = ref({
  procedure:'',
})
const tags=ref([])

onMounted(() => {
  //document.title = "企业综合服务系统"

  let code = route.query.code;
  getServiceItemDetails(code).then(response => {
    serviceDetail.value = response.data
    serviceItemId.value = response.data.id
    tags.value=response.data.tag!=null?response.data.tag.split('|'):""
  })

})

const ApplyFor=function () {
  loading.value = true;
  serviceApplication(serviceItemId.value).then((res) => {
    console.log("res1",res)
    loading.value = false;
    switch (res.code){
      case 200:
        isShow.value = true
        setTimeout(function () {
          isShow.value = false
        }, 3000)
        break
      case 9001:
        ElMessageBox.confirm("您的企业信息尚未完善，请前往“个人中心”完善信息", "系统提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: "warning",
        }).then(() => {
          router.push("/account");
        }).catch(() => { });
        break;
      case 9002:
        ElMessageBox.alert("您已申请过该服务项目，无需重复申请!", "系统提示", {
          type: "warning",
        }).catch(()=>{})
        break;
    }

  }).catch(() => {
    loading.value = false;
  });

}

</script>

<style lang="scss" scoped>
.banner {
  height: 770px;
  width: 100%;
  background: url("../assets/banner.png") center no-repeat;
}
.main-container {
  width: 1140px;
  margin: 0 auto;
  padding-bottom:100px;

  .services {
    padding: 20px 0;
    .title {
      font-size: 75px;
      color: #F2F2F2;
      font-weight: bold;
      position: relative;
      text-align: left;
      margin:15px 0 15px 0;

      .text {
        font-size: 36px;
        color: #333333;
        position: absolute;
        left: 50px;
        top: 25px;
        font-weight: 400;
      }

      .hotline{
        font-size: 21px;
        color: #101010;
        font-weight: lighter;
        position: absolute;
        right: 0px;
        top: 30px;

        i{
          color: #BDA26D;
        }
      }
    }
  }

  .service-details {

    .base-info{
      position: relative;
      height: auto;
      padding-bottom: 40px;
      border-bottom: #BBBBBB dashed 1px;

      img{
        width: 193px;
        height: 255px;
        float: left;
        display: inline-block;
        background-color: rgba(229, 229, 229, 100);
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
      }

      .info{
        padding-left: 30px;
        width: 500px;
        height: 100%;
        float: left;
        display: inline-block;
        text-align: left;
        color: #2C3E50;
        font-size: 16px;

        .flag{
          margin-bottom: 30px;

          i{
            display: inline-block;
            padding: 3px 10px;
            color: rgba(16, 16, 16, 100);
            font-size: 14px;
            text-align: center;
            border: 1px solid rgba(189, 162, 109, 100);
            margin-right: 15px;
            font-style: normal;
          }
        }

        p{
          font-size: 16px;
          span{
            color: #D49118;
          }
        }
      }

      .button{
        position: absolute;
        right: 0px;
        top: 100px;
        width: 168px;
        height: 46px;
        line-height: 20px;
        border-radius: 5px;
        background: linear-gradient(264.2deg, rgba(185,145,107,1) 1.7%,rgba(228,200,149,1) 93.74%);
        color: rgba(255, 255, 255, 100);
        font-size: 18px;
        text-align: center;
        font-family: Microsoft Yahei;
        border: 1px solid rgba(255, 255, 255, 100);
        cursor: pointer;
      }
    }

    .other-info{
      text-align: left;

      .title{
        padding: 50px 0 20px 0;
        font-size: 21px;
        color: #000000;

        span{
          display: inline-block;
          width: 8px;
          height: 22px;
          background: #BDA26D;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
      .content{
        font-size: 14px;
        line-height: 25px;
      }
    }
  }
}

.message-box{

  .shade{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
  }

  .content{
    background: #ffffff;
    width: 400px;
    height: 220px;
    padding: 30px 0;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -200px;
    margin-top: -130px;
    border-radius: 10px;
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    text-align: center;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);

    h4{
      font-size: 20px;
      color: #1F0757;
    }

    p{
      width: 320px;
      margin: 0 auto;
      font-size: 13px;
      color: #151515;
      text-align: left;
      line-height: 21px;
    }

  }

}
</style>
