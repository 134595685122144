<template>
  <div style='clear: both'></div>
  <div class='bottom-container'>
    <div class='link'>
      <p>
        <router-link :to="`/mobile/single-page-agreement`">用户服务协议</router-link>
        |
        <router-link :to="`/mobile/single-page-legal-notice`">法律声明</router-link>
      </p>
      <p>
        <router-link :to="`/mobile/single-page-protection-policy`">个人信息保护政策</router-link>
        |
        <router-link :to="`/mobile/single-page-about`">关于我们</router-link>
      </p>
    </div>
    <div class='copyright'>
      <p>© 版权所有上海市智慧城市发展研究院</p>
      <p>沪ICP备2020027374号-2</p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BottomView',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.bottom-container {
  padding: 10px 0;

  background: #F6F6F6;
  text-align: center;
  box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.12);

  .link {
    font-size: 13px;
    color: #BDA26D;
    margin: 0;
    font-weight: bold;

    a {
      color: #BDA26D;
      text-decoration: none;
      padding: 0 15px;
    }
  }

  .copyright {
    font-size: 12px;
    color: #101010;
    margin: 0;
    padding-top: 5px;

  }

  .tips {
    border-top: #DDDDDD solid 1px;
    font-size: 12px;
    color: #101010;
    line-height: 20px;
    width: 600px;
    margin: 0 auto;
    padding: 10px 0 8px 0;
  }

  .other {
    font-size: 12px;
    color: #8C9093;
    margin: 0;
    padding: 0;
  }

}
</style>
