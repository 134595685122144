<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/declaration-announcement"/>
    <div class="policy-interpretation-image"/>
    <div class="policy-interpretation-container">
      <Breadcrumb :items="[{name:'申报公告',link:''}]"/>
      <div class="policy-interpretation-table">
        <el-table :data="news" style="width: 100%" :show-header="false">
          <el-table-column>
            <template v-slot="scope">
              <router-link :to="`/news-details?code=${scope.row.id}`">
                <div class="flex-space-between table-row-cell" @click="goDetails(scope.row.id)">
                  <div class="cell-content">
                    <div class="cell-content-title">{{ scope.row.title }}</div>
                    <div class="cell-content-date">{{ parseTime(scope.row.releaseDate,'{y}-{m}-{d}') }}</div>
                    <div class="cell-content-desc" v-html="scope.row.detail.replace(/<[^>]+>/g, '') || ''">
                    </div>
                  </div>
                </div>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            ref="pagination"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="searchData.pageNo"
            :page-sizes="[10, 30, 50, 100]"
            :page-size="searchData.pageSize"
            :total="totalCount"
            style="padding-top: 10px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "PolicyInterpretation"
}
</script>

<script setup>

import MenuBar from '@/components/MenuBar';
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import {onMounted, ref} from "vue";
import apiNews from "@/api/news";
import Breadcrumb from "@/components/Breadcrumb.vue";
import {parseTime} from "@/utils/coreyee";

const searchData = ref({
  pageNo: 1,
  pageSize: 10
})
const totalCount = ref(0)

onMounted(() => {
  getNews()
})

const news = ref([])
const getNews = () => {
  apiNews.getNewsPage(searchData.value.pageSize, searchData.value.pageNo, 3).then(resp => {
    news.value = resp.rows;
    totalCount.value = resp.total
  })
}

const handleSizeChange = (pageSize) => {
  console.log('pageSize', pageSize)
  searchData.value.pageSize = pageSize
  getNews()
}
const handleCurrentChange = (current) => {
  console.log('current', current)
  searchData.value.pageNo = current
  getNews()
}

const goDetails = (id) => {
  console.log('goDetails', id)
}
</script>

<style scoped lang="scss">
.policy-interpretation-image {
  width: 100%;
  height: 350px;
  background-image: url("../assets/image/bg-05.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: top center;
}

.policy-interpretation-container {
  width: 1200px;
  margin: 30px auto;
  text-align: left;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px 30px 35px 30px;
  box-sizing: border-box;

  .policy-interpretation-table {
    margin-top: 24px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    padding: 0 0 20px;
    box-sizing: border-box;

    .el-table {
      margin-bottom: 10px;
    }

    .table-row-cell {
      height: 130px;
      box-sizing: border-box;
      align-items: flex-start;

      .cell-image {
        width: 224px;
        height: 130px;
        margin-right: 20px;

        img {
          width: 224px;
          height: 130px;
        }
      }

      .cell-content {
        flex: 1;
        font-size: 14px;
        color: #020202;

        .cell-content-title {
          font-size: 18px;
        }

        .cell-content-date {
          margin: 20px 0 6px 0;
        }

        .cell-content-desc {
          width: 100%;

          /* 控制显示行数 */
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }


    :deep td.el-table__cell {
      border-bottom: 1px #BBBBBB dashed;
      padding: 20px 0;
    }

    :deep .el-table--default .cell {
      padding: 0;
    }

  }

}
</style>
