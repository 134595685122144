<template>
  <div class="home">
    <HeaderView title="用户中心"/>
    <div class='account-container'>
      <div class='left'>
        <div class="user-avatar ">
          <div class="avatar">
            <img src="../../assets/avatar.png" height="64" width="64"/></div>
          <div class="nike-name">
            {{ maskPhoneNumber(userStore.name) }}
          </div>
        </div>
        <ul>
          <li v-on:click='$router.push({path:"/account"})' class='active'>企业信息</li>
          <li v-on:click='$router.push({path:"/account/myreport"})'>测评报告</li>
          <li v-on:click='$router.push({path:"/account/applied"})'>我的会员</li>
          <li v-on:click='$router.push({path:"/account/password"})'>修改密码</li>
        </ul>
      </div>
      <div class='right'>
        <el-form ref="companyRef"
                 :model="companyForm"
                 :rules="companyRules"
                 :inline="true"
                 label-width="150px"
                 class="demo-form-inline">
          <div class='title'><span></span>用户信息编辑</div>
          <p style="padding-left: 20px">注：请准确填写表单信息，以便我们的服务人员与您取得联系并提供更好的服务支持。</p>

          <el-form-item label="用户（手机号码）">
            <el-input v-model="companyForm.userName" style="width: 300px" :disabled="true"/>
          </el-form-item>
          <el-form-item label="联系人" prop="contactName">
            <el-input v-model="companyForm.contactName" style="width: 300px" placeholder="请输入联系人"/>
          </el-form-item>
          <el-form-item label="联系方式" prop="contactPhone">
            <el-input v-model="companyForm.contactPhone" style="width: 300px" placeholder="请输入联系方式"/>
          </el-form-item>
          <el-form-item label="岗位职务" prop="contactPost">
            <el-select v-model="companyForm.contactPost" style="width: 300px" placeholder="请选择岗位职务">
              <el-option value="董事长" label="董事长"></el-option>
              <el-option value="总经理" label="总经理"></el-option>

              <el-option value="财务部负责人" label="财务部负责人"></el-option>
              <el-option value="会计" label="会计"></el-option>

              <el-option value="人事部负责人" label="人事部负责人"></el-option>

            </el-select>
          </el-form-item>
          <div class='title'><span></span>企业信息编辑</div>
          <el-form-item label="企业（单位）名称" prop="companyName">
            <el-input v-model="companyForm.companyName" style="width: 300px" placeholder="请输入企业（单位）名称"/>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="creditCode">
            <el-input v-model="companyForm.creditCode" style="width: 300px" placeholder="请输入统一社会信用代码"/>
          </el-form-item>
          <el-form-item label="注册资本" prop="registeredCapital">
            <input-number v-model="companyForm.registeredCapital" placeholder="请输入注册资本"
                          @update:modelValue="companyForm.registeredCapital = $event" :is-decimal="false"
                          style="width: 300px"
                          :is-positive="true" :append="'万元'"/>
          </el-form-item>
          <el-form-item label="纳税人性质" prop="taxpayerNature">
            <el-select v-model="companyForm.taxpayerNature" style="width: 300px" placeholder="请选择纳税人性质">
              <el-option
                  v-for="(option, index) in taxpayerNatureOptions"
                  :key="index"
                  :label="option.label"
                  :value="option.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属行业" prop="industry">
            <template #label>
              <el-tooltip content="按照《国民经济行业分类(GB/T 4754-2017)》的大类行业填写所属行业."
                          placement="right">
                        <span>
                          <span>所属行业</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
              </el-tooltip>
            </template>
            <el-select v-model="companyForm.industry" :filterable="true" style="width: 300px"
                       placeholder="请输入国标行业">
              <el-option v-for="item in bigTypes" :key="item.id" :label="item.name" :value="item.industryCode"/>
            </el-select>
          </el-form-item>
          <el-form-item label="法定代表人" prop="legalRepresentative">
            <el-input v-model="companyForm.legalRepresentative" style="width: 300px" placeholder="请输入法定代表人"/>
          </el-form-item>
          <el-form-item label="企业类型" prop="companyType">
            <el-select v-model="companyForm.companyType" style="width: 300px" placeholder="请选择企业类型">
              <el-option value="1" label="民营"></el-option>
              <el-option value="2" label="国有"></el-option>
              <el-option value="3" label="合资"></el-option>
              <el-option value="4" label="外资"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="参保人数" prop="insuredPeopleNumber">
            <el-input type="number" v-model="companyForm.insuredPeopleNumber" style="width: 300px"
                      placeholder="请输入参保人数"/>
          </el-form-item>
          <el-form-item label="注册地" prop="registrationAuthority">
            <el-select v-model="companyForm.registrationAuthority" style="width: 300px" placeholder="请选择注册地">
              <el-option label="浦东新区" value="浦东新区"/>
              <el-option label="黄浦区" value="黄浦区"/>
              <el-option label="静安区" value="静安区"/>
              <el-option label="徐汇区" value="徐汇区"/>
              <el-option label="长宁区" value="长宁区"/>
              <el-option label="虹口区" value="虹口区"/>
              <el-option label="杨浦区" value="杨浦区"/>
              <el-option label="普陀区" value="普陀区"/>
              <el-option label="闵行区" value="闵行区"/>
              <el-option label="宝山区" value="宝山区"/>
              <el-option label="嘉定区" value="嘉定区"/>
              <el-option label="金山区" value="金山区"/>
              <el-option label="松江区" value="松江区"/>
              <el-option label="青浦区" value="青浦区"/>
              <el-option label="奉贤区" value="奉贤区"/>
              <el-option label="崇明区" value="崇明区"/>
            </el-select>
          </el-form-item>
          <el-form-item label="成立日期" prop="foundDate">
            <el-date-picker clearable
                            style="width:300px"
                            v-model="companyForm.foundDate"
                            type="date"
                            value-format="YYYY-MM-DD"
                            placeholder="请选择成立日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="公司地址" prop="address">
            <el-input v-model="companyForm.companyAddress" style="width: 300px" placeholder="请输入公司地址"/>
          </el-form-item>
          <el-form-item label="经营范围" prop="businessRange">
            <el-input
                v-model="companyForm.businessRange"
                :autosize="{ minRows: 4, maxRows: 10 }"
                type="textarea"
                style="width: 780px"
                placeholder="请输入经营范围内容"
            />
          </el-form-item>
          <el-form-item style='margin-left: 150px'>
            <el-button
                :loading="loading"
                size="large"
                class='submit'
                @click.prevent="submitForm"
            >
              <span v-if="!loading">确认保存</span>
              <span v-else>保存中...</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style='clear: both'></div>
    </div>
    <BottomView/>
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import {getCurrentInstance, onMounted, ref} from "vue";
import {getCompany, updateCompany} from "@/api/account";
import InputNumber from "@/components/InputNumber/index.vue";
import {QuestionFilled} from "@element-plus/icons-vue";
import {getGbtType} from "@/api/authentication";
import useUserStore from "@/store/modules/user";

const userStore = useUserStore()

const {proxy} = getCurrentInstance();
const loading = ref(false)
const companyForm = ref({
  userName: "",
  companyName: "",
  companyType: "",
  contactName: "",
  contactPhone: "",
  contactPost: "",
  creditCode: "",
  foundDate: "",
  industry: "",
  insuredPeopleNumber: 0,
  legalRepresentative: "",
  registeredCapital: "",
  registrationAuthority: "",
  taxpayerNature: "",
  businessRange: "",
  companyAddress:''
})

const taxpayerNatureOptions = ref([
  {label: '一般纳税人', value: '1'},
  {label: '小规模纳税人', value: '2'},
])

const isNumber = (rule, value, callback) => {
  let regPos = /^\d+(\.\d+)?$/; //非负浮点数
  if (!regPos.test(value)) {
    callback(new Error("请输入数值"));
  } else {
    callback();
  }
};

const companyRules = {
  companyName: [{required: true, trigger: "blur", message: "请输入您的企业名称"},],
  companyType: [{required: true, trigger: "blur", message: "请选择企业类型"},],
  contactName: [{required: true, trigger: "blur", message: "请输入联系人姓名"},],
  contactPhone: [{required: true, trigger: "blur", message: "请输入联系方式"},
    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }],
  contactPost: [{required: true, trigger: "blur", message: "请输入岗位职务"},],
  creditCode: [
    {required: true, message: '请输入社会信用代码', trigger: 'blur'},
    {pattern: /^[0-9A-Za-z]{18}$/, message: '信用代码为18位的数字和大小写字母', trigger: 'blur'}],
  foundDate: [{required: true, trigger: "blur", message: "请选择成立日期"},],
  industry: [{required: true, trigger: "blur", message: "请选择行业"},],
  insuredPeopleNumber: [
    {required: true, trigger: "blur", message: "请输入参保人数"},
    {required: true, validator: isNumber, trigger: "blur"}
  ],
  legalRepresentative: [{required: true, trigger: "blur", message: "请输入法人姓名"},],
  registeredCapital: [{required: true, trigger: "blur", message: "请输入注册资本"},],
  registrationAuthority: [{required: true, trigger: "blur", message: "请选择注册地"},],
  taxpayerNature: [{required: true, trigger: "blur", message: "请选择纳税人性质"},],
  businessRange: [{required: true, trigger: "blur", message: "请输入经营范围"},],
};

function submitForm() {
  proxy.$refs.companyRef.validate(valid => {
    if (valid) {
      loading.value = true;
      updateCompany(companyForm.value).then(res => {
        loading.value = false;
        proxy.$modal.msgSuccess("保存成功");
      }).catch((res) => {
        loading.value = false;
      });
    }
  });
}

function maskPhoneNumber(phoneNumber) {
  // 确保手机号码长度为11位
  if (phoneNumber.length === 11) {
    // 使用模板字符串和slice方法替换中间四位
    return `${phoneNumber.slice(0, 3)}****${phoneNumber.slice(-4)}`;
  } else {
    return phoneNumber;
  }
}

const bigTypes = ref()
onMounted(() => {
  //document.title = "企业综合服务系统"
  getGbtType().then((resp) => {
    bigTypes.value = resp
  })
  getCompany().then(response => {
    companyForm.value = response.user;
  });
})
</script>

<style lang="scss" scoped>
.account-container {
  width: 1200px;
  margin: 0 auto;
  padding: 0 0 100px 0;

  .left {
    width: 200px;
    min-height: 600px;
    height: 1000px;
    float: left;
    display: inline-block;
    border-right: 1px;
    text-align: left;
    background-color: #ffffff;
    padding: 20px 0 0 0;
    box-sizing: border-box;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        height: 50px;
        text-align: center;
        line-height: 50px;

        color: #000;
        margin-bottom: 15px;
        font-size: 14px;
        cursor: pointer;

      }

      .active {
        border-left: 2px solid #E4C895;
        background: #F8F8F8;
        font-size: 18px;
      }
    }

    .user-avatar {
      position: relative;
      margin: 0 auto;
      text-align: center;
      width: 100%;

      .avatar {

      }

      .nike-name {
        margin: 15px 0 15px 0;
      }
    }
  }

  .right {
    width: 1000px;
    min-height: 600px;
    height: 1000px;
    float: right;
    display: inline-block;
    text-align: left;
    background-color: rgba(255, 255, 255, 1);
    border-left: 2px solid #f5f6fa;
    box-sizing: border-box;


    .title {
      padding: 20px;
      font-size: 21px;
      color: #000000;

      span {
        display: inline-block;
        width: 4px;
        height: 22px;
        background: #BDA26D;
        vertical-align: middle;
        margin-right: 10px;
      }
    }

    p {
      color: #ff0000;
      font-size: 13px;
      margin: 0;
      padding: 0 0 20px 0;
    }

    button {
      width: 168px;
      height: 46px;
      line-height: 20px;
      border-radius: 5px;
      background: linear-gradient(264.2deg, rgba(185, 145, 107, 1) 1.7%, rgba(228, 200, 149, 1) 93.74%);
      color: rgba(255, 255, 255, 100);
      font-size: 18px;
      text-align: center;
    }
  }
}
</style>
