<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/project-declaration"/>
<!--    <div class="project-declaration-image"/>-->
    <div class="project-declaration-container">
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'}]"/>
      <div class="project-declaration-search">
        <div class="tag-container flex-flex-start">
          <div class="tag-title-tips"><span class="span">时效性</span>：</div>
          <div>
            <el-tag v-for="(item,index) in progress" :key="index" @click="searchForm('progress',item.dictValue)"
                    :class="searchData.progress==item.dictValue? 'active':''">
              {{ item.dictLabel }}
            </el-tag>
          </div>
        </div>
        <div class="tag-container flex-flex-start">
          <div class="tag-title-tips"><span class="span">主管部门</span>：</div>
          <div class="tag-item">
            <el-tag v-for="(item,index) in offices" :key="index" @click="searchForm('office',item.dictValue)"
                    :class="searchData.office==item.dictValue? 'active':''" style="margin-bottom: 5px">
              {{ item.dictLabel }}
            </el-tag>
          </div>
        </div>
        <div class="tag-container flex-flex-start" style="margin-top: -5px">
          <div class="tag-title-tips"><span class="span">区域</span>：</div>
          <div class="tag-item">
            <el-tag v-for="(item,index) in district" :key="index" @click="searchForm('district',item.dictValue)"
                    :class="searchData.district==item.dictValue? 'active':''" style="margin-bottom: 5px">
              {{ item.dictLabel==="上海市"?"全部":item.dictLabel }}
            </el-tag>
          </div>
        </div>
        <div class="tag-container flex-flex-start" style="margin-top: -5px">
          <div class="tag-title-tips"><span class="span">政策分类</span>：</div>
          <div>
            <el-tag v-for="(item,index) in policyClassification" :key="index" @click="searchForm('type',item.dictValue)"
                    :class="searchData.type==item.dictValue? 'active':''">
              {{ item.dictLabel }}
            </el-tag>
          </div>
        </div>
      </div>
      <el-divider/>
      <div class="project-declaration-table">
        <el-table :data="projects" style="width: 100%" :show-header="false">
          <el-table-column>
            <template v-slot="scope">
              <div class="flex-space-between table-row-cell">
                <router-link class="table-cell-content" :to="`/project-declaration-details?code=${scope.row.id}`">
                  <div class="table-cell-title">
                    <span class="title-span">【{{ districtEnum[scope.row.district] }}】{{ scope.row.name }}</span>
                    <el-tag :class="`progress-${scope.row.progress}`">{{ progressEnum[scope.row.progress] }}</el-tag>
                  </div>
                  <div class="flex-space-between cell-tag-container">
                    <div class="flex-space-between" style="align-items: flex-start;">
                      <div class="cell-tag-group">
                        <el-tag v-if="scope.row.progress == 2" effect="plain" class="cell-tag-day">
                          {{ scope.row.daysLeft }}
                        </el-tag>
                        <el-tag effect="plain" class="cell-tag-name">
                          {{ policyClassificationEnum[scope.row.type] }}
                        </el-tag>
                      </div>

                      <div class="cell-tag-money"><i>{{ scope.row.capitalPrefix??'' }}</i><span>{{ scope.row.maxCapital || "" }}</span> {{ scope.row.maxCapital?"万元":"未定" }}</div>
                    </div>
                    <div class="date">
                      <span v-if="scope.row.startDate">
                        申报时间：{{ scope.row.startDate }} 至 {{ scope.row.endDate }}
                      </span>
                    </div>
                  </div>
                </router-link>
                <div class="table-operation">
                  <el-button v-if="scope.row.isShow === 1" @click.stop="gotoVerifyRouter(`${scope.row.router}?code=${scope.row.id}`)">立即测评</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            ref="pagination"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="searchData.pageNo"
            :page-sizes="[10, 30, 50, 100]"
            :page-size="searchData.pageSize"
            :total="totalCount"
            style="padding-top: 10px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ProjectDeclaration"
}
</script>

<script setup>

import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar';
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, onMounted, ref} from "vue";
import apiProject from "@/api/project";
import {ElMessageBox} from "element-plus";
import {isRelogin} from "@/utils/request";
import useUserStore from "@/store/modules/user";
import {useRouter} from "vue-router";

const userStore = useUserStore()
const router = useRouter()

const progress = ref([])
const offices = ref([])
const district = ref([])
const policyClassification = ref([])

const progressEnum = ref({})
const officesEnum = ref({})
const districtEnum = ref({})
const policyClassificationEnum = ref({})
onBeforeMount(() => {
  const first = {dictLabel: '全部', dictValue: ''}

  progress.value = JSON.parse(sessionStorage.getItem('progress'))
  progress.value.unshift(first)
  progressEnum.value = JSON.parse(sessionStorage.getItem('progressEnum'))

  offices.value = JSON.parse(sessionStorage.getItem('offices'))
  offices.value.unshift(first)
  officesEnum.value = JSON.parse(sessionStorage.getItem('officesEnum'))

  district.value = JSON.parse(sessionStorage.getItem('districts'))
  districtEnum.value = JSON.parse(sessionStorage.getItem('districtEnum'))

  policyClassification.value = JSON.parse(sessionStorage.getItem('policyTypes'))
  policyClassification.value.unshift(first)
  policyClassificationEnum.value = JSON.parse(sessionStorage.getItem('policyTypesEnum'))
})
onMounted(() => {
  getProject()

})
const searchData = ref({
  progress: 0,
  office: 0,
  district: 0,
  type: 0,
  pageNo: 1,
  pageSize: 30
})
const projects = ref([])
const getProject = () => {
  apiProject.getList(searchData.value).then(resp => {
    projects.value = resp.rows;
    totalCount.value = resp.total
  })
}

const totalCount = ref(0)

const searchForm = (type, value) => {
  searchData.value[type] = value
  getProject()
}

const handleSizeChange = (pageSize) => {
  console.log('pageSize', pageSize)
  searchData.value.pageSize = pageSize
  getProject()
}
const handleCurrentChange = (current) => {
  console.log('current', current)
  searchData.value.pageNo = current
  getProject()
}

const gotoVerifyRouter = (path) => {
  event.stopPropagation(); // 显式阻止事件冒泡
  if (userStore.id != null) {
    router.push(path);
  } else {
    ElMessageBox.confirm('请您先登录系统，再继续操作!', '系统提示', {
      confirmButtonText: '登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      isRelogin.show = false;
      useUserStore().logOut().then(() => {
        useUserStore().showLogin()
      })
    }).catch(() => {
      isRelogin.show = false;
    });
  }
}

</script>

<style scoped lang="scss">
.project-declaration-image {
  width: 100%;
  height: 350px;
  background-image: url("../assets/image/project-declaration-image.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: top center;
}

.project-declaration-container {
  width: 1200px;
  margin: 30px auto;
  text-align: left;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px 30px 35px 30px ;
  box-sizing: border-box;

  .project-declaration-search {
    margin-top: 10px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    padding: 0;
    box-sizing: border-box;

    .tag-container {
      margin-bottom: 20px;
      align-items: flex-start;
      color: rgba(102,102,102,1);


      .tag-title-tips {
        span {
          width: 60px;
        }
      }

      .tag-item {
        flex: 1;
      }

      .span {
        /* 设置span 文字左右两端对齐 */
        display: inline-block;
        width: 45px;
        //color: rgba(46, 90, 166, 1);
        font-size: 14px;
        text-align: justify;
        text-align-last: justify;
      }

      .el-tag {
        cursor: pointer;
        min-width: 56px;
        height: 24px;
        margin-right: 4px;
        color: #666666;
        background-color: unset;
        border: unset;
        font-size: 14px;
      }

      .active {
        border-radius: 5px;
        //background-color: rgba(46, 90, 166, 1);
        background-color: #CBB486;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        padding: 0 10px;


      }
    }
  }

  .project-declaration-table {
    margin-top: 24px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    padding: 0 0 20px;
    box-sizing: border-box;

    .el-table {
      margin-bottom: 10px;
    }

    .table-row-cell {
      height: 81px;
      box-sizing: border-box;
      align-items: flex-start;


      .table-cell-content {
        flex: 1;
        margin-right: 50px;

        .table-cell-title {
          padding-left: 5px;
          .el-tag {
            width: 75px;
            height: 24px;
            line-height: 15px;
            border-radius: 4px;
            font-size: 14px;
            text-align: center;
            vertical-align: middle;
          }
          .progress-1{
            //border: 1px solid #398AFD;
            //background-color: #D5DDE8;
            color: #333;
          }
          .progress-2{
            //border: 1px solid #398AFD;
            //background-color: #D5DDE8;
            //color: #FD3938;
          }
          .progress-3{
            //border: 1px solid #398AFD;
            //background-color: #D5DDE8;
            //color: #398AFD;
          }

          .title-span {
            font-size: 18px;
            color: #020202;
            vertical-align: middle;
            margin-left: 10px;
            margin-right: 10px;
          }
        }

        .cell-tag-container {
          padding-left: 21px;
          margin-top: 15px;

          .cell-tag-group {
            .el-tag {
              padding: 0 4px;
            }

            .cell-tag-day {
              color: #FD3938;
              border-color: #FD3938;
              border-radius: unset;
              margin-right: 10px
            }

            .cell-tag-name {
              color: #2263E1;
              border-color: #2263E1;
              border-radius: unset;
            }
          }

          .cell-tag-money {
            color: #77808f;
            font-size: 12px;
            margin-left: 30px;

            i{
              font-style: normal;
              height: 25px;
              padding-right: 3px;
              display: inline-block;
              vertical-align: baseline;
              font-size: 12px;
            }

            span {
              color: #ff6f00;
              height: 25px;
              font-size: 20px;
              display: inline-block;
              vertical-align: baseline;
              font-weight: bold;
            }
          }
        }
      }

      .table-operation {
        width: 120px;
        line-height: 60px;

        .el-button {
          width: 107px;
          height: 40px;
          //background-color: rgba(46, 90, 166, 1);
          background-color: #CBB486;
          color: rgba(255, 255, 255, 1);
          font-size: 14px;
          border: none;
          border-radius: 0;
        }
      }
    }


    :deep td.el-table__cell {
      border-bottom: 1px #BBBBBB dashed;
      padding-top: 20px;
    }

    :deep .el-table--default .cell {
      padding: 0;
    }

  }

}
</style>
