<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>科技型中小企业-认定评分明细</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>科技型中小企业须同时满足以下条件</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>（一）、在中国境内（不包括港、澳、台地区）注册的居民企业</td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>（二）、职工总数不超过500人、年销售收入不超过2亿元、资产总额不超过2亿元</td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>（三）、企业提供的产品和服务不属于国家规定的禁止、限制和淘汰类</td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>
                （四）、企业在填报上一年及当年内未发生重大安全、重大质量事故和严重环境违法、科研严重失信行为，且企业未列入经营异常名录和严重违法失信企业名单
              </td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>（五）、企业根据科技型中小企业评价指标进行综合评价所得分值不低于60分，且科技人员指标得分不得为0分</td>
              <td v-html='(result[4] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td><span style="color: #ff0000">符合第（一）～（四）项条件的企业，若同时符合下列条件中的一项，则可直接确认符合科技型中小企业条件：</span>
                <br>1、企业拥有有效期内高新技术企业资格证书；
                <br>2、企业近五年内获得过国家级科技奖励，并在获奖单位中排在前三名；
                <br>3、企业拥有经认定的省部级以上研发机构；
                <br>4、企业近五年内主导制定过国际标准、国家标准或行业标准。
              </td>
              <td v-html='(result[5] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="result-score">
        <div class="title">
          <span>*</span>科技型中小企业评价指标具体包括科技人员、研发投入、科技成果三类，满分<span>100</span>分
        </div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th style="width: 500px">评价指标</th>
              <th>评分标准</th>
              <th style="width: 115px;">得分</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>1. 科技人员指标（满分20分）<br><span class="note">按科技人员数占企业职工总数的比例分档评价</span></td>
              <td>A. 30%（含）以上（20分）
                <br>B. 25%（含）-30%（16分）
                <br>C. 20%（含）-25%（12分）
                <br>D. 15%（含）-20%（8分）
                <br>E. 10%（含）-15%（4分）
                <br>F. 10%以下（0分）
              </td>
              <td class="core">{{ (result[6] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="2">2. 研发投入指标（满分50分）<br><span
                  class="note">企业从（1）、（2）两项指标中选择一个指标进行评分</span></td>
              <td>（1）按企业研发费用总额占销售收入总额的比例分档评价。
                <br>A. 6%（含）以上（50分）
                <br>B. 5%（含）-6%（40分）
                <br>C. 4%（含）-5%（30分）
                <br>D. 3%（含）-4%（20分）
                <br>E. 2%（含）-3%（10分）
                <br>F. 2%以下（0分）
              </td>
              <td class="core">{{ (result[7] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>（2）按企业研发费用总额占成本费用支出总额的比例分档评价。
                <br>A. 30%（含）以上（50分）
                <br>B. 25%（含）-30%（40分）
                <br>C. 20%（含）-25%（30分）
                <br>D. 15%（含）-20%（20分）
                <br>E. 10%（含）-15%（10分）
                <br>F. 10%以下（0分）
              </td>
              <td class="core">{{ (result[8] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>3. 科技成果指标（满分30分）<br><span
                  class="note">按企业拥有的在有效期内的与主要产品（或服务）相关的知识产权类别和数量（知识产权应没有争议或纠纷）分档评价</span>
              </td>
              <td>
                A. 1项及以上Ⅰ类知识产权（30分）
                <br>B. 4项及以上Ⅱ类知识产权（24分）
                <br>C. 3项Ⅱ类知识产权（18分）
                <br>D. 2项Ⅱ类知识产权（12分）
                <br>E. 1项Ⅱ类知识产权（6分）
                <br>F. 没有知识产权（0分）
              </td>
              <td class="core">{{ (result[9] || {}).score || 0 }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="result-total">指标总得分： <span>{{ total }}</span> 分</div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "result_1"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score
  getResultDetail({"evaluationType": query.evaluationType}).then((res)=>{
    result.value = res.data
  }).catch(()=>{})
})
</script>

<style scoped lang="scss">
</style>
