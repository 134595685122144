<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>高新技术企业-认定评分明细</div>
      <div class="result-score">
        <div class="title"><span>*</span>企业创新能力主要从知识产权、科技成果转化能力、研究开发组织管理水平、企业成长性等四项指标进行评价。
          各级指标均按整数打分，满分为100分，综合得分达到<span>70分以上</span>（不含70分）为符合认定要求。
        </div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th style="width: 270px">评价分类</th>
              <th>评定项目</th>
              <th style="width: 100px;">得分</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td rowspan="5">1. 知识产权（≤30 分）</td>
              <td>技术的先进程度（≤8 分）<br>
                A. 高 （7-8 分） B. 较高（5-6 分）
                C. 一般（3-4 分） D. 较低（1-2 分）
                E. 无 （0 分）</td>
              <td class="core">{{ (result[0] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>对主要产品（服务）在技术上发挥核心支持作用（≤8 分）<br>
                A. 强 （7-8 分） B. 较强（5-6 分）
                C. 一般（3-4 分） D. 较弱（1-2 分）
                E. 无 （0 分）</td>
              <td class="core">{{ (result[1] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>知识产权数量（≤8 分）<br>
                A. 1 项及以上（Ⅰ类）（7-8 分）
                B. 5 项及以上（Ⅱ类）（5-6 分）
                C. 3～4 项 （Ⅱ类）（3-4 分）
                D. 1～2 项 （Ⅱ类）（1-2 分）
                E. 0 项 （0 分）</td>
              <td class="core">{{ (result[2] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>知识产权获得方式（≤6 分）<br>
                A. 有自主研发 （1-6 分）
                B. 仅有受让、受赠和并购等（1-3 分）</td>
              <td class="core">{{ (result[3] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>企业是否参与编制国家标准、行业标准、检测方法、技术规范的情况（加分项，≤2 分）<br>
                A. 是（1-2 分） B. 否（0 分）</td>
              <td class="core">{{ (result[4] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>2. 科技成果转化能力（≤30 分）</td>
              <td>
                A. 转化能力强， ≥5 项（25-30 分） B. 转化能力较强，≥4 项（19-24 分）
                C. 转化能力一般，≥3 项（13-18 分） D. 转化能力较弱，≥2 项（7-12 分）
                E. 转化能力弱， ≥1 项（1-6 分） F. 转化能力无， 0 项（0 分）</td>
              <td class="core">{{ (result[5] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="4">3. 研究开发组织管理水平（≤20 分）</td>
              <td>
                制定了企业研究开发的组织管理制度，建立了研发投入核算体系， 编制了研发费用辅助账（≤6 分）</td>
              <td class="core">{{ (result[6] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>
                设立了内部科学技术研究开发机构并具备相应的科研条件，与国内外研究开发机构开展多种形式的产学研合作（≤6 分）</td>
              <td class="core">{{ (result[7] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>
                建立了科技成果转化的组织实施与激励奖励制度，建立开放式的创新创业平台（≤4 分）</td>
              <td class="core">{{ (result[8] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>
                建立了科技人员的培养进修、职工技能培训、优秀人才引进，以及人才绩效评价奖励制度（≤4 分）</td>
              <td class="core">{{ (result[9] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="2">4. 企业成长性（≤20 分）</td>
              <td>
                净资产增长率（≤10 分）<br>
                A. ≥35% （9-10 分） B. ≥25% （7-8 分）
                C. ≥15% （5-6 分） D. ＞5% （3-4 分）
                E. ＞0 （1-2 分） F. ≤0 （0 分）</td>
              <td class="core">{{ (result[10] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>
                销售收入增长率（≤10 分）<br>
                A. ≥35% （9-10 分） B. ≥25% （7-8 分）
                C. ≥15% （5-6 分） D. ＞5% （3-4 分）
                E. ＞0 （1-2 分） F. ≤0 （0 分）</td>
              <td class="core">{{ (result[11] || {}).score || 0 }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="result-total">评价总得分： <span>{{ total }}</span> 分</div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "result_1"
}
</script>
<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score
  getResultDetail({"evaluationType": query.evaluationType}).then((res)=>{
    result.value = res.data
  }).catch(()=>{})
})
</script>


<style scoped lang="scss">
.header {
  width: 1140px;
  margin: 35px auto 0;

  .title {
    font-size: 75px;
    color: #F2F2F2;
    font-weight: bold;
    position: relative;
    text-align: left;
    margin: 0;

    .text {
      font-size: 32px;
      color: #2C3E50;
      position: absolute;
      left: 50px;
      top: 25px;
    }
  }
}

.main-container {
  width: 1140px;
  margin: auto;
  padding-bottom: 100px;
  text-align: left;
  color: #101010;

  .container-title {
    padding: 50px 0 40px 0;
    font-size: 20px;
    color: #101010;

    span {
      display: inline-block;
      width: 8px;
      height: 20px;
      background: #5C7EF4;
      vertical-align: middle;
    }
  }

  .result-score {
    .title {
      font-size: 16px;
      line-height: 25px;

      span {
        color: red;
      }
    }

    .subtitle {
      margin-top: 10px;
      font-size: 14px;
    }

    .table {
      width: 100%;
      margin: 20px 0 40px;

      table {
        width: 100%;
        border-collapse: collapse;
      }

      table, th, td {
        border: 1px solid #DDDDDD;
        padding: 10px;
        line-height: 30px;
        color: #031A40;
      }

      th {
        font-size: 16px;
        font-weight: bolder;
        text-align: center;
        background-color: #DDDDDD;
      }

      .core {
        text-align: center;
      }
    }
  }

  .result-total {
    font-weight: bold;
    span {
      color: red;
    }
  }

  .td-red {
    color: red;
  }
}
</style>
