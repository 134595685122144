import {ElLoading, ElMessage, ElMessageBox} from "element-plus";

export function successMsg(msg){
    ElMessage({
        message: msg,
        type:'success',
        offset:300
    })
}

export function errorMsg(msg){
    ElMessage({
        message: msg,
        type:'error',
        offset:300
    })
}

export function resultMsg(resp,fn){
    const {code,msg} = resp
    if (code === 200){
        successMsg(msg)
        if (fn) fn()
    }else errorMsg(msg)
}

export function deleteConfirm(){
    return ElMessageBox.confirm("确认删除已选记录吗？", "系统提示")
}

export function confirmMsg(msg){
    return ElMessageBox.confirm(msg,'系统提示')
}

export function alertMsg(msg){
    return ElMessageBox.alert(msg,'系统提示')
}


export function getLoading(){
    return ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
}

export function getLoadingTimeout(timeout){
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    });
    setTimeout(() => {
        loading.close()
    }, timeout)
}
