<template>
  <div class="home">
    <HeaderView title=""/>
    <div class='main-container'>
      <MenuBar menuBarActive="/"/>
      <div class="home-page-image-container"/>
      <div class="home-page-tips-container">
        <div class="flex-space-between tips-container">
          <div class="tips-content">
            <div class="tips-title">惠企政策智能匹配&nbsp;&nbsp;&nbsp;&nbsp;精准测评</div>
            <div class="tips-desc">
              易企通政策服务平台，基于大数据和人工智能技术，为企业提供政策获悉、智能匹配、精准测评等一系列惠企服务，通过汇聚政府及各类社会化服务资源，为企业提供全生命周期精准服务。
            </div>
            <div class="tips-ul-container">
              <div class="tips-ul flex-space-between" v-for="(v,i) in tipsValue" :key="i">
                <div class="tips-li">
                  <img src="../assets/image/icon/icon-02.png" alt=""/>
                  <span>{{ v }}</span>
                </div>
              </div>
            </div>
            <div class="tips-number-container">
              <el-row :gutter="50">
                <el-col :span="8">
                  <div class="tips-number" style="width: 100px; font-weight: 600">30+</div>
                  <div>合作单位数</div>
                </el-col>
                <el-col :span="8">
                  <div class="tips-number" style="width: 110px; font-weight: 600">100+</div>
                  <div>优质服务商数</div>
                </el-col>
                <el-col :span="8">
                  <div class="tips-number" style="width: 125px; font-weight: 600">3000+</div>
                  <div>服务中小企业数</div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="tips-image"></div>
        </div>
      </div>
      <div class="home-page-content-container">
        <div class="query-container" v-if="false">
          <div class="query-desc">你的公司能拿多少补贴？输入企业名称快速匹配！</div>
          <el-input placeholder="输入企业名，快速获取政府补贴" v-model="search">
            <template #append>
              <el-button @click="query">快速匹配</el-button>
            </template>
          </el-input>
        </div>
        <div class="project-declaration-container">
          <div class="project-declaration-header flex-space-between">
            <div class="header-left">政策测评</div>
            <div class="header-right">
              <router-link to="/project-declaration" style="color: #1c84c6;  text-decoration: underline;">更多海量政策申报查询</router-link>
            </div>
          </div>
          <el-divider/>
          <div class="project-declaration-content">
            <el-row :gutter="20">
              <el-col :span="8" v-for="(item,index) in projects" :key="index">
                <div class="project-declaration-item" @click="gotoRouter(`/project-declaration-details?code=${ item.id }`)">
                  <div class="item-name">{{ item.name }}</div>
                  <div class="flex-space-between item-tag-container">
                    <div class="item-tag">
                      <el-tag v-if="item.progress == 2" color="#ffffff" class="tag-day">
                        {{ item.daysLeft }}
                      </el-tag>
                      <el-tag color="#ffffff" class="tag-name">{{ item.typeName }}</el-tag>
                    </div>
                    <div class="tag-money"><i>{{ item.capitalPrefix??'' }}</i><span>{{ item.maxCapital || "" }}</span> {{ item.maxCapital?"万元":"未定" }}</div>
                  </div>
                  <div class="flex-space-between item-btn">
                    <span>主管部门：{{ item.officeName }}</span>
                    <el-button v-if="item.isShow === 1" @click.stop="gotoVerifyRouter(`${item.router}?code=${item.id}`)">立即测评</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="header-right">
            <router-link to="/project-declaration" class="project-declaration"> 更多海量政策申报查询&nbsp;&nbsp;&nbsp;&nbsp;一对一政策精准测评 </router-link>
          </div>
        </div>
        <div class="news-container"  v-if="false">
          <div class="hot-policy-encyclopedia-container">
            <div class="hot-policy-encyclopedia-header flex-flex-start">
<!--              <img src="../assets/image/icon/icon-03.png" alt="">-->
              <span>热点政策大全</span>
            </div>
            <div class="hot-policy-encyclopedia-content">
              <div class="tag-industry-classification" v-if="false">
                <div class="tag-title">行业</div>
                <el-tag v-for="(item,index) in industryClassification" :key="index"
                        @click="findPolicy.industry = item.dictValue"
                        :class="findPolicy.industry===item.dictValue? 'active':''">
                  {{ item.dictLabel }}
                </el-tag>
              </div>
              <div class="tag-district">
                <div class="tag-title">区域</div>
                <el-tag v-for="(item,index) in district" :key="index"
                        @click="findPolicy.district = item.dictValue"
                        :class="findPolicy.district===item.dictValue? 'active':''">
                  {{ item.dictLabel }}
                </el-tag>
              </div>
              <div class="tag-office">
                <div class="tag-title">主管部门</div>
                <el-tag v-for="(item,index) in offices" :key="index"
                        @click="findPolicy.office = item.dictValue"
                        :class="findPolicy.office===item.dictValue? 'active':''">
                  {{ item.dictLabel }}
                </el-tag>
              </div>
              <div class="tag-policy-classification">
                <div class="tag-title">政策分类</div>
                <el-tag v-for="(item,index) in policyClassification" :key="index"
                        @click="findPolicy.type = item.dictValue"
                        :class="findPolicy.type===item.dictValue? 'active':''">
                  {{ item.dictLabel }}
                </el-tag>
              </div>
              <el-button @click="goToFindPolicy()">找政策</el-button>
            </div>
          </div>
          <div class="declaration-announcement-container" v-if="false">
            <div class="declaration-announcement-header flex-flex-start">
              <img src="../assets/image/icon/icon-04.png" alt="">
              <span>申报公告</span>
            </div>
            <div class="declaration-announcement-content">
              <div class="announcement-title" v-for="(item,index) in news" :key="index">
                <router-link :to="`/news-details?code=${item.id}`">{{ item.title }}<span>[{{ parseTime(item.releaseDate,'{y}-{m}-{d}') }}]</span>
                </router-link>
              </div>
              <router-link class="announcement-more" to="/declaration-announcement">更多申报公告</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script setup>

import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import {onBeforeMount, onMounted, ref} from "vue";
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import {getNewsPage} from "@/api/news";
import apiProject from "@/api/project";
import dict from "@/api/dict";
import {useRouter} from "vue-router";
import {parseTime} from "@/utils/coreyee";
import useUserStore from '@/store/modules/user'
import {ElMessageBox} from "element-plus";
import {isRelogin} from "@/utils/request";

const userStore = useUserStore()
const router = useRouter()
const search = ref('')
const query = () => {
  console.log("查询", search.value)
}
const tipsValue = ref(['惠企政策一键式精准测评，为企业申报政策降本增效。',
  '政策库信息全面及时，倒计时提示申报时间节点。',
  '配套资深企服专家团队，为企业解决个性化全方位的需求。'])
const industryClassification = ref([])
const district = ref([])
const offices = ref([])
const policyClassification = ref([])
onBeforeMount(()=>{
  dict.getDictStorage().then(()=>{
    industryClassification.value = JSON.parse(sessionStorage.getItem('industryCategories'))
    district.value = JSON.parse(sessionStorage.getItem('districts'))
    offices.value = JSON.parse(sessionStorage.getItem('offices'))
    policyClassification.value = JSON.parse(sessionStorage.getItem('policyTypes'))
  })
})
onMounted(() => {
  getProject()
  getNews()
})
const projects = ref([])
const getProject = () => {
  apiProject.getList({pageSize: 12, pageNo: 1}).then(resp => {
    projects.value = resp.rows;
  })
}
const news = ref([])
const getNews = () => {
  getNewsPage(6, 1, 3).then(resp => {
    news.value = resp.rows;
  })
}
const findPolicy = ref({
  industry: 0,
  district: 0,
  office: 0,
  type: 0,

})

const goToFindPolicy = () => {
  console.log(findPolicy.value)
  router.push({name: 'policy-encyclopedia', state: {params: JSON.stringify(findPolicy.value)}})
}

const gotoRouter =(path)=> {
  router.push(path);
}
const gotoVerifyRouter = (path) => {
  event.stopPropagation(); // 显式阻止事件冒泡
  if (userStore.id != null) {
    router.push(path);
  } else {
    ElMessageBox.confirm('请您先登录系统，再继续操作!', '系统提示', {
      confirmButtonText: '登录',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      isRelogin.show = false;
      useUserStore().logOut().then(() => {
        useUserStore().showLogin()
      })
    }).catch(() => {
      isRelogin.show = false;
    });
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/my-style.scss";

.main-container {
  background-color: #f9f9f9;

  .home-page-container {
    width: 1200px;
    margin: 0 auto;

    .home-page-container-left {
      width: 210px;
      height: 374px;

      .navigation-container {

        .navigation-title {
          height: 54px;
          background-color: #1971D1;
          line-height: 54px;
          text-align: center;
          color: #FFFFFF;
        }

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 20px;
        }

        .navigation-menu {
          .el-menu-item {
            height: 80px;
            font-size: 16px;
            margin: 0 20px 0 50px;
            padding: 0;
            border-bottom: 1px solid rgba(187, 187, 187, 1);

            &:last-child {
              border-bottom: unset;
            }

            &:hover {
              background-color: unset;
            }
          }
        }
      }
    }

    .home-page-container-right {
      width: 970px;
      height: 374px;

      .navigation-menu-horizontal {
        .el-menu {
          background-color: #f9f9f9;
          font-size: 16px;
          color: #101010;
          border-bottom: unset;
        }

        .el-menu-item {
          height: 54px;

          &:hover {
            background-color: unset;
            color: #101010;
          }

          &:focus {
            background-color: unset;
            color: #101010;
          }

          &.is-active {
            background-color: unset;
            color: #2E5AA6;
            border-bottom: 5px solid rgba(46, 90, 166, 1);
          }
        }

        .flex-grow {
          flex-grow: 1;
        }
      }
    }
  }

  .home-page-image-container {
    width: 100%;
    height: 400px;

    background-repeat: no-repeat;
    background-position: top center;
    margin: 0 auto;
    background-image: url("../assets/image/new-banner.png");
    //background-size: contain;
    background-size: cover;
  }



  .home-page-tips-container {
    width: 100%;
    background: linear-gradient(89.89deg, rgba(255, 255, 255, 1) 0.1%, rgba(241, 231, 247, 1) 99.8%, rgba(241, 231, 247, 1) 99.8%);
    height: 500px;

    .tips-container {
      width: 1200px;
      margin: 0 auto;
      padding-top: 56px;
      align-items: flex-start;

      .tips-content {
        width: 628px;
        color: #000000;
        font-size: 16px;
        text-align: left;
        margin-left: 30px;

        .tips-title {
          height: 39px;
          line-height: 39px;
          color: rgba(0, 0, 0, 1);
          font-size: 28px;
          text-align: left;
          margin-bottom: 56px;
        }

        .tips-desc {
          line-height: 29px;
          color: rgba(0, 0, 0, 1);
          font-size: 16px;
          text-align: left;
          padding-bottom: 25px;
        }

        .tips-ul-container {

          .tips-ul {
            width: 100%;
            margin-bottom: 12px;

            .tips-li {
              img {
                width: 16px;
                height: 16px;
                margin-right: 6px;
                vertical-align: middle;
              }

              span {
                display: inline-block;
                height: 16px;
                line-height: 16px;
                vertical-align: middle;
              }
            }
          }
        }

        .tips-number-container{
          margin-top: 25px;
          text-align: left;
          color: rgba(16,16,16,1);
          font-size: 18px;
          .tips-number{
            color: rgba(3,147,217,1);
            font-size: 28px;
            text-align: center;
            margin-bottom: 10px;
          }
        }
      }

      .tips-image {
        width: 464px;
        height: 383px;
        background-image: url("../assets/image/bg-03.png");
        background-size: contain;
        background-repeat: no-repeat;
      }


      .active  {


        color: red;
      }



    }
  }

  .home-page-content-container {
    width: 1200px;
    margin: 32px auto;

    .query-container {
      height: 163px;
      background-image: url("../assets/image/bg-01.png");
      background-size: contain;
      background-repeat: no-repeat;
      margin-bottom: 20px;
      padding-top: 33px;
      box-sizing: border-box;

      .query-desc {
        font-size: 20px;
        color: #2E5AA6;
        margin-bottom: 33px;
      }

      .el-input {
        width: 505px;

        :deep .el-input__wrapper, :deep .el-input-group__append {
          border-radius: unset;
        }

        :deep .el-input-group__append {
          background-color: rgba(46, 90, 166, 1);
          color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(46, 90, 166, 1);
          box-shadow: unset;
        }
      }
    }

    .project-declaration-container {
      padding: 15px 15px 25px 20px;
      box-sizing: border-box;
      min-height: 500px;
      background-color: #FFFFFF;

      .project-declaration-header {
        color: #000000;

        .header-left {
          font-size: 24px;
          font-weight: bold;
        }

        .header-right {
          span {
            font-size: 16px;
          }

          a {
            font-size: 14px;
            color: #000000;
            margin-left: 30px;
            text-decoration: none;
          }
        }
      }

      .el-divider {
        border-top: 5px solid #000000;
        margin: 15px 0 20px 0;
      }

      .project-declaration-content {
        .project-declaration-item {
          box-shadow: 0 2px 12px 0 rgba(217, 217, 217, 1);
          background-color: rgba(255, 255, 255, 1);
          padding: 16px 16px 16px 13px;
          box-sizing: border-box;
          margin-bottom: 20px;
          cursor: pointer;

          .item-name {
            height: 70px;
            line-height: 23px;
            color: rgba(0, 0, 0, 1);
            font-size: 16px;
            text-align: left;
          }

          .item-tag-container {
            margin-bottom: 15px;
            text-align: left;

            .el-tag {
              padding: 0 4px;
            }

            .tag-day {
              color: #FD3938;
              border-color: #FD3938;
              border-radius: unset;
              margin-right: 10px
            }

            .tag-name {
              color: #2263E1;
              border-color: #2263E1;
              border-radius: unset;
            }

            .tag-money {
              color: #77808f;
              font-size: 12px;
              text-align: right;

              i{
                font-style: normal;
                height: 25px;
                padding-right: 3px;
                display: inline-block;
                vertical-align: baseline;
                font-size: 12px;
              }

              span {
                color: #ff6f00;
                height: 25px;
                font-size: 20px;
                display: inline-block;
                vertical-align: baseline;
                font-weight: bold;
              }
            }
          }

          .item-btn {
            span {
              color: #77808f;
              font-size: 14px;
            }

            .el-button {
              background-color: #CBB486;
              color: rgba(255, 255, 255, 1);
              font-size: 13px;
              border-radius: unset;
              width: 75px;
              height: 28px;
              border: none;
            }
          }
        }

      }
    }

    .news-container {
      width: 1200px;
      margin: 35px auto 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;

      .hot-policy-encyclopedia-container {
        //width: 760px;
        width: 1200px;
        min-height: 600px;
        background-color: #f5f6fd;
        border-radius: 8px 8px 0 0;

        .hot-policy-encyclopedia-header {
          height: 46px;
          border-radius: 8px 8px 0 0;
          background-color: #CBB486;
          //background: linear-gradient(145deg,#eacb90 0%,#bb7c35 100%);
          //background: linear-gradient(91.36deg, rgba(96, 67, 191, 1) 4.86%, rgba(200, 68, 124, 1) 99.27%);
          border: 1px solid rgba(238, 237, 248, 1);
          color: rgba(255, 255, 255, 1);
          font-size: 20px;
          padding-left: 15px;
          box-sizing: border-box;

          img {
            width: 32px;
            height: 32px;
            margin-right: 20px;
          }
        }

        .hot-policy-encyclopedia-content {
          padding: 15px 0 15px 15px;
          box-sizing: border-box;

          .tag-title {
            color: rgba(16, 16, 16, 1);
            font-size: 16px;
            margin-bottom: 16px;
          }

          .el-tag {
            font-size: 14px;
            min-width: 87px;
            height: 30px;
            background-color: #ffffff;
            color: #101010;
            border: 1px solid rgba(187, 187, 187, 1);
            border-radius: 5px;
            margin-right: 5px;
            margin-bottom: 15px;
            cursor: pointer;
          }

          .active {
            background-color: rgba(46, 90, 166, 1);
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
          }

          .tag-industry-classification {
            text-align: left;
            margin-bottom: 15px;

            .el-tag {
              margin-right: 15px;
              margin-bottom: 15px;
              padding: 0 25px;
            }
          }

          .tag-office {
            text-align: left;
            margin-bottom: 15px;
          }

          .tag-district {
            text-align: left;
            margin-bottom: 15px;
          }

          .tag-policy-classification {
            text-align: left;
            margin-bottom: 15px;
          }
        }

        .el-button {
          width: 158px;
          height: 40px;
          line-height: 23px;
          border-radius: 5px;
          //background: linear-gradient(92.08deg, rgba(188, 67, 181, 1) 0.52%, rgba(84, 65, 187, 1) 96.37%);
          background: linear-gradient(145deg,#eacb90 0%,#bb7c35 100%);
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          text-align: center;
        }
      }

      .declaration-announcement-container {
        width: 420px;
        min-height: 999px;
        background-color: #f5f6fd;
        border-radius: 8px 8px 0 0;

        .declaration-announcement-header {
          height: 46px;
          border-radius: 8px 8px 0 0;
          background: linear-gradient(91.36deg, rgba(251, 117, 77, 1) 4.86%, rgba(254, 209, 94, 1) 99.27%);
          color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(238, 237, 248, 1);
          font-size: 20px;
          padding-left: 15px;
          box-sizing: border-box;

          img {
            width: 32px;
            height: 32px;
            margin-right: 20px;
          }
        }

        .declaration-announcement-content {
          padding: 0 15px;
          box-sizing: border-box;

          .announcement-title {
            height: 113px;
            border-bottom: 1px dashed rgba(187, 187, 187, 1);
            padding-top: 18px;
            box-sizing: border-box;
            text-align: left;

            a {
              line-height: 25px;
              color: rgba(51, 51, 51, 1);
              font-size: 18px;
            }

            span {
              color: #999999;
            }
          }

          .announcement-more {
            display: inline-block;
            margin-top: 20px;
            width: 100px;
            height: 20px;
            line-height: 20px;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
          }


        }
      }
    }
  }
  .project-declaration{
    font-weight: bold;
    color: #30B08F;
    font-size: 21px;
    text-decoration: underline;
  }
}

</style>
