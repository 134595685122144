<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/policy-encyclopedia"/>
    <div class="policy-encyclopedia-image"/>
    <div class="policy-encyclopedia-container">
      <Breadcrumb :items="['政策大全']"/>
      <div class="policy-encyclopedia-search">
        <div class="tag-container flex-flex-start" v-if="false">
          <div class="tag-title-tips"><span class="span">行业</span>：</div>
          <div class="tag-item">
            <el-tag v-for="(item,index) in industries" :key="index"
                    @click="searchForm('industry',item.dictValue)"
                    :class="searchData.industry==item.dictValue? 'active':''" style="margin-bottom: 5px">
              {{ item.dictLabel }}
            </el-tag>
          </div>
        </div>
        <div class="tag-container flex-flex-start" style="margin-top: -5px">
          <div class="tag-title-tips"><span class="span">主管部门</span>：</div>
          <div class="tag-item">
            <el-tag v-for="(item,index) in offices" :key="index" @click="searchForm('office',item.dictValue)"
                    :class="searchData.office==item.dictValue? 'active':''" style="margin-bottom: 5px">
              {{ item.dictLabel }}
            </el-tag>
          </div>
        </div>
        <div class="tag-container flex-flex-start" style="margin-top: -5px">
          <div class="tag-title-tips"><span class="span">区域</span>：</div>
          <div class="tag-item">
            <el-tag v-for="(item,index) in district" :key="index" @click="searchForm('district',item.dictValue)"
                    :class="searchData.district==item.dictValue? 'active':''" style="margin-bottom: 5px">
              {{ item.dictLabel === "上海市" ? "全部" : item.dictLabel }}
            </el-tag>
          </div>
        </div>
        <div class="tag-container flex-flex-start" style="margin-top: -5px">
          <div class="tag-title-tips"><span class="span">政策分类</span>：</div>
          <div>
            <el-tag v-for="(item,index) in policyClassification" @click="searchForm('type',item.dictValue)"
                    :key="index" :class="searchData.type==item.dictValue? 'active':''">
              {{ item.dictLabel }}
            </el-tag>
          </div>
        </div>
      </div>
      <el-divider/>
      <!--      <div class="policy-encyclopedia-list" v-if="policy.length>0">-->
      <!--        <router-link class="list-item" v-for="(item,index) in policy" :key="index"-->
      <!--                     :to="`/policy-encyclopedia-details?code=${item.id}`">-->
      <!--          <img :src="`${baseUrl}${item.image}`" alt="" :title="item.name"/>-->
      <!--          <div class="item-title">{{ item.name }}</div>-->
      <!--        </router-link>-->
      <!--        <el-pagination-->
      <!--            ref="pagination"-->
      <!--            layout="total, sizes, prev, pager, next, jumper"-->
      <!--            :current-page="searchData.pageNo"-->
      <!--            :page-sizes="[15, 30, 45, 150]"-->
      <!--            :page-size="searchData.pageSize"-->
      <!--            :total="totalCount"-->
      <!--            style="padding-top: 10px"-->
      <!--            @size-change="handleSizeChange"-->
      <!--            @current-change="handleCurrentChange"-->
      <!--        />-->
      <!--      </div>-->
      <div class="policy-encyclopedia-list" v-if="policy.length>0">
        <el-table :data="policy" style="width: 100%" :show-header="false">
          <el-table-column>
            <template v-slot="scope">
              <router-link :to="`/policy-encyclopedia-details?code=${scope.row.id}`">
                <div class="flex-space-between table-row-cell">
                  <div class="cell-content">
                    <div class="cell-content-title">{{ scope.row.name }}</div>
                    <div class="cell-content-date">{{ parseTime(scope.row.publishDate, '{y}-{m}-{d}') }}</div>
                    <div class="cell-content-desc" v-html="scope.row.content.replace(/<[^>]+>/g, '') || ''">
                    </div>
                  </div>
                </div>
              </router-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            ref="pagination"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="searchData.pageNo"
            :page-sizes="[10, 20, 30, 100]"
            :page-size="searchData.pageSize"
            :total="totalCount"
            style="padding-top: 10px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
      <div class="null-data" v-else>暂无数据</div>


    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ProjectDeclaration"
}
</script>

<script setup>

import MenuBar from '@/components/MenuBar';
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import {onBeforeMount, onMounted, ref} from "vue";
import apiPolicy from "@/api/policy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import {parseTime} from "@/utils/coreyee";

// const baseUrl = process.env.VUE_APP_BASE_API
const offices = ref([])
const district = ref([])
const policyClassification = ref([])
const industries = ref([])

const searchData = ref({
  industry: 0,
  progress: 0,
  office: 0,
  district: 0,
  type: 0,
  pageNo: 1,
  pageSize: 10
})

onBeforeMount(() => {
  const first = {dictLabel: '全部', dictValue: '0'}
  industries.value = JSON.parse(sessionStorage.getItem('industryCategories'))
  industries.value.unshift(first)
  offices.value = JSON.parse(sessionStorage.getItem('offices'))
  offices.value.unshift(first)
  district.value = JSON.parse(sessionStorage.getItem('districts'))
  policyClassification.value = JSON.parse(sessionStorage.getItem('policyTypes'))
  policyClassification.value.unshift(first)
  if (history.state.params) {
    initSearch(JSON.parse(history.state.params))
  }
})
onMounted(() => {
  getPolicy()
})
const initSearch = (params) => {
  searchData.value = {...searchData.value, ...params}
}
const policy = ref([])
const getPolicy = () => {
  apiPolicy.getList(searchData.value).then(resp => {
    policy.value = resp.rows;
    totalCount.value = resp.total
  })
}

const totalCount = ref(0)

const searchForm = (type, value) => {
  searchData.value[type] = value
  getPolicy()
}

const handleSizeChange = (pageSize) => {
  console.log('pageSize', pageSize)
  searchData.value.pageSize = pageSize
  getPolicy()
}
const handleCurrentChange = (current) => {
  console.log('current', current)
  searchData.value.pageNo = current
  getPolicy()
}
</script>

<style scoped lang="scss">
.policy-encyclopedia-image {
  width: 100%;
  height: 350px;
  margin: 0 auto;
  background-image: url("../assets/image/bg-04.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: top center;
}

.policy-encyclopedia-container {
  width: 1200px;
  margin: 30px auto;
  text-align: left;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px 30px 35px 30px;
  box-sizing: border-box;

  .el-divider {
    margin-top: 0;
  }

  .policy-encyclopedia-search {
    margin-top: 10px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    padding: 0;
    box-sizing: border-box;

    .tag-container {
      margin-bottom: 20px;
      align-items: flex-start;
      color: rgba(102, 102, 102, 1);


      .tag-title-tips {
        span {
          width: 60px;
        }
      }

      .tag-item {
        flex: 1;
      }

      .span {
        /* 设置span 文字左右两端对齐 */
        display: inline-block;
        width: 45px;
        font-size: 14px;
        text-align: justify;
        text-align-last: justify;
      }

      .el-tag {
        cursor: pointer;
        min-width: 56px;
        height: 24px;
        margin-right: 4px;
        color: #666666;
        background-color: unset;
        border: unset;
        font-size: 14px;
      }

      .active {
        border-radius: 5px;
        background-color: #CBB486;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        padding: 0 10px;


      }
    }
  }

  .policy-encyclopedia-list {
    margin-top: 24px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    padding: 0 0 20px;

    .list-item {
      margin-bottom: 24px;
      width: 210px;
      margin-right: 22px;
      display: inline-block;

      &:nth-child(5n) {
        margin-right: 0;
      }

      img {
        width: 210px;
        height: 180px;
      }

      .item-title {
        width: 210px;
        height: 45px;
        line-height: 21px;
        font-size: 14px;
        color: #020202;

        /* 控制显示行数 */
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }


    .el-table {
      margin-bottom: 10px;
    }

    .table-row-cell {
      height: 130px;
      box-sizing: border-box;
      align-items: flex-start;

      .cell-image {
        width: 224px;
        height: 130px;
        margin-right: 20px;

        img {
          width: 224px;
          height: 130px;
        }
      }

      .cell-content {
        flex: 1;
        font-size: 14px;
        color: #020202;

        .cell-content-title {
          font-size: 18px;
        }

        .cell-content-date {
          margin: 20px 0 6px 0;
        }

        .cell-content-desc {
          width: 100%;

          /* 控制显示行数 */
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    :deep td.el-table__cell {
      border-bottom: 1px #BBBBBB dashed;
      padding: 20px 0;
    }

    :deep .el-table--default .cell {
      padding: 0;
    }

  }

  .null-data {
    text-align: center;
    color: #909399;
    font-size: 14px;
  }

}
</style>
