<template>
  <div class="common-layout fixed">
    <el-container>
      <HeaderView title=""/>
      <el-main>
        <div class="mobile-news-details">
          <h1>{{ newsDetail.title }}</h1>
          <p>
            <span>信息来源：{{ newsDetail.source }}</span><br>
            <span>发布日期：{{ formatDate(newsDetail.releaseDate) }}</span>
          </p>
          <div class='mobile-news-content'>
            <div class="ql-editor">
              <article v-html="newsDetail.detail"/>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <FooterView/>
      </el-footer>
    </el-container>
  </div>
</template>

<script setup>
import HeaderView from '@/components/mobile/Header'
import FooterView from '@/components/mobile/Footer'
import {onMounted, ref} from "vue";
import {useRoute} from 'vue-router';
import {getNewsDetail} from "@/api/news";

const formatDate = (value)=>{
  if (!value) return ''
  let dt = new Date(value);
  let year = dt.getFullYear();
  let month = (dt.getMonth() + 1).toString().padStart(2,'0');
  let date = dt.getDate().toString().padStart(2,'0');
  return `${year}-${month}-${date}`
}

let route = useRoute();

let newsDetail = ref({});

onMounted(() => {
  //document.title = "企业综合服务系统"

  let code = route.query.code;
  getNewsDetail(code).then(response => {
    newsDetail.value = response.data
  })

})

</script>

<style lang="scss" scoped>

.common-layout {
  height: 100%;
  min-height: 100vh;
  display: flex;

  .el-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.fixed {
    .el-container {
      flex: 1;
    }

    .el-footer {
      --el-footer-height: unset;
    }
  }

  .el-footer {
    --el-footer-padding: 0;
    margin-top: 100px;
  }

  .el-main {
    padding: 0 10px;

    .mobile-news-details{
      h1 {
        margin: 0;
        color: #333333;
        font-size: 20px;
        padding: 20px 0 15px 0;
        text-align: left;
        font-weight: normal;
      }

      p {
        margin: 0;
        border-bottom: #DDDDDD dashed 1px;
        text-align: left;
        padding-bottom: 20px;

        span {
          color: #999999;
          font-size: 13px;
        }
      }

      .mobile-news-content{
        padding: 30px 0;
        text-align: left;
      }
    }
  }
  :deep .ql-editor{
    min-height: unset;
    max-height: unset;
    padding: 0;
    img{
      width: 100%;
    }
  }


}
</style>
