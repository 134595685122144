<template>
  <div class="common-layout fixed">
    <el-container>
      <HeaderView title=""/>
      <el-main>
        <div class="mobile-services">
          <div class="mobile-services-title">Enterprise Service<span class="text">{{ serviceDetail.name }}</span></div>
          <div class="mobile-services-details">
            <div class="brief-intro">
              {{ serviceDetail.briefIntro }}
            </div>
            <div class="range">
              <div class='title'><span></span>业务范围</div>
              <div class='ql-editor'>
                <article v-html="serviceDetail.businessRange"/>
              </div>
            </div>
            <div class="introduce">
              <div class='title'><span></span>业务介绍</div>
              <div class='ql-editor'>
                <article v-html="serviceDetail.introduction"/>
              </div>
            </div>
          </div>
          <div class="mobile-services-apply">
            <el-button
                :loading="loading"
                size="large"
                class="button"
                @click.prevent='ApplyFor()'>
              <span v-if="!loading">立即申请</span>
              <span v-else>申请提交中...</span>
            </el-button>
          </div>
        </div>
      </el-main>
      <el-footer>
        <FooterView/>
      </el-footer>
    </el-container>
    <div class='mobile-message-box' v-show='isShow'>
      <div class='shade'></div>
      <div class='content'>
        <img src='@/assets/fa5-check-circle-fas.svg' >
        <h4>您的服务申请已提交成功</h4>
        <p>*系统后台已收到您的服务申请，我们的服务人员将尽快与您取得联系，为您提供后续的服务支持。</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import HeaderView from '@/components/mobile/Header'
import FooterView from '@/components/mobile/Footer'
import {onMounted, ref} from "vue";
import {useRoute} from 'vue-router';
import {getServiceItemDetails, serviceApplication} from "@/api/service";
import {ElMessageBox} from "element-plus";
import router from "@/router";

const route = useRoute()
const loading=ref(false)
const isShow=ref(false)
const serviceItemId=ref(0)
const serviceDetail = ref({
  procedure:'',
})
const tags=ref([])

onMounted(() => {
  //document.title = "企业综合服务系统"

  let code = route.query.code;
  getServiceItemDetails(code).then(response => {
    serviceDetail.value = response.data
    serviceItemId.value = response.data.id
    tags.value=response.data.tag!=null?response.data.tag.split('|'):""
  })

})

const ApplyFor=function () {
  loading.value = true;
  serviceApplication(serviceItemId.value).then((res) => {
    console.log("res1",res)
    loading.value = false;
    switch (res.code){
      case 200:
        isShow.value = true
        setTimeout(function () {
          isShow.value = false
        }, 3000)
        break
      case 9001:
        ElMessageBox.confirm("您的企业信息尚未完善，请前往“个人中心”完善信息", "系统提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: "warning",
        }).then(() => {
          router.push("/account");
        }).catch(() => { });
        break;
      case 9002:
        ElMessageBox.alert("您已申请过该服务项目，无需重复申请!", "系统提示", {
          type: "warning",
        }).catch(()=>{})
        break;
    }

  }).catch(() => {
    loading.value = false;
  });

}

</script>

<style lang="scss" scoped>

.common-layout {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;

  .el-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .el-footer {
    --el-footer-padding: 0;
    margin-top: 100px;
  }

  .el-main {
    padding: 0 10px;

    .mobile-services{
      .mobile-services-title {
        font-size: 39px;
        color: #F2F2F2;
        font-weight: bold;
        position: relative;
        text-align: left;
        margin: 20px 0 10px;

        .text {
          font-size: 28px;
          color: #BDA26D;
          position: absolute;
          left: 19px;
          top: -7px;
        }
      }

      .mobile-services-details{
        font-size: 14px;
        color: #101010;
        line-height: 24px;
        text-align: left;
        .brief-intro{
          text-align: left;
        }
        .title{
          padding: 50px 0 20px 0;
          font-size: 22px;
          color: #000000;

          span{
            display: inline-block;
            width: 8px;
            height: 22px;
            background: #BDA26D;
            vertical-align: middle;
            margin-right: 10px;
          }
        }
      }

      .mobile-services-apply{
        margin-top: 50px;
        .button{
          left: 20px;
          top: 718px;
          width: 347px;
          height: 46px;
          line-height: 20px;
          border-radius: 5px;
          background: linear-gradient(264.2deg, rgba(185,145,107,1) 1.7%,rgba(228,200,149,1) 93.74%);
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          text-align: center;
          font-family: Microsoft Yahei;
          border: 1px solid rgba(255, 255, 255, 1);
        }
      }
    }
  }
  :deep .ql-editor{
    min-height: unset;
    max-height: unset;
    padding: 0;
    img{
      width: 100%;
    }
  }
  .mobile-message-box{

    .shade{
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.5);
    }

    .content{
      background: #ffffff;
      text-align: center;
      width: 75%;
      padding: 15px 25px;
      position: fixed;
      left: 8%;
      top: 26%;
      border-radius: 10px;
      color: rgba(16, 16, 16, 100);
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);

      img{
        width: 49px;
        height: 49px;
      }

      h4{
        font-size: 18px;
        color: #1F0757;
        margin: 15px 0;
      }

      p{
        margin: 0 auto;
        font-size: 12px;
        color: #151515;
        text-align: left;
        line-height: 21px;
      }

    }

  }

  &.fixed {
    .el-container {
      flex: 1;
    }

    .el-footer {
      --el-footer-height: unset;
    }
  }
}
</style>
<style>
 .el-popup-parent--hidden{
   width: 100% !important;
 }
</style>