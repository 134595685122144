<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition high-growth-projects-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">浦东新区促进高端装备产业高质量发展专项</div>
      <BasicInfo ref="basicInfo" :is-financial="false" :is-int-prop="false"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="请选择符合您企业的专题类型："
                            required prop="meetType">
                <el-radio-group v-model="formData.meetType" style="text-align: left">
                  <el-radio :label="1" style="height: 110px;">
                    1、支持整配联动。
                    <p class="radio-p">对为重大装备整机企业首批次配套供应具有自主知识产权、可替代国外同类产品的核心零部件企业，按
                      与整机企业签订的同一产品首批次合同，给予不超过合同金额10%的奖励，单个企业最高支持额度不超过 500
                      万元。</p>
                  </el-radio>
                  <el-radio :label="2" style="height: 110px;">
                    2、鼓励智能网联汽车创新应用。
                    <p class="radio-p">支持企业开展智能网联汽车改装，给予不超过改装成本 30%的补贴，每家企业每年不超过200
                      万元。支持企业开展智能网联汽车创新应用，给予不超过投
                      入成本 10%的补贴，每家企业每年不超过 200
                      万元。企业申请智能网联汽车改装或智能网联汽车创新应用，政策支持从优不重复。</p>
                  </el-radio>
                  <el-radio :label="3" style="height: 90px;">
                    3、鼓励无人驾驶装备创新应用。
                    <p class="radio-p">支持取得资质的无人驾驶装备企业开展无人驾驶装备创新应用，根据示范应用效果给予每家企业每年不超过
                      200 万元的奖励。</p>
                  </el-radio>
                  <el-radio :label="4" style="height: 90px;">
                    4、支持智能工厂建设。
                    <p class="radio-p">对经上海市相关部门评选认定的上海市标杆性智能工厂和上海市智能工厂，分别给予企业 50
                      万元、20 万元的一次性奖励，升级给予差额奖励。</p>
                  </el-radio>
                  <el-radio :label="5" style="height: 130px;">
                    5、智能工厂评估诊断补贴。
                    <p class="radio-p">对于向评估诊断服务机构和数字化转型服务商购买智能制造能力成熟度诊断服务，产值规模 1
                      亿元以下的规上工业企业，报告经评估合格后，依据合同实际金额给予全额补贴，最高不超过 4 万元。鼓励产值规模
                      1 亿元以上的规上工业企业申请享受市“智评券”政策，对于列入市级“智评券”政策支持的工业企业，按照与市级扶持资金最高
                      1:1 的比例给予配套支持，市、区两级总支持金额不超过 4 万元。</p>
                  </el-radio>
                  <el-radio :label="6" style="height: 130px;">
                    6、配套支持。
                    <p class="radio-p">对获得上海市级政策支持的高端智能装备首台套突破项目，可在项目验收完成后，给予一定配套奖励。
                      对明确配套要求的，按市级部门立项时明确的区级支持要求给予配套支持。对未明确配套要求的，经区相关部门协商一致，
                      按照企业获得的上海市级项目扶持资金总额，给予不超过企业获得上海市级项目扶持资金
                      50%的奖励。同一项目各级政府奖励和资助总额不超过企业总投入的 50%，最高 5000 万元。</p>
                  </el-radio>
                  <el-radio :label="0">以上均不符合</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并测评
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPromoteDevelopmentOfHighendEquipment"
}
</script>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import MenuBar from '@/components/MenuBar.vue'
import BasicInfo from '@/components/BasicInfo.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import {CircleCheckFilled, Finished, Promotion} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'pudongPromoteDevelopmentOfHighendEquipment'

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({})
onMounted(() => {
  getProjectPudongByPath()
})
const getProjectPudongByPath = () => {
  companyApi.getProjectPudongByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveProjectPudongByPath(urlPath, company).then((resp) => {
    if (resp.code === 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getProjectPudongByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitProjectPudongByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

</script>
<style scoped lang="scss">
.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
  :deep .el-radio__label{
    white-space: normal;  /* 换行 */
  }

  :deep .el-checkbox__label{
    white-space: normal;  /* 换行 */
  }

  .radio-p {
    width: 1080px;
    word-wrap: break-word;
    background: #f1f1f1;
    padding: 10px;
    border-radius: 10px;
    line-height: 21px;
    margin-top: 0;
    text-align: left;
  }
}
</style>
