<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/policy-encyclopedia"/>
    <div class='details-main-container'>
      <Breadcrumb :items="['政策大全','详情']" />
      <h1 class="details-title">{{ detail.name }}</h1>
      <div class="details-tag">
        <span>区域：{{ districtEnum[detail.district] }}</span>
        <span>主管部门：{{ officesEnum[detail.office] }}</span>
        <span>政策分类：{{ policyTypesEnum[detail.type] }}</span>
        <span>发布日期：{{ detail.publishDate }}</span>
      </div>
      <div class='details-content'>
        <div class="ql-editor" style="min-height: auto; max-height: none">
          <article v-html="detail.content"/>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, onMounted, ref} from "vue";
import {useRoute} from 'vue-router';
import apiPolicy from "@/api/policy";

let route = useRoute();

let detail = ref({});
const officesEnum = ref({})
const districtEnum = ref({})
const policyTypesEnum = ref({})
onBeforeMount(() => {
  officesEnum.value = JSON.parse(sessionStorage.getItem('officesEnum'))
  districtEnum.value = JSON.parse(sessionStorage.getItem('districtEnum'))
  policyTypesEnum.value = JSON.parse(sessionStorage.getItem('policyTypesEnum'))
})
onMounted(() => {
  let code = route.query.code;
  apiPolicy.getDetail(code).then(response => {
    detail.value = response.data
  })

})
</script>

<style lang="scss" scoped>

</style>
