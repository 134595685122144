<template>
  <div style='clear: both'></div>
  <div class='bottom-container'>

    <p class="copyright">
      <router-link style="color:#FFFFFF;" to="#">Copyright © 2014-2024, 上海芯屹信息技术有限公司. All rights reserved.
        沪ICP备2020027374-2号
      </router-link>
    </p>
  </div>

  <div class="affix">
    <router-link to="/project-declaration" class="affix-item">
      <el-icon>
        <Notebook/>
      </el-icon>
      <span > 全部政策</span>
    </router-link>
<!--    <router-link to="/project-declaration" class="affix-item">-->
<!--      <el-icon>-->
<!--        <Download/>-->
<!--      </el-icon>-->
<!--      <span> AI研究院</span>-->
<!--    </router-link>-->
    <router-link to="/vipdesc" class="affix-item">
      <el-icon>
        <User/>
      </el-icon>
      <span>会员中心</span>
    </router-link>
    <router-link to="/feedback" class="affix-item">
      <el-icon>
        <Tickets/>
      </el-icon>
      <span> 留言咨询</span>
    </router-link>
    <router-link to="/help" class="affix-item">
      <el-icon>
        <Service/>
      </el-icon>
      <span>  帮助中心</span>
    </router-link>
    <router-link to="/all-privacy" class="affix-item">
      <el-icon>
        <ScaleToOriginal/>
      </el-icon>
      <span>  用户协议</span>
    </router-link>
  </div>
  <el-backtop :bottom="50" :right="70" style="width: 30px; height: 30px; padding: 8px; font-size: 13px; border-radius:0"><span>回到顶部</span></el-backtop>
</template>


<script setup>
import {onMounted} from "vue";
import {Notebook} from "@element-plus/icons-vue";
import {Service} from "@element-plus/icons-vue";
import {Tickets} from "@element-plus/icons-vue";

import {User} from "@element-plus/icons-vue";
import {ScaleToOriginal} from "@element-plus/icons-vue";
onMounted(() => {
  // console.log( this.routes);
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.bottom-container {
  width: 100%;
  background: #F6F6F6;
  text-align: center;
  box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.12);

  .copyright {
    font-size: 14px;
    height: 77px;
    line-height: 77px;
    color: #FFFFFF;
    background-color: #000000;
    margin: 0;
  }


}

.affix {
  position: fixed;
  top: 40%;
  //left: 0.5rem;
  align-items: center;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0 .1rem .23rem 0 rgba(0, 0, 0, .28);
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  height: 260px;
  justify-content: center;
  width: 120px;
  overflow: hidden;
  opacity: 80%;
}

.affix-item {
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.affix-item span{margin-left: 10px;}

.affix-item:hover {
  background-color: #1890ff;
  animation: bgColorAnimation 1s infinite alternate;

  color: #FFFFFF
}

@keyframes bgColorAnimation {
  from {
    background-color: #c9d8ff;
  }
  to {
    background-color: #1890ff;
  }
}


@keyframes blink-red {
  0% {
    width: 0
  }

  100% {
    width: 100%
  }
}


@keyframes blink-yellow {
  0% {
    width: 0
  }

  100% {
    width: 100%
  }
}

.blinking-text-red {
  font-weight: bold;
  color: #ff6600;
  overflow: hidden;
  white-space: nowrap;
  animation: blink-red 3s infinite;
}

.blinking-text-yellow {
  font-weight: bold;
  color: #CBB486;
  overflow: hidden;
  white-space: nowrap;
  animation: blink-yellow 3s infinite;
}


</style>
