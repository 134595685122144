<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>浦东新区人工智能赋能经济数字化转型高质量发展专项</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>工商注册、税收户管均在浦东新区，经营状态正常、信用记录良好、财务制度健全的企业。
              </td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>
                1、支持数字化转型标杆项目
                <br>支持符合国家、上海市、浦东新区经济数字化转型发展方向，
                具有创新性和引领性的经济数字化转型项目。对世界人工智能大
                会应用场景、人工智能创新应用先导区和经济数字化转型“揭榜
                挂帅”项目、数据要素市场建设的重大平台和创新应用等标杆项
                目，经审核，给予不超过项目总投资 50%的资助，单个项目最高
                不超过 2000 万元。
                <br>2、支持数据管理能力提升
                <br>对 2022 年 1 月 1 日后通过《数据管理能力成熟度评价模型》
                贯标认证的企业，经核定，根据认证级别给予最高不超过 25 万
                元补贴。同一企业升级认证级别，给予差额补贴
                <br>3、支持服务型制造
                <br>对国家、上海市相关部门认定的服务型制造示范企业、平台、
                项目，分别给予 100 万元、50 万元的一次性奖励。同类示范层
                级从市级升级为国家级，给予差额奖励
                <br>4、配套支持
                <br>对获得上海市级政策支持的人工智能和软件项目，可在项目
                验收完成后，给予一定配套奖励。
                对明确配套要求的，按市级部门立项时明确的区级支持要求
                给予配套支持。对未明确配套要求的，经区相关部门协商一致，
                按照企业获得的上海市级项目扶持资金总额，给予不超过企业获
                得上海市级项目扶持资金 50%的奖励。同一项目各级政府奖励和
                资助总额不超过企业总投入的 50%，最高 5000 万元。

              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPromoteDevelopmentOfAi"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
