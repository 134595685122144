<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/policy-interpretation"/>
    <div class="policy-interpretation-image"/>
    <div class="policy-interpretation-container">
      <Breadcrumb :items="['政策解读']"/>
      <div class="policy-interpretation-table">
        <div class="policy-group" style="padding-top:20px">
          <div class="policy-group-title">
            《上海市促进中小企业发展条例》解读
          </div>
          <div class="policy-group-sub-title">
            分章节对《条例》9个部分进行深度解读，企业通过《条例》学习更好促进发展，维护自身权益
          </div>
          <div class="policy-group-item-grid">
            <template v-for="(item, index) in policyData">
              <div class="policy-grid-item" @click="showPlayer(item)">
                <div class="cover" :style="{ backgroundImage: 'url(' + publicPath + item.cover +')'}"></div>
                <div class="title">{{item.title}}</div>
              </div>
            </template>
          </div>
        </div>
        <div class="policy-group">
          <div class="policy-group-title">《税收优惠政策》解读</div>
          <div class="policy-group-sub-title">选区普惠性政策（所得税、增值税、个税等）进行深度解读，帮助企业降本增效，普惠政策应享尽享</div>
          <div class="policy-group-item-grid">
            <template v-for="(item, index) in taxData">
              <div class="policy-grid-item" @click="showPlayer(item)">
                <div class="cover" :style="{ backgroundImage: 'url(' + publicPath + item.cover +')'}"></div>
                <div class="title">{{item.title}}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
  <el-dialog
      v-model="videoPlayerDialogVisible"
      destroy-on-close
      :modal="true"
      :title="videoPlayerDialogTitle"
      width="800px"
      align-center>
    <video class="video-player" :src="videoPlayerSrc" autoplay controls disablepictureinpicture controlslist="nodownload noremoteplayback noplaybackrate"></video>
  </el-dialog>

</template>

<script>
export default {
  name: "PolicyInterpretation"
}
</script>

<script setup>

import MenuBar from '@/components/MenuBar';
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import {onMounted, ref} from "vue";
import apiPolicyInfo from "@/api/policyInfo";
import Breadcrumb from "@/components/Breadcrumb.vue";

const baseUrl = process.env.VUE_APP_BASE_API
const publicPath = process.env.BASE_URL
const videoPlayerDialogVisible = ref(false)
const videoPlayerDialogTitle = ref(false)
const videoPlayerSrc = ref(null)

const policyData = ref([
  {title: "《上海市促进中小企业发展条例》解读动画第1期第1章", cover: 'video/policy_1.png', video:'video/policy_1.mp4' },
  {title: "《上海市促进中小企业发展条例》解读动画第2期第2章", cover: 'video/policy_2.png', video:'video/policy_2.mp4' },
  {title: "《上海市促进中小企业发展条例》解读动画第3期第3章（上）", cover: 'video/policy_3-1.png', video:'video/policy_3-1.mp4' },
  {title: "《上海市促进中小企业发展条例》解读动画第4期第3章（下）", cover: 'video/policy_3-2.png', video:'video/policy_3-2.mp4' },
  {title: "《上海市促进中小企业发展条例》解读动画第5期第4章（上）", cover: 'video/policy_4-1.png', video:'video/policy_4-1.mp4' },
  {title: "《上海市促进中小企业发展条例》解读动画第6期第4章（下）", cover: 'video/policy_4-2.png', video:'video/policy_4-2.mp4' },
  {title: "《上海市促进中小企业发展条例》解读动画第7期第5章 一", cover: 'video/policy_5-1.png', video:'video/policy_5-1.mp4' },
  {title: "《上海市促进中小企业发展条例》解读动画第8期第5章 二", cover: 'video/policy_5-2.png', video:'video/policy_5-2.mp4' },
  {title: "《上海市促进中小企业发展条例》解读动画第9期第5章 三", cover: 'video/policy_5-3.png', video:'video/policy_5-3.mp4' },
])

const taxData = ref([
  {title: "解读动画第45期 企业所得税优惠政策前言", cover: 'video/tax_45.png', video:'video/tax_45.mp4' },
  {title: "解读动画第46期 企业所得税优惠政策1", cover: 'video/tax_46.png', video:'video/tax_46.mp4' },
  {title: "解读动画第47期 企业所得税优惠政策2", cover: 'video/tax_47.png', video:'video/tax_47.mp4' },
  {title: "解读动画第48期 企业所得税优惠政策3", cover: 'video/tax_48.png', video:'video/tax_48.mp4' },
  {title: "解读动画第49期 企业所得税优惠政策4", cover: 'video/tax_49.png', video:'video/tax_49.mp4' },
  {title: "解读动画第50期 企业所得税优惠政策5", cover: 'video/tax_50.png', video:'video/tax_50.mp4' },
  {title: "解读动画第51期 增值税优惠政策1", cover: 'video/tax_51.png', video:'video/tax_51.mp4' },
  {title: "解读动画第52期 增值税优惠政策2", cover: 'video/tax_52.png', video:'video/tax_52.mp4' },
  {title: "解读动画第53期 增值税优惠政策3", cover: 'video/tax_53.png', video:'video/tax_53.mp4' },
])

onMounted(() => {
})

function showPlayer(item) {
  videoPlayerSrc.value = item.video
  videoPlayerDialogTitle.value = item.title
  videoPlayerDialogVisible.value = true
}
</script>

<style scoped lang="scss">
.policy-interpretation-image {
  width: 100%;
  height: 350px;
  background-image: url("../assets/image/policy-interpretation-image.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: top center;
}

.policy-interpretation-container {
  width: 1200px;
  margin: 30px auto;
  text-align: left;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px 30px 35px 30px;
  box-sizing: border-box;

  .policy-group {
    padding-top: 60px;
    .policy-group-title {
      color: rgba(16,16,16,1);
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
    }
    .policy-group-sub-title {
      color: rgba(16,16,16,1);
      font-size: 16px;
      padding-top: 20px;
    }
    .policy-group-item-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 36px;
      grid-row-gap: 18px;
      padding-top: 8px;

      .policy-grid-item {
        cursor: pointer;

        .title {
          line-height: 23px;
          color: rgba(16,16,16,1);
          font-size: 16px;
          margin-top: 20px;
        }
        .cover {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          height: 180px;
        }
      }
    }
  }

  .policy-interpretation-table {
    border-radius: 8px;

    .el-table {
      margin-bottom: 10px;
    }

    .table-row-cell {
      height: 130px;
      box-sizing: border-box;
      align-items: flex-start;

      .cell-image {
        width: 224px;
        height: 130px;
        margin-right: 20px;

        img {
          width: 224px;
          height: 130px;
        }
      }

      .cell-content {
        flex: 1;
        font-size: 14px;
        color: #020202;

        .cell-content-title {
          font-size: 18px;
        }

        .cell-content-date {
          margin: 20px 0 6px;
        }

        .cell-content-desc {
          width: 100%;

          /* 控制显示行数 */
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
        }

      }
    }


    :deep td.el-table__cell {
      border-bottom: 1px #BBBBBB dashed;
      padding: 20px 0;
    }

    :deep .el-table--default .cell {
      padding: 0;
    }

  }
}


.video-player {
  height: 400px;
  aspect-ratio: 16 / 9;
}
</style>
