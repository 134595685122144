import router from './router'
import {ElMessage} from 'element-plus'

import {getToken} from '@/utils/auth'
import {isRelogin} from '@/utils/request'
import useUserStore from '@/store/modules/user'


const whiteList = ['/', '/login', '/register', '/news', '/news-details', '/service-details', '/project-declaration'
    , '/policy-encyclopedia', '/policy-encyclopedia-details', '/policy-interpretation', '/policy-interpretation-details'
    , '/declaration-announcement', '/declaration-filling', '/declaration-details', '/project-declaration-details','/feedback',
    , '/single-page-benefit', '/single-page-agreement', '/single-page-legal-notice', '/single-page-protection-policy', '/single-page-about'
    , '/service-provider-registration', '/single-page-service-provider'
    , '/mobile', '/mobile/login', '/mobile/register', '/mobile/news', '/mobile/news-details', '/mobile/service-details'
    , '/mobile/single-page-benefit', '/mobile/single-page-agreement', '/mobile/single-page-legal-notice'
    , '/mobile/single-page-protection-policy', '/mobile/single-page-about'
    , '/authentication', '/authentication/result', '/authentication/result_1', '/authentication/result_2', '/authentication/result_3'
    , '/condition-form/high-new-tech', '/condition-form/high-new-tech-transform', '/condition-form/innovate-smes'
    , '/condition-form/sh-patent-demo-enterprise', '/condition-form/sh-patent-demo-public', '/condition-form/sh-patent-polit-enterprise', '/condition-form/sh-patent-polit-public'
    , '/condition-form/sh-tech-center', '/condition-form/spec-ultra-new-smes', '/condition-form/tech-smes', '/all-privacy', '/vip', '/help', '/myreport', '/vipdesc'
];

router.beforeEach((to, from, next) => {

    if (getToken()) {
        /* has token*/
        if (to.path === '/login') {
            next({path: '/'})
        } else {
            if (useUserStore().roles.length === 0) {
                isRelogin.show = true
                // 判断当前用户是否已拉取完user_info信息
                useUserStore().getInfo().then(() => {
                    isRelogin.show = false
                    next()
                }).catch(err => {
                    useUserStore().logOut().then(() => {
                        ElMessage({
                            message: err,
                            type: 'error',
                            offset: 300
                        })
                        next({path: '/'})
                    })
                })
            } else {
                next()
            }
        }
    } else {
        // 没有token
        console.log(to.path)
        if (whiteList.indexOf(to.path) !== -1) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页

        }
    }
})

router.afterEach(() => {
})
