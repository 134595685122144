<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>浦东新区促投资提能级专项</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>工商注册地和税收户管地均在浦东新区的企业
              </td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>
                （一）集聚优质产业创新资源
                <br>定期发布战略招商、精准招商目录，对新引进的产业生态塑
                造能力强、产业链带动能力强的领军企业、创新型企业、重点产
                业配套企业等，按不超过实际投资的 50%，根据企业能级、综合
                贡献，分别给予不超过 5000 万元、2000 万元、1000 万元的奖励。
                区级产业招商统筹，经区领导决策后事前立项，按项目投入
                或产出考核指标，分阶段拨付奖励资金。对新引进的企业和项目，
                在后续享受与贡献度挂钩的财政扶持政策时，应予以查重扣除。
                <br>（二）鼓励企业加大投资力度
                <br>1、对纳入工业固定资产投资统计的、投资总额 1000 万元及
                以上的项目，项目建设完成后、经申报评定为浦东新区重点投资
                项目的，验收完成后，按照不超过项目核定固定资产投资额的
                10%予以奖励。
                2、对经申报评定为上海市重点技术改造项目的，纳入浦东
                新区重点投资项目给予立项支持，验收完成后，按照不超过市核
                定的固定资产投资额的 10%予以奖励，且单个项目市、区两级总
                支持金额不超过该项目核定固定资产投资额的 15%。
                以上项目优先考虑符合“六大硬核”优势产业定位，单个项目
                奖励不超过 2000 万元；企业两年内不得关停并转或外迁。
                <br>（三）支持产业规模跃升
                <br>对纳入浦东新区规上企业统计范围，连续生产经营两年以上，
                且近三年未出现负增长（不可抗因素除外），产值或增速达到一
                定条件的制造业、软件信息服务业、科技服务业企业，给予奖励。
                <br>（四）配套支持
                <br>对获得上海市级专项资金立项资助的项目，给予一定配套奖
                励。对配套要求明确的，按立项时明确的区级配套资金足额给予
                配套支持。对未明确配套要求的，根据申报指南明确的市级专项
                政策配套目录，经区相关部门协商一致，可在项目验收完成后，
                给予不超过企业获得上海市级项目扶持资金 50%的奖励，最高
                5000 万元。企业项目已获新区支持的，视同区级配套或奖励部
                分，可补差支持

              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPromoteDevelopmentOfMedicine"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
