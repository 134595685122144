<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>"专精特新"中小企业-认定评分明细</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>同时满足以下四项条件即视为满足认定条件</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th colspan="2">评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="2">1、从事特定细分市场时间达到2年以上</td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td colspan="2">2、上年度研发费用总额不低于100万元，且占营业收入总额比重不低于3％</td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td colspan="2">
                3、上年度营业收入总额在1000万元以上，或近2年新增股权融资总额（合格机构投资者的实缴额）达到2000万元以上
              </td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td rowspan="5" style="color: #ff0000">4、满足右边4项条件之一或评价得分达到60分以上</td>
            </tr>
            <tr>
              <td>①近三年获得过省级科技奖励，并在获奖单位中排名前三；或获得国家级科技奖励，并在获奖单位中排名前五</td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>②近两年研发费用总额均值在1000万元以上</td>
              <td v-html='(result[4] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>③近两年新增股权融资总额（合格机构投资者的实缴额）6000万元以上</td>
              <td v-html='(result[5] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>④近三年进入“创客中国”中小企业创新创业大赛全国500强企业组名单</td>
              <td v-html='(result[6] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="result-score">
        <div class="title"><span>*</span>各项指标评分明细
        </div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th style="width: 200px">评价分类</th>
              <th>评定项目</th>
              <th style="width: 124px;">得分</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td rowspan="4">专业化指标</td>
              <td>1.上年度主营营业收入占营业收入总额比重（5分）<br>
                A.80%以上  (5分)	B.70-80%  (3分)
                C.60-70%  (1分)	D.60%以下  (0分)</td>
              <td class="core">{{ (result[7] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>2.近2年主营业务收入平均增长率（10分）<br>
                A.10%以上   (10分)	 B.8-10%  (8分)
                C.6-8%    (6分)	 D.4-6%    (4分)
                E.0-4%    (2分) 	 F.0以下    (0分)</td>
              <td class="core">{{ (result[8] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>3.从事特定细分市场年限（5分）<br>每满2年得1分，最高不超过5分</td>
              <td class="core">{{ (result[9] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>4.主导产品所属领域情况（5分）
                <br>在产业链供应链关键环节及关键领域“补短板”“锻长板”“填空白”取得实际成效  （5分）
                <br>属于工业“六基”领域、中华老字号名录或企业主导产品服务关键产业链重点龙头企业（3分）
                <br>不属于以上情况  （0分）
              </td>
              <td class="core">{{ (result[10] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="4">精细化指标</td>
              <td>5.数字化水平（5分）
                <br>A.三级及以上（5分）
               B.二级（3分）
                C.一级（0分）
              </td>
              <td class="core">{{ (result[11] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>6.质量管理水平（5分）(每满足1项加3分，最高5分)
                <br>A.获得省级以上质量奖荣誉
                <br>B.建立质量管理体系，获得ISO9001等质量管理体系认证证书
                <br>C.拥有自主品牌
                <br>D.参与制修订标准
              </td>
              <td class="core">{{ (result[12] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>7.上年度净利润率（10分）<br>
                A.10%以上   10分       B.8-10%       8分
                C.6-8%     6分        E.4-6%        4分
                F.2-4%     2分        G.2%以下       0分
              </td>
              <td class="core">{{ (result[13] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>8.上年度资产负债率（5分）<br>
                A.50%以下  5分    B.50-60%  3分
                C.60-70%   1分   E.70%以上  0分
              </td>
              <td class="core">{{ (result[14] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="3">特色化指标</td>
              <td>9.所属领域符合本市产业导向（4分）
                <br>A.属于“3+6”新型产业体系，或战略性新兴产业领域（4分）
                <br>B.属于区级重点导向产业（2分）
                <br>C.不属于以上情况（0分）
              </td>
              <td class="core">{{ (result[15] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>10.细分市场领先地位（6分）
                <br>A.国内细分市场占有率超过10%，或位居全国前三（6分）
                <br>B.国内细分市场占有率位居全国前十或本市前五（5分）
                <br>C.国内细分市场占有率位居本市前十（4分）
                <br>D.不属于以上情况（0分）
              </td>
              <td class="core">{{ (result[16] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>11.实施特色化经营（每满足一项加3分，最高5分）
                <br>A.具有特色资源或技术进行研制生产，提供独具特色的产品或服务
                <br>B.实现绿色低碳发展，拥有绿色产品、绿色车间或绿色工厂称号，获得能源管理体系、碳排放管理体系、产品碳足迹等认证
                <br>C.企业或产品获得区级及以上技术创新、品牌、质量、专利、人才等奖项、称号认定或市级及以上行业协会重大奖项和称号认定
                <br>D.企业有改制上市计划，已完成股份制改制
              </td>
              <td class="core">{{ (result[17] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td rowspan="4">创新能力指标</td>
              <td>12.与企业主导产品相关的有效知识产权数量（10分）
                <br>A.I类高价值知识产权1项以上（10分）
                <br>B.自主研发I类知识产权1项以上（8分）
                <br>C.I类知识产权1项以上（6分）
                <br>D.II类知识产权1项以上（2分）
                <br>E.无（0分）
              </td>
              <td class="core">{{ (result[18] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>13.上年度研发费用投入（10分）
                <br>A.支出＞500万元或强度＞10%（10分）
                <br>B.支出400-500万元或强度8-10%（8分）
                <br>C.支出300-400万元或强度6-8%（6分）
                <br>D.支出200-300万元或强度4-6%（4分）
                <br>E.支出100-200万元或强度3-4%（2分）
                <br>F.不属于以上情况（0分）
              </td>
              <td class="core">{{ (result[19] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>14.上年度研发人员占比（5分）<br>
                A.20%以上 （5分）
                B.10-20% （3分）
                C.5-10%（1分）
                D.5%以下（0分）
              </td>
              <td class="core">{{ (result[20] || {}).score || 0 }}</td>
            </tr>
            <tr>
              <td>15.建立研发机构级别（10分）<br>
                A.国家级（10分）
                B.市级（8分）
                C.区级（4分）
                D.未建立研发机构（0分）
              </td>
              <td class="core">{{ (result[21] || {}).score || 0 }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="result-total">评价总得分： <span>{{ total }}</span> 分</div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "result_2"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score
  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>


<style scoped lang="scss">
.header {
  width: 1140px;
  margin: 35px auto 0;

  .title {
    font-size: 75px;
    color: #F2F2F2;
    font-weight: bold;
    position: relative;
    text-align: left;
    margin: 0;

    .text {
      font-size: 32px;
      color: #2C3E50;
      position: absolute;
      left: 50px;
      top: 25px;
    }
  }
}

.main-container {
  width: 1140px;
  margin: auto;
  padding-bottom: 100px;
  text-align: left;
  color: #101010;

  .container-title {
    padding: 50px 0 40px 0;
    font-size: 20px;
    color: #101010;

    span {
      display: inline-block;
      width: 8px;
      height: 20px;
      background: #5C7EF4;
      vertical-align: middle;
    }
  }

  .result-boolean {
    .title {
      font-size: 18px;

      span {
        color: red;
      }
    }

    .table {
      width: 100%;
      margin: 20px 0 40px;

      table {
        width: 100%;
        border-collapse: collapse;
      }

      table, th, td {
        border: 1px solid #378DD3;
        padding: 10px;
        line-height: 30px;
        font-size: 14px;
        font-weight: bold;
      }

      th {
        font-size: 16px;
        font-weight: bolder;
        text-align: center;
        color: #ffffff;
        background-color: #378DD3;
      }

      td:nth-child(2) {
        text-align: center;
      }
    }
  }

  .result-score {
    .title {
      font-size: 16px;

      span {
        color: red;
      }
    }

    .subtitle {
      margin-top: 10px;
      font-size: 14px;
    }

    .table {
      width: 100%;
      margin: 20px 0 40px;

      table {
        width: 100%;
        border-collapse: collapse;
      }

      table, th, td {
        border: 1px solid #DDDDDD;
        padding: 10px;
        line-height: 30px;
        color: #031A40;
      }

      th {
        font-size: 16px;
        font-weight: bolder;
        text-align: center;
        background-color: #DDDDDD;
      }
      .core {
        text-align: center;
      }
    }
  }

  .result-total {
    font-weight: bold;
    span {
      color: red;
    }
  }

  .td-red {
    color: red;
  }
}
</style>
