<template>
  <div class="common-layout fixed">
    <el-container>
      <HeaderView title="用户中心-修改密码"/>
      <el-main>
        <div class="mobile-tabs">
          <ul>
            <li v-on:click='$router.push({path:"/mobile/account"})'>企业信息</li>
            <li v-on:click='$router.push({path:"/mobile/account/applied"})'>我的申请</li>
            <li v-on:click='$router.push({path:"/mobile/account/password"})' class='active'>修改密码</li>
          </ul>
        </div>
        <div class="mobile-container">
          <el-form
              ref="passwordRef"
              :model="passwordForm"
              :rules="passwordRules"
              size="large"
              :inline="false"
              label-width="125px"
              class="demo-form-inline">
            <div class='title'><span></span>修改密码</div>
            <p>注：验证原密码，输入新密码完成修改。</p>

            <el-form-item label="原密码" prop="oldPassword">
              <el-input v-model="passwordForm.oldPassword"
                        type="password"
                        auto-complete="off"
                        placeholder="请输入原密码" />
            </el-form-item>
            <el-form-item label="新密码" prop="password">
              <el-input v-model="passwordForm.password"
                        type="password"
                        auto-complete="off"
                        placeholder="请输入新密码" />
            </el-form-item>
            <el-form-item label="再次输入新密码" prop="repeatPassword">
              <el-input v-model="passwordForm.repeatPassword"
                        type="password"
                        auto-complete="off"
                        placeholder="请再次输入新密码" />
            </el-form-item>
            <div style="text-align: center">
              <el-button
                  :loading="loading"
                  size="large"
                  class='submit'
                  @click.prevent="submitForm"
              >
                <span v-if="!loading">确认保存</span>
                <span v-else>保存中...</span>
              </el-button>
            </div>

          </el-form>
        </div>
      </el-main>
      <el-footer>
      </el-footer>
    </el-container>
  </div>
</template>

<script setup>
import HeaderView from '@/components/mobile/Header'
import {getCurrentInstance, onMounted, ref} from "vue";
import {resetPassword} from "@/api/account";
import useUserStore from "@/store/modules/user";
import {ElMessageBox} from "element-plus";
import router from "@/router";
const userStore = useUserStore()
const { proxy } = getCurrentInstance();
const loading=ref(false)
const passwordForm = ref({
  oldPassword: "",
  password: "",
  repeatPassword: ""
})
const equalToPassword = (rule, value, callback) => {
  if (passwordForm.value.password !== value) {
    callback(new Error("两次输入的密码不一致"));
  } else {
    callback();
  }
};

const passwordRules = {
  oldPassword: [
    { required: true, trigger: "blur", message: "请输入原密码" },
  ],
  password: [
    { required: true, trigger: "blur", message: "请输入您的密码" },
    { min: 5, max: 20, message: "用户密码长度必须介于 5 和 20 之间", trigger: "blur" }
  ],
  repeatPassword: [
    { required: true, trigger: "blur", message: "请再次输入您的密码" },
    { required: true, validator: equalToPassword, trigger: "blur" }
  ],
};

function submitForm() {
  proxy.$refs.passwordRef.validate(valid => {
    if (valid) {
      loading.value = true;
      resetPassword(passwordForm.value).then(res => {
        loading.value = false;
        ElMessageBox.alert("密码修改成功，请重新登录", "系统提示", {
          dangerouslyUseHTMLString: true,
          type: "success",
        }).then(() => {
          userStore.logOut().then(() => {
            router.push("/login");
          })
        }).catch(() => {
        });
      }).catch((res) => {
        loading.value = false;
      });
    }
  });
}

onMounted(() => {
  //document.title = "企业综合服务系统"
})
</script>

<style lang="scss" scoped>
.common-layout {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;

  .el-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.fixed {
    .el-container {
      flex: 1;
    }

    .el-footer {
      --el-footer-height: unset;
    }
  }
  .el-footer {
    --el-footer-padding: 0;
    margin-top: 100px;
  }

  .el-main {
    padding: 0 10px;
    .mobile-tabs{
      float: left;
      display: inline-block;

      ul{
        padding: 20px 0 30px;
        margin: 0;
        li {
          display: inline;
          list-style: none;
          text-align: center;
          background: #DDDDDD;
          color: #101010;
          margin-right: 5px;
          font-size: 14px;
          padding: 8px 20px;
        }
        .active{
          background: #5C7EF4;
          color: #ffffff;
          font-size: 14px;
        }
      }
    }
    .mobile-container{
      width: 100%;
      display: inline-block;
      text-align: left;

      .title{
        padding: 0 0 20px 0;
        font-size: 21px;
        color: #000000;

        span{
          display: inline-block;
          width: 8px;
          height: 22px;
          background: #5C7EF4;
          vertical-align: middle;
        }
      }

      p{
        color: #ff0000;
        font-size: 13px;
        margin: 0;
        padding: 0 0 20px 0;
      }

      button{
        width: 85%;
        height: 45px;
        line-height: 20px;
        border-radius: 5px;
        //background: linear-gradient(264.2deg, rgba(185,145,107,1) 1.7%,rgba(228,200,149,1) 93.74%);
        background: #025CE2;
        color: rgba(255, 255, 255, 100);
        font-size: 18px;
        text-align: center;
      }
    }
  }
}
</style>
