<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>上海市科技型中小企业技术创新资金项目</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>业上年度营业收入不超过3000万元</td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>已完成的研发费用总额不低于50万元，且研发费用占营业收入比例不低于5%</td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>职工总数不超过300人，其中直接从事研究开发的科技人员占比不低于10%</td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>优先支持具备以下条件的项目：<br>
                1、国家重点支持的高新技术领域中自主创新性强、技术含量高、市场前景好、能够培育成新的经济增长点的项目<br>
                2、拥有自主知识产权的科技成果转化、在关键共性技术、前沿引领技术、现代工程技术和颠覆性技术等方面取得新突破的项目<br>
                3、以企业为主体的产、学、研联合创新项目，高新技术园区、大学科技园、各类孵化和产业化基地的创新创业项目，创投机构投资的创新项目，相关创新创业赛事遴选的优秀项目等<br>
              </td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ShanghaiInnovationFund"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
