<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/feedback"/>
    <div class='main-container'>
      <Breadcrumb :items="[{name:'留言咨询',link:''}]"/>
      <div class="news-details">
        <el-form
            ref="feedbackRef"
            :model="feedbackForm"
            :rules="feedbackRules"
            size="large"
            label-width="250px">
          <el-form-item label="反馈内容" prop="content">
            <el-input v-model="feedbackForm.content"
                      auto-complete="off"
                      :rows="18"
                      type="textarea"
                      maxlength="300"
                      show-word-limit
                      style="width: 600px"
                      placeholder="请输入反馈您的具体需求或意见建议..." />
          </el-form-item>
          <el-form-item label="联系人" prop="name">
            <el-input v-model="feedbackForm.name"
                      auto-complete="off"
                      :prefix-icon="User"
                      style="width: 320px"
                      placeholder="请输入联系人" />
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="feedbackForm.phone"
                      auto-complete="off"
                      :prefix-icon="Phone"
                      style="width: 320px"
                      placeholder="请输入您的联系方式" />
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input
                v-model="feedbackForm.code"
                size="large"
                auto-complete="off"
                placeholder="请输入验证码"
                style="width: 200px"
                :prefix-icon="CircleCheck"
            >
            </el-input>
            <div>
              <img :src="codeUrl" @click="getCode"  style="height: 38px;margin-left: 20px;vertical-align: middle;" alt=""/>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button :loading="loading" size="large" class='submit' @click.prevent="submitForm">
              <span v-if="!loading">提交信息</span>
              <span v-else>提交中...</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script setup>
import HeaderView from '@/components/Header.vue'
import BottomView from '@/components/Bottom.vue'
import {getCurrentInstance, onMounted, ref} from "vue";
import MenuBar from "@/components/MenuBar.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import {feedbackAdd} from "@/api/feedback";
import {ElMessageBox} from "element-plus";
import {CircleCheck, Phone, User} from "@element-plus/icons-vue";
import {getCodeImg} from "@/api/login";

import useUserStore from '@/store/modules/user'
const userStore = useUserStore()

const codeUrl = ref("");
const captchaEnabled = ref(true);
const { proxy } = getCurrentInstance();
const loading=ref(false)
const feedbackForm = ref({
  content: "",
  name: "",
  phone: "",
  code: ""
})

const feedbackRules = {
  content: [
    { required: true, trigger: "blur", message: "请输入您的反馈内容" },
  ],
  name: [
    { required: true, trigger: "blur", message: "请输入联系人" },
  ],
  phone: [
    { required: true, trigger: "blur", message: "请输入您的联系方式" },
  ],
  code: [
    { required: true, trigger: "blur", message: "请输入验证码" },
  ],
};

function getCode() {
  getCodeImg().then(res => {
    captchaEnabled.value = res.captchaEnabled === undefined ? true : res.captchaEnabled;
    if (captchaEnabled.value) {
      codeUrl.value = "data:image/gif;base64," + res.img;
      feedbackForm.value.uuid = res.uuid;
    }
  });
}
function submitForm() {
  proxy.$refs.feedbackRef.validate(valid => {
    if (valid) {
      loading.value = true;
      feedbackAdd(feedbackForm.value).then(res => {
        loading.value = false;
        ElMessageBox.alert("您的反馈内容已成功提交至客服系统", "感谢您的反馈", {
          type: "success",
        })
        proxy.$refs.feedbackRef.resetFields();
        if (userStore.id!=null) {
          feedbackForm.value.name = userStore.companyName
          feedbackForm.value.phone = userStore.name
        }
      }).catch(() => {
        loading.value = false;
        // 重新获取验证码
        if (captchaEnabled.value) {
          getCode();
        }
      });
    }
  });
}

onMounted(() => {
  // //document.title = "企业综合服务系统"
  getCode()
  if (userStore.id!=null) {
    feedbackForm.value.name = userStore.companyName
    feedbackForm.value.phone = userStore.name
  }
})
</script>

<style lang="scss" scoped>
.banner {
  height: 770px;
  width: 100%;
}



.main-container {
  width: 1200px;
  margin: 30px auto;
  background-color: rgba(255, 255, 255, 1);
  padding: 15px 30px 35px 30px;
  box-sizing: border-box;

  .news-details {
    :deep .el-form-item--large .el-form-item__label {
      height: 40px;
      line-height: 40px;
      font-size: 15px;
      font-weight: 600;
    }

    .submit {
      width: 140px;
      height: 40px;
      margin-right: 20px;
      border: none;
      outline-color: #fff;
      background-color: #c3040f;
      font-size: 17px;
      line-height: 40px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      transition: all ease-in-out 0.2s;
    }
  }
}

</style>
