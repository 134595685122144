<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>园区知识产权集中托管项目申报工作</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>在本市注册并具有独立法人资格，且为房屋产权所有人的产业园区管委会或管理公司</td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>产业基础扎实。<br>
                园区主导产业符合本市战略性新兴产业和先导产业发展方向，拥有有效专利的中小微企业不少于100家，培育各级知识产权试点示范单位不少于3家</td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>知识产权管理体系健全。<br>
                园区建立领导牵头的知识产权组织协调工作机制，设立知识产权管理机构，配备专业知识产权管理人员（知识产权工作者，初、中、高级知识产权师，专利代理师），能够有效开展专利商标管理工作，具有一定的知识产权经费投入。%以上</td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>知识产权服务成效明显。<br>
                园区具有独立或协作开展知识产权服务的经验，协助园区企业实际开展过知识产权质押融资、保险、许可转让等运营工作或者高价值专利培育工作，维护园区良好的知识产权保护环境，园区企业近三年内无行政和司法程序认定的侵犯知识产权行为。</td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>已承担知识产权集中托管项目的单位自立项起三年内不再申报。</td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "ShanghaiInnovationFund"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
