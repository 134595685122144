<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>高新技术成果转化-认定评分明细</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>申报转化项目的高新技术成果须为基于核心技术研发，首次实现转化并形成样品、样机或服务的项目，同时应当符合以下条件：</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 120px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>申报项目不属于以下任何情形：
                <br>（一）一般性的工艺改进或产品改型所形成的产品（服务）；
                <br>（二）已形成规模销售（提供）的成熟产品（服务）；
                <br>（三）食品、保健品、饮料、化妆品、服装、家具、家用电器、工艺品、办公用品等常规日用产品。</td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>1、近两年无知识产权侵权行为，未发生重大安全、重大质量事故或严重环境违法、科研失信行为，且未列入严重违法失信名单。</td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>2、申报转化项目的高新技术成果须为基于核心技术研发，首次实现转化并形成样品、样机或服务的项目</td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>3、项目的核心技术属于《国家重点支持的高新技术领域》规定的范围</td>
              <td v-html='(result[3] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
<!--            <tr>-->
<!--              <td>3、获得 1 类、2 类新药的药品注册证书，且在新药监测期的项目</td>-->
<!--              <td v-html='(result[4] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>-->
<!--            </tr>-->
            <tr>
              <td>4、项目的核心技术拥有受中国法律保护的知识产权</td>
              <td v-html='(result[4] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
<!--            <tr>-->
<!--              <td>5、近一年内首次取得国内三类医疗器械注册证的项目，其核心技术获得发明专利授权</td>-->
<!--              <td v-html='(result[6] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>-->
<!--            </tr>-->
            <tr>
              <td>6、项目所形成的产品应有第三方出具的产品质量性能检测报告</td>
              <td v-html='(result[5] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>7、项目所形成的产品（服务）属于工商营业执照、事业单位法人证书的经营范围内</td>
              <td v-html='(result[6] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>8、项目的总体技术与其它同类产品（服务）相比具有良好的创新性和先进性，并具有潜在的经济效益和较好的市场前景内</td>
              <td v-html='(result[7] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "result_1"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score
  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>


<style scoped lang="scss">
.header {
  width: 1140px;
  margin: 35px auto 0;

  .title {
    font-size: 75px;
    color: #F2F2F2;
    font-weight: bold;
    position: relative;
    text-align: left;
    margin: 0;

    .text {
      font-size: 32px;
      color: #2C3E50;
      position: absolute;
      left: 50px;
      top: 25px;
    }
  }
}

.main-container {
  width: 1140px;
  margin: auto;
  padding-bottom: 100px;
  text-align: left;
  color: #101010;

  .container-title {
    padding: 50px 0 40px 0;
    font-size: 20px;
    color: #101010;

    span {
      display: inline-block;
      width: 8px;
      height: 20px;
      background: #5C7EF4;
      vertical-align: middle;
    }
  }

  .result-boolean {
    .title {
      font-size: 18px;

      span {
        color: red;
      }
    }

    .table {
      width: 100%;
      margin: 20px 0 40px;

      table {
        width: 100%;
        border-collapse: collapse;
      }

      table, th, td {
        border: 1px solid #378DD3;
        padding: 10px;
        line-height: 25px;
        font-size: 14px;
        font-weight: bold;
      }

      th {
        font-size: 16px;
        font-weight: bolder;
        text-align: center;
        color: #ffffff;
        background-color: #378DD3;
      }

      td:nth-child(2) {
        text-align: center;
      }
    }
  }

  .result-score {
    .title {
      font-size: 16px;

      span {
        color: red;
      }
    }

    .subtitle {
      margin-top: 10px;
      font-size: 14px;
    }

    .table {
      width: 100%;
      margin: 20px 0 40px;

      table {
        width: 100%;
        border-collapse: collapse;
      }

      table, th, td {
        border: 1px solid #DDDDDD;
        padding: 10px;
        line-height: 30px;
        color: #031A40;
      }

      th {
        font-size: 16px;
        font-weight: bolder;
        text-align: center;
        background-color: #DDDDDD;
      }

      .core {
        text-align: center;
      }
    }
  }

  .result-total {
    font-weight: bold;
    span {
      color: red;
    }
  }

  .td-red {
    color: red;
  }
}
</style>
