<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>浦东新区“明珠计划”高峰人才项目</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="false">
              <td>申报类型： 个人申报 / 项目申报
              </td>
              <td v-html='(result[0] || {}).option'></td>
            </tr>
            <tr>
              <td>个人申报标准：
                <br/>1、国际性重大科学技术奖项获得者。
                <br/>2、中国及其他重要创新国家科学技术奖项或学术荣誉获得者。
                <br/>3、国家级、上海市级人才计划重点项目入选者或相当层面的高层次人才。
                <br/>4、在世界知名大学、科研机构任职的科学家，或世界一流科技型企业的科学家、技术官等。
                <br/>5、担任国家或上海市重大科技基础设施、重大平台机构、重大科研任务、大科学计划(工程)的领军科学家。
                <br/>6、近5年内国家顶级期刊、顶级会议、顶级论坛等评出的杰出青年科技人才，包括但不限于:《麻省理工科技评论》年度35岁以下的全球最佳35名创新人才、世界经济论坛青年科学家、《科学》杂志年度青年科学家等。
                <br/>7、其他对引领区高端产业发展或科技创新策源具有战略性、决定性和带动性作用的人才。
              </td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>项目申报标准：
                <br/>1、属产业项目的，2年内新增投资额原则上应不低于1亿元，项目技术能级属于国际领先或先进，项目投资强度及产出效益一般应高于同产业领域30%以上。对于技术创新度高、具有重大产业化潜力和前景的可适当放宽以上要求。
                <br/>2、创新项目的，原则上须为2年内新立项项目，项目的实施将对于浦东新区加强基础研究和应用基础研究，打好关键核心技术攻坚战，加速科技成果向现实生产力转化，提升产业链水平具有重大引领性、突破性、示范性作用。
              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>支持对象年龄一般不超过70周岁，原则上须已在用人单位全职工作或已与用人单位签订全职工作合同或意向协议，并承诺入选后在用人单位全职工作不少于三年。
              </td>
              <td v-html='(result[2] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPearlPlanTalent"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([
  {option: ''}
])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score
  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
