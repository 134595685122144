<template>
  <div class="home">
    <HeaderView title=""/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class="condition high-new-tech-container">
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'测评表单',link:''}]"/>
      <div class="condition-form-tips">请您务必如实填写，否则影响测评结果！</div>
      <div class="condition-title">高新技术企业认定</div>
      <BasicInfo ref="basicInfo" :is-financial="true" :is-int-prop="true"/>
      <el-form
          ref="conditionForm"
          :model="formData"
          :label-position="'top'"
      >
        <el-collapse class="condition-el-collapse" ref="techCollapse" v-model="collapseVal">
          <el-collapse-item name="1">
            <template v-slot:title>
              <div class="item-title">填报表单</div>
            </template>
            <div class="form-module">
              <el-form-item label="技术的先进程度（单选）" required prop="technologyExtent">
                <template #label>
                  <el-tooltip placement="right">
                    <template #content>
                      <br>技术的先进性定义：先进性是指科技成果的技术水平必须是先进的。
                      <br>确定一项技术是否具有先进性，需用技术与此前的同类技术水平进行比较。
                      <br>证明该项技术具有突出的特点和明显的进步。新的技术必然是来源于现有的科技成果，而又将高于现有的科技成果。
                      <br><br><strong>评定一项技术成果是否具有技术先进性，主要从以下三个方面来进行：</strong>
                      <br>1）技术原理的进步；
                      <br>2）技术构成的进步；
                      <br>3）技术效果的进步；
                      <br><br><strong>相关证明材料：</strong>
                      <br>1）查新报告；
                      <br>2）专利检索报告；
                      <br>3）软件测试报告；
                      <br>4）应用单位证明材料；
                      <br>5）设计、工艺及质量检测；
                      <br>6）鉴定报告；
                      <br>7）研发项目鉴定报告；
                      <br>8）新技术新产品证书；
                      <br>9）经过第三方鉴定的技术合同
                    </template>
                    <span>
                          <span>技术的先进程度（单选）</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                  </el-tooltip>
                </template>
                <el-radio-group v-model="formData.technologyExtent">
                  <el-radio :label="1">高</el-radio>
                  <el-radio :label="2">较高</el-radio>
                  <el-radio :label="3">一般</el-radio>
                  <el-radio :label="4">较低</el-radio>
                  <el-radio :label="5">无</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="相关知识产权对企业主要产品（服务）在技术上发挥核心支持作用（单选）" required
                            prop="centralRole">
                <template #label>
                  <el-tooltip placement="right">
                    <template #content>
                      知识产权技术对主要产品（服务）在技术上发挥核心支持作用，可以从以下几方面判定：
                      <br><strong>一、是看知识产权的所有权。</strong>
                      <br>企业有完全所有权的，得分肯定比共有的支持作用强；共有者越多，支持作用也就越弱。
                      <br><br><strong>二、是看知识产权权利要求项与产品技术的符合度。</strong>
                      <br>这个很好理解，也就是我们是生产产品的技术如果能从知识产权权利要求项下找得到对应条款，
                      <br>也就说明该项产品技术与知识产权符合度高，知识产权对主要产品（服务）在技术上发挥核心支持作用就强。
                      <br><br><strong>三、是从知识产权的申请授权时间来判定。</strong>
                      <br>申报高企时，知识产权距离授权时间越长，就表明其对主要产品（服务）在技术上发挥核心支持作用是越强的。
                      <br>这是因为知识产权在维持 过程中也会交年费，所以时间越长，就表明了这一点。毕竟如果只是产权对主要
                      <br>产品（服务）在技术上发挥核心支持作用的效果不好，企业估计也不会继续缴纳年费。
                      <br><br><strong>四、是用该知识产权技术生产出来的产品情况米判定。</strong>
                      <br>因为技术只有转化成生产力才能体现其价值。所以我们可以从知识产权技术生产出来对应的产品的成本、效率、
                      <br>功能、维权、获奖等方面，与市场同类产品比较，判定知识产权对主要产品（服务）在技术上是否发挥了核心支持作用。
                      <br>还有就是也可以从技术领先性和含金量这一方面来作出相关评价，比如你所拥有的知识产权技术可以降低产品成本，
                      <br>比市场同类的低，那就能表明知识产权对主要产品（服务）在技术上发挥核心支持作用的强。
                    </template>
                    <span>
                          <span>相关知识产权对企业主要产品（服务）在技术上发挥核心支持作用（单选）</span>
                          <el-icon color="#1ab394">
                            <QuestionFilled/>
                          </el-icon>
                        </span>
                  </el-tooltip>
                </template>
                <el-radio-group v-model="formData.centralRole">
                  <el-radio :label="1">强</el-radio>
                  <el-radio :label="2">较强</el-radio>
                  <el-radio :label="3">一般</el-radio>
                  <el-radio :label="4">较弱</el-radio>
                  <el-radio :label="5">无</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="企业是否参与编制国家标准、行业标准、检测方法、技术规范的情况" required
                            prop="createStandard">
                <el-radio-group v-model="formData.createStandard">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="研究开发组织管理水平(可多选)" required prop="rdManageLevel" style="text-align: left">
                <el-checkbox-group v-model="formData.rdManageLevel">
                  <el-checkbox :label="1">制定了企业研究开发的组织管理制度</el-checkbox>
                  <el-checkbox :label="2">建立了研发投入核算体系</el-checkbox>
                  <el-checkbox :label="3">编制了研发费用辅助账</el-checkbox>
                  <br>
                  <el-checkbox :label="4">设立了内部科学技术研究开发机构并具备相应的科研条件</el-checkbox>
                  <el-checkbox :label="5">与国内外研究开发机构开展多种形式的产学研合作</el-checkbox>
                  <br>
                  <el-checkbox :label="6">建立了科技成果转化的组织实施与激励奖励制度</el-checkbox>
                  <el-checkbox :label="7">建立开放式的创新创业平台</el-checkbox>
                  <br>
                  <el-checkbox :label="8">建立了科技人员的培养进修职工技能培训、优秀人才引进</el-checkbox>
                  <el-checkbox :label="9">建立了人才绩效评价奖励制度</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </div>
            <div class="int-prop-list">
              <h2>知识产权明细</h2>
              <div class="table-data">
                <div class="table-header">
                  <div class="table-cell">知识产权编号</div>
                  <div class="table-cell">知识产权名称</div>
                  <div class="table-cell">类别</div>
                  <div class="table-cell">授权日期</div>
                  <div class="table-cell">授权号</div>
                  <div class="table-cell">获得方式</div>
                </div>
                <div class="table-row" v-for="(item,index) in formData.properties" :key="index">
                  <div class="table-cell">
                    <el-input v-model="item.serialNumber" placeholder="IP......"/>
                  </div>
                  <div class="table-cell">
                    <el-input v-model="item.name"/>
                  </div>
                  <div class="table-cell">
                    <el-select placeholder="请选择" v-model="item.category">
                      <el-option
                          v-for="item in intPropType"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="table-cell">
                    <el-date-picker clearable
                                    style="width:100%"
                                    v-model="item.day"
                                    type="date"
                                    value-format="YYYY-MM-DD"
                                    placeholder="请选择授权日期">
                    </el-date-picker>
                  </div>
                  <div class="table-cell">
                    <el-input v-model="item.code"/>
                  </div>
                  <div class="table-cell">
                    <el-select placeholder="请选择" v-model="item.method" clearable>
                      <el-option
                          v-for="item in accessType"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <el-tooltip content="行数不够，点击再加一行" placement="left">
                <span class="plus" @click="addPropertiesRows()">+</span>
              </el-tooltip>
              <el-tooltip content="行数太多，点击减一行" placement="right" v-if="formData.properties.length>5">
                <span class="plus" @click="delPropertiesRows()">-</span>
              </el-tooltip>
            </div>
            <div class="int-prop-act-list">
              <h2>企业研究开发活动情况表（近三年执行的活动，按单一活动填报）</h2>
              <div class="table-data">
                <div class="table-header">
                  <div class="table-cell">研发活动编号</div>
                  <div class="table-cell">研发活动名称</div>
                  <div class="table-cell">开始日期</div>
                  <div class="table-cell">结束日期</div>
                  <div class="table-cell">技术领域
                    <el-tooltip content="《国家重点支持的高新技术领域》中规定的八大领域。"
                                placement="top-start">
                      <el-icon>
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">技术来源
                    <el-tooltip
                        content="企业自有技术，其他企业技术，中央属科研院所，地方属科研院所，大专院校，引进技术本企业消化创新，国外技术。"
                        placement="top-start">
                      <el-icon>
                        <QuestionFilled/>
                      </el-icon>
                    </el-tooltip>
                  </div>
                  <div class="table-cell">知识产权编号</div>
                </div>
                <div class="table-row" v-for="(item,index) in formData.smeCompanyResearchDevActivities" :key="index">
                  <div class="table-cell">
                    <el-input v-model="item.rdNumber" placeholder="RD......"/>
                  </div>
                  <div class="table-cell">
                    <el-input v-model="item.rdName"/>
                  </div>
                  <div class="table-cell">
                    <el-date-picker clearable
                                    style="width:100%"
                                    v-model="item.beginDate"
                                    type="date"
                                    value-format="YYYY-MM-DD"
                                    placeholder="请选择开始日期">
                    </el-date-picker>
                  </div>
                  <div class="table-cell">
                    <el-date-picker clearable
                                    style="width:100%"
                                    v-model="item.endDate"
                                    type="date"
                                    value-format="YYYY-MM-DD"
                                    placeholder="请选择结束日期">
                    </el-date-picker>
                  </div>
                  <div class="table-cell">
                    <el-select v-model="item.technicalField" clearable>
                      <el-option value="一、电子信息" label="一、电子信息"/>
                      <el-option value="二、生物与新医药" label="二、生物与新医药"/>
                      <el-option value="三、航空航天" label="三、航空航天"/>
                      <el-option value="四、新材料" label="四、新材料"/>
                      <el-option value="五、高技术服务" label="五、高技术服务"/>
                      <el-option value="六、新能源与节能" label="六、新能源与节能"/>
                      <el-option value="七、资源与环境" label="七、资源与环境"/>
                      <el-option value="八、先进制造与自动化" label="八、先进制造与自动化"/>
                    </el-select>
                  </div>
                  <div class="table-cell">
                    <el-select v-model="item.technicalSource" clearable>
                      <el-option value="企业自有技术" label="企业自有技术"/>
                      <el-option value="其他企业技术" label="其他企业技术"/>
                      <el-option value="中央属科研院所" label="中央属科研院所"/>
                      <el-option value="地方属科研院所" label="地方属科研院所"/>
                      <el-option value="大专院校" label="大专院校"/>
                      <el-option value="引进技术本企业消化创新" label="引进技术本企业消化创新"/>
                      <el-option value="国外技术" label="国外技术"/>
                    </el-select>
                  </div>
                  <div class="table-cell">
                    <el-input v-model="item.ipNumber" placeholder="IP......"/>
                  </div>
                </div>
              </div>
              <el-tooltip content="行数不够，点击再加一行" placement="right">
                <span class="plus" @click="addRows()">+</span>
              </el-tooltip>
              <el-tooltip content="行数太多，点击减一行" placement="right"
                          v-if="formData.smeCompanyResearchDevActivities.length>5">
                <span class="plus" @click="delRows()">-</span>
              </el-tooltip>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <div class="condition-form-btn">
        <el-tooltip effect="dark" content="临时保存未完成的表单内容" placement="top-end">
          <el-button type="success" :disabled="submitBtn" class="btn-submit" @click="saveForm(conditionForm)"
                     :icon="Finished" :loading="loading">
            临时保存
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="提交表单并进行系统测评" placement="top-end">
          <el-button type="danger" :disabled="submitBtn" class="btn-submit" @click="submitForm(conditionForm)"
                     :icon="CircleCheckFilled" :loading="loading">
            提交并认定
          </el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="查看上次提交测评的结果" placement="top-end">
          <router-link v-if="isResult" :to="`/authentication/result`" style="margin-left: 10px">
            <el-button type="primary" class="btn-submit" :icon="Promotion" :loading="loading">查看测评结果</el-button>
          </router-link>
        </el-tooltip>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "HighNewTech"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import BasicInfo from '@/components/BasicInfo'
import Breadcrumb from '@/components/Breadcrumb'
import {CircleCheckFilled, Finished, Promotion, QuestionFilled} from "@element-plus/icons-vue";
import {onMounted, ref} from "vue";
import {errorMsg, successMsg} from "@/utils/customizeMsg";
import router from "@/router";
import {useRoute} from "vue-router";
import companyApi from "@/api/company";

const collapseVal = ref(['1'])
const basicInfo = ref()
const urlPath = 'highNewTech'
const intPropType = ref([{
      value: '1',
      label: '发明专利'
    }, {
      value: '2',
      label: '植物新品种'
    }, {
      value: '3',
      label: '国家级农作物品种'
    }, {
      value: '4',
      label: '国家一级中药保护品种'
    }, {
      value: '5',
      label: '集成电路布图设计专有权'
    }, {
      value: '6',
      label: '实用新型'
    }, {
      value: '7',
      label: '外观设计'
    }, {
      value: '8',
      label: '软件著作权'
    }]
)

const accessType = ref([{
      value: 1,
      label: '自主研发'
    }, {
      value: 2,
      label: '受让'
    }, {
      value: 3,
      label: '受赠'
    }, {
      value: 4,
      label: '并购'
    }, {
      value: 5,
      label: '5年以上的独占许可'
    }]
)

const route = useRoute()
const evaluationType = ref(route.query.code)

const conditionForm = ref()
const formData = ref({
  technologyExtent: 3,
  centralRole: 3,
  createStandard: 0,
  rdManageLevel: [],
  properties: [],
  smeCompanyResearchDevActivities: []
})
onMounted(() => {
  getConditionByPath()
})
const getConditionByPath = () => {
  companyApi.getConditionByPath(urlPath).then((resp) => {
    if (resp.data && resp.data.id) {
      formData.value = resp.data
    }
  }).then(() => {
    formData.value.properties = formData.value.properties || []
    formData.value.smeCompanyResearchDevActivities = formData.value.smeCompanyResearchDevActivities || []
    formData.value.rdManageLevel = formData.value.rdManageLevel || []
    createRows()
    createProperties()
  })
}

const loading = ref(false)
const isResult = ref(true)
const submitBtn = ref(false)
const saveForm = function (formEl) {
  if (!formEl) return
  submitBtn.value = true
  loading.value = true
  const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
  return companyApi.saveConditionByPath(urlPath, company).then((resp) => {
    if (resp.code == 200) {
      successMsg(resp.msg)
      basicInfo.value.getBasicInfo()
      getConditionByPath()
    } else {
      errorMsg(resp.msg)
    }
    submitBtn.value = false
    loading.value = false
  }).catch(() => {
    submitBtn.value = false
    loading.value = false
  })
}
const submitForm = function (formEl) {
  basicInfo.value.saveForm(basicInfo.value.basicInfoForm).then(() => {
    const company = {...basicInfo.value.formData, condition: formData.value, evaluationType: evaluationType.value}
    if (basicInfo.value.saveFormValid) {
      if (!formEl) return
      submitBtn.value = true
      loading.value = true

      formEl.validate((valid, fields) => {
        if (valid) {
          return companyApi.submitConditionByPath(urlPath, company).then((resp) => {
            submitBtn.value = false
            loading.value = false
            if (resp.code === 200)
              router.push("/authentication/result")
          }).catch(() => {
            submitBtn.value = false
            loading.value = false
          })
        } else {
          Object.keys(fields).forEach((key, i) => {
            const propName = fields[key][0].field
            if (i === 0) {
              formEl.scrollToField(propName)
            }
          })
          errorMsg("您有必填项没有填写，请仔细检查。")
          submitBtn.value = false
          loading.value = false
          return false
        }
      })
    }
  })
}

const createProperties = () => {
  const propertiesLength = formData.value.properties.length
  const rows = propertiesLength >= 5 ? propertiesLength : 5
  for (let i = propertiesLength; i < rows; i++) {
    formData.value.properties.push({})
  }
}

const addPropertiesRows = () => {
  formData.value.properties.push({})
}

const delPropertiesRows = () => {
  const n = formData.value.properties.length
  if (n > 5) {
    formData.value.properties.splice(n - 1, 1);
  }
}

const createRows = () => {
  const length = formData.value.smeCompanyResearchDevActivities.length
  const rows = length >= 5 ? length : 5
  for (let i = length; i < rows; i++) {
    formData.value.smeCompanyResearchDevActivities.push({})
  }
}

const addRows = () => {
  formData.value.smeCompanyResearchDevActivities.push({})
}

const delRows = () => {
  const n = formData.value.smeCompanyResearchDevActivities.length
  if (n > 5) {
    formData.value.smeCompanyResearchDevActivities.splice(n - 1, 1);
  }
}
</script>
<style scoped lang="scss">
.high-new-tech-container {
  .int-prop-list, .int-prop-act-list {
    margin-bottom: 25px;

    h2 {
      text-align: left;
      font-size: 16px;
      color: #101010;
    }

    .plus {
      display: inline-block;
      padding: 3px 20px;
      border: 1px solid #ebeef5;
      border-top: none;
      border-radius: 0 0 5px 5px;
      cursor: pointer;
      margin: 0 5px;
    }

    .table-data {
      width: 99%;
      height: 100%;
      border: 1px solid #ebeef5;
      $odd-color: #F2F2F2;
      $even-color: #FFFFFF;

      .table-header {
        background-color: $odd-color;
      }

      .table-header, .table-row {
        height: 40px;
        line-height: 38px;
      }

      .table-header {
        .table-cell {
          display: inline-block;
          text-align: center;
          color: #101010;
          font-weight: 600;
          font-size: 14px;
        }
      }

      .table-row {
        .table-cell {
          display: inline-block;
          text-align: left;
          font-size: 14px;
        }
      }

      .table-cell {
        &:nth-child(1) {
          width: 14.6%;
          padding: 0 1%
        }

        &:nth-child(2) {
          width: 14.6%;
          padding: 0 1%
        }

        &:nth-child(3) {
          width: 14.6%;
          padding: 0 1%
        }

        &:nth-child(4) {
          width: 14.6%;
          padding: 0 1%
        }

        &:nth-child(5) {
          width: 14.6%;
          padding: 0 1%
        }

        &:nth-child(6) {
          width: 14.6%;
          padding: 0 1%
        }
      }
    }
  }

  .int-prop-act-list {
    .table-data {
      .table-cell {
        &:nth-child(1) {
          width: 12%;
          padding: 0 1%
        }

        &:nth-child(2) {
          width: 12.2%;
          padding: 0 1%
        }

        &:nth-child(3) {
          width: 12.7%;
          padding: 0 1%
        }

        &:nth-child(4) {
          width: 12.7%;
          padding: 0 1%
        }

        &:nth-child(5) {
          width: 12.2%;
          padding: 0 1%
        }

        &:nth-child(6) {
          width: 12.2%;
          padding: 0 1%
        }

        &:nth-child(7) {
          width: 12%;
          padding: 0 1%
        }
      }
    }
  }

}

.condition-el-collapse {
  border-bottom: unset;

  .item-title {
    border-left: 7px solid #308bd8;
    line-height: 40px;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
    width: 100%;
    text-align: left;
  }

  :deep .el-collapse-item__header {
    background-color: #fafafa;
    height: 40px;
    margin-bottom: 40px;
  }

  :deep .el-collapse-item__arrow:before {
    content: "展开";
  }

  :deep .el-collapse-item__arrow.is-active:before {
    content: "收起";
  }

  :deep .el-collapse-item__arrow.is-active {
    transform: rotate(0deg);
  }

  :deep .el-collapse-item__arrow {
    color: rgba(48, 139, 216, 1);
    font-size: 16px;
    text-align: left;
    width: 45.09px;
    height: 23px;
    line-height: 23px;
    font-style: unset;

    svg {
      display: none;
    }
  }

  :deep .el-form-item__label {
    color: #101010;
    font-weight: 700;
  }
}
</style>
