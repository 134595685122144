<template>
  <div class="home">
    <HeaderView/>
    <MenuBar menuBarActive="/project-declaration"/>
    <div class='condition result-main-container'>
      <Breadcrumb :items="[{name:'项目测评',link:'/project-declaration'},{name:'评分明细',link:''}]"/>
      <div class='container-title'><span></span>浦东新区促进高端装备产业高质量发展专项</div>
      <div class="result-boolean">
        <div class="title"><span>*</span>需要同时满足下列条件，才适用于申报</div>
        <div class="table">
          <table>
            <thead>
            <tr>
              <th>评定项目</th>
              <th style="width: 100px">是否满足</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>工商注册、税收户管均需在浦东新区。申报企业应内部治理结构规范、财务管理制度健全、经营状态正常、信用记录良好，具有独立承担民事责任能力(分支机构除外)。
              </td>
              <td v-html='(result[0] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            <tr>
              <td>
                1、支持整配联动。<br>对为重大装备整机企业首批次配套供应
                具有自主知识产权、可替代国外同类产品的核心零部件企业，按
                与整机企业签订的同一产品首批次合同，给予不超过合同金额
                10%的奖励，单个企业最高支持额度不超过 500 万元。
                <br>2、鼓励智能网联汽车创新应用。<br>支持企业开展智能网联汽
                车改装，给予不超过改装成本 30%的补贴，每家企业每年不超过
                200 万元。支持企业开展智能网联汽车创新应用，给予不超过投
                入成本 10%的补贴，每家企业每年不超过 200 万元。企业申请智
                能网联汽车改装或智能网联汽车创新应用，政策支持从优不重复。
                <br>3、鼓励无人驾驶装备创新应用。<br>支持取得资质的无人驾驶
                装备企业开展无人驾驶装备创新应用，根据示范应用效果给予每
                家企业每年不超过 200 万元的奖励。
                <br>4、支持智能工厂建设。<br>对经上海市相关部门评选认定的上
                海市标杆性智能工厂和上海市智能工厂，分别给予企业 50 万元、
                20 万元的一次性奖励，升级给予差额奖励。
                <br>5、智能工厂评估诊断补贴。<br>对于向评估诊断服务机构和数
                字化转型服务商购买智能制造能力成熟度诊断服务，产值规模 1
                亿元以下的规上工业企业，报告经评估合格后，依据合同实际金
                额给予全额补贴，最高不超过 4 万元。鼓励产值规模 1 亿元以上
                的规上工业企业申请享受市“智评券”政策，对于列入市级“智
                评券”政策支持的工业企业，按照与市级扶持资金最高 1:1 的比
                例给予配套支持，市、区两级总支持金额不超过 4 万元。
                <br>6、配套支持。<br>对获得上海市级政策支持的高端智能装备首
                台套突破项目，可在项目验收完成后，给予一定配套奖励。
                对明确配套要求的，按市级部门立项时明确的区级支持要求
                给予配套支持。对未明确配套要求的，经区相关部门协商一致，
                按照企业获得的上海市级项目扶持资金总额，给予不超过企业获
                得上海市级项目扶持资金 50%的奖励。同一项目各级政府奖励和
                资助总额不超过企业总投入的 50%，最高 5000 万元。

              </td>
              <td v-html='(result[1] || {}).satisfy===1?"是":`<span style="color: red">否</span>`'></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <BottomView/>
  </div>
</template>

<script>
export default {
  name: "pudongPromoteDevelopmentOfHighendEquipment"
}
</script>
<script setup>
import HeaderView from '@/components/Header'
import BottomView from '@/components/Bottom'
import MenuBar from '@/components/MenuBar'
import Breadcrumb from '@/components/Breadcrumb'
import {onBeforeMount, ref} from "vue";
import {getResultDetail} from "@/api/authentication";
import {useRouter} from "vue-router/dist/vue-router";

const result = ref([])
const router = useRouter()
const total = ref()
onBeforeMount(() => {

  const query = router.currentRoute.value.query
  total.value = query.score

  getResultDetail({"evaluationType": query.evaluationType}).then((res) => {
    result.value = res.data
  }).catch(() => {
  })
})
</script>

<style scoped lang="scss">
</style>
